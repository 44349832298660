import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'

import Auth from './components/Auth'
import ErrorPage from './components/ErrorsPage/index'
import ERRORS from './utils/data/errors'
import GroupEditCard from './components/UserGroups/EditCard'
import Header from './components/Header/Header'
import MainPage from './components/MainPage'
import OfficesRouter from './components/Office/OfficesRouter'
import UserCard from './components/Users/index'
import UsersGroupsRouter from './components/UserGroups/Router'
import { GET_ACCOUNT } from './actions/account'
import Notifications from './components/Notifications'
import UserBooking from './components/Reservation/userScreen'

class App extends React.Component {
  state = { isNotificationPanelVisible: false }

  componentDidMount() {
    window.location.pathname !== '/auth' && this.props.getAccount()
  }

  toggleNotificationPanelVisibility = () => this.setState(prevState => ({
    isNotificationPanelVisible: !prevState.isNotificationPanelVisible,
  }))

  render() {
    const { isNotificationPanelVisible } = this.state
    const { pathname } = this.props.location
    return (
      <div className='app_BG'>
        {pathname !== '/auth' && (
          <Header
            onMenuClick={this.toggleNotificationPanelVisibility}
            isMenuVisible={isNotificationPanelVisible}
          />
        )}
        <div className='screen__no_grid'>
          <Switch>
            <Route path='/' exact component={MainPage}>
              <Redirect to='/offices' />
            </Route>
            <Route
              exact
              path='/groups/new'
              render={props => <GroupEditCard {...props} isNew />}
            />
            <Route exact path='/auth' component={Auth} />
            <Route
              exact
              path='/404'
              render={props => <ErrorPage {...props} error={ERRORS['404']} />}
            />
            <Route
              exact
              path='/500'
              render={props => <ErrorPage {...props} error={ERRORS['500']} />}
            />
            <Route exact path='/users' component={UserCard} />
            <Route path='/users/:id/booking' component={UserBooking} />
            <Route path='/map' component={MainPage} />
            <Route path='/notifications' component={Notifications} />
            <Route path='/groups' component={UsersGroupsRouter} />
            <Route path='/offices' component={OfficesRouter} />
            <Route render={props => <ErrorPage {...props} error={ERRORS['404']} />} />
          </Switch>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getAccount: () => dispatch({ type: GET_ACCOUNT }),
})

export default withRouter(connect(null, mapDispatchToProps)(App))
