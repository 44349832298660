import React from 'react'
import { CSSTransition } from 'react-transition-group'

const FadeTransition = ({ loading, children, style }) => (
  <CSSTransition
    in={loading}
    classNames='fade_block'
    timeout={600}
    appear
    mountOnEnter
    unmountOnExit
    style={style}
  >
    {children}
  </CSSTransition>
)

export default FadeTransition
