/* Ф-ия ниже делает линейное изменение любой величины (изменение зависит от x в возвращаемой функции).
Например, при зуме 0,25 - радиус иконки должен быть 8% от карты, а при зуме 1 - размер должен быть 3% от карты.
запускаем функцию вот так: equationStraightLineGenerate(0.25, 8, 1, 3)(scale),
где scale - это зум. Ф-ия возвращает линейно изменяющийся размер иконки в зависимости от зума.

Так, при зуме 0.5 - размер иконки будет 6%, а при зуме 0.3 - размер будет 7,68%
*/

export const equationStraightLineGenerate = (x1, y1, x2, y2) => {
  const firstMultiplier = -(y1 - y2) / (x2 - x1);
  const secondMultiplier = -(x1 * y2 - x2 * y1) / (x2 - x1);

  return (x) => firstMultiplier * x + secondMultiplier;
};