import {
  CLEAR_GROUP,
  DELETE_GROUP,
  GET_ACCESS_GROUP,
  GET_ACCESS_GROUPS,
  IMPORT_GROUPS,
  GET_USERS,
} from '../users'

export const getGroupsList = () => ({ type: GET_ACCESS_GROUPS })

export const deleteGroup = (id, callback) => ({
  type: DELETE_GROUP,
  callback,
  id,
})

export const importGroups = (file, path, callback) => ({
  type: IMPORT_GROUPS,
  file,
  path,
  callback,
})

export const getUsersInGroup = (group, searchString = '') => ({
  type: GET_USERS,
  group,
  searchString,
})

export const getGroup = id => ({ type: GET_ACCESS_GROUP, id })

export const clearGroup = () => ({ type: CLEAR_GROUP })
