import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Button from '../../../../../UI/Button'
import EmptyPlaceholder from '../../../../../UI/EmptyPlaceholder'
import FloorListItem from './FloorListItem'
import handleUpload from '../../../../../Map/helpers/loadMap'
import ImportFromCsvModal from '../../helpers/ImportFromCsvModal'
import LargeSpin from '../../../../../UI/Spin/LargeSpin'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import SubtitleWithActions from '../../../../../UI/SubtitleWithActions'
import TableHeader from '../../../../../UI/TableHeader'
import TextModal from '../../../../../UI/modals/TextModal'
import UnderlinedTitle from '../../../../../UI/UnderlinedTitle'
import {
  DELETE_FLOOR,
  POST_MAP_TO_FLOOR_NEW_DESIGN,
  PUT_FLOOR,
  POST_FLOOR,
} from '../../../../../../actions/offices'

const FLOORS_LIST_COLUMNS_NAMES = ['Название этажа', 'Карта']
const COLUMNS_FLEX_VALUES = [1, 1]

const FloorsInfo = ({
  floors,
  loading,
  deleteFloor,
  history,
  match,
  postMap,
  putFloor,
  postFloor,
}) => {
  const [isAddSingleFloorInputVisible, setIsAddSingleFloorInputVisible] = useState(false)
  const [isImportFromCsvModalVisible, setIsImportFromCsvModalVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { current: office } = useRef(match.params.id)

  const handleFloorPost = floor => postFloor({ ...floor, office },
    () => setIsAddSingleFloorInputVisible(false))

  const handleAddSingleFloor = () => setIsAddSingleFloorInputVisible(val => !val)

  const handleAddMultipleFloors = () => setIsModalOpen(true)

  const handlePackageUpload = (titles, callback) =>
    postFloor({ title: titles, office }, callback, true)

  const renderImportFromCsvBtn = () => (
    <Button
      icon='icon__download_2'
      styles='rounded_btn__yellow'
      title='Импорт из .csv'
      onClick={() => setIsImportFromCsvModalVisible(true)}
    />
  )

  const renderFloorsList = () => floors.length || isAddSingleFloorInputVisible
    ? (
      <ScrollTransition loading>
        <div className='floors-list'>
          {isAddSingleFloorInputVisible && (
            <FloorListItem
              onCancel={handleAddSingleFloor}
              onSave={handleFloorPost}
              isNew
            />
          )}
          {floors.map(floor => (
            <FloorListItem
              floor={floor}
              key={floor.id}
              onDelete={() => deleteFloor(floor.id)}
              onMapUpload={e => handleUpload(e, floor.id, postMap)}
              onSave={(updatedFloor, callback) => putFloor(floor.id, updatedFloor, callback)}
              redirectToMap={floorID => history.push(`/offices/${match.params.id}/map/${floorID}`)}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Список этажей пуст' />

  return (
    <>
      <div className='flex_container__column' style={{ paddingRight: '10px' }}>
        <UnderlinedTitle title='ЭТАЖИ' />
        <SubtitleWithActions
          subtitle={`Всего этажей: ${loading ? '' : floors?.length || 0}`}
          // actions={renderImportFromCsvBtn()}
        />
        <div className='btn-group' style={{ margin: '12px 0 20px' }}>
          <Button
            onClick={handleAddSingleFloor}
            styles='btn_solid__light-blue btn-group__item'
            title={isAddSingleFloorInputVisible ? 'Отменить добавление этажа' : 'Добавить 1 этаж'}
          />
          <Button
            onClick={handleAddMultipleFloors}
            styles='btn_solid__light-blue btn-group__item'
            title='Добавить несколько'
          />
        </div>
      </div>

      <TableHeader
        columns={FLOORS_LIST_COLUMNS_NAMES}
        flexValues={COLUMNS_FLEX_VALUES}
        rightPaddingWidth={64}
      />
      {loading ? <LargeSpin /> : renderFloorsList()}

      <TextModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onSubmit={handlePackageUpload}
        title='ДОБАВИТЬ НЕСКОЛЬКО ЭТАЖЕЙ'
        subtitle='Введите названия этажей через запятую или числовой диапазон'
        hint='Пример: Цокольный этаж, 3..4, 6'
        submitTitle='Добавить этажи'
      />
      <ImportFromCsvModal
        form='floors'
        onClose={() => setIsImportFromCsvModalVisible(false)}
        start={isImportFromCsvModalVisible}
      />
    </>
  )
}

const mapStateToProps = ({ offices_newDesign: offices }) => {
  const { office = {} } = offices
  return ({ floors: office.floors || [], loading: office.loading })
}

const mapDispatchToProps = dispatch => ({
  deleteFloor: floorId => dispatch({ type: DELETE_FLOOR, floorId }),
  putFloor: (floorId, floor, callback) => dispatch({ type: PUT_FLOOR, floorId, floor, callback }),
  postFloor: (floor, callback, isPackageUpload) =>
    dispatch({ type: POST_FLOOR, floor, callback, isPackageUpload }),
  postMap: (floorId, map, sizes) =>
    dispatch({ type: POST_MAP_TO_FLOOR_NEW_DESIGN, floorId, map, sizes }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FloorsInfo))
