export const OFFICES_LOADING = 'OFFICE_LOADING'
export const OFFICES_LOADING_NEW_DESIGN = 'OFFICES_LOADING_NEW_DESIGN'

export const OFFICE_LOADING_NEW_DESIGN = 'OFFICE_LOADING_NEW_DESIGN'

export const GET_OFFICES = 'GET_OFFICES'
export const GET_OFFICES_NEW_DESIGN = 'GET_OFFICES_NEW_DESIGN'
export const GET_OFFICES_SUCCEED = 'GET_OFFICES_SUCCEED'
export const GET_OFFICES_SUCCEED_NEW_DESIGN = 'GET_OFFICES_SUCCEED_NEW_DESIGN'
export const GET_OFFICES_FAILED = 'GET_OFFICES_FAILED'
export const GET_OFFICES_FAILED_NEW_DESIGN = 'GET_OFFICES_FAILED_NEW_DESIGN'

export const SET_FLOORS_LOADING = 'SET_FLOORS_LOADING'
export const GET_OFFICE = 'GET_OFFICE'
export const GET_OFFICE_NEW_DESIGN = 'GET_OFFICE_NEW_DESIGN'
export const GET_OFFICE_LOCAL = 'GET_OFFICE_LOCAL'
export const GET_OFFICE_SUCCEED = 'GET_OFFICE_SUCCEED'
export const GET_OFFICE_SUCCEED_NEW_DESIGN = 'GET_OFFICE_SUCCEED_NEW_DESIGN'
export const GET_OFFICE_FAILED = 'GET_OFFICE_FAILED'
export const GET_OFFICE_FAILED_NEW_DESIGN = 'GET_OFFICE_FAILED_NEW_DESIGN'

export const DELETE_OFFICE = 'DELETE_OFFICE'
export const DELETE_OFFICE_SUCCEED = 'DELETE_OFFICE_SUCCEED'

export const GET_FLOORS = 'GET_FLOORS'
export const GET_FLOORS_SUCCEED = 'GET_FLOORS_SUCCEED'

export const SET_ROOMS_LOADING = 'SET_ROOMS_LOADING'
export const GET_ROOMS_LIST = 'GET_ROOMS_LIST'
export const GET_ROOMS_LIST_SUCCEED = 'GET_ROOMS_LIST_SUCCEED'

export const GET_FILTERED_ROOMS = 'GET_FILTERED_ROOMS'

export const SET_TABLES_LOADING = 'SET_TABLES_LOADING'
export const GET_TABLES = 'GET_TABLES'
export const GET_TABLES_SUCCEED = 'GET_TABLES_SUCCEED'

export const SET_TABLES_LOADING_NEW_DESIGN = 'SET_TABLE_LOADING_NEW_DESIGN'
export const GET_TABLES_NEW_DESIGN = 'GET_TABLES_NEW_DESIGN'
export const GET_TABLES_SUCCEED_NEW_DESIGN = 'GET_TABLES_SUCCEED_NEW_DESIGN'

export const CLEAN_TABLES = 'CLEAN_TABLES'
export const CLEAN_OFFICES_LIST = 'CLEAN_OFFICES_LIST'

export const CLEAN_OFFICE = 'CLEAN_OFFICE'
export const CLEAR_OFFICE_NEW_DESIGN = 'CLEAR_OFFICE_NEW_DESIGN'

export const SET_TABLE_TAGS_LOADING = 'SET_TABLE_TAGS_LOADING'
export const GET_TABLE_TAGS = 'GET_TABLE_TAGS'
export const GET_TABLE_TAGS_SUCCEED = 'GET_TABLE_TAGS_SUCCEED'

export const POST_OFFICE = 'POST_OFFICE'
export const POST_OFFICE_SUCCEED = 'POST_OFFICE_SUCCEED'
export const POST_OFFICE_NEW_DESIGN = 'POST_OFFICE_NEW_DESIGN'
export const POST_OFFICE_SUCCEED_NEW_DESIGN = 'POST_OFFICE_SUCCEED_NEW_DESIGN'

export const PUT_OFFICE = 'PUT_OFFICE'
export const PUT_OFFICE_SUCCEED = 'PUT_OFFICE_SUCCEED'
export const PUT_OFFICE_NEW_DESIGN = 'PUT_OFFICE_NEW_DESIGN'
export const PUT_OFFICE_SUCCEED_NEW_DESIGN = 'PUT_OFFICE_SUCCEED_NEW_DESIGN'

export const POST_ROOM = 'POST_ROOM'
export const POST_ROOM_SUCCEED = 'POST_ROOM_SUCCEED'

export const POST_ROOM_NEW_DESIGN = 'POST_ROOM_NEW_DESIGN'

export const POST_TABLE = 'POST_TABLE'
export const POST_TABLE_SUCCEED = 'POST_TABLE_SUCCEED'

export const POST_TABLE_NEW_DESIGN = 'POST_TABLE_NEW_DESIGN'

export const DELETE_TABLE = 'DELETE_TABLE'
export const DELETE_TABLE_SUCCEED = 'DELETE_TABLE_SUCCEED'

export const DELETE_TABLE_NEW_DESIGN = 'DELETE_TABLE_NEW_DESIGN'

export const DELETE_TABLES_NEW_DESIGN = 'DELETE_TABLES_NEW_DESIGN'

export const DELETE_ROOM = 'DELETE_ROOM'
export const DELETE_ROOM_SUCCEED = 'DELETE_ROOM_SUCCEED'
export const DELETE_ROOM_FROM_MAP = 'DELETE_ROOM_FROM_MAP'
export const DELETE_ROOM_FROM_MAP_SUCCEED = 'DELETE_ROOM_FROM_MAP_SUCCEED'

export const DELETE_ROOM_NEW_DESIGN = 'DELETE_ROOM_NEW_DESIGN'
export const DELETE_ROOM_SUCCEED_NEW_DESIGN = 'DELETE_ROOM_SUCCEED_NEW_DESIGN'

export const DELETE_ROOMS_NEW_DESIGN = 'DELETE_ROOMS_NEW_DESIGN'

export const PUT_ROOM = 'PUT_ROOM'
export const PUT_ROOM_SUCCEED = 'PUT_ROOM_SUCCEED'

export const PUT_ROOM_NEW_DESIGN = 'PUT_ROOM_NEW_DESIGN'
export const PUT_ROOM_NEW_DESIGN_SUCCEED = 'PUT_ROOM_NEW_DESIGN_SUCCEED'

export const PUT_TABLE = 'PUT_TABLE'
export const PUT_TABLE_SUCCEED = 'PUT_TABLE_SUCCEED'

export const PUT_TABLE_NEW_DESIGN = 'PUT_TABLE_NEW_DESIGN'

export const ACTIVATE_TABLE = 'ACTIVATE_TABLE'

export const SET_CURRENT_TABLE = 'SET_CURRENT_TABLE'
export const ACTIVATE_TABLE_SUCCEED = 'ACTIVATE_TABLE_SUCCEED'

export const GET_SEARCH_OFFICES_SUCCEED = 'GET_SEARCH_OFFICES_SUCCEED'
export const GET_SEARCH_OFFICES = 'GET_SEARCH_OFFICES'

export const DELETE_TABLE_TAG = 'DELETE_TABLE_TAG'
export const DELETE_TABLE_TAG_SUCCEED = 'DELETE_TABLE_TAG_SUCCEED'

export const POST_ZONE = 'POST_ZONE'
export const POST_ZONE_SUCCEED = 'POST_ZONE_SUCCEED'

export const PUT_ZONE = 'PUT_ZONE'
export const PUT_ZONE_SUCCEED = 'PUT_ZONE_SUCCEED'

export const DELETE_ZONE = 'DELETE_ZONE'
export const DELETE_ZONE_SUCCEED = 'DELETE_ZONE_SUCCEED'

export const POST_MAP_TO_FLOOR = 'POST_MAP_TO_FLOOR'
export const POST_MAP_TO_FLOOR_SUCCEED = 'POST_MAP_TO_FLOOR_SUCCEED'
export const POST_MAP_TO_FLOOR_NEW_DESIGN = 'POST_MAP_TO_FLOOR_NEW_DESIGN'
export const POST_MAP_TO_FLOOR_SUCCEED_NEW_DESIGN = 'POST_MAP_TO_FLOOR_SUCCEED_NEW_DESIGN'

export const DELETE_MAP = 'DELETE_MAP'
export const DELETE_MAP_SUCCEED = 'DELETE_MAP_SUCCEED'

export const ADD_MARKER = 'ADD_MARKER'
export const ADD_MARKER_SUCCEED = 'ADD_MARKER_SUCCEED'

export const DELETE_MARKER = 'DELETE_MARKER'
export const DELETE_MARKER_SUCCEED = 'DELETE_MARKER_SUCCEED'

export const DELETE_MARKERS_ON_FLOOR = 'DELETE_MARKERS_ON_FLOOR'
export const DELETE_MARKERS_ON_FLOOR_SUCCEED = 'DELETE_MARKERS_ON_FLOOR_SUCCEED'

export const POST_OFFICE_IMAGE = 'POST_OFFICE_IMAGE'
export const POST_OFFICE_IMAGE_SUCCEED = 'POST_OFFICE_IMAGE_SUCCEED'

export const POST_OFFICE_IMAGES_NEW_DESIGN = 'POST_OFFICE_IMAGES_NEW_DESIGN'
export const POST_OFFICE_IMAGES_SUCCEED_NEW_DESIGN = 'POST_OFFICE_IMAGES_SUCCEED_NEW_DESIGN'

export const DELETE_OFFICE_PHOTO = 'DELETE_OFFICE_PHOTO'

export const POST_ROOM_IMAGE = 'POST_ROOM_IMAGE'
export const POST_ROOM_IMAGE_SUCCEED = 'POST_ROOM_IMAGE_SUCCEED'
export const DELETE_ROOM_PHOTO = 'DELETE_ROOM_PHOTO'

export const GET_LICENSES = 'GET_LICENSES'
export const GET_LICENSES_SUCCEED = 'GET_LICENSES_SUCCEED'

export const POST_FLOOR = 'POST_FLOOR'
export const POST_FLOOR_NEW_DESIGN_SUCCEED = 'POST_FLOOR_SUCCEED'

export const PUT_FLOOR = 'PUT_FLOOR'
export const PUT_FLOOR_NEW_DESIGN_SUCCEED = 'PUT_FLOOR_SUCCEED'

export const DELETE_FLOOR = 'DELETE_FLOOR'
export const DELETE_FLOOR_SUCCEED = 'DELETE_FLOOR_SUCCEED'
export const DELETE_FLOOR_SUCCEED_NEW_DESIGN = 'DELETE_FLOOR_SUCCEED_NEW_DESIGN'

export const SET_OFFICE_TAGS_LOADING = 'SET_OFFICE_TAG_LOADING'
export const GET_OFFICE_TAGS = 'GET_OFFICE_TAGS'
export const GET_OFFICE_TAGS_SUCCEED = 'GET_OFFICE_TAGS_SUCCEED'
export const GET_OFFICE_TAGS_FAILED = 'GET_OFFICE_TAGS_FAILED'

export const POST_OFFICE_TAG = 'POST_OFFICE_TAG'
export const POST_OFFICE_TAG_SUCCEED = 'POST_OFFICE_TAG_SUCCEED'

export const PUT_OFFICE_TAG = 'PUT_OFFICE_TAG'
export const PUT_OFFICE_TAG_SUCCEED = 'PUT_OFFICE_TAG_SUCCEED'

export const POST_TAG_ICON_NEW_DESIGN = 'POST_IMAGE_NEW_DESIGN'
export const POST_TAG_ICON_SUCCEED_NEW_DESIGN = 'POST_TAG_ICON_SUCCEED_NEW_DESIGN'

export const DELETE_OFFICE_TAG = 'DELETE_OFFICE_TAG'
export const DELETE_OFFICE_TAG_SUCCEED = 'DELETE_OFFICE_TAG_SUCCEED'

export const POST_ROOM_TYPE = 'POST_ROOM_TYPE'
export const POST_ROOM_TYPE_SUCCEED = 'POST_ROOM_TYPE_SUCCEED'

export const PUT_ROOM_TYPE = 'PUT_ROOM_TYPE'
export const PUT_ROOM_TYPE_SUCCEED = 'PUT_ROOM_TYPE_SUCCEED'

export const DELETE_ROOM_TYPE = 'DELETE_ROOM_TYPE'
export const DELETE_ROOM_TYPE_SUCCEED = 'DELETE_ROOM_TYPE_SUCCEED'

export const SET_ROOM_TYPES_LOADING = 'SET_ROOM_TYPES_LOADING'
export const GET_ROOM_TYPES = 'GET_ROOM_TYPES'

export const GET_ROOM_TYPES_SUCCEED = 'GET_ROOM_TYPES_SUCCEED'
export const GET_ROOM_TYPES_FAILED = 'GET_ROOM_TYPES_FAILED'

export const POST_ROOM_TYPE_ICON = 'POST_ROOM_TYPE_ICON'
export const POST_ROOM_TYPE_ICON_SUCCEED = 'POST_ROOM_TYPE_ICON_SUCCEED'

export const SET_REFRESH_ROOMS_LOADING = 'SET_REFRESH_ROOMS_LOADING'
export const REFRESH_ROOMS = 'REFRESH_ROOMS'
export const REFRESH_ROOMS_SUCCESS = 'REFRESH_ROOMS_SUCCESS'

export const UPDATE_ADVANCED_SETTINS_RESPONSE = 'UPDATE_ADVANCED_SETTINS_RESPONSE'
export const UPDATE_ADVANCED_SETTINS_SUCCESS = 'UPDATE_ADVANCED_SETTINS_SUCCESS'
export const UPDATE_ADVANCED_SETTINS_FAILED = 'UPDATE_ADVANCED_SETTINS_FAILED'
