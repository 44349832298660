import React from 'react'

const BucketWithoutLid = ({
  style = {},
  className = 'icon__bucket-on-map',
  height = '18',
  width = '22',
  fill = 'white'
}) => (
  <div className={className} style={style} >
    <svg width={width} height={height} viewBox={`0 0 ${width * 2} ${height * 2}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.49219 3.57812H5.88672V34.5352C5.88672 35.3448 6.54189 36 7.35156 36H36.6484C37.4581 36 38.1133 35.3448 38.1133 34.5352V3.57812H42.5078C43.3175 3.57812 43.9727 2.92295 43.9727 2.11328C43.9727 1.30361 43.3175 0.648438 42.5078 0.648438H1.49219C0.682519 0.648438 0.0273438 1.30361 0.0273438 2.11328C0.0273438 2.92295 0.682519 3.57812 1.49219 3.57812ZM14.6758 30.1406C14.6758 30.9503 14.0206 31.6055 13.2109 31.6055C12.4013 31.6055 11.7461 30.9503 11.7461 30.1406V6.50781C11.7461 5.69814 12.4013 5.04297 13.2109 5.04297C14.0206 5.04297 14.6758 5.69814 14.6758 6.50781V30.1406ZM23.4648 30.1406C23.4648 30.9503 22.8097 31.6055 22 31.6055C21.1903 31.6055 20.5352 30.9503 20.5352 30.1406V6.50781C20.5352 5.69814 21.1903 5.04297 22 5.04297C22.8097 5.04297 23.4648 5.69814 23.4648 6.50781V30.1406ZM32.2539 30.1406C32.2539 30.9503 31.5987 31.6055 30.7891 31.6055C29.9794 31.6055 29.3242 30.9503 29.3242 30.1406V6.50781C29.3242 5.69814 29.9794 5.04297 30.7891 5.04297C31.5987 5.04297 32.2539 5.69814 32.2539 6.50781V30.1406Z" fill={fill} />
    </svg>
  </div>
)

export default BucketWithoutLid
