import React, { useEffect, useState } from 'react'
import { Input, message } from 'antd'

import AddImageBtn from '../../helpers/AddImageBtn'
import Button from '../../../../../UI/Button'
import CardListItem from '../../../../../helpers/lists/CardListItem'
import IconDelete from '../../../../../UI/icons/Delete'
import IconEdit from '../../../../../UI/icons/Edit'
import IconSave from '../../../../../UI/icons/Save'
import { formFormData } from '../../../../../Map/helpers/loadMap'
import { onImageUpload } from '../../../uploadImage'
import ConfirmationModal from '../../../../../UI/modals/ConfirmationModal'

const TagListItem = ({ tag, flexValues, isNew, style, postIcon, onSave, onDelete, onCancel }) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [tagTitle, setTagTitle] = useState('')
  const [tagIcon, setTagIcon] = useState('')
  const [error, setError] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const mapPreviewStyle = { flex: '1', marginRight: isEditMode && '29px' }

  const inputStyle = { width: '95%', marginLeft: '2px', border: error && '1px solid #CD4141' }

  const isTagNameValid = (tag = '') => {
    const tagLength = tag.length
    if (tagLength >= 2 && tagLength <= 140) return true

    message.error(`Введите название тега (от 2 до 140 символов). Введено: ${tagLength} символов`)
    return false
  }

  const toggleEditMode = () => setIsEditMode(val => !val)

  const handlePostTagIconInReadMode = ({ target }) => {
    const file = target.files[0]
    const formData = formFormData(file)
    postIcon(tag.id, formData)
  }

  const setImages = images => {
    const fulFilledImages = images.map(img => img.status === 'fulfilled' && img.value)
    fulFilledImages.length === 1 && setTagIcon(fulFilledImages[0])
  }

  const handlePostTagIconInEditMode = e => onImageUpload(e, setImages)

  const handleInputChange = ({ target }) => {
    const inputValue = target.value
    setTagTitle(inputValue)
    setError(!inputValue)
  }

  const handleSave = () => {
    const formattedTitle = tagTitle.trim()

    if (!isTagNameValid(formattedTitle)) {
      !error && setError(true)
      return
    }

    if (formattedTitle === tag.title && tagIcon?.path === tag.icon?.path) {
      toggleEditMode()
      return
    }

    onSave({ title: formattedTitle, icon: tagIcon?.id || null }, toggleEditMode)
  }

  const renderActivityIcons = () => isEditMode
    ? <IconSave className='tags-list__icon_wrapper icon__save_blue' onClick={handleSave} />
    : (
      <>
        <IconDelete
          className='tags-list__icon_wrapper'
          onClick={() => setIsDeleteModalVisible(true)}
        />
        <IconEdit
          width='18'
          height='18'
          className='tags-list__icon_wrapper'
          onClick={() => setIsEditMode(true)}
        />
      </>
    )

  const renderInput = () => (
    <Input
      autoFocus
      value={tagTitle}
      placeholder='Введите название тега'
      onChange={handleInputChange}
      style={inputStyle}
    />
  )

  const renderDeleteIcon = () => (
    <Button
      onClick={() => setTagIcon(null)}
      styles='btn-remove-image'
      renderIcon={() =>
        <IconDelete height={16} width={16} className='btn-remove-image__icon_red' />}
    />
  )

  useEffect(() => {
    isNew && setIsEditMode(true)

    setTagTitle(tag.title)
    setTagIcon(tag.icon)
  }, [tag])

  const clickOutsideNewItemHandler = ({ target }) => {
    const isClickOnBtn = target.closest('.btn-group__item')
    const isClickOnSidePanel = target.closest('.side_panel')
    if (isClickOnBtn || isClickOnSidePanel) return

    const isClickOutsideItem = !target.closest('.card_list__item ')
    if (isClickOutsideItem) onCancel()
  }

  useEffect(() => {
    if (isNew) {
      document.addEventListener('mousedown', clickOutsideNewItemHandler)
      return () => document.removeEventListener('mousedown', clickOutsideNewItemHandler)
    }
  }, [])

  return (
    <>
      <CardListItem style={style}>
        <div className='card_list__item_tag_title' style={{ flex: flexValues[0] }}>
          {isEditMode ? renderInput() : tag.title}
        </div>
        {isNew
          ? (
            <span className='secondary_text__inactive' style={mapPreviewStyle}>
              Сохраните тег перед добавлением иконки
            </span>
          )
          : (
            <AddImageBtn
              image={tagIcon?.path}
              onUpload={isEditMode ? handlePostTagIconInEditMode : handlePostTagIconInReadMode}
              extraIcon={isEditMode && renderDeleteIcon()}
              style={{ flex: flexValues[1], marginRight: isEditMode && '29px' }}
            />
          )}
        {renderActivityIcons()}
      </CardListItem>
      <ConfirmationModal
        start={isDeleteModalVisible}
        text={`Удалить тег '${tag.title}'?`}
        onConfirm={() => onDelete(tag.id)}
        onClose={() => setIsDeleteModalVisible(false)}
      />
    </>
  )
}

export default TagListItem
