export default {
  SET_INITIAL: 'SET_INITIAL',
  SET_INITIAL_SUCCESS: 'SET_INITIAL_SUCCESS',
  SET_INITIAL_FAILED: 'SET_INITIAL_FAILED',

  SET_ROOMS: 'SET_ROOMS',

  CHECK_ROOM: 'CHECK_ROOM',
  CHECK_TABLE: 'CHECK_TABLE',

  CHECK_ALL_ROOMS: 'CHECK_ALL_ROOMS',
  CHECK_ALL_TABLES: 'CHECK_ALL_TABLES',

  ADD_ROOM_SUCCESS: 'ADD_ROOM_SUCCESS',
  ADD_TABLE_SUCCESS: 'ADD_TABLE_SUCCESS',

  UPDATE_ROOM_SUCCESS: 'UPDATE_ROOM_SUCCESS',
  UPDATE_TABLE_SUCCESS: 'UPDATE_TABLE_SUCCESS',

  DELETE_ROOMS_SUCCESS: 'DELETE_ROOMS_SUCCESS',
  DELETE_TABLES_SUCCESS: 'DELETE_TABLES_SUCCESS',
}
