import React from 'react'
import { message } from 'antd'

const SideNavigationPanel = ({
  navItems,
  backBtnTitle,
  onBackBtnClick,
  activeItem,
  setActiveItem,
  isSaved,
}) => {
  const getClassName = itemName => {
    const basicClassName = 'side_panel_item'
    return itemName === activeItem ? basicClassName : `${basicClassName}__inactive`
  }

  const handleSetActiveItem = name => isSaved
    ? setActiveItem(name)
    : message.info('Заполните и сохраните основную информацию о БЦ')

  return (
    <div className='side_panel'>
      <div className='side_panel__back_btn' onClick={onBackBtnClick}>{backBtnTitle}</div>
      {navItems.map(({ title, name }) => (
        <div
          key={`side-nav-item-${name}`}
          className={getClassName(name)}
          onClick={() => handleSetActiveItem(name)}
        >
          {title}
        </div>
      ))}
    </div>
  )
}

export default SideNavigationPanel
