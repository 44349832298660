import React from 'react'
import { Select } from 'antd'
import { ErrorMessage } from 'formik'

import SimpleSelect from '../../../UI/input/SimpleSelect'
import CheckBox from '../../../UI/input/CheckBox'
import ValidationMsg from '../../../UI/ValidationMsg'

const BookingTypeBlock = ({
  values,
  setFieldTouched,
  onTypeChange,
  onCheckBoxClick,
  typesList,
  getErrorCondition,
}) => (
  <div className='flex_container flex_container__space_between container__booking_type full_width'>
    <div className='flex_container__column flex_container__flex_2'>
      <SimpleSelect
        name='type'
        label='Тип'
        value={values.type}
        placeholder='Тип помещения'
        style={{ width: '100%' }}
        onChange={onTypeChange}
        setFieldTouched={setFieldTouched}
        error={getErrorCondition('type')}
        errorComponent={() => <ErrorMessage component={ValidationMsg} name='type' />}
      >
        {typesList.map(type => (
          <Select.Option key={type.id} value={type.title}>
            {type.title}
          </Select.Option>
        ))}
      </SimpleSelect>
    </div>
    <div className='flex_container flex_container__flex_1'>
      <CheckBox
        name='isDetailBooking'
        onChange={onCheckBoxClick}
        setFieldTouched={setFieldTouched}
        value={values.isDetailBooking}
        style={{ top: '30px', left: '18px' }}
        title='Подробнее'
      />
    </div>
  </div>
)

export default BookingTypeBlock
