import React from 'react'

export default ({ subtitle, actions, style }) => (
  <div
    className='flex_container__row'
    style={{ ...style, alignItems: 'center', justifyContent: 'space-between' }}
  >
    <span className='secondary_text__white'>{subtitle}</span>
    <div>{actions}</div>
  </div>
)
