import React, { useState } from 'react'
import Modal from './Modal'

import { colorPalette } from '../../../utils/data/constants'
import Button from '../Button'

const ColorPickModal = ({ icon, start, onSave, color = colorPalette[0], onClose }) => {
  const [pickedColor, setPickedColor] = useState(color)

  const getColorPreviewClassName = color =>
    `color-pick-modal__color-preview${color === pickedColor ? '_checked' : ''}`

  const handleColorPick = color => setPickedColor(color)

  return (
    <Modal onClose={onClose} start={start} style={{ width: '505px', height: 'min-content' }}>
      <div className='color-pick-modal__marker-preview-container'>
        <div className='marker-preview' style={{ backgroundColor: pickedColor }}>
          {icon && <img src={icon} alt='preview' />}
        </div>
      </div>
      <span className='main_text__white text__uppercase'>Выберите цвет фона типа помещения</span>
      <div className='color-pick-modal__colors-container'>
        {colorPalette.map((color, index) => (
          <div
            key={`color-preview-${index}`}
            onClick={() => handleColorPick(color)}
            className={getColorPreviewClassName(color)}
            style={{ backgroundColor: color }}
          />
        ))}
      </div>
      <div className='flex_container' style={{ justifyContent: 'flex-end', marginTop: '15px' }}>
        <Button styles='simple-btn_white' title='Отмена' onClick={onClose} />
        <Button
          title='Сохранить'
          styles='rounded-btn_blue'
          delay={300}
          onClick={() => onSave(pickedColor)}
        />
      </div>
    </Modal>
  )
}

export default ColorPickModal
