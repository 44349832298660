import React from 'react'
import localeTime from 'antd/es/time-picker/locale/ru_RU'
import { TimePicker } from 'antd'

const TimePick = ({ name, value, onBlur, onChange, style, minuteStep = 60 }) => (
  <TimePicker
    id={name}
    name={name}
    minuteStep={minuteStep}
    value={value}
    style={style}
    locale={localeTime}
    format='HH:mm'
    onBlur={onBlur}
    onChange={val => onChange(name, val)}
    suffixIcon={<div className='icon__clock' />}
    allowClear={false}
  />
)

export default TimePick
