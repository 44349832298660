import React from 'react'

export default ({ width = '14', height = '14', ...rest }) => (
  <div {...rest} className='icon__cross-rounded'>
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='7' cy='7' r='7' fill='#2B3647' />
      <path d='M7.58992 7.0017L9.38158 5.2142C9.46004 5.13574 9.50412 5.02933 9.50412 4.91837C9.50412 4.80741 9.46004 4.701 9.38158 4.62254C9.30312 4.54408 9.19671 4.5 9.08575 4.5C8.97479 4.5 8.86838 4.54408 8.78992 4.62254L7.00242 6.4142L5.21492 4.62254C5.13646 4.54408 5.03004 4.5 4.91908 4.5C4.80812 4.5 4.70171 4.54408 4.62325 4.62254C4.54479 4.701 4.50071 4.80741 4.50071 4.91837C4.50071 5.02933 4.54479 5.13574 4.62325 5.2142L6.41492 7.0017L4.62325 8.7892C4.5842 8.82794 4.5532 8.87402 4.53204 8.9248C4.51089 8.97557 4.5 9.03003 4.5 9.08504C4.5 9.14004 4.51089 9.1945 4.53204 9.24528C4.5532 9.29605 4.5842 9.34214 4.62325 9.38087C4.66198 9.41992 4.70807 9.45092 4.75884 9.47208C4.80962 9.49323 4.86408 9.50412 4.91908 9.50412C4.97409 9.50412 5.02855 9.49323 5.07932 9.47208C5.1301 9.45092 5.17618 9.41992 5.21492 9.38087L7.00242 7.5892L8.78992 9.38087C8.82865 9.41992 8.87473 9.45092 8.92551 9.47208C8.97628 9.49323 9.03074 9.50412 9.08575 9.50412C9.14075 9.50412 9.19522 9.49323 9.24599 9.47208C9.29676 9.45092 9.34285 9.41992 9.38158 9.38087C9.42064 9.34214 9.45163 9.29605 9.47279 9.24528C9.49394 9.1945 9.50483 9.14004 9.50483 9.08504C9.50483 9.03003 9.49394 8.97557 9.47279 8.9248C9.45163 8.87402 9.42064 8.82794 9.38158 8.7892L7.58992 7.0017Z' fill='#CD4141' />
    </svg>
  </div>
)
