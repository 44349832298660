import React from 'react'
import { Select } from 'antd'
import { ErrorMessage } from 'formik'

import SimpleSelect from '../../../UI/input/SimpleSelect'
import FadeTransition from '../../../UI/animations/transition/FadeTransition'
import ValidationMsg from '../../../UI/ValidationMsg'

const BookingDetailsBlock = ({
  values,
  floors,
  rooms,
  types,
  tables,
  onFloorSelect,
  onRoomSelect,
  setFieldTouched,
  setFieldValue,
  getErrorCondition,
}) => {
  const isWorkPlace = (() => {
    const selectedRoomType = types.find(({ title }) => title === values.type)
    return !selectedRoomType?.unified
  })()

  return (
    <FadeTransition loading={values.isDetailBooking}>
      <div className='flex_container flex_container__space_between'>
        <div
          className='container__booking__floor_field'
          style={isWorkPlace
            ? { width: '20%', maxWidth: '20%' }
            : { width: '35%', maxWidth: '35%' }}
        >
          <SimpleSelect
            name='floor'
            label='Этаж'
            placeholder='Этаж'
            value={values.floor}
            onChange={onFloorSelect}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('floor')}
            errorComponent={() => <ErrorMessage component={ValidationMsg} name='floor' />}
          >
            {floors?.length > 0 && floors.map((f => (
              <Select.Option key={`floor-${f.id}`} value={f.title}>{f.title}</Select.Option>
            )))}
          </SimpleSelect>
        </div>

        <div
          className='container__booking__room_field'
          style={isWorkPlace
            ? { marginRight: '10px', width: '45%', maxWidth: '45%' }
            : { width: '65%', maxWidth: '65%' }}
        >
          <SimpleSelect
            name='room'
            label='Помещение'
            placeholder='Выберите кабинет'
            onChange={onRoomSelect}
            setFieldTouched={setFieldTouched}
            disabled={!values.floor}
            value={values.room}
            error={getErrorCondition('room')}
            errorComponent={() => <ErrorMessage component={ValidationMsg} name='room' />}
          >
            {rooms?.map(({ id, title }) => (
              <Select.Option key={`room-${id}`} value={id}>{title}</Select.Option>
            ))}
          </SimpleSelect>
        </div>
        {isWorkPlace && (
          <div className='container__booking__table_field'>
            <SimpleSelect
              name='table'
              label='Стол'
              value={values.table}
              placeholder='Выберите место'
              onChange={setFieldValue}
              setFieldTouched={setFieldTouched}
              disabled={!values.room || !values.floor}
              error={getErrorCondition('table')}
              errorComponent={() => <ErrorMessage component={ValidationMsg} name='table' />}
            >
              {tables?.map(t => (
                <Select.Option key={`table-${t.id}`} value={t.id}>{t.title}</Select.Option>
              ))}
            </SimpleSelect>
          </div>
        )}
      </div>
    </FadeTransition>
  )
}

export default BookingDetailsBlock
