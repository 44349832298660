import React from 'react'

const Info = ({ extraClassName, width = 10, height = 10 }) => (
  <svg
    className={`icon__info_svg${extraClassName ? '__' + extraClassName : ''}`}
    width={width}
    height={height}
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='5' cy='2.5' r='0.5' fill='#F7A833' />
    <path fillRule='evenodd' clipRule='evenodd' d='M5 3.46484C5.27614 3.46484 5.5 3.70363 5.5 3.99818V7.46575C5.5 7.76031 5.27614 7.99909 5 7.99909C4.72386 7.99909 4.5 7.76031 4.5 7.46575L4.5 3.99818C4.5 3.70363 4.72386 3.46484 5 3.46484Z' fill='black' />
    <path fillRule='evenodd' clipRule='evenodd' d='M5 3.46484C5.27614 3.46484 5.5 3.70363 5.5 3.99818V7.46575C5.5 7.76031 5.27614 7.99909 5 7.99909C4.72386 7.99909 4.5 7.76031 4.5 7.46575L4.5 3.99818C4.5 3.70363 4.72386 3.46484 5 3.46484Z' fill='#F7A833' />
    <circle cx='5' cy='5' r='4.65' stroke='black' strokeWidth='0.7' />
    <circle cx='5' cy='5' r='4.65' stroke='#F7A833' strokeWidth='0.7' />
  </svg>
)

export default Info
