export default (num, textForms) => {
  const hundredthRemainder = Math.abs(num) % 100
  const [firstOption, secondOption, thirdOption] = textForms
  const decimalRemainder = hundredthRemainder % 10

  switch (true) {
    case (hundredthRemainder > 10 && hundredthRemainder < 20): return thirdOption
    case (decimalRemainder > 1 && decimalRemainder < 5): return secondOption
    case (decimalRemainder === 1): return firstOption
    default: return thirdOption
  }
}
