import React from 'react'

export default ({ onClick, className, tooltipText, width = '20', height = '20', color = '#0079C1' }) => (
  <div className={className} onClick={onClick} data-tooltip={tooltipText}>
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.00049 0C4.0304 0 0.000488281 4.02991 0.000488281 9C0.000488281 13.9701 4.0304 18 9.00049 18C13.9706 18 18.0005 13.9701 18.0005 9C18.0005 4.02991 13.9706 0 9.00049 0ZM9.00049 16.4732C4.87415 16.4732 1.52727 13.1263 1.52727 9C1.52727 4.87366 4.87415 1.52679 9.00049 1.52679C13.1268 1.52679 16.4737 4.87366 16.4737 9C16.4737 13.1263 13.1268 16.4732 9.00049 16.4732Z' fill={color} />
      <path d='M8.03613 5.46429C8.03613 5.72003 8.13773 5.9653 8.31857 6.14614C8.4994 6.32698 8.74467 6.42857 9.00042 6.42857C9.25616 6.42857 9.50143 6.32698 9.68227 6.14614C9.86311 5.9653 9.9647 5.72003 9.9647 5.46429C9.9647 5.20854 9.86311 4.96327 9.68227 4.78243C9.50143 4.60159 9.25616 4.5 9.00042 4.5C8.74467 4.5 8.4994 4.60159 8.31857 4.78243C8.13773 4.96327 8.03613 5.20854 8.03613 5.46429ZM9.48256 7.71429H8.51828C8.42988 7.71429 8.35756 7.78661 8.35756 7.875V13.3393C8.35756 13.4277 8.42988 13.5 8.51828 13.5H9.48256C9.57095 13.5 9.64328 13.4277 9.64328 13.3393V7.875C9.64328 7.78661 9.57095 7.71429 9.48256 7.71429Z' fill={color} />
    </svg>
  </div>
)
