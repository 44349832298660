import React from 'react'
import { connect } from 'react-redux'

import CardListItem from '../../../helpers/lists/CardListItem'
import EmptyPlaceholder from '../../../UI/EmptyPlaceholder'
import ScrollTransition from '../../../UI/animations/transition/ScrollTransition'
import UnderlinedTitle from '../../../UI/UnderlinedTitle'
import UserBookingCardListItem from './UserBookingCardListItem'
import { DELETE_BOOKING } from '../../../../actions/booking'

const UserBookingHistory = ({ deleteBooking, bookHistory }) => (
  <div className='container_form__right' style={{ maxWidth: 'none', flex: 1.8 }}>
    <UnderlinedTitle title='ИСТОРИЯ БРОНИ' />
    <ScrollTransition loading>
      <div className='card_list'>
        {bookHistory?.length > 0
          ? bookHistory.map(b => (
            <CardListItem
              key={b.id}
              onDelete={() => deleteBooking(b.id)}
              extraClassName='card_list__item__no_hover'
            >
              <UserBookingCardListItem booking={b} />
            </CardListItem>
          ))
          : <EmptyPlaceholder text='Список бронирований пуст' />}
      </div>
    </ScrollTransition>
  </div>
)

const mapStateToProps = ({ booking }) => ({ bookHistory: booking.history || [] })

const mapDispatchToProps = dispatch => ({
  deleteBooking: bookId => dispatch({ type: DELETE_BOOKING, bookId }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserBookingHistory)
