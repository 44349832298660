import React from 'react'
import { Button } from 'antd'

import Modal from '../UI/modals/Modal'
import LabeledDataBlock from '../UI/dataBlock/LabeledDataBlock'
import ScrollTransition from '../UI/animations/transition/ScrollTransition'
import CardListItem from '../helpers/lists/CardListItem'
import EmptyPlaceholder from '../UI/EmptyPlaceholder'

const SendModal = ({
  onClose,
  onSend,
  start,
  loading,
  pushTitle = 'Название',
  pushMessage = 'Описание',
  recipients = [],
}) => (
  <Modal
    onClose={onClose}
    start={start}
    extraClassName='notifications__modal'
  >
    <span className='large_btn__title__center border_bottom'>
      Подтверждение отправки уведомления
    </span>

    <h3>Содержание уведомления</h3>
    <LabeledDataBlock title='Тема' data={pushTitle} />
    <LabeledDataBlock title='Описание' data={pushMessage} />

    <h3>Список получателей</h3>
    <span className='recipients-list__subtitle'>
      {`Всего ${recipients.length} получателей`}
    </span>

    {/* TODO: обернуть в react-virtualized */}
    <ScrollTransition loading>
      <div className='recipients-list'>
        {recipients.length
          ? recipients.map((user, index) => (
            <CardListItem key={user.id} small>
              <span className='recipients-list__bullet'>{index + 1}.</span>
              {user.phone_number
                ? <span>{user.phone_number}</span>
                : <span className='secondary_text__inactive'>Не указано</span>}
            </CardListItem>
          ))
          : <EmptyPlaceholder text='Не выбрано ни одного адресата' />}
      </div>
    </ScrollTransition>

    <div className='notifications__button-wrapper'>
      <div className='simple-btn' onClick={onClose}>Отмена</div>
      <Button loading={loading} delay={200} type='primary' onClick={onSend}>
        Отправить
      </Button>
    </div>
  </Modal>
)

export default SendModal
