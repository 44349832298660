import React from 'react'

const DoubleLineColonBlock = ({
  containerClass = 'flex_container',
  containerStyle = {},
  textClass = 'secondary_text__white text__wrapper',
  first = {},
  second = {},
}) => (
  <div className={containerClass} style={containerStyle}>
    <div className='flex_container__column flex_container__column__flex_end'>
      <span className='label margin_right'>{first.title}:</span>
      <span className='label margin_right'>{second.title}:</span>
    </div>
    <div className='flex_container__column text__wrapper' style={{ display: 'block' }}>
      <span className={textClass}>{first.text}</span>
      <span className={textClass}>{second.text}</span>
    </div>
  </div>
)

export default DoubleLineColonBlock
