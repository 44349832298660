import React, { useState } from 'react'
import CardListItem from '../../../../../helpers/lists/CardListItem'

const RoomListItem = ({ title, floor, tables, index }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const getTableTags = tags => {
    if (tags.length) return tags.map(tag => tag.title).join(', ')
    return <span className='secondary_text__inactive'>Нет тегов</span>
  }

  const renderTableList = () => tables.map((tableItem, id) => (
    <div key={tableItem.id} className='table-item'>
      <div className='table-item__id' style={{ flex: 0.1 }}>{id + 1}</div>
      <div className='table-item__title' style={{ flex: 1 }}>{tableItem.title}</div>
      <div className='table-item__tags' style={{ flex: 1 }}>{getTableTags(tableItem.tags)}</div>
    </div>
  ))

  return (
    <>
      <CardListItem onCardClick={() => tables.length && setIsExpanded(!isExpanded)} extraClassName='room-list-item'>
        <div className='room-list-item__id' style={{ flex: 0.1, marginRight: '10px' }}>
          {index + 1}
        </div>
        <div className='room-list-item__title' style={{ flex: 0.6, marginRight: '14px' }}>
          {title}
        </div>
        <div className='room-list-item__floor' style={{ flex: 0.5, marginRight: '15px' }}>
          {floor.title}
        </div>
        <div className='room-list-item__qr-amount' style={{ flex: 0.5 }}>
          {tables.length}
        </div>
        <div
          className={tables.length ? 'expand_arrow' : ''}
          style={{ width: '20px',
            height: '12px',
            transform: `${isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}` }}
        />
      </CardListItem>
      {isExpanded && renderTableList()}
    </>
  )
}

export default RoomListItem
