import { message, notification } from 'antd'
import { takeLatest, call, put, takeEvery } from 'redux-saga/effects'

import getResponse from './getResponse'
import nextPaginationStart from '../utils/nextPaginationStart'
import isNoMessage from '../utils/isNoMessage'
import formPath from '../utils/formPath'
import DEFAULT_AVATAR from '../utils/data/defaultAvatarUrl'
import { content, auth } from '../utils/data/requestHeaders'
import { GET, PUT, POST, DELETE } from '../utils/methods'
import { ITEMS_ON_PAGE, PUSH_WORKSPACE } from '../utils/data/constants'
import {
  CREATE_OPERATOR,
  CREATE_OPERATOR_SUCCEED,
  DELETE_USER,
  DELETE_USER_SUCCEED,
  GET_USERS,
  GET_USERS_SUCCEED,
  PUT_USER,
  PUT_USER_SUCCEED,
  USERS_LOADING,
  GET_ACCESS_GROUPS,
  GET_ACCESS_GROUPS_SUCCEED,
  SET_ACCESS_GROUPS_LOADING,
  GET_ACCESS_GROUP,
  GET_GROUP_SUCCEED,
  PUT_USERS_IN_ACCESS_GROUP,
  POST_GROUP,
  DELETE_GROUP,
  DELETE_GROUP_SUCCEED,
  UPDATE_GROUP,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCEED,
  CREATE_KIOSK,
  CREATE_KIOSK_SUCCEED,
  GET_KIOSKS,
  SEND_EMAIL,
  PUT_KIOSK,
  PUT_KIOSK_SUCCEED,
  RESET_PASSWORD,
  PROMOTE_OPERATOR_SUCCEED,
  PROMOTE_OPERATOR,
  CREATE_USER_SUCCEED,
  CREATE_USER,
  IMPORT_USERS_IN_GROUP,
  SET_IMPORT_USERS_GROUP_LOADING,
  SET_IMPORT_USERS_GROUP_ERROR,
  IMPORT_USERS_IN_GROUP_SUCCEED,
  IMPORT_GROUPS_SUCCEED,
  IMPORT_GROUPS,
  GET_USERS_FOR_NOTIFICATIONS,
  GET_USERS_FOR_NOTIFICATIONS_SUCCEED,
  GET_USERS_FOR_NOTIFICATIONS_SEARCH,
  GET_ACCESS_GROUP_NO_REDUX,
  GET_GROUP_ACCESS_SUCCEED,
  GET_GROUP_ACCESS,
  GET_USER_ACCESS_GROUPS,
  GET_USER_ACCESS_GROUPS_SUCCEED,
} from '../actions/users'
import notificationBasicSetups from '../utils/data/notificationConfig'

// <--- Users --->

function* putUser(action) {
  try {
    const userWithPhotoId = {
      ...action.user,
      photo: action.photo ? action.photo.id : null,
    }

    const user = yield call(() => getResponse({
      method: PUT,
      path: `/accounts/${action.userId}`,
      headers: content,
      body: userWithPhotoId,
    }))

    if (!isNoMessage(user)) return null

    message.success('Изменения сохранены')
    yield put({ type: PUT_USER_SUCCEED, user })
    yield action.callback && action.callback()
  } catch (e) { console.log(e.message) }
}

function* createOperator({ user, photo, callback }) {
  try {
    const accountId = yield call(() => getResponse({
      method: POST,
      path: '/register_employee',
      headers: content,
      body: { username: user.email },
    }))

    if (!isNoMessage(accountId)) return null

    const userAttrs = photo ? { ...user, photo: photo.id } : user
    const account = yield call(() => getResponse({
      method: PUT,
      path: `/accounts/${accountId.account}`,
      headers: content,
      body: userAttrs,
    }))

    if (!isNoMessage(account)) return null

    message.success('Пользователь создан')
    account.photo = {
      id: photo ? photo.id : null,
      path: photo ? photo.path : DEFAULT_AVATAR,
    }
    yield put({ type: CREATE_OPERATOR_SUCCEED, user: { ...account } })
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* createUser({ user, callback, isPromise }) {
  try {
    const newUser = yield call(() => getResponse({
      method: POST,
      path: '/register_user',
      headers: content,
      body: user,
    }))

    if (isNoMessage(newUser)) {
      yield put({ type: CREATE_USER_SUCCEED, user: newUser })
      yield callback && callback(isPromise && newUser)
      message.success('Пользователь создан')
    }
  } catch (e) { console.log(e.message) }
}

function* getUsers({ searchString, page, filter, callback, group }) {
  try {
    yield put({ type: USERS_LOADING, group })

    const path = formPath('/accounts_list', [
      { start: nextPaginationStart(page) },
      { limit: page && ITEMS_ON_PAGE },
      { include_not_activated: filter },
      { access_group: group },
      { search: searchString?.replace('+', '%2B') },
    ])

    const users = yield call(() => getResponse({ method: GET, path: path, headers: auth }))
    yield put({ type: GET_USERS_SUCCEED, users: users.results, count: users.count, page, group })

    yield callback && callback(users.results)
  } catch (e) { console.log(e.message) }
}

export function* getUsersWithExpoTokens({ searchString, callback }) {
  const path = formPath('/accounts_list', [
    { search: searchString?.replace('+', '%2B') },
  ])

  const users = yield call(() =>
    getResponse({ method: GET, path: path, headers: auth }))

  const expoUsers = yield call(() =>
    getResponse({ method: GET, path: '/tokens', headers: auth, isPush: true }))

  const targetAppExpoUsers = expoUsers[PUSH_WORKSPACE]
  const result = users.results.filter(u =>
    targetAppExpoUsers?.some(expoAcc => expoAcc.account === u.id))

  yield callback && callback(result)
  return result
}

export function* getGroupsNoRedux({ searchString, callback }) {
  const path = formPath('/groups', [
    { search: searchString?.replace('+', '%2B') },
  ])

  const groups = yield call(() => getResponse({ method: GET, path: path, headers: auth }))

  yield callback && callback(groups)
  return groups
}

function* getUsersForNotifications({ searchString, callback }) {
  try {
    yield put({ type: USERS_LOADING })

    const expoUsersAccounts = yield getUsersWithExpoTokens({ searchString })

    yield put({
      type: GET_USERS_FOR_NOTIFICATIONS_SUCCEED,
      users: expoUsersAccounts,
      count: expoUsersAccounts.length,
    })

    yield callback && callback(expoUsersAccounts)
  } catch (e) { console.log(e.message) }
}

function* deleteUser({ userId, callback }) {
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/accounts/${userId}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_USER_SUCCEED, userId })
      yield callback && callback()
      message.success('Удалено')
    }
  } catch (e) { console.log(e.message) }
}

function* confirmEmail({ user, callback }) {
  try {
    const response = yield getResponse({
      method: POST,
      path: '/account_confirm',
      headers: content,
      body: user,
    })

    if (isNoMessage(response)) {
      yield put({ type: CONFIRM_EMAIL_SUCCEED, user: response })
      yield callback && callback()
      message.success('Email подтвержден')
    }
  } catch (e) { console.log(e.message) }
}

function* resetUserPassword({ userId, userEmail }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      path: '/pass_reset',
      headers: content,
      body: userId,
    }))

    response.message === 'OK'
      && message.success(`Пользователь получит письмо с новым паролем на адрес ${userEmail}`)
  } catch (e) { console.log(e.message) }
}

function* promoteOperator({ userId, userEmail }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      path: '/operator_promotion',
      headers: content,
      body: { account: userId },
    }))

    if (response.message === 'Promoted' || response.message === 'Demoted') {
      message.success(response.message === 'Promoted'
        ? `Пользователь получит письмо с паролем на адрес ${userEmail}`
        : 'Пользователь лишен доступа к панели оператора')
      yield put({ type: PROMOTE_OPERATOR_SUCCEED, userId })
    }
  } catch (e) { console.log(e.message) }
}

// <--- Groups --->

function* getGroupsList({ callback }) {
  try {
    yield put({ type: SET_ACCESS_GROUPS_LOADING })
    const groups = yield call(() => getResponse({
      method: GET,
      path: '/groups',
      headers: auth,
    }))

    yield put({ type: GET_ACCESS_GROUPS_SUCCEED, groups })
    yield callback && callback(groups)
  } catch (e) { console.log(e.message) }
}

function* getGroup({ id }) {
  try {
    yield put({ type: SET_ACCESS_GROUPS_LOADING })
    const group = yield call(() => getResponse({
      method: GET,
      path: `/groups?id=${id}`,
      headers: auth,
    }))
    yield put({ type: GET_GROUP_SUCCEED, group })
  } catch (e) { console.log(e.message) }
}

function* getGroupAccess({ id }) {
  try {
    const access = yield call(() => getResponse({
      method: GET,
      path: `/group_access/${id}`,
      headers: auth,
    }))
    yield put({ type: GET_GROUP_ACCESS_SUCCEED, access })
  } catch (e) { console.log(e.message) }
}

function* getUserAccessGroups({ id }) {
  try {
    const groups = yield call(() => getResponse({
      method: GET,
      path: `/user_access/${id}`,
      headers: auth,
    }))
    yield put({ type: GET_USER_ACCESS_GROUPS_SUCCEED, id, groups })
  } catch (e) { console.log(e.message) }
}

function* putUsersInGroup({ id, users }) {
  try {
    const response = yield call(() => getResponse({
      method: PUT,
      path: '/groups/update',
      headers: content,
      body: { id, users },
    }))
    return response
  } catch (e) { console.log(e.messagge) }
}

function* putMainInfoInGroup({ id, mainInfo }) {
  try {
    const response = yield call(() => getResponse({
      method: PUT,
      path: `/group/${id}`,
      headers: content,
      body: mainInfo,
    }))

    return response
  } catch (e) { console.log(e.message) }
}

function* postNewGroup({ group, succeedCallback, failedCallback }) {
  try {
    const { users, ...groupWithoutUsers } = group
    const resGroupInfo = yield call(() => getResponse({
      method: POST,
      path: '/groups',
      body: groupWithoutUsers,
    }))

    const usersList = yield { id: resGroupInfo.id, users: users }

    const resUsers = yield call(() => putUsersInGroup(usersList))
    if (!(resUsers.message || resUsers.msg || resGroupInfo.msg || resGroupInfo.message))
      yield succeedCallback(resGroupInfo.id)
  } catch (e) {
    failedCallback()
    console.log(e.message)
  }
}

function* deleteGroup({ id, callback }) {
  const res = yield getResponse({
    method: DELETE,
    path: `/group/${id}`,
    headers: auth,
  })

  yield (isNoMessage(res) && callback) && callback()
  yield put({ type: DELETE_GROUP_SUCCEED, id })
  message.success('Группа доступа удалена')
}

function* updateGroup({ id, mainInfo, usersInfo, succeedCallback, failedCallback }) {
  try {
    const mainInfoRes = yield !!mainInfo && call(() => putMainInfoInGroup({ id, mainInfo }))
    const usersListRes = yield !!usersInfo && call(() => putUsersInGroup({ id, users: usersInfo }))

    if (isNoMessage(mainInfoRes) && isNoMessage(usersListRes) && succeedCallback)
      yield succeedCallback()
  } catch (e) {
    console.log(e.message)
    failedCallback()
  }
}

function* importUsersInGroup({ file, callback }) {
  try {
    yield put({ type: SET_IMPORT_USERS_GROUP_LOADING })
    const response = yield call(() => getResponse({
      method: POST,
      path: '/groups/import_single',
      headers: auth,
      body: file,
      isFile: true,
    }))

    if (response.message !== 'OK') {
      yield put({ type: SET_IMPORT_USERS_GROUP_ERROR })
      return
    }

    yield put({ type: IMPORT_USERS_IN_GROUP_SUCCEED, users: response.result.users })
    yield callback(response.result.users)
    notification.success({
      ...notificationBasicSetups,
      className: 'notification_antd___succeed',
      message: 'Пользователи добавлены',
      description: `Всего добавлено ${response.counters.accounts_added} пользователей. Создано ${response.counters.accounts_created} новых аккаунтов.`,
    })
  } catch (e) {
    yield put({ type: SET_IMPORT_USERS_GROUP_ERROR })
    console.log(e.message)
  }
}

function* importGroups({ file, path, callback }) {
  try {
    yield put({ type: SET_IMPORT_USERS_GROUP_LOADING })
    const response = yield call(() => getResponse({
      method: POST,
      path: `/groups${path}`,
      headers: auth,
      body: file,
      isFile: true,
    }))

    if (response.message !== 'OK') {
      yield put({ type: SET_IMPORT_USERS_GROUP_ERROR })
      return
    }
    yield put({ type: IMPORT_GROUPS_SUCCEED, updatedGroups: response.result })
    yield callback()
    const { counters } = response
    const extraText = path === '/import_list'
      ? ` Добавлено ${counters.accounts_added} пользователей, из них новых аккаунтов: ${counters.accounts_created}.`
      : ''
    notification.success({
      ...notificationBasicSetups,
      className: 'notification_antd___succeed',
      message: 'Изменения сохранены',
      description: `Обработано ${counters.groups_processed} групп, из них новых: ${counters.groups_created}.${extraText}`,
    })
  } catch (e) {
    yield put({ type: SET_IMPORT_USERS_GROUP_ERROR })
    console.log(e.message)
  }
}

//  <---- Kiosks --->

function* postKiosk({ kiosk, callback }) {
  try {
    const response = yield getResponse({
      method: POST,
      path: '/register_kiosk',
      headers: content,
      body: kiosk,
    })

    if (isNoMessage(response)) {
      yield put({ type: CREATE_KIOSK_SUCCEED, user: response })
      yield callback && callback()
      message.success('Киоск создан')
    }
  } catch (e) { console.log(e.message) }
}

function* getKiosks({ page, searchString, callback }) {
  try {
    yield put({ type: USERS_LOADING })

    const path = formPath('/accounts_list', [
      { account_type: 'kiosk' },
      { start: nextPaginationStart(page) },
      { limit: ITEMS_ON_PAGE },
      { search: searchString },
    ])

    const kiosks = yield getResponse({ method: GET, path, headers: auth })

    if (isNoMessage(kiosks)) {
      yield put({ type: GET_USERS_SUCCEED, users: kiosks.results, count: kiosks.count, page })
      yield callback && callback()
    }
  } catch (e) { console.log(e.message) }
}

function* sendEmail({ payload, callback }) {
  try {
    const response = yield getResponse({
      method: POST,
      path: '/service_email',
      headers: content,
      body: payload,
    })

    if ((response.message === 'OK') && callback) {
      yield callback && callback()
      message.success('Сообщение отправлено')
    }
  } catch (e) { console.log(e.message) }
}

function* putKiosk({ kioskId, kiosk, callback }) {
  try {
    const newKiosk = yield getResponse({
      method: PUT,
      path: `/register_kiosk/${kioskId}`,
      headers: content,
      body: kiosk,
    })

    if (isNoMessage(newKiosk)) {
      yield put({ type: PUT_KIOSK_SUCCEED, user: newKiosk })
      yield callback && callback()
      message.success('Изменения сохранены')
    }
  } catch (e) { console.log(e.message) }
}

export default function* filesSagas() {
  yield takeLatest(CREATE_USER, createUser)
  yield takeLatest(IMPORT_USERS_IN_GROUP, importUsersInGroup)
  yield takeLatest(IMPORT_GROUPS, importGroups)

  yield takeLatest(CREATE_OPERATOR, createOperator)
  yield takeLatest(DELETE_GROUP, deleteGroup)
  yield takeLatest(DELETE_USER, deleteUser)
  yield takeLatest(GET_ACCESS_GROUP, getGroup)
  yield takeLatest(GET_ACCESS_GROUPS, getGroupsList)
  yield takeLatest(GET_ACCESS_GROUP_NO_REDUX, getGroupsNoRedux)
  yield takeLatest(GET_GROUP_ACCESS, getGroupAccess)
  yield takeEvery(GET_USERS, getUsers)
  yield takeEvery(GET_USERS_FOR_NOTIFICATIONS, getUsersForNotifications)
  yield takeEvery(GET_USERS_FOR_NOTIFICATIONS_SEARCH, getUsersWithExpoTokens)
  yield takeEvery(GET_USER_ACCESS_GROUPS, getUserAccessGroups)

  yield takeLatest(POST_GROUP, postNewGroup)
  yield takeLatest(PUT_USER, putUser)
  yield takeLatest(PUT_USERS_IN_ACCESS_GROUP, putUsersInGroup)
  yield takeLatest(UPDATE_GROUP, updateGroup)
  yield takeEvery(CONFIRM_EMAIL, confirmEmail)
  yield takeEvery(CREATE_KIOSK, postKiosk)
  yield takeEvery(GET_KIOSKS, getKiosks)
  yield takeEvery(SEND_EMAIL, sendEmail)
  yield takeEvery(PUT_KIOSK, putKiosk)
  yield takeEvery(RESET_PASSWORD, resetUserPassword)
  yield takeEvery(PROMOTE_OPERATOR, promoteOperator)
}
