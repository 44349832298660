import React from 'react'
import HashtagIcon from '../../../../../UI/icons/Hashtag'

const Tag = ({ onClick, selected, tag }) => {
  const renderTagPayload = () => {
    const icon = tag.icon?.path
      ? (
        <img
          style={{ marginRight: '5px' }}
          width='11px'
          height='11px'
          src={tag.icon.path}
          alt='tag icon'
        />
      )
      : <HashtagIcon style={{ marginRight: '5px' }} />

    return (
      <>
        {icon} {tag.title}
      </>
    )
  }

  return (
    <span
      onClick={() => onClick(tag)}
      className={selected ? 'tag tag-selected' : 'tag'}
    >
      {renderTagPayload()}
    </span>
  )
}

export default Tag
