import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Button from '../../UI/Button'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import ExpandingSearch from '../../UI/ExpandingSearch'
import LargeSpin from '../../UI/Spin/LargeSpin'
import MainCard from '../../MainCard'
import OfficeListItem from './OfficeListItem'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import TableHeader from '../../UI/TableHeader'
import { CLEAN_OFFICES_LIST, GET_OFFICES_NEW_DESIGN } from '../../../actions/offices'
import { ITEMS_ON_PAGE } from '../../../utils/data/constants'

const OFFICES_LIST_COLUMNS_NAMES = ['#', 'Название бизнес-центра', 'Адрес', 'Кол-во этажей', 'Всего мест', 'Свободно']

const COLUMNS_FLEX_VALUES = [0.3, 2.8, 2.8, 0.9, 0.9, 0.9]

const List = ({ getOffices, cleanList, offices, loading, count, history }) => {
  const [page, setPage] = useState(1)
  const [searchString, setSearchString] = useState('')

  const isCancelled = useRef(false)

  const isFirstPageLoading = loading && page === 1

  const shouldNotLoadNewPage = () => {
    const isAllFetched = count <= (page * ITEMS_ON_PAGE)
    return Boolean(loading || isAllFetched)
  }

  const onListScroll = ({ contentScrollHeight, clientHeight, scrollTop }) => {
    if (shouldNotLoadNewPage()) return null
    const isScrollAtTheBottom = (contentScrollHeight - (clientHeight + scrollTop)) <= 1

    isScrollAtTheBottom && !isCancelled.current && setPage(page + 1)
  }

  const CardSubtitle = (() => (
    <>
      <ExpandingSearch
        setSearchString={setSearchString}
        searchString={searchString}
        getItems={getOffices}
        placeholder='Название бизнес-центра'
        style={{ maxWidth: '50%' }}
        defaultExpanded
        isExpanded
        large
      />
      <div className='button_container__content_right'>
        <Button
          confirm
          icon='icon__add__in_circle'
          styles='bordered_btn'
          title='Добавить БЦ'
          onClick={() => history.push('/offices/new')}
        />
      </div>
    </>
  ))()

  const renderOfficesList = () => offices.length
    ? (
      <ScrollTransition loading paginationLoading={loading} onScroll={onListScroll}>
        <div style={{ padding: '0 10px 0 2px' }}>
          {offices.map((office, index) => (
            <OfficeListItem
              key={office.id}
              office={office}
              index={index}
              flexValues={COLUMNS_FLEX_VALUES}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Список бизнес-центров пуст' />

  // TODO: ошибка в консоли
  useEffect(() => {
    !isCancelled.current && getOffices({ page })
  }, [page])

  useEffect(() => {
    isCancelled.current = true
    cleanList()
  }, [])

  return (
    <MainCard
      icon='icon__briefcase'
      title='БИЗНЕС-ЦЕНТРЫ'
      filter={CardSubtitle}
      subtitleClassName='main_card__subtitle'
    >
      <TableHeader
        columns={OFFICES_LIST_COLUMNS_NAMES}
        flexValues={COLUMNS_FLEX_VALUES}
        rightPaddingWidth={50}
      />
      {isFirstPageLoading ? <LargeSpin /> : renderOfficesList()}
    </MainCard>
  )
}

const mapStateToProps = ({ offices_newDesign: offices }) => {
  const { count = 0, list = [], loading } = offices
  return ({ count, offices: list, loading })
}

const mapDispatchToProps = dispatch => ({
  cleanList: () => dispatch({ type: CLEAN_OFFICES_LIST }),
  getOffices: ({ page, searchString, options }) =>
    dispatch({ type: GET_OFFICES_NEW_DESIGN, page, searchString, options }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(List))
