import React from 'react'

const ColorpickerIcon = ({
  onClick,
  className = 'icon__colorpicker flex_container',
  height = '30',
  width = '30',
  color,
}) => (
  <div
    style={{ backgroundColor: color, width: '30px', height: '30px' }}
    onClick={onClick}
    className={`${className} ${color ? 'color-selected' : ''}`}
  >
    {!color && (
      <svg
        width={width}
        height={height}
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='1'
          y='1'
          width='28'
          height='28'
          rx='4'
          fill='#1f2631'
          stroke='#0079C1'
          strokeWidth='2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15 5C15.5523 5 16 5.44772 16 6V8.07089C19.0657 8.5094 21.4906 10.9343 21.9291 14H24C24.5523 14 25 14.4477 25 15C25 15.5523 24.5523 16 24 16H21.9291C21.4906 19.0657 19.0657 21.4906 16 21.9291V24C16 24.5523 15.5523 25 15 25C14.4477 25 14 24.5523 14 24V21.9291C10.9343 21.4906 8.5094 19.0657 8.07089 16H6C5.44772 16 5 15.5523 5 15C5 14.4477 5.44772 14 6 14H8.07089C8.5094 10.9343 10.9343 8.5094 14 8.07089V6C14 5.44772 14.4477 5 15 5ZM19.9 14H18C17.4477 14 17 14.4477 17 15C17 15.5523 17.4477 16 18 16H19.9C19.5023 17.9591 17.9591 19.5023 16 19.9V18C16 17.4477 15.5523 17 15 17C14.4477 17 14 17.4477 14 18V19.9C12.0409 19.5023 10.4977 17.9591 10.1 16H12C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14H10.1C10.4977 12.0409 12.0409 10.4977 14 10.1V12C14 12.5523 14.4477 13 15 13C15.5523 13 16 12.5523 16 12V10.1C17.9591 10.4977 19.5023 12.0409 19.9 14Z'
          fill='#0079C1'
        />
      </svg>
    )}
  </div>
)

export default ColorpickerIcon
