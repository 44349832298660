import React from 'react'

const LabeledDataBlock = ({ title = 'Не указано', data }) => {
  const renderData = () => data
    ? <div className='main_text__subtitle'>{data}</div>
    : <div className='main_text__inactive'>Не указано</div>

  return (
    <div className='labeled_field__block__bordered'>
      <span className='labeled_field__label'>{title}</span>
      <div className='main_text__subtitle text__break_new_line'>{renderData()}</div>
    </div>
  )
}

export default LabeledDataBlock
