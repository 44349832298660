import React from 'react'

const Delete = ({ tooltipText, className, onClick, width = '16', height = '16' }) => (
    <div data-tooltip={tooltipText} className={className ? `${className} icon__delete__svg` : 'icon__delete__svg'} onClick={onClick}>
      <svg width={width} height={height} viewBox='0 0 15 16' xmlns='http://www.w3.org/2000/svg'>
        <path d='M4.53125 1.40625H4.375C4.46094 1.40625 4.53125 1.33594 4.53125 1.25V1.40625H10.4688V1.25C10.4688 1.33594 10.5391 1.40625 10.625 1.40625H10.4688V2.8125H11.875V1.25C11.875 0.560547 11.3145 0 10.625 0H4.375C3.68555 0 3.125 0.560547 3.125 1.25V2.8125H4.53125V1.40625ZM14.375 2.8125H0.625C0.279297 2.8125 0 3.0918 0 3.4375V4.0625C0 4.14844 0.0703125 4.21875 0.15625 4.21875H1.33594L1.81836 14.4336C1.84961 15.0996 2.40039 15.625 3.06641 15.625H11.9336C12.6016 15.625 13.1504 15.1016 13.1816 14.4336L13.6641 4.21875H14.8438C14.9297 4.21875 15 4.14844 15 4.0625V3.4375C15 3.0918 14.7207 2.8125 14.375 2.8125ZM11.7832 14.2188H3.2168L2.74414 4.21875H12.2559L11.7832 14.2188Z' />
      </svg>
    </div>
  )

export default Delete
