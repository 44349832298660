const translateAccess = access => {
  switch (access) {
    case 'guest': return 'Гость'
    default: return 'Спикер'
  }
}

const packUser = values => ({
  firstname: values.firstName,
  lastname: values.lastName,
  middlename: values.middleName,
  phone_number: values.phone_number,
  email: values.email,
  description: values.description,
  access: translateAccess(values.access),
  city: values.city?.id,
  birthday: values.birthday?.format('YYYY-MM-DD'),
  gender: values.sex === 'М' ? 'Мужчина' : 'Женщина',
})

export default packUser
