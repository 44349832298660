import React, { useEffect, useState, useContext } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'

import MoveIcon from '../UI/icons/Move'
import InfoIcon from '../UI/icons/InfoBig'
import EditIcon from '../UI/icons/Edit'
import CrossIcon from '../UI/icons/Cross'
import DeleteIcon from '../UI/icons/Delete'
import EditRoomModalContext from './editRoomModalContext'
import { DELETE_MARKER, DELETE_ROOM_FROM_MAP, ADD_MARKER, REFRESH_ROOMS } from '../../actions/offices'

import './toolbar.scss'
import ToolbarInfo from './ToolbarInfo'

const rootDocument = document.getElementById('root')

const ToolbarInner = ({ style, onClose, marker, index }) => {
  const [infoTabVisibility, setInfoTabVisibility] = useState(false)
  const [cursorIndicator, setCursorIndicator] = useState(false)
  const [visible, setVisible] = useState(true)
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  const dispatch = useDispatch()

  const roomEditModalManipulation = useContext(EditRoomModalContext)

  const handleClickOutside = e => {
    e.stopPropagation()
    const { target } = e

    if (target.closest(`.info, #marker-${index}, .tooltip`)) return
    if (cursorIndicator) {
      setCursorIndicator(false)
      return
    }

    onClose()
  }

  const deleteMarker = () => {
    dispatch({
      type: DELETE_MARKER,
      floorId: marker.floorId,
      room: { room: marker.roomId },
    })
    dispatch({ type: REFRESH_ROOMS, withLoader: false });
  }

  const deleteRoom = () => {
    dispatch({ type: DELETE_ROOM_FROM_MAP, roomId: marker.roomId });
    dispatch({ type: REFRESH_ROOMS, withLoader: false });
  }

  const handleMouseMove = ({ clientX, clientY }) => setMousePosition({ x: clientX, y: clientY })

  const getCursorStyle = () => {
    const { x, y } = mousePosition
    return ({ top: (y - 12) + 'px', left: (x - 12) + 'px' })
  }

  const packMarker = e => {
    const elem = document.getElementById('map')

    const x = e.offsetX
    const y = e.offsetY
    const xP = (x / elem.offsetWidth) * 100
    const yP = (y / elem.offsetHeight) * 100

    return { x: xP.toFixed(2), y: yP.toFixed(2) }
  }

  const postMarker = (floorId, marker) => dispatch({ type: ADD_MARKER, floorId, marker })

  const addMarker = e => {
    e.stopPropagation()

    const packedMarker = { ...packMarker(e), room: marker.roomId, icon: marker.icon }
    postMarker(marker.floorId, packedMarker)

    document.removeEventListener('click', addMarker)
  }

  useEffect(() => {
    if (cursorIndicator) {
      setVisible(false)
      return () => setVisible(true)
    }
  }, [cursorIndicator])

  useEffect(() => {
    if (cursorIndicator) {
      document.removeEventListener('click', handleClickOutside)
      document.addEventListener('click', addMarker)
    } else {
      document.addEventListener('click', handleClickOutside)
      document.removeEventListener('click', addMarker)
    }
  }, [cursorIndicator])

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('click', addMarker)
    }
  }, [])

  return (
    <>
      <div
        id={`toolbar-${index}`}
        className={`marker__toolbar ${infoTabVisibility ? 'wide' : ''}`}
        style={{ ...style, display: visible ? 'flex' : 'none' }}
      >
        {infoTabVisibility
          ? (
            <ToolbarInfo
              marker={marker}
              roomEditModalManipulation={roomEditModalManipulation}
              setInfoTabVisibility={setInfoTabVisibility}
            />
          )
          : (
            <>
              <MoveIcon
                className='tooltip'
                tooltipText='Переместить маркер'
                onClick={() => setCursorIndicator(val => !val)}
              />
              <InfoIcon
                onClick={() => setInfoTabVisibility(true)}
                className='tooltip'
                tooltipText='Показать информацию'
              />
              <EditIcon
                className='tooltip'
                tooltipText='Редактировать информацию'
                width='20'
                height='20'
                color='#0079C1'
                onClick={() => roomEditModalManipulation.set(marker.roomId)}
              />
              <CrossIcon
                className='tooltip'
                tooltipText='Удалить маркер'
                style={{ marginTop: '6px' }}
                onClick={deleteMarker}
              />
              <DeleteIcon
                className='tooltip'
                tooltipText='Удалить помещение'
                width='18'
                height='18'
                onClick={deleteRoom}
              />
            </>
          )}
      </div>

      <div
        id='cursor'
        className='cursor-indicator'
        style={{ display: cursorIndicator ? 'block' : 'none', ...getCursorStyle() }}
      />
    </>
  )
}

const Toolbar = ({ start, style, marker, onClose, index }) => start
  && ReactDOM.createPortal((
    <ToolbarInner
      style={style}
      marker={marker}
      onClose={onClose}
      index={index}
    />
  ), rootDocument)

export default Toolbar
