import React from 'react'

const PlusRounded = ({ className, width = 16, height = 16, color = '#31CB81' }) => (
  <svg className={className} width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M8 0C3.57333 0 0 3.57333 0 8C0 12.4267 3.57333 16 8 16C12.4267 16 16 12.4267 16 8C16 3.57333 12.4267 0 8 0ZM8 14.9333C4.16 14.9333 1.06667 11.84 1.06667 8C1.06667 4.16 4.16 1.06667 8 1.06667C11.84 1.06667 14.9333 4.16 14.9333 8C14.9333 11.84 11.84 14.9333 8 14.9333Z' fill={color} />
    <path d='M12.2669 7.46687H8.53354V3.73354C8.53354 3.41354 8.3202 3.2002 8.0002 3.2002C7.6802 3.2002 7.46687 3.41354 7.46687 3.73354V7.46687H3.73354C3.41354 7.46687 3.2002 7.68019 3.2002 8.00019C3.2002 8.32019 3.41354 8.53354 3.73354 8.53354H7.46687V12.2669C7.46687 12.5869 7.6802 12.8002 8.0002 12.8002C8.3202 12.8002 8.53354 12.5869 8.53354 12.2669V8.53354H12.2669C12.5869 8.53354 12.8002 8.32019 12.8002 8.00019C12.8002 7.68019 12.5869 7.46687 12.2669 7.46687Z' fill={color} />
  </svg>
)

export default PlusRounded
