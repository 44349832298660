export const USERS_LOADING = 'USERS_LOADING'

export const CREATE_OPERATOR = 'CREATE_OPERATOR'
export const CREATE_OPERATOR_SUCCEED = 'CREATE_OPERATOR_SUCCEED'

export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCEED = 'GET_USERS_SUCCEED'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCEED = 'DELETE_USER_SUCCEED'

export const PUT_USER = 'PUT_USER'
export const PUT_USER_SUCCEED = 'PUT_USER_SUCCEED'

export const GET_ACCESS_GROUPS = 'GET_ACCESS_GROUPS'
export const GET_ACCESS_GROUPS_SUCCEED = 'GET_ACCESS_GROUPS_SUCCEED'

export const SET_ACCESS_GROUPS_LOADING = 'SET_ACCESS_GROUPS_LOADING'

export const GET_ACCESS_GROUP = 'GET_ACCESS_GROUP'
export const GET_GROUP_SUCCEED = 'GET_GROUP_SUCCEED'

export const PUT_USERS_IN_ACCESS_GROUP = 'PUT_USERS_IN_ACCESS_GROUP'

export const POST_GROUP = 'POST_GROUP'

export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_SUCCEED = 'DELETE_GROUP_SUCCEED'

export const CLEAR_GROUP = 'CLEAR_GROUP'
export const CLEAN_USERS = 'CLEAN_USERS'

export const UPDATE_GROUP = 'UPDATE_GROUP'

export const CONFIRM_EMAIL = 'CONFIRM_EMAIL'
export const CONFIRM_EMAIL_SUCCEED = 'CONFIRM_EMAIL_SUCCEED'

export const CREATE_KIOSK = 'CREATE_KIOSK'
export const CREATE_KIOSK_SUCCEED = 'CREATE_KIOSK_SUCCEED'

export const PUT_KIOSK = 'PUT_KIOSK'
export const PUT_KIOSK_SUCCEED = 'PUT_KIOSK_SUCCEED'

export const GET_KIOSKS = 'GET_KIOSKS'
export const GET_KIOSKS_SUCCEED = 'GET_KIOSKS_SUCCEED'

export const SEND_EMAIL = 'SEND_EMAIL'

export const RESET_PASSWORD = 'RESET_PASSWORD'

export const PROMOTE_OPERATOR = 'PROMOTE_OPERATOR'
export const PROMOTE_OPERATOR_SUCCEED = 'PROMOTE_OPERATOR_SUCCEED'

export const SET_IMPORT_USERS_GROUP_LOADING = 'SET_IMPORT_USERS_GROUP_LOADING'
export const SET_IMPORT_USERS_GROUP_ERROR = 'SET_IMPORT_USERS_GROUP_ERROR'

export const IMPORT_USERS_IN_GROUP = 'IMPORT_USERS_IN_GROUP'
export const IMPORT_USERS_IN_GROUP_SUCCEED = 'IMPORT_USERS_IN_GROUP_SUCCEED'

export const IMPORT_GROUPS = 'IMPORT_GROUPS'
export const IMPORT_GROUPS_SUCCEED = 'IMPORT_GROUPS_SUCCEED'

export const GET_USERS_FOR_NOTIFICATIONS = 'GET_USERS_FOR_NOTIFICATIONS'
export const GET_USERS_FOR_NOTIFICATIONS_SUCCEED = 'GET_USERS_FOR_NOTIFICATIONS_SUCCEED'
export const GET_USERS_FOR_NOTIFICATIONS_SEARCH = 'GET_USERS_FOR_NOTIFICATIONS_SEARCH'
export const GET_ACCESS_GROUP_NO_REDUX = 'GET_ACCESS_GROUP_NO_REDUX'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCEED = 'CREATE_USER_SUCCEED'

export const GET_GROUP_ACCESS_SUCCEED = 'GET_GROUP_ACCESS_SUCCEED'
export const GET_GROUP_ACCESS = 'GET_GROUP_ACCESS'

export const GET_USER_ACCESS_GROUPS = 'GET_USER_ACCESS_GROUPS'
export const GET_USER_ACCESS_GROUPS_SUCCEED = 'GET_USER_ACCESS_GROUPS_SUCCEED'
