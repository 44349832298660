import React from 'react'

const Food = ({ className = 'icon__add_white', color = 'white', height = '16', width = '16' }) => (
  <div className={className} >
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.2351 27.014L22.8592 16.6946H22.8975C23.3015 16.6946 23.6291 16.367 23.6291 15.963V1.08755C23.6291 0.8305 23.4947 0.593272 23.2751 0.460931C23.0554 0.328468 22.7828 0.32065 22.5562 0.440311C19.7035 1.94555 17.9177 4.90517 17.9177 8.13157V14.4038C17.9177 15.6688 18.9435 16.6945 20.2086 16.6945H20.3821L19.0062 27.0139C18.9062 27.767 19.1357 28.5281 19.6358 29.0997C20.1369 29.6715 20.8606 30 21.6206 30C22.3806 30 23.1043 29.6715 23.6055 29.0997C24.1056 28.5281 24.3351 27.7672 24.2351 27.014Z" fill="white" />
      <path d="M12.7713 0C12.2163 0 11.7671 0.449158 11.7671 1.00413V6.6134H10.7629V1.00413C10.7629 0.449158 10.3138 0 9.75872 0C9.20369 0 8.7546 0.449158 8.7546 1.00413V6.6134H7.75041V1.00413C7.75035 0.449158 7.30119 0 6.74622 0C6.19113 0 5.74203 0.449158 5.74203 1.00413V6.6134V7.53135V10.0673C5.74203 11.5569 6.94916 12.764 8.43877 12.764H8.66134L7.07867 27.0061C6.99435 27.768 7.23847 28.53 7.74936 29.1018C8.26131 29.6735 8.99189 30 9.75879 30C10.5257 30 11.2562 29.6735 11.7682 29.1018C12.2792 28.53 12.5233 27.768 12.4389 27.0061L10.8562 12.7641H11.0788C12.5683 12.7641 13.7755 11.557 13.7755 10.0674V7.53141V6.61346V1.00413C13.7755 0.449158 13.3263 0 12.7713 0Z" fill="white" />
    </svg>
  </div>
)

export default Food
