import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import UserInGroupItem from './UserInGroupItem'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import Button from '../../UI/Button'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import ExpandingSearch from '../../UI/ExpandingSearch'
import { GET_USERS } from '../../../actions/users'
import LargeSpin from '../../UI/Spin/LargeSpin'

const UsersInGroupList = ({
  users,
  onButtonClick,
  shouldShowBtn,
  isKiosk,
  searchEnabled,
  groupId,
  loading,
}) => {
  const [searchString, setSearchString] = useState('')
  const dispatch = useDispatch()

  const getUsers = ({ searchString }) =>
    dispatch({ type: GET_USERS, searchString, group: groupId })

  const renderUsersList = () => users?.length
    ? users.map((u, index) => (
      <UserInGroupItem isKiosk={isKiosk} key={u.id} user={u} index={index} />))
    : <EmptyPlaceholder text='В группе нет пользователей' />

  return (
    <div className='container__right_side'>
      {searchEnabled && (
        <div style={{ margin: '0 17px 20px 0' }}>
          <ExpandingSearch
            setSearchString={setSearchString}
            searchString={searchString}
            getItems={getUsers}
            placeholder='Начните искать пользователя'
            defaultExpanded
            large
          />
        </div>
      )}

      <ScrollTransition loading style={{ marginBottom: '10px' }}>
        <div className='card_list full_height' style={{ flex: 1 }}>
          {loading ? <LargeSpin styles={{ flex: 1 }} /> : renderUsersList()}
        </div>
      </ScrollTransition>

      {shouldShowBtn && (
        <div className='button_container__edit_card'>
          <Button
            icon='icon__edit icon__wrapper'
            styles='bordered_btn'
            title='Редактировать'
            onClick={onButtonClick}
          />
        </div>
      )}
    </div>
  )
}

export default UsersInGroupList
