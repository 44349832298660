import React from 'react'

const FilterItem = ({ className, name, value, id, onChange, htmlFor, children, style }) => (
  <label className={className} htmlFor={htmlFor} style={style}>
    <input type='radio' name={name} value={value} id={id} onChange={onChange} />
    {children}
  </label>
)

export default FilterItem
