import React from 'react'
import { connect } from 'react-redux'
import { Checkbox } from 'antd'

import ConfirmationModal from '../UI/modals/ConfirmationModal'
import LeftSidePanel from './LeftSidePanel'
import EmailConfirmationModal from './modals/EmailConfirmationModal'
import ExpandingSearch from '../UI/ExpandingSearch'
import FadeTransition from '../UI/animations/transition/FadeTransition'
import KioskList from './list/KioskList'
import LargeSpin from '../UI/Spin/LargeSpin'
import ListLoadingSpin from '../UI/Spin/ListLoadingSpin'
import MainCard from '../MainCard'
import ScrollTransition from '../UI/animations/transition/ScrollTransition'
import UserList from './list/UserList'
import { CLEAR_FILES } from '../../actions/files'
import { GET_USERS, DELETE_USER, CONFIRM_EMAIL, GET_KIOSKS } from '../../actions/users'
import { ITEMS_ON_PAGE } from '../../utils/data/constants'
import { GET_OFFICES } from '../../actions/offices'

export const DEFAULT_USER = { id: null, name: '' }
export const DEFAULT_USER_EMAIL = { ...DEFAULT_USER, email: '' }

class UserCard extends React.Component {
  state = {
    page: 1,
    filteredList: [],
    usersList: [],
    isConfirmShow: false,
    isSearchFieldExpanded: false,
    searchString: '',
    selectedEmail: DEFAULT_USER_EMAIL,
    isConfirmEmailShow: false,
    accountType: 'Пользователь',
    isInactiveUsersVisible: false,
  }

  componentDidMount() {
    this.props.getUsers({ page: 1 })
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      usersList: nextProps.users,
      filteredList: nextProps.users,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { accountType } = this.state
    const { getUsers, getKiosks } = this.props

    if (prevState.accountType !== accountType)
      accountType === 'Пользователь'
        ? getUsers({ page: 1, filter: this.getFilterStringForResponse() })
        : getKiosks({ page: 1 })
  }

  getFilterStringForResponse = () => this.state.isInactiveUsersVisible ? 'true' : false

  setCurrentAccountType = val => this.setState({ accountType: val, page: 1, searchString: '' })

  setSearchString = val => this.setState({ searchString: val, page: 1 })

  loadFilteredAccounts = () => {
    const { getUsers, getKiosks } = this.props
    const { page, searchString, accountType } = this.state

    const body = { page, searchString, filter: this.getFilterStringForResponse() }
    accountType === 'Пользователь' ? getUsers(body) : getKiosks(body)
  }

  onListScroll = ({ contentScrollHeight, clientHeight, scrollTop }) => {
    const { loading, count } = this.props
    const isAllFetched = count <= (this.state.page * ITEMS_ON_PAGE)

    if (loading || isAllFetched) return null

    const isScrollAtTheBottom = (contentScrollHeight - (clientHeight + scrollTop) <= 3)
    if (isScrollAtTheBottom)
      this.setState(prevState => ({ page: prevState.page + 1 }), this.loadFilteredAccounts)
  }

  onEmailConfirmIconClick = ({ id, email, name }) => this.setState({
    isConfirmEmailShow: true,
    selectedEmail: { id, name, email },
  })

  confirmEmail = () => {
    const { id } = this.state?.selectedEmail
    this.props.onEmailConfirm({ account: id },
      () => this.setState({ isConfirmEmailShow: false, selectedEmail: DEFAULT_USER_EMAIL }))
  }

  getSearchPlaceHolder = () => `Начните вводить ${this.state.accountType === 'Пользователь'
    ? 'имя пользователя'
    : 'название киоска'}`

  toggleInactiveUsersVisibility = () => this.setState(prevState => ({
    isInactiveUsersVisible: !prevState.isInactiveUsersVisible,
    page: 1,
  }), this.loadFilteredAccounts)

  renderActiveUserCheckbox = () => (
    <div className='flex_container__align_items__center flex_container__flex_1'>
      <Checkbox
        checked={this.state.isInactiveUsersVisible}
        onChange={this.toggleInactiveUsersVisibility}
      >
        Неактивные аккаунты
      </Checkbox>
    </div>
  )

  render() {
    const { loading, getUsers, getKiosks, getOffices } = this.props
    const {
      filteredList,
      page,
      isSearchFieldExpanded,
      isConfirmEmailShow,
      selectedEmail,
      accountType,
    } = this.state

    const isFirstLoading = page === 1
    const newLoading = loading && isFirstLoading
    const isUserFilterEnabled = accountType === 'Пользователь'

    return (
      <>
        <MainCard title='ВСЕ ПОЛЬЗОВАТЕЛИ' icon='icon__user'>
          <div className='flex_container full_height'>
            <LeftSidePanel
              accountType={accountType}
              setAccountType={this.setCurrentAccountType}
              getOffices={getOffices}
            />
            <div className='container__right_side'>
              <FadeTransition loading style={{ marginBottom: '12px' }}>
                <div className='flex_container flex_container__space_between'>
                  <ExpandingSearch
                    filter={this.getFilterStringForResponse()}
                    setIsExpanded={val => this.setState({ isSearchFieldExpanded: val })}
                    setSearchString={this.setSearchString}
                    searchString={this.state.searchString}
                    isExpanded={isSearchFieldExpanded}
                    placeholder={this.getSearchPlaceHolder()}
                    getItems={isUserFilterEnabled ? getUsers : getKiosks}
                    style={{ flex: 2 }}
                    defaultExpanded
                    large
                    autofocus
                  />
                  {/* {isUserFilterEnabled && this.renderActiveUserCheckbox()} */}
                </div>
              </FadeTransition>
              {newLoading
                ? <LargeSpin />
                : (
                  <ScrollTransition onScroll={this.onListScroll} loading={!newLoading}>
                    <div className='flex_container__column flex_container__center'>
                      {isUserFilterEnabled
                        ? (
                          <UserList
                            list={filteredList}
                            onEmailConfirm={this.onEmailConfirmIconClick}
                          />
                        )
                        : <KioskList list={filteredList} />}
                      {!isFirstLoading && loading && <ListLoadingSpin />}
                    </div>
                  </ScrollTransition>
                )}
            </div>
          </div>
        </MainCard>
        <EmailConfirmationModal
          {...selectedEmail}
          start={isConfirmEmailShow}
          onConfirm={this.confirmEmail}
          onClose={() => this.setState({ isConfirmEmailShow: false, selectedEmail: DEFAULT_USER })}
        />
      </>
    )
  }
}

const mapStateToProps = ({ users }) => {
  const { list, loading, count } = users
  return { users: list, loading, count }
}

const mapDispatchToProps = dispatch => ({
  clearPhoto: () => dispatch({ type: CLEAR_FILES }),
  onEmailConfirm: (user, callback) => dispatch({ type: CONFIRM_EMAIL, user, callback }),
  getUsers: ({ page, filter, searchString }) =>
    dispatch({ type: GET_USERS, page, filter, searchString }),
  getKiosks: ({ page, searchString }) => dispatch({ type: GET_KIOSKS, page, searchString }),
  getOffices: (all, callback) => dispatch({ type: GET_OFFICES, all, callback }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserCard)
