import React, { useState } from 'react'
import { withFormik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Button, Select } from 'antd'
import Gallery from 'react-dynamic-image-gallery'

import useTableFormState from '../../../../../../utils/hooks/useTableFormState'
import Modal from '../../../../../UI/modals/Modal'
import SimpleInput from '../../../../../UI/input/SimpleInput'
import ValidationMsg from '../../../../../UI/ValidationMsg'
import SimpleTextArea from '../../../../../UI/input/SimpleTextArea'
import TagsSelector from './TagsSelector'
import actions from '../../selectedReducer/actions'
import { onImageUpload } from '../../../uploadImage'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import SimpleSelect from '../../../../../UI/input/SimpleSelect'
import useSelectedReducer from '../../selectedReducer/reducer'

const { ADD_TABLE_SUCCESS, UPDATE_TABLE_SUCCESS } = actions

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Обязательное поле')
    .max(140, 'Название не более 140 символов'),
  description: Yup.string()
    .max(150, 'Описание не более 150 символов'),
  room: Yup.string()
    .nullable(),
})

const InnerForm = ({
  start,
  onClose,
  values,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  officeId,
  isNew,
  roomId,
  table,
  setSelectedDispatch,
  tags,
  isRoomUnified,
}) => {
  const [{ rooms = [] }] = useSelectedReducer(officeId)

  const onlyBookableRooms = rooms.length
    ? rooms?.filter(r => r.is_bookable && !r.room_type_unified)
    : null

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [postTable, putTable] = useTableFormState(officeId)

  const getErrorCondition = field => errors[field] && touched[field]

  const isFormValid = ValidationSchema.isValidSync(values)

  const succeedCallback = table => {
    const actionObj = isNew
      ? { type: ADD_TABLE_SUCCESS, table, roomId }
      : {
        type: UPDATE_TABLE_SUCCESS,
        table,
        roomId: isNew ? roomId : values.room,
        oldRoomId: roomId,
      }

    setSelectedDispatch(actionObj)
    onClose()
  }

  const handleSubmit = () => {
    setIsSubmitDisabled(true)

    const payload = {
      title: values.title.trim().replace(/ +(?= )/g, ''),
      description: values.description,
      tags: values.tags.length ? values.tags.map(tag => tag.title) : null,
      room: isNew ? roomId : values.room,
      images: values.images.map(img => img.id),
    }

    isNew
      ? postTable(payload, succeedCallback)
      : putTable(payload, succeedCallback, table.id)

    setIsSubmitDisabled(false)
  }

  const setImages = images => {
    const fulFilledImages = images.map(({ status, value }) => status === 'fulfilled' && value)

    const nextImagesArr = values.images.length
      ? [...values.images, ...fulFilledImages]
      : fulFilledImages

    setFieldValue('images', nextImagesArr)
  }

  const handleImageUpload = e => onImageUpload(e, setImages)

  const onImageDelete = deletedImage =>
    setFieldValue('images', values.images.filter(img => img.id !== deletedImage.id))

  const getFormTitle = () => isNew ? 'ДОБАВИТЬ РАБОЧЕЕ МЕСТО' : 'РЕДАКТИРОВАТЬ РАБОЧЕЕ МЕСТО'

  return (
    <Modal extraClassName='table-form-modal' start={start} onClose={onClose}>
      <h3 className='table-form-modal__title'>{getFormTitle()}</h3>
      <ScrollTransition style={{ height: '100%' }} loading>
        <div className='table-form-modal__form-wrapper'>
          <span className='table-form-modal__form-title'>
            Заполните данные о рабочем месте
          </span>
          <SimpleInput
            name='title'
            label='Номер стола'
            value={values.title}
            placeholder='Введите номер стола'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('title')}
          >
            <ErrorMessage component={ValidationMsg} name='title' />
          </SimpleInput>
          <SimpleTextArea
            name='description'
            label='Описание'
            value={values.description}
            placeholder='Введите описание'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('description')}
            rows={3}
          >
            <ErrorMessage component={ValidationMsg} name='description' />
          </SimpleTextArea>
          {!(isNew || isRoomUnified) && (
            <SimpleSelect
              setFieldTouched={setFieldTouched}
              name='room'
              label='Помещение'
              placeholder='Выберите Помещени'
              onChange={setFieldValue}
              disabled={onlyBookableRooms === null}
              value={onlyBookableRooms?.length ? values.room : 'Загрузка...'}
            >
              {rooms?.map(room => (
                <Select.Option
                  disabled={!room.is_bookable || room.room_type_unified}
                  key={room.id}
                  value={room.id}
                >
                  {room.title}
                </Select.Option>
              ))}
            </SimpleSelect>
          )}
          <TagsSelector
            inputProps={{ setFieldTouched }}
            name='tags'
            selectedTags={values.tags}
            setFieldValue={setFieldValue}
            tags={tags}
          />
          <span className='table-form-modal__form-title'>
            Добавьте фото рабочего места
          </span>
          <Gallery
            source={values.images}
            itemsToShow={values.images.length}
            itemsToShowInPreview={5}
            uploadEnabled
            isItemDeleteEnable
            onUpload={handleImageUpload}
            onItemDelete={onImageDelete}
          />
        </div>
      </ScrollTransition>
      <div className='table-form-modal-actions'>
        <Button type='text' onClick={onClose}>Отмена</Button>
        <Button
          disabled={isSubmitDisabled || !isFormValid}
          type='primary'
          onClick={handleSubmit}
        >
          {isNew ? 'Сохранить и добавить' : 'Сохранить изменения'}
        </Button>
      </div>
    </Modal>
  )
}

const mapPropsToValues = ({ roomId, isNew, table = {} }) => ({
  title: table.title || '',
  description: table.description || '',
  tags: table.tags || [],
  images: table.images || [],
  room: isNew ? roomId : table.room,
})

const TableFormModal = withFormik({
  mapPropsToValues,
  validationSchema: ValidationSchema,
})(InnerForm)

export default TableFormModal
