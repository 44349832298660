import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { GET_OFFICE_TAGS, POST_TABLE_NEW_DESIGN, PUT_TABLE_NEW_DESIGN } from '../../actions/offices'

export default officeId => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: GET_OFFICE_TAGS, officeId })
  }, [])

  const postTable = (table, callback) =>
    dispatch({ type: POST_TABLE_NEW_DESIGN, table, callback })

  const putTable = (table, callback, tableId) =>
    dispatch({ type: PUT_TABLE_NEW_DESIGN, table, callback, tableId })

  return [postTable, putTable]
}
