import React from 'react'

const WorkPlace = ({ className = 'icon__add_white', color = 'white', height = '16', width = '16' }) => (
  <div className={className} >
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.00049" y="11.091" width="20" height="3.63636" fill="#FBFBFB" />
      <rect x="7.45496" y="11.091" width="9.09091" height="1.81818" fill="#FBFBFB" />
      <rect x="20.1823" y="11.091" width="10.9091" height="1.81818" transform="rotate(90 20.1823 11.091)" fill="#FBFBFB" />
      <rect x="5.63672" y="11.091" width="10.9091" height="1.81818" transform="rotate(90 5.63672 11.091)" fill="#FBFBFB" />
      <path d="M5.63672 2H7.4549V9.27273H5.63672V2Z" fill="#FBFBFB" />
      <path d="M16.546 2H18.3642V9.27273H16.546V2Z" fill="#FBFBFB" />
      <rect x="16.546" y="2" width="1.81818" height="9.09091" transform="rotate(90 16.546 2)" fill="#FBFBFB" />
      <rect x="18.3643" y="7.45447" width="1.81818" height="12.7273" transform="rotate(90 18.3643 7.45447)" fill="#FBFBFB" />
      <rect x="12.9094" y="7.45447" width="3.63636" height="1.81818" transform="rotate(90 12.9094 7.45447)" fill="#FBFBFB" />
    </svg>
  </div>
)

export default WorkPlace
