import React from 'react'

class ListPopUp extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.clickHandler)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickHandler)
  }

  onClickHandler = ({ innerText }) => {
    const optionClickHandler = this.props.options.find(({ title }) => title === innerText).onClick
    optionClickHandler()
  }

  clickHandler = ({ target }) => {
    const { closePopUp } = this.props
    const { nodeName } = target
    const isClickOutsideMenu = !target.closest('#list-more-menu')

    if (isClickOutsideMenu) {
      closePopUp()
      return
    }
    if (nodeName === 'LI') {
      this.onClickHandler(target)
      closePopUp()
    }
  }

  render() {
    const { options } = this.props
    return (
      <div id='list-more-menu' className='card_list__item_pop_up'>
        <ul>
          {options.map(({ title, icon, style }, i) => (
            <li key={`list-more-menu-${i}`}>
              <div className={icon} style={style} />
              {title}
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default ListPopUp
