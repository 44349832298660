import React, { useEffect, useState } from 'react'

import importFromCsvSource from './importFromCsvSource'
import Modal from '../../../../UI/modals/Modal'
import packFile from '../../../../../utils/objects/packFile'
import UploadButton from '../../../../UI/UploadButton'

const ImportFromCsvModal = ({ start, onClose, form }) => {
  const [modalDetails, setModalDetails] = useState({})

  const handleCsvImport = ({ target }) => {
    const file = target.files[0]
    if (!file) return

    const data = packFile(file)
    modalDetails.onSubmit(data, onClose)
  }

  useEffect(() => {
    setModalDetails(importFromCsvSource.find(modal => modal.form === form))
  }, [form])

  return (
    <Modal start={start} onClose={onClose} style={{ width: '400px' }}>
      <span className='modal_card__title text__uppercase'>{modalDetails.title}</span>
      <div className='flex_container__column' style={{ flex: 1, alignItems: 'center' }}>
        <h3 className='h2 text__white' style={{ marginTop: '20px' }}>{modalDetails.description}</h3>
        <div className='code__block' style={{ width: '100%' }}>
          <span className='text__code'>{modalDetails.example}</span>
        </div>
        <UploadButton
          acceptFiles='.csv'
          btnClassName='rounded_btn__green'
          btnTitle='Загрузить из файла'
          handleChange={handleCsvImport}
        />
      </div>
    </Modal>
  )
}

export default ImportFromCsvModal
