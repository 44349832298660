import React, { useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { message } from 'antd'

import UnderlinedTitle from '../../../../../UI/UnderlinedTitle'
import SubtitleWithActions from '../../../../../UI/SubtitleWithActions'
import Button from '../../../../../UI/Button'
import ImportFromCsvModal from '../../helpers/ImportFromCsvModal'
import TableHeader from '../../../../../UI/TableHeader'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import EmptyPlaceholder from '../../../../../UI/EmptyPlaceholder'
import ZonesListItem from './ZoneListItem'
import TextModal from '../../../../../UI/modals/TextModal'
import { DELETE_ZONE, POST_ZONE, PUT_ZONE } from '../../../../../../actions/offices'
import GroupsInfoModal from './GroupsInfoModal'

const LIST_COLUMNS_NAMES = ['Название зоны', 'Доступна для групп']
const COLUMNS_FLEX_VALUES = [1, 1.5]

const ZonesInfo = ({
  zones,
  postZone,
  putZone,
  deleteZone,
  match,
  groups,
}) => {
  const [isImportFromCsvModalVisible, setIsImportFromCsvModalVisible] = useState(false)
  const [isAddSingleZoneInputVisible, setIsAddSingleZoneInputVisible] = useState(false)
  const [isTextModalOpen, setIsTextModalOpen] = useState(false)
  const [isGroupsInfoModalOpen, setIsGroupsInfoModalOpen] = useState(false)

  const { current: office } = useRef(match.params.id)

  const toggleInputVisibility = () => setIsAddSingleZoneInputVisible(val => !val)

  const toggleAddMultipleZonesModal = () => setIsTextModalOpen(val => !val)

  const handleZonePost = zone => postZone({ ...zone, office },
    () => setIsAddSingleZoneInputVisible(false))

  const handleZonePut = (zoneId, updatedZone, callback) =>
    putZone(zoneId, { ...updatedZone, office }, callback)

  const isTitlesNotValid = titles => titles.some(title => title.length < 3 || title.length > 140)

  const handlePackageUpload = (titlesArray, callback) => {
    if (!isTitlesNotValid(titlesArray)) {
      postZone({ title: titlesArray, office }, callback, true)
      return
    }
    message.error('Некорректные значения. Длина названия зоны – от 3 до 140 символов')
  }

  const renderImportFromCsvBtn = () => (
    <Button
      icon='icon__download_2'
      styles='rounded_btn__yellow'
      title='Импорт из .csv'
      onClick={() => setIsImportFromCsvModalVisible(true)}
    />
  )

  const renderZonesList = () => zones.length || isAddSingleZoneInputVisible
    ? (
      <ScrollTransition loading>
        <div className='zones-list'>
          {isAddSingleZoneInputVisible && (
            <ZonesListItem
              isNew
              groups={groups}
              onSave={handleZonePost}
              onCancel={toggleInputVisibility}
            />
          )}
          {zones.map(zone => (
            <ZonesListItem
              zone={zone}
              key={zone.id}
              groups={groups}
              onDelete={deleteZone}
              onSave={(updatedZone, callback) => handleZonePut(zone.id, updatedZone, callback)}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Список зон пуст' />

  return (
    <>
      <div className='flex_container__column' style={{ paddingRight: '10px' }}>
        <UnderlinedTitle title='ЗОНЫ' />
        <Button
          style={{ position: 'absolute', top: 28, right: 0 }}
          styles='simple-btn'
          title='Информация о допущенных группах'
          onClick={() => setIsGroupsInfoModalOpen(true)}
        />
        <SubtitleWithActions
          style={{ width: 'fit-content' }}
          subtitle={`Всего зон: ${zones.length || 0}`}
        // actions={renderImportFromCsvBtn()}
        />
        <div className='btn-group' style={{ margin: '12px 0 20px' }}>
          <Button
            onClick={toggleInputVisibility}
            styles='btn_solid__light-blue btn-group__item'
            title={isAddSingleZoneInputVisible ? 'Отменить добавление зоны' : 'Добавить 1 зону'}
          />
          <Button
            onClick={toggleAddMultipleZonesModal}
            styles='btn_solid__light-blue btn-group__item'
            title='Добавить несколько'
          />
        </div>
      </div>

      <TableHeader
        columns={LIST_COLUMNS_NAMES}
        flexValues={COLUMNS_FLEX_VALUES}
        rightPaddingWidth={34}
      />
      {renderZonesList()}

      <ImportFromCsvModal
        form='zones'
        onClose={() => setIsImportFromCsvModalVisible(false)}
        start={isImportFromCsvModalVisible}
      />

      <TextModal
        isOpen={isTextModalOpen}
        setIsOpen={setIsTextModalOpen}
        onSubmit={handlePackageUpload}
        title='ДОБАВИТЬ НЕСКОЛЬКО ЗОН'
        subtitle='Введите названия зон через запятую'
        hint='Пример: Коворкинг, Зона для сотрудников, Парковка'
        submitTitle='Добавить зоны'
      />

      <GroupsInfoModal
        groups={zones.reduce((acc, z) => z.groups?.length ? [...acc, ...z.groups] : acc, [])}
        start={isGroupsInfoModalOpen}
        onClose={() => setIsGroupsInfoModalOpen(false)}
      />
    </>
  )
}

const mapStateToProps = ({ offices_newDesign: offices = {}, users }) => {
  const { zones = [] } = offices.office
  return ({ zones: zones || [], groups: users?.groups?.list })
}

const mapDispatchToProps = dispatch => ({
  deleteZone: zoneId => dispatch({ type: DELETE_ZONE, zoneId }),
  postZone: (zone, callback, isPackageUpload) =>
    dispatch({ type: POST_ZONE, zone, callback, isPackageUpload }),
  putZone: (zoneId, zone, callback) => dispatch({ type: PUT_ZONE, zoneId, zone, callback }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ZonesInfo))
