import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from '@react-pdf/renderer'
import QRious from 'qrious'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    fontSize: '8',
    fontFamily: 'Roboto',
  },
  wrapper: {
    width: '30%',
    padding: '2px',
    marginBottom: '16px',
  },
  image: {
    border: 1,
    marginTop: '4px',
    borderStyle: 'dashed',
    padding: '16px',
  },
})

export default ({ rooms }) => {
  const handleTableList = (tableList, roomTitle) =>
    tableList.map(table => ({ ...table, roomTitle }))

  const prepareTables = () =>
    rooms.reduce((acc, cur) => [...acc, ...handleTableList(cur.tables, cur.title)], [])

  const makeSrc = value => new QRious({ value }).toDataURL()

  const renderCodes = () => prepareTables().map(table => (
    <View key={table.id} wrap={false} style={styles.wrapper}>
      <Text style={styles.roomTitle}>Помещение: {table.roomTitle}</Text>
      <Text style={styles.tableTitle}>Стол: {table.title}</Text>
      <Image style={styles.image} src={makeSrc(table.id)} />
    </View>
  ))

  return (
    <Document>
      <Page style={styles.body} size='A4'>
        {renderCodes()}
      </Page>
    </Document>
  )
}
