import React from 'react'
import AnimatedHeight from '../../UI/animations/AnimatedHeight'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'

const FloorsList = ({ condition, setActiveFloor, floors }) => {
  const getPopUpStyle = () => ({
    position: 'absolute',
    maxHeight: '400px',
    top: '50px',
    left: 0,
    height: 42 * floors?.length || 100,
    zIndex: 10,
  })

  return (
    <ScrollTransition loading style={getPopUpStyle()}>
      <AnimatedHeight condition={condition}>
        <div id='floorsList' className='pop_up__floor__select'>
          {floors?.map(floor => (
            <div
              key={floor.id}
              className='pop_up__floor__select_item'
              onClick={() => setActiveFloor(floor.id)}
            >
              {floor.title}
            </div>
          ))}
        </div>
      </AnimatedHeight>
    </ScrollTransition>
  )
}

export default FloorsList
