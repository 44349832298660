import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import * as _ from 'lodash'
import { message } from 'antd'

import MainCard from '../../MainCard'
import GroupInfoForm from './GroupInfoForm'
import UsersPickContainer from './UsersPickContainer'
import {
  GET_ACCESS_GROUP,
  GET_USERS,
  POST_GROUP,
  CLEAR_GROUP,
  UPDATE_GROUP,
} from '../../../actions/users'

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Название должно содержать минимум 2 символа')
    .max(50, 'Максимальная длина 50 символов')
    .required('Обязательное поле'),
  description: Yup.string()
    .max(1020, 'Описание не более 1020 символов'),
})

const translateAccessToRights = (access, withLongName) => {
  const extraName = withLongName ? 'can_' : ''
  const isAdmin = (access === 'Администратор')
  const isOperator = (access === 'Оператор')

  return {
    [`global_${extraName}read`]: true,
    [`global_${extraName}write`]: isOperator || isAdmin,
    [`global_${extraName}manage`]: isAdmin,
  }
}

const translateRightsToAccess = rights => {
  if (rights?.global_manage) return 'Администратор'
  return rights?.global_write ? 'Оператор' : 'Посетитель'
}

class InnerForm extends React.Component {
  radioGroupData = ['Посетитель', 'Администратор']

  componentDidMount() {
    const { getGroup, match, getUsersList, isNew } = this.props
    !isNew && getGroup(match.params.id)
    getUsersList()
  }

  componentWillUnmount() {
    this.props.clearGroup()
  }

  anyChangesInTheValues = usersList => {
    const { values, group } = this.props
    const { id, pre_defined, count, users, ...groupMainInfo } = group

    const valuesMainInfo = {
      title: values.title,
      description: values.description || '',
      ...translateAccessToRights(values.access, false),
    }

    if (!group.description) groupMainInfo.description = ''

    return {
      mainInfo: !_.isEqual(valuesMainInfo, groupMainInfo),
      usersInfo: !_.isEqual(usersList, group.users),
    }
  }

  redirectToGroupPage = () => {
    this.props.history.push(`/groups/${this.props.match.params.id}`)
    message.success('Изменения сохранены')
  }

  packGroup = (changes, basicGroupInfo, usersIdArray) => {
    const { match } = this.props
    return ({
      id: match.params.id,
      mainInfo: changes.mainInfo ? basicGroupInfo : null,
      usersInfo: changes.usersInfo ? usersIdArray : null,
    })
  }

  getBasicGroupInfo = () => {
    const { access, ...valuesWithoutAccessField } = this.props.values
    return { ...valuesWithoutAccessField, ...translateAccessToRights(access, true) }
  }

  saveGroup = ({ usersList }) => {
    const {
      updateGroup,
      isNew,
      values,
      postNewGroup,
      history,
      setTouched,
      setSubmitting,
      isSubmitting,
    } = this.props

    if (isSubmitting) return

    setSubmitting(true)

    setTouched({ title: true, description: true })
    if (!ValidationSchema.isValidSync(values)) {
      setSubmitting(false)
      return
    }

    const users = usersList?.length ? usersList?.map(u => u.id) : []

    if (isNew) {
      const usersObj = { ...this.getBasicGroupInfo(), users }
      postNewGroup(
        usersObj,
        createdGroupId => history.push(`/groups/${createdGroupId}`),
        () => setSubmitting(false),
      )
      return
    }

    const changes = this.anyChangesInTheValues(usersList)
    const areValuesChanged = _.values(changes).some(item => Boolean(item))
    if (!areValuesChanged) {
      setSubmitting(false)
      message.info('Изменений не обнаружено')
      return
    }

    const groupInfo = this.packGroup(changes, this.getBasicGroupInfo(), users)
    updateGroup(groupInfo, this.redirectToGroupPage, () => setSubmitting(false))
  }

  getErrorCondition = field => this.props.touched[field] && this.props.errors[field]

  render() {
    const { loading, values, setFieldValue, setFieldTouched, group, history } = this.props
    const isKiosk = group?.title === 'Информационный киоск'

    group?.pre_defined && isKiosk && history.push(`/groups/${group.id}`)

    return (
      <>
        <MainCard
          title='Группа доступа'
          icon='icon__user'
          loading={loading}
          style={{ width: '100%' }}
        >
          <div className='flex_container full_height'>
            <GroupInfoForm
              values={values}
              preDefined={group?.pre_defined}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              radioOptions={this.radioGroupData}
              getErrorCondition={this.getErrorCondition}
            />
            <UsersPickContainer onSave={this.saveGroup} />
          </div>
        </MainCard>
      </>
    )
  }
}

const GroupEditCard = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ group, isNew }) => isNew
    ? { title: '', description: '', access: 'Посетитель' }
    : {
      title: group?.title,
      description: group?.description,
      access: translateRightsToAccess(group),
    },
  validationSchema: ValidationSchema,
})(InnerForm)

const mapStateToProps = ({ users }) => ({
  usersLoading: users?.loading,
  group: users?.groups?.currentGroup,
  loading: users?.groups?.loading,
})

const mapDispatchToProps = dispatch => ({
  updateGroup: ({ id, mainInfo, usersInfo }, succeedCallback, failedCallback) =>
    dispatch({ type: UPDATE_GROUP, id, mainInfo, usersInfo, succeedCallback, failedCallback }),
  postNewGroup: (group, succeedCallback, failedCallback) =>
    dispatch({ type: POST_GROUP, group, succeedCallback, failedCallback }),
  getGroup: id => dispatch({ type: GET_ACCESS_GROUP, id }),
  getUsersList: () => dispatch({ type: GET_USERS, filter: true }),
  clearGroup: () => dispatch({ type: CLEAR_GROUP }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupEditCard))
