import { CHANGE_PASSWORD, LOG_IN_USER } from '../account'

export const changeUserPassword = (passwords, callback) => ({
  type: CHANGE_PASSWORD,
  passwords,
  callback,
})

export const logInUser = (credentials, callback) => ({
  type: LOG_IN_USER,
  credentials,
  callback,
})
