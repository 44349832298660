import React from 'react'

import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import UserCard from './UserCard'

import '../../../styles/animations/userForm.scss'

const UserList = ({ list, onDelete, onEmailConfirm }) => (
  <div className='container__list '>
    {(list?.length > 0)
      ? list.map((u, index) => (
        <UserCard
          key={u.id}
          user={u}
          onDelete={onDelete}
          index={index}
          onEmailConfirm={onEmailConfirm}
        />
      ))
      : <EmptyPlaceholder text='Список пользователей пуст' />}
  </div>
)

export default UserList
