import React from 'react'
import * as Yup from 'yup'
import { Form as FormikForm, withFormik } from 'formik'
import { Select } from 'antd'

import SimpleInput from '../../../UI/input/SimpleInput'
import { ErrorMsg } from '../../../UI/ValidationMsg'
import SimpleSelect from '../../../UI/input/SimpleSelect'
import Button from '../../../UI/Button'
import getBasicInputProps from '../../../../utils/getBasicInputProps'

const { Option } = Select

const ValidationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(4, 'Минимум 4 символа')
    .max(120, 'Максимум 120 символов')
    .required('Обязательное поле'),
  office: Yup.string()
    .required('Обязательное поле'),
  floor: Yup.string()
    .required('Обязательное поле'),
})

const InnerForm = ({
  values,
  setFieldTouched,
  errors,
  touched,
  setFieldValue,
  setValues,
  offices,
  onSave,
  setTouched,
  resetForm,
  code,
  isEditMode,
}) => {
  const getInputProps = getBasicInputProps({
    values,
    errors,
    touched,
    setFieldTouched,
    onChange: setFieldValue,
  })

  const floorsArray = offices
    .find(({ id }) => id === values.office)?.floors
    .map(({ id, title }) => ({ id, title }))

  const handleOfficeChange = (_, val) =>
    setValues({ office: val, floor: undefined, firstname: values.firstname })

  const renderOfficeOptions = () => offices?.length > 0
    && offices.map(({ id, title }) => <Option value={id} key={id}>{title}</Option>)

  const renderFloorsOptions = () => values.office && floorsArray?.length > 0
    && floorsArray.map(({ id, title }) => <Option value={id} key={id}>{title}</Option>)

  const onFormSubmit = () => {
    setTouched({ firstname: true, office: true, floor: true })
    ValidationSchema.isValidSync(values) && onSave(values, resetForm)
  }

  return (
    <FormikForm>
      <div className='container_form__left' style={{ flex: isEditMode && 1 }}>
        <SimpleInput
          label='Название киоска*'
          placeholder='Название'
          {...getInputProps('firstname')}
        >
          <ErrorMsg field='firstname' />
        </SimpleInput>
        {code}
      </div>
      <div className='container_form__right flex_container__column' style={{ flex: 1 }}>
        <SimpleSelect
          name='office'
          label='Бизнес-центр*'
          placeholder='Укажите бизнес-центр'
          value={values.office}
          onChange={handleOfficeChange}
          setFieldTouched={setFieldTouched}
          error={errors.office && touched.office}
          errorComponent={() => <ErrorMsg field='office' />}
        >
          {renderOfficeOptions()}
        </SimpleSelect>

        <SimpleSelect
          label='Этаж*'
          placeholder='Выберите этаж'
          disabled={!values.office}
          errorComponent={() => <ErrorMsg field='floor' />}
          {...getInputProps('floor')}
        >
          {renderFloorsOptions()}
        </SimpleSelect>
        <div className='button_container__content_right' style={{ flex: !isEditMode && 1 }}>
          <Button
            styles='btn_solid'
            title={`${isEditMode ? 'Сохранить' : 'Создать'} киоск`}
            onClick={onFormSubmit}
            delay={300}
          />
        </div>
      </div>
    </FormikForm>
  )
}

const Form = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues }) => ({
    firstname: initialValues?.firstname || undefined,
    office: initialValues?.office?.id || undefined,
    floor: initialValues?.floor?.id || undefined,
  }),
  validationSchema: ValidationSchema,
})(InnerForm)

export default Form
