import React from 'react'
import { message } from 'antd'
import { connect } from 'react-redux'
import { MapInteractionCSS } from 'react-map-interaction'

import Map from './Map'
import EmptyPlaceholder from '../UI/EmptyPlaceholder'
import { translateRoomTypes } from './helpers/roomsTypes'
import { ADD_MARKER, POST_MAP_TO_FLOOR } from '../../actions/offices'
import IconPlus from '../UI/icons/Plus'
import handleUpload from './helpers/loadMap'
import MapKonva from './MapKonva'

const DEFAULT_POSITION_PARAMS = { scale: 0.4, translation: { x: 0, y: 0 } }

class ResponsibleMapContainer extends React.Component {
  state = {
    points: [],
    floor: null,
    ...DEFAULT_POSITION_PARAMS,
  }

  input = React.createRef()

  mapConfig = {
    minScale: 0.4,
    maxScale: 2,
    btnClass: 'map__button',
    showControls: true,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (nextProps.activeFloor !== prevState.floor)
      ? ({ ...DEFAULT_POSITION_PARAMS, floor: nextProps.activeFloor })
      : null
  }

  addMarker = point => {
    const { activeRoom, activeFloor, postMarker, onMapClickCallback, markers } = this.props
    const isNotUniq = markers.find(p => (p.x === point.x) && (p.y === point.y))

    if (isNotUniq) {
      message.error('В этом месте уже установлен маркер')
      return
    }
    const marker = { ...point, icon: translateRoomTypes(point.type), room: activeRoom }
    activeRoom && postMarker(activeFloor, marker, onMapClickCallback)
  }

  getMarkerStyle = roomId => roomId === this.props.activeRoom && 'marker__active'

  render() {
    const { scale, translation } = this.state
    const { markers, activeRoom, mode, loading, mapImage, activeFloor, postMap, floors } = this.props
    const activeFloorMap = floors.find(f => f.id === activeFloor)?.floor_map
    return (
      <>
        {mapImage
          ? (
            <MapKonva
              map={mapImage}
              points={markers}
              floorMap={activeFloorMap}
              activeRoom={activeRoom}
              activeFloor={activeFloor}
              mapState={this.props.mapState}
              stateWithoutRedux={this.props.stateWithoutRedux}
              setConfirmModal={this.props.setConfirmModal}

              stateWithoutRedux={this.props.stateWithoutRedux}
              dispatchWithoutRedux={this.props.dispatchWithoutRedux}
            />
          )
          : (
            <div className='container__add_image'>
              <input
                type='file'
                ref={this.input}
                className='input__file_uploader'
                onChange={e => handleUpload(e, activeFloor, postMap)}
              />
              <div className='btn__add_map' onClick={() => this.input.current.click()}>
                <IconPlus className='icon__add_inactive' />
                <EmptyPlaceholder text='Добавить карту этажа' />
              </div>
            </div>
          )}
      </>
    )
  }
}

const mapStateToProps = ({ offices_newDesign = {}, offices = {} }, { activeFloor }) => {
  const rooms = offices_newDesign.office?.rooms?.filter(room => room.floor.id === activeFloor)
  const markers = rooms?.map(({
    marker,
    id,
    floor,
    title,
    type,
    room_type_color,
    room_type_icon,
    zone,
    description,
    deleted,
    images,
  }) =>
  ({
    ...marker,
    roomId: id,
    floorId: floor.id,
    title,
    deleted,
    type,
    room_type_color,
    description,
    images,
    zoneTitle: zone.title,
    room_type_icon: room_type_icon?.thumb || room_type_icon?.path,
  }))

  const currentFloor = offices.floors?.list?.find(floor => floor.id === activeFloor)
  const mapImage = currentFloor?.floor_map?.image?.path

  return { markers, floors: offices.floors?.list, mapImage }
}

const mapDispatchToProps = dispatch => ({
  postMarker: (floorId, marker, callback) =>
    dispatch({ type: ADD_MARKER, floorId, marker, callback }),
  postMap: (floorId, map, sizes) => dispatch({ type: POST_MAP_TO_FLOOR, floorId, map, sizes }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleMapContainer)
