import React from 'react'

const DEFAULT_IMAGE = 'https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80'

const ToolbarInfo = props => {
  const {
    marker,
    roomEditModalManipulation,
    setInfoTabVisibility,
    top,
    left,
    currentRadius,
    mapState,
  } = props

  return (
    <div
      className='map-point-info__wrap'
      style={{
        top: top - currentRadius * mapState.scale,
        left: left,
      }}>
      <div className='map-point-info__image-wrap'>
        <div
          className='map-point-info__image'
          style={{ backgroundImage: `url(${marker.images.length ? marker.images[0].path : DEFAULT_IMAGE})` }}
        />
      </div>
      <div className='map-point-info__text-wrap'>
        <span className='map-point-info__title'>{marker.title}</span>
        <span className='map-point-info__zone-title'>{marker.zoneTitle}</span>
      </div>
      <div className='map-point-info__button-wrapper'>
        <div className='map-point-info__button' onClick={() => roomEditModalManipulation().set(marker.roomId)}>
          <span>Изменить инфо</span>
        </div>
        <div className='map-point-info__button' onClick={() => setInfoTabVisibility(false)}>
          <span>Закрыть</span>
        </div>
      </div>
    </div>
  )
}


export default ToolbarInfo
