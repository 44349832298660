import React from 'react'

import BucketWithoutLid from '../UI/icons/BucketWithoutLid';
import LidBucket from '../UI/icons/LidBucket';

const BucketKonva = (props) => {
  const {
    isBucketHover,
    handleHoverBucket,
    handleDropBucket,
    handleMouseLevaeFromBucket,
    style = {},
  } = props;

  const getLidBucketStyle = isBucketHover => ({
    opacity: isBucketHover ? 1 : 0.5,
    transformOrigin: 'right bottom',
    transform: isBucketHover ? 'rotate(45deg)' : 'rotate(0deg)',
  })

  return (
    <div
      className='map-bucket-wrap'
      style={{ cursor: isBucketHover ? 'none' : 'default', ...style }}
      onMouseEnter={handleHoverBucket}
      onMouseUp={handleDropBucket}
      onMouseLeave={handleMouseLevaeFromBucket}
    >
      <LidBucket
        style={getLidBucketStyle(isBucketHover)}
        fill={isBucketHover ? '#CD4141' : 'white'}
      />
      <BucketWithoutLid
        style={{ opacity: isBucketHover ? 1 : 0.5 }}
        fill={isBucketHover ? '#CD4141' : 'white'}
      />
    </div>
  )
}

export default BucketKonva
