import { useReducer, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from './actions'
import {
  GET_OFFICE_LOCAL,
  GET_OFFICE_TAGS,
  GET_FILTERED_ROOMS,
} from '../../../../../actions/offices'

const {
  SET_INITIAL,
  SET_INITIAL_SUCCESS,
  SET_INITIAL_FAILED,
  CHECK_ROOM,
  CHECK_ALL_ROOMS,
  CHECK_ALL_TABLES,
  CHECK_TABLE,
  ADD_ROOM_SUCCESS,
  ADD_TABLE_SUCCESS,
  UPDATE_TABLE_SUCCESS,
  UPDATE_ROOM_SUCCESS,
  DELETE_ROOMS_SUCCESS,
  DELETE_TABLES_SUCCESS,
  SET_ROOMS,
} = actions

const initialCheckboxState = {
  tags: [],
  rooms: [],
  selectedRoomsCount: 0,
  selectedTablesCount: 0,
  loading: false,
}

const getSelectedRoomsCount = rooms => rooms.filter(room => room.checked).length
const getSelectedTablesCount = rooms => rooms
  .reduce((acc, cur) => [...acc, ...cur.tables], [])
  .filter(table => table.checked).length

const getSelectedItemsIn = list => list.filter(({ checked }) => checked)

const markTablesAsChecked = (tables, selectedTables) => tables.map(table => ({
  ...table,
  checked: selectedTables.some(({ id }) => id === table.id),
}))

const selectedReducer = (state, action) => {
  switch (action.type) {
    case SET_INITIAL: {
      return { ...state, loading: true }
    }

    case SET_INITIAL_SUCCESS: {
      const { office } = action
      return { ...state, ...office, loading: false }
    }

    case SET_INITIAL_FAILED: {
      return { ...state, ...initialCheckboxState }
    }

    case CHECK_ROOM: {
      const { roomId } = action

      const newRooms = state.rooms.map(room => {
        if (room.id !== roomId)
          return room

        const newRoom = { ...room }
        newRoom.checked = !newRoom.checked
        newRoom.tables = newRoom.tables.map(table => ({ ...table, checked: newRoom.checked }))
        return newRoom
      })

      const newSelectedRoomsCount = getSelectedRoomsCount(newRooms)
      const newSelectedTablesCount = getSelectedTablesCount(newRooms)

      return {
        ...state,
        rooms: newRooms,
        selectedRoomsCount: newSelectedRoomsCount,
        selectedTablesCount: newSelectedTablesCount,
      }
    }

    case CHECK_ALL_ROOMS: {
      const { checked } = action
      const newRooms = state.rooms.map(room => {
        const newRoom = { ...room }
        newRoom.checked = checked
        newRoom.tables = newRoom.tables.map(table => ({ ...table, checked }))
        return newRoom
      })

      const newSelectedRoomsCount = getSelectedRoomsCount(newRooms)
      const newSelectedTablesCount = getSelectedTablesCount(newRooms)

      return {
        ...state,
        rooms: newRooms,
        selectedRoomsCount: newSelectedRoomsCount,
        selectedTablesCount: newSelectedTablesCount,
      }
    }

    case CHECK_TABLE: {
      const { tableId, roomId } = action

      const newRooms = [...state.rooms]
      const targetRoomIndex = newRooms.findIndex(room => room.id === roomId)
      const newTargetRoom = { ...newRooms[targetRoomIndex] }

      newTargetRoom.tables = newTargetRoom.tables.map(table => {
        const newCheckedValue = table.id === tableId
          ? !table.checked
          : table.checked

        return { ...table, checked: newCheckedValue }
      })

      newRooms[targetRoomIndex] = newTargetRoom

      const newSelectedTablesCount = getSelectedTablesCount(newRooms)

      return { ...state, selectedTablesCount: newSelectedTablesCount, rooms: newRooms }
    }

    case CHECK_ALL_TABLES: {
      const { roomId, checked } = action
      const newRooms = [...state.rooms]

      const targetRoomIndex = newRooms.findIndex(({ id }) => id === roomId)
      const newTargetRoom = { ...newRooms[targetRoomIndex] }
      newTargetRoom.tables = newTargetRoom.tables.map(table => ({ ...table, checked }))

      newRooms[targetRoomIndex] = newTargetRoom

      const newSelectedTablesCount = getSelectedTablesCount(newRooms)

      return { ...state, selectedTablesCount: newSelectedTablesCount, rooms: newRooms }
    }

    case SET_ROOMS: {
      const { rooms } = action

      const selectedRooms = getSelectedItemsIn(state.rooms)

      const selectedTables = state.rooms.reduce((acc, { tables }) => {
        const selectedTablesInRoom = getSelectedItemsIn(tables)
        return selectedTablesInRoom.length
          ? [...acc, ...selectedTablesInRoom]
          : acc
      }, [])

      const newRooms = rooms.map(room => ({
        ...room,
        checked: selectedRooms.some(({ id }) => id === room.id),
        tables: markTablesAsChecked(room.tables, selectedTables),
      }))

      const newSelectedRoomsCount = getSelectedRoomsCount(newRooms)

      const newSelectedTablesCount = getSelectedTablesCount(newRooms)

      return {
        ...state,
        selectedTablesCount: newSelectedTablesCount,
        selectedRoomsCount: newSelectedRoomsCount,
        rooms: newRooms,
      }
    }

    case ADD_ROOM_SUCCESS: {
      const { room } = action

      const newRooms = [{ ...room, tables: room.tables || [] }, ...state.rooms]
      return { ...state, rooms: newRooms }
    }

    case UPDATE_ROOM_SUCCESS: {
      const { room } = action
      const newRooms = [...state.rooms]

      const targetRoomId = state.rooms.findIndex(({ id }) => id === room.id)
      newRooms[targetRoomId] = { ...newRooms[targetRoomId], ...room }

      return { ...state, rooms: newRooms }
    }

    case DELETE_ROOMS_SUCCESS: {
      const { roomIds } = action
      const newRooms = state.rooms.filter(({ id }) => !roomIds.includes(id))

      const newSelectedRoomsCount = getSelectedRoomsCount(newRooms)

      return { ...state, selectedRoomsCount: newSelectedRoomsCount, rooms: newRooms }
    }

    case ADD_TABLE_SUCCESS: {
      const { table, roomId } = action
      const newRooms = [...state.rooms]

      const targetRoomIndex = newRooms.findIndex(({ id }) => id === roomId)
      const newTargetRoom = { ...newRooms[targetRoomIndex] }

      newTargetRoom.tables = [table, ...newTargetRoom.tables]
      newRooms[targetRoomIndex] = newTargetRoom

      return { ...state, rooms: newRooms }
    }

    case UPDATE_TABLE_SUCCESS: {
      const { table, roomId, oldRoomId } = action
      const newRooms = [...state.rooms]
      const targetRoomIndex = newRooms.findIndex(({ id }) => id === roomId)
      const targetRoom = newRooms[targetRoomIndex]

      if (oldRoomId === roomId) {
        const targetTableIndex = targetRoom.tables.findIndex(({ id }) => id === table.id)
        targetRoom.tables[targetTableIndex] = table

        return { ...state, rooms: newRooms }
      }

      targetRoom.tables = [...targetRoom.tables, table]
      const oldRoomIndex = newRooms.findIndex(({ id }) => id === oldRoomId)
      const oldRoom = newRooms[oldRoomIndex]
      oldRoom.tables = oldRoom.tables.filter(t => t.id !== table.id)

      return { ...state, rooms: [...newRooms] }
    }

    case DELETE_TABLES_SUCCESS: {
      const { tableIds } = action
      const newRooms = state.rooms.map(room => {
        const newTables = room.tables.filter(({ id }) => !tableIds.includes(id))
        return { ...room, tables: newTables }
      })

      const newSelectedTablesCount = getSelectedTablesCount(newRooms)

      return { ...state, selectedTablesCount: newSelectedTablesCount, rooms: newRooms }
    }

    default:
      return state
  }
}

const tagsSelector = ({ offices_newDesign }) => offices_newDesign.office?.tags?.list || []

export default officeId => {
  const dispatch = useDispatch()
  const [state, selectedDispatch] = useReducer(selectedReducer, initialCheckboxState)
  const tags = useSelector(tagsSelector)

  const getOffice = callback =>
    dispatch({ type: GET_OFFICE_LOCAL, officeId, callback })

  const getOfficeTags = () => dispatch({ type: GET_OFFICE_TAGS, officeId })

  const getFilteredRooms = filters => {
    const callback = rooms => selectedDispatch({ type: SET_ROOMS, rooms })

    dispatch({ type: GET_FILTERED_ROOMS, filters, callback, officeId })
  }

  useEffect(() => {
    const callback = office => office
      ? selectedDispatch({ type: SET_INITIAL_SUCCESS, office })
      : selectedDispatch({ type: SET_INITIAL_FAILED })

    selectedDispatch({ type: SET_INITIAL })

    getOffice(callback)
    getOfficeTags()
  }, [])

  return [{ ...state, tags }, selectedDispatch, getFilteredRooms]
}
