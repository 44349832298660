/* eslint-disable import/no-cycle */
import React from 'react'
import { Select } from 'antd'

import { DEFAULT_TIME_INTERVAL } from './AdvancedSettings'
import IconInfo from '../../../../../UI/icons/Info'

const { Option } = Select
const SettingsItem = props => {
  const {
    onClickInfoHandler,
    handleSelectChange,
    value,
    type,
    title,
  } = props

  return (
    <div className='advanced-settings__wrap'>
      <span className='advanced-settings__label'>{title}</span>
      <Select
        style={{ width: 'auto', minWidth: '8rem', marginRight: '1rem' }}
        onChange={value => handleSelectChange(value, type)}
        defaultValue={15}
        value={value}
      >
        {DEFAULT_TIME_INTERVAL.map(i => (<Option key={i.title} value={i.value}>{i.title}</Option>))}
      </Select>
      <div
        className='icon__wrapper__hover_enabled full_height'
        onClick={() => onClickInfoHandler(type)}
      >
        <IconInfo extraClassName='icon__info_svg__orange' />
      </div>
    </div>
  )
}

export default SettingsItem
