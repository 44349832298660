import { GET_ACCOUNT_SUCCEED, SET_TOKEN, SET_TOKEN_LOADING } from '../actions/account'
import setTokenToStorage from '../utils/manageTokens'

const initialAccount = { token: { access_token: localStorage.getItem('access_token') } }

export const account = (state = initialAccount, action) => {
  switch (action.type) {
    case SET_TOKEN_LOADING: return { ...state, loading: true }

    case SET_TOKEN: {
      setTokenToStorage(action.token)
      return { ...state, token: action.token, loading: false }
    }

    case GET_ACCOUNT_SUCCEED: {
      return { ...state, account: action.account }
    }

    default: return state
  }
}

export default account
