import React, { useEffect, useState, useReducer } from 'react'
import { connect } from 'react-redux'

import FloorsList from './ControlPanel'
import LargeSpin from '../UI/Spin/LargeSpin'
import ConfirmationModal from '../UI/modals/ConfirmationModal'
import MainCard from '../MainCard'
import ResponsibleMapContainer from './ResponsibleMapContainer'
import RoomsList from './lists/RoomsList'
import RoomFormModal from '../Office/OfficeEdit/DetailInfo/Modal/RoomFormModal'
import EditRoomModalContext from './editRoomModalContext'
import { GET_OFFICE_NEW_DESIGN, DELETE_MARKER, GET_ROOM_TYPES } from '../../actions/offices'
import {
  reducer,
  INITIAL_STATE_WITHOUT_REDUX,
  SET_MAP_STATE,
  SET_STATE_WITHOUT_REDUX,
  SET_MARKER_ON_CLICK_ATTR,
  SET_MARKERS,
} from './helpers/useReducerForMapComponent'

import './styles.scss'

const DEFAULT_CONFIRM_MODAL = { isVisible: false, type: null, callback: () => { }, args: [] }

const OfficeMapFuncComponent = props => {
  const {
    getOffice,
    getRoomTypes,
    match,
    dispatch,
    floors,
    office,
    loading,
    deleteMarker,
    loadingFiles,
    roomTypes,
    zonesFromRedux,
  } = props;

  const [prevActiveFloor, setPrevActiveFloor] = useState(null);
  const [confirmModal, setConfirmModal] = useState(DEFAULT_CONFIRM_MODAL)
  const [stateWithoutRedux, dispatchWithoutRedux] = useReducer(reducer, INITIAL_STATE_WITHOUT_REDUX);

  const {
    activeFloor,
    activeRoom,
    mode,
    isRoomModalVisible,
    editableRoom,
    isAddRoomModalVisible
  } = stateWithoutRedux

  useEffect(() => {
    getOffice(match.params.id, true)
    getRoomTypes(match.params.id)
  }, [])

  useEffect(() => {
    !prevActiveFloor && floors?.length && setPrevActiveFloor(floors[0].id);
  }, [floors])

  useEffect(() => {
    const rooms = props.office.rooms?.filter(room => room.floor.id === (stateWithoutRedux.activeFloor || floors[0]?.id))
    const markers = rooms?.map(({
      marker,
      id,
      floor,
      title,
      type,
      room_type_color,
      room_type_icon,
      zone,
      description,
      deleted,
      images,
    }) =>
    ({
      ...marker,
      roomId: id,
      floorId: floor.id,
      title,
      deleted,
      type,
      room_type_color,
      description,
      images,
      zoneTitle: zone.title,
      room_type_icon: room_type_icon?.thumb || room_type_icon?.path,
    }))
    dispatchWithoutRedux({ type: SET_MARKERS, payload: markers })
  }, [stateWithoutRedux.activeFloor, office])

  const roomModalManipulating = () => ({
    set: roomId => {
      const room = props.office.rooms.find(room => room.id === roomId)
      dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { editableRoom: room, isRoomModalVisible: true } })
    },
    remove: () => {
      dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { editableRoom: null, isRoomModalVisible: false } })
    }
  })

  const setActiveRoom = (roomId, mode) => {
    const isSameRoom = roomId === stateWithoutRedux.activeRoom && mode === stateWithoutRedux.mode
    dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { mode, activeRoom: isSameRoom ? null : roomId } })
  }

  const setActiveFloor = newActiveFloorId => {
    if (newActiveFloorId === prevActiveFloor) return null
    dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { activeFloor: newActiveFloorId } })
    dispatchWithoutRedux({ type: SET_MAP_STATE, payload: { loading: true } })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
    setPrevActiveFloor(newActiveFloorId)
  }

  const handleMapClick = () => {
    dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { mode: 'read', activeRoom: undefined } })
  }

  const toogleModalAddNewRoom = () => {
    const newVisibleValue = { isAddRoomModalVisible: !stateWithoutRedux.isAddRoomModalVisible }
    dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { ...newVisibleValue } })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
  }

  const getConfirmModalTitle = type => {
    switch (type) {
      case 'removeRoom': return 'Удалить помещение?'
      case 'removeMap': return 'Удалить карту?'
      case 'removeMarkers': return 'Удалить все маркеры?'
      default: return ''
    }
  }
  const onCloseModal = () => {
    dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { isRoomModalVisible: false } })
  }

  if (!floors?.length) return <LargeSpin />

  return (
    <MainCard
      icon='icon__briefcase'
      loading={loading}
      addClass={'grabbing'}
      title={office?.title}
      innerContainerStyle={{ overflow: 'visible', flexDirection: 'row' }}
    >
      <RoomsList
        mode={mode}
        activeFloor={activeFloor || floors[0].id}
        activeRoom={activeRoom}
        deleteMarker={deleteMarker}
        setActiveRoom={setActiveRoom}
        addNewRoomHandler={toogleModalAddNewRoom}
        stateWithoutRedux={stateWithoutRedux}
        setConfirmModal={setConfirmModal}
        dispatchWithoutRedux={dispatchWithoutRedux}
      />
      <div className='container__rooms_and_map'>
        <FloorsList
          activeFloor={activeFloor || floors[0].id}
          setActiveFloor={setActiveFloor}
          stateWithoutRedux={stateWithoutRedux}
          dispatchWithoutRedux={dispatchWithoutRedux}
          setConfirmModal={setConfirmModal}
        />
        <div id='map-container' className='container_map'>
          {loadingFiles
            ? <LargeSpin />
            : (
              <EditRoomModalContext.Provider value={roomModalManipulating}>
                <ResponsibleMapContainer
                  mode={mode}
                  activeRoom={activeRoom}
                  activeFloor={activeFloor || floors[0].id}
                  onMapClickCallback={handleMapClick}
                  stateWithoutRedux={stateWithoutRedux}
                  setConfirmModal={setConfirmModal}
                  stateWithoutRedux={stateWithoutRedux}
                  dispatchWithoutRedux={dispatchWithoutRedux}
                />
              </EditRoomModalContext.Provider>
            )}
        </div>
      </div>
      <ConfirmationModal
        start={confirmModal.isVisible}
        text={getConfirmModalTitle(confirmModal.type)}
        onClose={() => setConfirmModal(DEFAULT_CONFIRM_MODAL)}
        onConfirm={() => {
          confirmModal.callback()
          setConfirmModal(DEFAULT_CONFIRM_MODAL)
        }}
      />
      {isRoomModalVisible && (
        <RoomFormModal
          mapMode
          room={editableRoom}
          zones={office.zones}
          floors={office.floors}
          roomTypes={roomTypes}
          start={isRoomModalVisible}
          onClose={onCloseModal}
          dispatchWithoutRedux={dispatchWithoutRedux}
        />
      )}
      {isAddRoomModalVisible && (
        <RoomFormModal
          isNew
          start={isAddRoomModalVisible}
          onClose={toogleModalAddNewRoom}
          setSelectedDispatch={dispatch}
          floors={floors}
          zones={zonesFromRedux}
          roomTypes={roomTypes}
          activeFloor={activeFloor || floors[0].id}
          getOffice={() => getOffice(match.params.id, true)}
          dispatchWithoutRedux={dispatchWithoutRedux}
          test={1}
        />
      )}
    </MainCard>
  )
}

const mapStateToProps = ({ files = {}, offices_newDesign = {} }) => {
  const { zones = {} } = offices_newDesign.office

  return ({
    office: offices_newDesign.office,
    loading: offices_newDesign.office.loading,
    floors: offices_newDesign.office.floors,
    loadingFiles: files.loading,
    roomTypes: offices_newDesign.roomTypes?.list,
    zonesFromRedux: zones || [],
  })
}

const mapDispatchToProps = dispatch => ({
  getOffice: (officeId, expanded) => dispatch({ type: GET_OFFICE_NEW_DESIGN, officeId, expanded }),
  deleteMarker: (floorId, room) => dispatch({ type: DELETE_MARKER, floorId, room }),
  getRoomTypes: officeId => dispatch({ type: GET_ROOM_TYPES, officeId }),
})

export default connect(mapStateToProps, mapDispatchToProps)(OfficeMapFuncComponent)
