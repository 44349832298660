import React from 'react'
import * as _ from 'lodash'
import { Link } from 'react-router-dom'

import Modal from '../../../../../UI/modals/Modal'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'

import './groupsInfoModal.scss'

const GroupsInfoModal = ({ start, onClose, groups = [] }) => (
  <Modal start={start} onClose={onClose} extraClassName='group-info-modal'>
    <span className='large_btn__title__center border_bottom'>ДОПУЩЕННЫЕ ГРУППЫ ПОЛЬЗОВАТЕЛЕЙ</span>
    <ScrollTransition loading>
      <div className='group-info-modal__groups-list'>
        {_.uniqBy(groups, 'id').map(group => (
          <div key={group.id} className='group-info-modal__group-item'>
            <Link target='_blank' to={`/groups/${group.id}`}>{group.title}</Link>
            <span className='secondary_text'>{`Пользователей: ${group.count}`}</span>
          </div>
        ))}
      </div>
    </ScrollTransition>
  </Modal>
)

export default GroupsInfoModal
