import React from 'react'

const UserInGroupItem = ({ index, user, isKiosk }) => (
  <div className='card_list__item__user__no_hover'>
    <span className='card_list__item_id'>{`${index + 1}.`}</span>
    {/* Test version */}
    <span className='card_list__item__group_title'>
      {isKiosk
        ? user.firstname
        : user?.phone_number || <span className='secondary_text__inactive'>Не указано</span>}
    </span>
    <span className='card_list__item_description' style={{ flex: 1.5 }}>
      {user?.description
        ? <span className='secondary_text'>{user?.description}</span>
        : <div className='secondary_text__inactive text__wrapper'>Без описания</div>}
    </span>
  </div>
)

export default UserInGroupItem
