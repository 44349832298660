import { filterFilesBySizeInBits } from '../../../utils/filesValidator'
import { formFormData } from '../../Map/helpers/loadMap'
import { POST_FILE } from '../../../actions/files'
import store from '../../../store'

const postFile = (file, callback) => store.dispatch({ type: POST_FILE, file, callback })

export const postImages = arr => {
  const promisesArr = arr.map(img => new Promise(resolve => postFile(img, resolve)))
  return Promise.allSettled(promisesArr)
}

export const onImageUpload = ({ target = {} }, callback) => {
  const files = Array.from(target.files)

  if (!files.length) return
  const filesUnderMaxSize = files.filter(filterFilesBySizeInBits)

  const formattedImages = filesUnderMaxSize.map(formFormData)
  postImages(formattedImages).then(callback)
}
