import React from 'react'

import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import '../../../styles/animations/userForm.scss'
import KioskCard from './KioskCard'

const KioskList = ({ list, onDelete }) => (
  <div className='container__list '>
    {(list?.length > 0)
      ? list.map((k, index) => (
        <KioskCard
          key={k.id}
          kiosk={k}
          index={index}
          onDelete={onDelete}
        />
      ))
      : <EmptyPlaceholder text='Список пользователей пуст' />}
  </div>
)

export default KioskList
