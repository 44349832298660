import React, { useEffect, useRef, useState } from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getGroup, clearGroup, getUsersInGroup } from '../../../actions/actionCreator/groups'
import MainCard from '../../MainCard'
import UsersInGroupList from './UsersInGroupList'
import GroupInfo from './GroupInfo'
import FilterItem from '../../helpers/FilterItem'
import OfficesAndZonesList from './OfficesAndZonesList'

const TABS = [{ title: 'Пользователи', id: 'users' }, { title: 'БЦ', id: 'offices' }]

const GroupCard = ({
  getGroup,
  group = {},
  loading,
  match,
  history,
  clearGroup,
  usersList,
  getUsersInGroup,
  usersLoading,
}) => {
  const [filter, setFilter] = useState('Пользователи')
  const { current: groupId } = useRef(match.params.id)

  const setFilterTabStyle = radioItem => {
    const defaultClass = 'filter__tab'
    return (radioItem === filter) ? `${defaultClass}__active` : defaultClass
  }

  const redirectToGroupEditPage = () => history.push(`/groups/${groupId}/edit`)

  const isKiosk = group.pre_defined && group.title === 'Информационный киоск'

  useEffect(() => {
    getGroup(groupId)
    return clearGroup
  }, [])

  return (
    <MainCard icon='icon__groups' title='Группа доступа' loading={loading}>
      <div className='flex_container full_height'>
        <GroupInfo group={group} />

        <div className='container__right_side'>
          <div className='filter-tabs__group-mode'>
            {TABS.map(({ title, id }) => (
              <FilterItem
                key={`account-type-switch-${id}`}
                className={setFilterTabStyle(title)}
                htmlFor={id}
                name='mode'
                value={title}
                id={id}
                onChange={e => setFilter(e.target.value)}
              >
                {title}
              </FilterItem>
            ))}
          </div>
          {filter === 'Пользователи'
            ? (
              <UsersInGroupList
                searchEnabled={!isKiosk}
                groupId={groupId}
                isKiosk={isKiosk}
                users={_.uniqBy(usersList, 'id')}
                onButtonClick={redirectToGroupEditPage}
                shouldShowBtn={!isKiosk}
                loading={usersLoading}
              />
            )
            : (
              <OfficesAndZonesList
                onButtonClick={redirectToGroupEditPage}
                shouldShowBtn={!isKiosk}
              />
            )}
        </div>
      </div>
    </MainCard>
  )
}

const mapStateToProps = ({ users }) => {
  const { groups = {} } = users
  return ({
    group: groups.currentGroup || {},
    loading: groups.loading,
    usersList: groups.currentGroup?.users?.list || [],
    usersLoading: groups.currentGroup?.users?.loading,
  })
}

const mapDispatchToProps = { getGroup, clearGroup, getUsersInGroup }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupCard))
