import React from 'react'

export default ({ width = '18', height = '18', className, onClick }) => (
  <div onClick={onClick} className={`${className || ''} icon__map__svg`}>
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M9.00047 14.1429L5.08547 8.61429C4.44466 7.85769 4.03198 6.9346 3.89551 5.95253C3.75904 4.97046 3.90439 3.96983 4.31462 3.06717C4.72486 2.16451 5.3831 1.39698 6.21269 0.853965C7.04228 0.310949 8.00907 0.0147969 9.00047 0C10.3747 0.0135805 11.6876 0.571239 12.6515 1.55084C13.6155 2.53044 14.1519 3.85212 14.1433 5.22643C14.1439 6.42532 13.7357 7.58857 12.9862 8.52429L9.00047 14.1429ZM9.00047 1.28571C7.96666 1.29759 6.97982 1.71924 6.25663 2.4581C5.53345 3.19695 5.13304 4.1926 5.14333 5.22643C5.14763 6.17003 5.48712 7.08139 6.10118 7.79786L9.00047 11.9057L11.9769 7.71429C12.5427 7.00807 12.853 6.13131 12.8576 5.22643C12.8679 4.1926 12.4675 3.19695 11.7443 2.4581C11.0211 1.71924 10.0343 1.29759 9.00047 1.28571Z' />
      <path d='M9.00056 5.78566C9.71064 5.78566 10.2863 5.21003 10.2863 4.49995C10.2863 3.78987 9.71064 3.21423 9.00056 3.21423C8.29048 3.21423 7.71484 3.78987 7.71484 4.49995C7.71484 5.21003 8.29048 5.78566 9.00056 5.78566Z' />
      <path d='M16.7143 6.42859H15.4286V7.7143H16.7143V16.7143H1.28571V7.7143H2.57143V6.42859H1.28571C0.944722 6.42859 0.617695 6.56405 0.376577 6.80517C0.135459 7.04628 0 7.37331 0 7.7143V16.7143C0 17.0553 0.135459 17.3823 0.376577 17.6234C0.617695 17.8646 0.944722 18 1.28571 18H16.7143C17.0553 18 17.3823 17.8646 17.6234 17.6234C17.8645 17.3823 18 17.0553 18 16.7143V7.7143C18 7.37331 17.8645 7.04628 17.6234 6.80517C17.3823 6.56405 17.0553 6.42859 16.7143 6.42859Z' />
    </svg>
  </div>
)
