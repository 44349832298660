import React from 'react'
import ConfirmationModal from '../../UI/modals/ConfirmationModal'

const EmailConfirmationModal = ({ id, name, email, start, onConfirm, onClose }) => (
  <ConfirmationModal id={id} start={start} onConfirm={onConfirm} onClose={onClose}>
    <div className='flex_container__column full_height'>
      <span className='main_text__white margin_top' style={{ marginBottom: '5px' }}>
        Подтвердить Email пользователя?
      </span>

      <div className='labeled_field__block'>
        <span className='labeled_field__label'>Пользователь:</span>
        <div className='main_text__subtitle'>{name}</div>
      </div>

      <div className='labeled_field__block' style={{ marginBottom: '45px' }}>
        <span className='labeled_field__label'>Email:</span>
        <div className='main_text__subtitle'>{email || 'Не указан'}</div>
      </div>
    </div>
  </ConfirmationModal>
)

export default EmailConfirmationModal
