import React, { useEffect, useCallback, useRef } from 'react'

import PopUpTransition from '../animations/transition/PopUptransition'
import Icon from '../Icon'

const HANDLED_PARENTS = [
  '.ant-calendar',
  '.ant-select-dropdown',
  '.ant-time-picker-panel-inner',
  '.ant-calendar-year-panel',
  '.ant-calendar-month-panel',
  '.ant-calendar-decade-panel']

const Modal = ({ children, onClose, start, style, extraClassName }) => {
  const wrapperRef = useRef(null)
  const handleKeyDown = useCallback(e => e.keyCode === 27 && onClose(), [onClose])

  const isClickOnCalendar = target => {
    const isClickOnCalendar = HANDLED_PARENTS.map(parent => Boolean(target?.closest(parent)))
    return isClickOnCalendar.some(parent => parent)
  }

  const handleClickOutside = e => {
    e.stopPropagation()
    const { current } = wrapperRef

    if (isClickOnCalendar(e.target)) return
    current && !current?.contains(e.target) && onClose()
  }

  const targetEvents = [
    { name: 'keydown', callback: handleKeyDown },
    { name: 'mousedown', callback: handleClickOutside },
  ]

  useEffect(() => {
    targetEvents.map(({ name, callback }) => document.addEventListener(name, callback))
    return () => {
      targetEvents.map(({ name, callback }) => document.removeEventListener(name, callback))
    }
  }, [handleKeyDown, handleClickOutside])

  return (
    <PopUpTransition loading={start}>
      <div className='modal_bg' onKeyDown={handleKeyDown}>
        <div
          className={['modal_card', extraClassName].filter(Boolean).join(' ')}
          ref={wrapperRef}
          style={style}
        >
          <Icon name=' icon__x' onClick={onClose} />
          {children}
        </div>
      </div>
    </PopUpTransition>
  )
}

export default Modal
