import * as _ from 'lodash'

import getFullName from '../utils/getFullName'
import {
  CREATE_OPERATOR_SUCCEED,
  DELETE_GROUP_SUCCEED,
  DELETE_USER_SUCCEED,
  GET_GROUP_SUCCEED,
  GET_ACCESS_GROUPS_SUCCEED,
  GET_USERS_SUCCEED,
  PUT_USER_SUCCEED,
  SET_ACCESS_GROUPS_LOADING,
  USERS_LOADING,
  CLEAR_GROUP,
  CONFIRM_EMAIL_SUCCEED,
  CREATE_KIOSK_SUCCEED,
  PUT_KIOSK_SUCCEED,
  PROMOTE_OPERATOR_SUCCEED,
  CREATE_USER_SUCCEED,
  SET_IMPORT_USERS_GROUP_LOADING,
  IMPORT_USERS_IN_GROUP_SUCCEED,
  IMPORT_GROUPS_SUCCEED,
  SET_IMPORT_USERS_GROUP_ERROR,
  CLEAN_USERS,
  GET_USERS_FOR_NOTIFICATIONS_SUCCEED,
  GET_GROUP_ACCESS_SUCCEED,
  GET_USER_ACCESS_GROUPS_SUCCEED,
} from '../actions/users'

const addNameField = user => ({ ...user, name: getFullName(user) })

export const users = (state = {}, action) => {
  switch (action.type) {
    case USERS_LOADING: {
      if (action.group)
        return {
          ...state,
          groups: {
            ...state.groups,
            currentGroup: {
              ...state.groups?.currentGroup,
              users: {
                ...state.groups?.currentGroup?.users,
                loading: true,
              },
            },
          },
        }

      return { ...state, loading: true }
    }

    case CLEAN_USERS: return {}

    case GET_GROUP_ACCESS_SUCCEED: {
      const { access } = action
      return {
        ...state,
        groups: {
          ...state.groups,
          currentGroup: {
            ...state.groups.currentGroup,
            accessInfo: access,
          },
        },
      }
    }

    case GET_USER_ACCESS_GROUPS_SUCCEED: {
      const { id, groups } = action
      const newList = [...state.list]

      const targetUserIndex = newList.findIndex(u => u.id === id)
      const newUser = newList[targetUserIndex]
      console.log(targetUserIndex, newUser)
      newUser.groups = groups
      newList[targetUserIndex] = newUser

      return {
        ...state,
        list: newList,
      }
    }

    case GET_USERS_FOR_NOTIFICATIONS_SUCCEED:
    case GET_USERS_SUCCEED: {
      const { page, users, count, group } = action

      const usersWithNameField = users.map(u => addNameField(u))

      if (group)
        return {
          ...state,
          groups: {
            ...state.groups,
            currentGroup: {
              ...state.groups?.currentGroup,
              users: {
                ...state.groups?.currentGroup?.users,
                list: usersWithNameField,
                loading: false,
              },
            },
          },
        }

      if (!page) return { ...state, list: usersWithNameField, loading: false }

      const shouldRewrite = !(state.list && page !== 1)

      const newUsers = shouldRewrite
        ? usersWithNameField
        : _.uniqBy([...state.list, ...usersWithNameField], 'id')

      return {
        ...state,
        list: newUsers,
        count,
        loading: false,
      }
    }

    case CREATE_USER_SUCCEED:
    case CREATE_KIOSK_SUCCEED:
    case CREATE_OPERATOR_SUCCEED: {
      const { user } = action
      const userWithNameField = addNameField(user)
      const newList = state.list ? [userWithNameField, ...state.list] : [userWithNameField]
      return { ...state, list: newList }
    }

    case DELETE_USER_SUCCEED: {
      const newList = state.list.filter(u => u.id !== action.userId)
      return { ...state, list: newList }
    }

    case CONFIRM_EMAIL_SUCCEED:
    case PUT_KIOSK_SUCCEED:
    case PUT_USER_SUCCEED: {
      const { user } = action
      const userIndex = state.list.findIndex(u => u.id === user.id)
      const userWithNameField = addNameField(user)
      const newList = state.list

      newList[userIndex] = userWithNameField
      return { ...state, list: [...newList] }
    }

    case GET_ACCESS_GROUPS_SUCCEED: {
      const { groups } = action
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false,
          list: groups,
        },
      }
    }

    case SET_ACCESS_GROUPS_LOADING: return { ...state, groups: { ...state.groups, loading: true } }

    case GET_GROUP_SUCCEED: {
      const usersWithNameField = action.group.users.map(user => addNameField(user))

      return {
        ...state,
        groups: {
          ...state.groups,
          currentGroup: {
            ...action.group,
            users: {
              list: usersWithNameField,
            },
          },
          loading: false,
        },
      }
    }

    case DELETE_GROUP_SUCCEED: {
      const filteredGroupList = state.groups.list.filter(group => group.id !== action.id)
      return {
        ...state,
        groups: { ...state.groups, list: filteredGroupList },
      }
    }

    case CLEAR_GROUP: return { ...state, groups: {} }

    case PROMOTE_OPERATOR_SUCCEED: {
      const { userId } = action
      const userIndex = state.list.findIndex(u => u.id === userId)
      const newUser = state.list[userIndex]
      newUser.has_cp_access = !newUser.has_cp_access
      const newList = state.list

      newList[userIndex] = newUser
      return { ...state, list: [...newList] }
    }

    case SET_IMPORT_USERS_GROUP_LOADING:
      return {
        ...state,
        groups: {
          ...state.groups,
          importLoading: true,
        },
      }

    case IMPORT_USERS_IN_GROUP_SUCCEED:
      return {
        ...state,
        groups: {
          importLoading: false,
          currentGroup: {
            ...state.groups.currentGroup,
            users: action.users,
          },
        },
      }

    case SET_IMPORT_USERS_GROUP_ERROR:
      return {
        ...state,
        groups: {
          ...state.groups,
          importLoading: false,
        },
      }

    case IMPORT_GROUPS_SUCCEED: {
      const { updatedGroups } = action
      return {
        ...state,
        groups: {
          importLoading: false,
          list: updatedGroups,
        },
      }
    }

    default: return state
  }
}

export default users
