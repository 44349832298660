import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Form } from 'antd'
import { withFormik, Form as FormikForm, ErrorMessage } from 'formik'

import BookingDetailsBlock from './BookingDetailsBlock'
import BookingTypeBlock from './BookingTypeBlock'
import Button from '../../../UI/Button'
import FadeTransition from '../../../UI/animations/transition/FadeTransition'
import LargeSpin from '../../../UI/Spin/LargeSpin'
import RangePicker from '../../../UI/input/RangePicker'
import SelectWithSearch from '../../../UI/input/SelectWithSearch'
import ValidationMsg from '../../../UI/ValidationMsg'
import { formatDate } from '../../../../utils/dates/formatDate'
import { GET_OFFICE_NEW_DESIGN, GET_OFFICES_NEW_DESIGN, GET_TABLES, GET_ROOM_TYPES } from '../../../../actions/offices'
import { POST_BOOKING, POST_FAST_BOOKING } from '../../../../actions/booking'

const ValidationSchema = Yup.object().shape({
  date: Yup.array()
    .min(2, 'Обязательное поле')
    .required('Обязательное поле'),
  office: Yup.object().shape({
    id: Yup.string().nullable().required('Обязательное поле') }),
  type: Yup.string()
    .required('Обязательное поле'),
  floor: Yup.string()
    .when('isDetailBooking', {
      is: true,
      then: Yup.string().required('Выберите'),
      otherwise: Yup.string().nullable(),
    }),
  room: Yup.string()
    .when('isDetailBooking', {
      is: true,
      then: Yup.string().required('Обязательное поле'),
      otherwise: Yup.string().nullable(),
    }),
  table: Yup.string()
    .when(['isDetailBooking', 'isRoomUnified'], {
      is: (isDetailBooking, isRoomUnified) => isDetailBooking && !isRoomUnified,
      then: Yup.string().required('Обязательное поле'),
      otherwise: Yup.string().nullable(),
    }),
})

class InnerForm extends React.Component {
  state = { loading: false, rooms: [] }

  isBtnLocked = false

  shouldComponentUpdate(nextProps) {
    return (nextProps.isFormShown !== false)
  }

  componentDidUpdate(prevProps) {
    const { isFormShown } = this.props
    if (isFormShown !== prevProps.isFormShown && isFormShown === true) this.clearValues()
  }

  clearValues = () => this.props.setValues({ date: [], name: { id: null, title: '' } })

  onDateChange = (name, value) => {
    const newVal = value.map(time => time.set({ minute: 0, second: 0, millisecond: 0 }))
    const isEndTimeBeforeStart = newVal[0].diff(newVal[1], 'hours') >= 0 && newVal[0] && newVal[1]

    if (isEndTimeBeforeStart) newVal[1] = newVal[1].add(1, 'hours')
    this.props.setFieldValue(name, newVal)
  }

  resetDetails = () => {
    const { setValues, values } = this.props
    setValues({ ...values, room: undefined, floor: undefined, table: undefined })
  }

  resetRoom = () => {
    const { setValues, values } = this.props
    setValues({ ...values, room: undefined, table: undefined })
  }

  resetTable = () => this.props.setValues({ ...this.props.values, table: undefined })

  onOfficeSelect = (name, value) => {
    const { getOffice, getRoomTypes, setFieldValue } = this.props

    this.resetDetails()
    getOffice(value.id)
    getRoomTypes(value.id)

    setFieldValue(name, value)
    setFieldValue('type', undefined)
  }

  getRoomsOnFloor = value => this.props.floors.find(floor => floor.title === String(value)).rooms

  onFloorSelect = (name, value) => {
    if (this.props.values.room) this.resetRoom()
    const roomsOnFloor = this.getRoomsOnFloor(value)
    this.setState({ rooms: roomsOnFloor }, () => this.props.setFieldValue(name, value))
  }

  onRoomSelect = (name, value) => {
    const { values, setFieldValue } = this.props
    if (values.table) this.resetTable()
    setFieldValue(name, value)
    const isRoomUnified = this.state.rooms?.find(room => room.id === value).room_type_unified
    setFieldValue('isRoomUnified', isRoomUnified)
  }

  onTypeChange = (name, value) => {
    const { values, getOffice, setFieldValue } = this.props
    this.resetDetails()
    getOffice(values.office.id, value)
    setFieldValue(name, value)
  }

  getBookingDate = () => {
    const { values } = this.props
    return {
      date_from: formatDate(values.date[0]),
      date_to: formatDate(values.date[1]),
    }
  }

  postDetailBooking = async baseBooking => {
    const { postBooking, onCancel, floors, values, roomTypes, setFieldError } = this.props
    const { table, type, room, floor, isDetailBooking } = values
    const selectedRoomType = roomTypes.find(({ title }) => title === type)
    if (isDetailBooking && !selectedRoomType.unified && !table) {
      setFieldError('table', true)
      return
    }

    const { tables } = floors
      .find(({ title }) => floor === title).rooms?.find(({ id }) => id === room)

    const booking = {
      ...baseBooking,
      table: selectedRoomType?.unified ? tables[0].id : table,
    }
    await postBooking(booking, onCancel)
    this.isBtnLocked = false
  }

  postRandomBooking = async baseBooking => {
    const { postFastBooking, onCancel, values, roomTypes } = this.props
    const { office, type } = values
    const selectedRoomType = roomTypes.find(({ title }) => title === type)

    const booking = { ...baseBooking, type: selectedRoomType?.id, office: office.id }
    await postFastBooking(booking, true, onCancel)
    this.isBtnLocked = false
  }

  onSaveClick = async () => {
    this.isBtnLocked = true

    const { values, setTouched } = this.props
    await setTouched({ date: true, office: true, floor: true, room: true, table: true, type: true })

    if (!ValidationSchema.isValidSync(values)) return null

    const baseBooking = { user: this.props.userId, ...this.getBookingDate() }

    values.isDetailBooking
      ? this.postDetailBooking(baseBooking)
      : this.postRandomBooking(baseBooking)
  }

  getErrorCondition = field => this.props.touched[field] && this.props.errors[field]

  onCheckBoxClick = (name, value) => {
    const { setFieldValue, setTouched } = this.props
    setFieldValue(name, value)
    setTouched({ room: false, floor: false, table: false })
  }

  render() {
    const {
      errors,
      floors,
      getOffices,
      offices,
      onCancel,
      setFieldTouched,
      setFieldValue,
      touched,
      values,
      roomTypes,
    } = this.props

    const { rooms, loading } = this.state

    return (
      loading
        ? <LargeSpin />
        : (
          <FormikForm style={{ flex: 1, padding: '0 2px', overflowX: 'hidden' }}>
            <div className='flex_container'>
              <div className='flex_container__flex_2'>
                <Form.Item label='Время'>
                  <RangePicker
                    name='date'
                    value={values.date}
                    onChange={this.onDateChange}
                    setFieldTouched={setFieldTouched}
                    style={{ width: '100%', marginRight: '20px' }}
                    error={this.getErrorCondition('date')}
                  >
                    <ErrorMessage component={ValidationMsg} name='date' />
                  </RangePicker>
                </Form.Item>
              </div>
              <div className='flex_container__flex_3'>
                <SelectWithSearch
                  label='Бизнес-центр'
                  name='office'
                  targetField='title'
                  idField='id'
                  onChange={this.onOfficeSelect}
                  onSearch={getOffices}
                  placeholder='Выберите бизнес-центр'
                  setFieldTouched={setFieldTouched}
                  source={offices}
                  style={{ width: '100%' }}
                  toShownString={item => item.title}
                  value={values.office}
                  error={this.getErrorCondition('office')}
                >
                  {touched.office && errors.office
                    && <ValidationMsg>{errors.office.id}</ValidationMsg>}
                </SelectWithSearch>
              </div>
            </div>

            <FadeTransition loading={Boolean(values.office.title)}>
              <div className='flex_container__column'>
                <BookingTypeBlock
                  values={values}
                  typesList={roomTypes}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  onCheckBoxClick={this.onCheckBoxClick}
                  onTypeChange={this.onTypeChange}
                  getErrorCondition={this.getErrorCondition}
                />

                <BookingDetailsBlock
                  tables={values.room ? rooms?.find(room => room.id === values.room).tables : []}
                  rooms={rooms}
                  types={roomTypes}
                  floors={floors}
                  values={values}
                  onFloorSelect={this.onFloorSelect}
                  onRoomSelect={this.onRoomSelect}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  getErrorCondition={this.getErrorCondition}
                />
              </div>
            </FadeTransition>

            <div className='button_container__user_booking__content_right'>
              <Button
                styles='simple-btn'
                title='Отмена'
                onClick={onCancel}
              />
              <Button
                delay={300}
                hardLock={this.isBtnLocked}
                styles='bordered_btn__save_lecture'
                title='Забронировать'
                onClick={this.onSaveClick}
              />
            </div>
          </FormikForm>
        )
    )
  }
}

const NewBookingForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    date: [],
    office: { id: null, title: '' },
    type: undefined,
    floor: undefined,
    room: undefined,
    table: undefined,
    isDetailBooking: false,
    isRoomUnified: false,
  }),
  validationSchema: ValidationSchema,
})(InnerForm)

const mapStateToProps = ({ users, offices = {}, offices_newDesign = {} }) => ({
  loading: users.loading,
  offices: offices.list || [],
  checkedOffice: offices.office || {},
  floors: offices.floors?.list || [],
  roomTypes: offices_newDesign.roomTypes?.list?.filter(type => type.bookable) || [],
})

const mapDispatchToProps = dispatch => ({
  getOffices: searchString => dispatch({ type: GET_OFFICES_NEW_DESIGN, searchString }),
  getRoomTypes: officeId => dispatch({ type: GET_ROOM_TYPES, officeId }),
  getOffice: (officeId, type) => dispatch({ type: GET_OFFICE_NEW_DESIGN, officeId, filter: type }),
  getTables: (roomId, officeId) => dispatch({ type: GET_TABLES, roomId, officeId }),
  postFastBooking: (booking, addToHistory, callback) =>
    dispatch({ type: POST_FAST_BOOKING, booking, addToHistory, callback }),
  postBooking: (booking, callback) => dispatch({ type: POST_BOOKING, booking, callback }),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewBookingForm)
