import React from 'react'

const OneLineInfoBlock = ({ label, text, textColor = 'white', style }) => (
  <div className='container__one_line_info' style={style}>
    <span className='one_line_info__label'>{label}:</span>
    <span className='one_line_info__text' style={{ color: textColor }}>{text}</span>
  </div>
)

export default OneLineInfoBlock
