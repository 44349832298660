import { message } from 'antd'
import { MAX_IMAGE_SIZE_IN_BITS } from './data/constants'

export const filterFilesBySizeInBits = file => {
  if (file.size > MAX_IMAGE_SIZE_IN_BITS) {
    message.error(`Файл ${file.name} превышает максимальный размер в 10Мб и не будет загружен`)
    return false
  }
  return true
}
