export default codes => {
  const doc = window.open('', '', 'height=500, width=500')
  doc.document.write('<html>')
  doc.document.write('<head>')
  doc.document.write('<link rel=\'shortcut icon\' href=\'../../../../../../public/favicon.png\' type=\'image/png\'>')
  doc.document.write('<title>Печать QR-кодов</title>')
  doc.document.write('<style>')
  doc.document.write('body { font-family: Arial, Helvetica, sans-serif; }')
  doc.document.write('</style>')
  doc.document.write('</head>')
  doc.document.write('<body>')
  codes.forEach(({ code, roomTitle, tableTitle }) => {
    doc.document.write('<div style="display: inline-block; width: 215px; margin-bottom: 2.5rem">')
    doc.document.write(`<h4 style="max-width: 215px; margin: 0 0 4px; font-size: 14px;">Стол: 
      ${tableTitle}</h4>`)
    doc.document.write(`<h4 style='max-width: 215px; margin: 0 0 4px; font-size: 14px;'>Помещение: 
      ${roomTitle}</h4>`)
    // doc.document.write(`<img style='box-sizing: border-box; width: 215px; padding: 16px; border: 1px dashed black' src='${code}' />`)
    doc.document.write(`${code}`)
    doc.document.write('</div>')
  })
  doc.document.write('</body>')
  doc.document.write('</html>')
  doc.document.close()
  doc.onload = () => doc.print()
}
