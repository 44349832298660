import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import KioskCardListItem from './KioskCardListItem'
import KioskForm from '../forms/Kiosk/index'
import AnimatedHeight from '../../UI/animations/AnimatedHeight'
import { DELETE_USER } from '../../../actions/users'
import ConfirmationModal from '../../UI/modals/ConfirmationModal'

const KioskCard = ({ kiosk, index }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const dispatch = useDispatch()

  const deleteKiosk = () => dispatch({
    type: DELETE_USER,
    userId: kiosk.id,
    callback: () => setIsDeleteModalVisible(false),
  })

  const getItemActivityClass = () => {
    const defaultClass = 'card_list__item'
    return isOpen ? `${defaultClass}__active` : defaultClass
  }

  return (
    <React.Fragment key={kiosk.id}>
      <KioskCardListItem
        kiosk={kiosk}
        index={index}
        onDelete={() => setIsDeleteModalVisible(true)}
        onItemClick={() => setIsOpen(!isOpen)}
        getItemActivityClass={getItemActivityClass}
      />
      <AnimatedHeight condition={isOpen}>
        <KioskForm
          isEditMode
          kiosk={kiosk}
          onCancel={() => setIsOpen(false)}
        />
      </AnimatedHeight>
      <ConfirmationModal
        start={isDeleteModalVisible}
        text={`Удалить киоск '${kiosk.firstname}'?`}
        onConfirm={deleteKiosk}
        onClose={() => setIsDeleteModalVisible(false)}
      />

    </React.Fragment>
  )
}

export default KioskCard
