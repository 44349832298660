import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { message } from 'antd'
import { withRouter } from 'react-router-dom'

import Button from '../../../../../UI/Button'
import EmptyPlaceholder from '../../../../../UI/EmptyPlaceholder'
import ImportFromCsvModal from '../../helpers/ImportFromCsvModal'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import SubtitleWithActions from '../../../../../UI/SubtitleWithActions'
import TableHeader from '../../../../../UI/TableHeader'
import TagListItem from './TagListItem'
import TextModal from '../../../../../UI/modals/TextModal'
import UnderlinedTitle from '../../../../../UI/UnderlinedTitle'
import {
  POST_TAG_ICON_NEW_DESIGN,
  DELETE_OFFICE_TAG,
  POST_OFFICE_TAG,
  PUT_OFFICE_TAG,
} from '../../../../../../actions/offices'

const TAGS_LIST_COLUMNS_NAMES = ['Название', 'Иконка']
const COLUMNS_FLEX_VALUES = [1, 1]

const TagsInfo = ({
  match,
  tags,
  loading,
  postTagIcon,
  postOfficeTag,
  deleteTag,
  putOfficeTag,
}) => {
  const [isAddSingleTagInputVisible, setIsSingleTagInputVisible] = useState(false)
  const [isImportFromCsvModalVisible, setIsImportFromCsvModalVisible] = useState(false)
  const [isMultipleInputModalVisible, setIsMultipleInputModalVisible] = useState(false)

  const { current: office } = useRef(match.params.id)

  const toggleAddMultipleModal = () => setIsMultipleInputModalVisible(val => !val)

  const toggleSingleInputVisible = () => setIsSingleTagInputVisible(val => !val)

  const handleTagPost = tag => {
    if (tags.some(({ title }) => tag.title === title)) {
      message.error('Тег с таким названием уже существует')
      return
    }

    postOfficeTag({ ...tag, office }, () => setIsSingleTagInputVisible(false))
  }

  const handlePutOfficeTag = (tagId, updatedTag, callback) => {
    if (tags.some(({ title }) => updatedTag.title === title)) {
      message.error('Тег с таким названием уже существует')
      return
    }

    putOfficeTag(tagId, updatedTag, callback)
  }

  const isTitlesNotValid = titles => titles.some(title => title.length < 2 || title.length > 140)

  const handlePackageUpload = (titlesArray, callback) => {
    if (!isTitlesNotValid(titlesArray)) {
      postOfficeTag({ title: titlesArray, office }, callback, true)
      return
    }
    message.error('Некорректные значения. Длина тега – от 2 до 140 символов')
  }

  const renderTagsList = () => tags.length || isAddSingleTagInputVisible
    ? (
      <ScrollTransition loading>
        <div className='tags-list'>
          {isAddSingleTagInputVisible && (
            <TagListItem
              isNew
              tag={{ title: '', icon: {} }}
              onSave={handleTagPost}
              onCancel={toggleSingleInputVisible}
              flexValues={COLUMNS_FLEX_VALUES}
            />
          )}
          {tags.map(tag => (
            <TagListItem
              key={tag.id}
              tag={tag}
              flexValues={COLUMNS_FLEX_VALUES}
              onSave={(updatedTag, callback) => handlePutOfficeTag(tag.id, updatedTag, callback)}
              postIcon={postTagIcon}
              onDelete={deleteTag}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Список тегов пуст' />

  const renderImportFromCsvBtn = () => (
    <Button
      icon='icon__download_2'
      styles='rounded_btn__yellow'
      title='Импорт из .csv'
      onClick={() => setIsImportFromCsvModalVisible(true)}
    />
  )

  return (
    <div className='flex_container__column full_height'>
      <div className='flex_container__column' style={{ paddingRight: '10px' }}>
        <UnderlinedTitle title='ТЕГИ' />
        <SubtitleWithActions
          subtitle={`Всего тегов: ${loading ? '' : tags.length || 0}`}
          // actions={renderImportFromCsvBtn()}
        />
        <div className='btn-group' style={{ margin: '12px 0 20px' }}>
          <Button
            onClick={toggleSingleInputVisible}
            styles='btn_solid__light-blue btn-group__item'
            title={isAddSingleTagInputVisible ? 'Отменить добавление тега' : 'Добавить 1 тег'}
          />
          <Button
            onClick={toggleAddMultipleModal}
            styles='btn_solid__light-blue btn-group__item'
            title='Добавить несколько'
          />
        </div>
      </div>
      <TableHeader
        columns={TAGS_LIST_COLUMNS_NAMES}
        flexValues={COLUMNS_FLEX_VALUES}
        rightPaddingWidth={47}
      />
      {renderTagsList()}

      <ImportFromCsvModal
        form='tags'
        onClose={() => setIsImportFromCsvModalVisible(false)}
        start={isImportFromCsvModalVisible}
      />
      <TextModal
        isOpen={isMultipleInputModalVisible}
        setIsOpen={setIsMultipleInputModalVisible}
        onSubmit={handlePackageUpload}
        title='ДОБАВИТЬ НЕСКОЛЬКО ТЕГОВ'
        subtitle='Введите названия тегов через запятую (минимум 2 символа)'
        hint='Пример: с ПК, У окна, С монитором'
        submitTitle='Добавить теги'
      />
    </div>
  )
}

const mapStateToProps = ({ offices_newDesign: offices = {} }) =>
  ({ tags: offices.office?.tags?.list || [] })

const mapDispatchToProps = dispatch => ({
  putOfficeTag: (tagId, tag, callback) => dispatch({ type: PUT_OFFICE_TAG, tagId, tag, callback }),
  postOfficeTag: (tag, callback, isPackageUpload) =>
    dispatch({ type: POST_OFFICE_TAG, tag, callback, isPackageUpload }),
  deleteTag: tagId => dispatch({ type: DELETE_OFFICE_TAG, tagId }),
  postTagIcon: (tagId, icon) => dispatch({ type: POST_TAG_ICON_NEW_DESIGN, tagId, icon }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagsInfo))
