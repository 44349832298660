import React from 'react'

const Booking = ({ color = '#0079C1' }) => (
  <svg width="17" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.8454 7.94723V2.81504C13.8454 1.89584 13.0986 1.16815 12.1985 1.16815H10.1495V0.593648C10.1495 0.268099 9.88137 0 9.55582 0C9.23027 0 8.96217 0.268099 8.96217 0.593648V1.149H4.88324V0.593648C4.88324 0.268099 4.61514 0 4.28959 0C3.96404 0 3.69594 0.268099 3.69594 0.593648V1.149H1.6469C0.727698 1.149 0 1.89584 0 2.79589V11.7581C0 12.6773 0.746848 13.405 1.6469 13.405H8.36853C8.65577 15.837 10.7431 17.752 13.2518 17.752C15.9711 17.752 18.1733 15.5498 18.1733 12.8305C18.1924 10.3218 16.2966 8.23448 13.8454 7.94723ZM1.6469 2.33629H3.69594V2.89164C3.69594 3.21719 3.96404 3.48529 4.28959 3.48529C4.61514 3.48529 4.88324 3.21719 4.88324 2.89164V2.33629H8.96217V2.89164C8.96217 3.21719 9.23027 3.48529 9.55582 3.48529C9.88137 3.48529 10.1495 3.21719 10.1495 2.89164V2.33629H12.1985C12.4666 2.33629 12.6773 2.54694 12.6773 2.81504V4.82579H1.1873V2.81504C1.1873 2.54694 1.39795 2.33629 1.6469 2.33629ZM1.1873 11.7772V5.99393H12.6773V7.94723C10.4367 8.21533 8.65577 9.99627 8.38767 12.2368H1.6469C1.39795 12.2368 1.1873 12.0262 1.1873 11.7772ZM13.2709 16.5839C11.2027 16.5839 9.51752 14.8987 9.51752 12.8305C9.51752 10.7623 11.2027 9.09622 13.2709 9.09622C15.3391 9.09622 17.0243 10.7814 17.0243 12.8496C17.0243 14.9178 15.3391 16.5839 13.2709 16.5839Z" fill={color} />
    <path d="M14.8987 12.2373H13.8455V10.8202C13.8455 10.4947 13.5774 10.2266 13.2519 10.2266C12.9263 10.2266 12.6582 10.4947 12.6582 10.8202V12.831C12.6582 13.1565 12.9263 13.4246 13.2519 13.4246H14.8987C15.2243 13.4246 15.4924 13.1565 15.4924 12.831C15.4924 12.5054 15.2243 12.2373 14.8987 12.2373Z" fill={color} />
  </svg>
)

export default Booking
