import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { ErrorMessage } from 'formik'
import TimePicker from './TimePicker'

import ValidationMsg from '../ValidationMsg'
import { formatDateHoursMinutes } from '../../../utils/dates/formatDate'

const TimeRangePicker = ({
  label,
  value = '',
  setFieldTouched,
  setFieldValue,
  name,
  inputsStyle,
  error,
}) => {
  const [state, setState] = useState([])

  const handleChange = (fieldName, value) => {
    const valueInString = formatDateHoursMinutes(value)
    const newValue = (fieldName === `${name}-0`)
      ? `${valueInString}-${formatDateHoursMinutes(state[1])}`
      : `${formatDateHoursMinutes(state[0])}-${valueInString}`

    setFieldValue(name, newValue)
  }

  const translateValueToArray = () => value.split('-').map(time => {
    const splittedTime = time.split(':')
    return moment().set({ hours: splittedTime[0], minutes: splittedTime[1], seconds: 0 })
  })

  useEffect(() => { setState(translateValueToArray()) }, [value])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span className='label'>{label}</span>
      <div className='validation_block time_range_picker'>
        <div style={inputsStyle}>
          <TimePicker
            name={`${name}-0`}
            value={state[0]}
            placeholder='00:00'
            format='HH:mm'
            minuteStep={10}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
          />
        </div>
        <span>—</span>
        <div style={inputsStyle}>
          <TimePicker
            name={`${name}-1`}
            value={state[1]}
            format='HH:mm'
            placeholder='00:00'
            minuteStep={10}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
          />
        </div>
        <ErrorMessage component={ValidationMsg} name={name} />
      </div>
    </div>
  )
}

export default TimeRangePicker
