import React from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash'

import UnderlinedTitle from '../../UI/UnderlinedTitle'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import stopPropagation from '../../../utils/stopPropagation'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import { serializeRoomsByTypes } from '../helpers/roomsTypes'
import RoomTypeGroupCard from './RoomTypeGroupCard'
import LargeSpin from '../../UI/Spin/LargeSpin'

const RoomsList = (props) => {
  const {
    activeRoom,
    activeFloor,
    rooms,
    setActiveRoom,
    deleteMarker,
    mode,
    isMapSet,
    addNewRoomHandler,
    refreshRoomsLoading,
    stateWithoutRedux,
    setConfirmModal,
    dispatchWithoutRedux,
  } = props;
  const isRoomActiveInWriteMode = roomId => roomId === activeRoom && mode === 'write'

  const getRoomCardStyle = roomId =>
    `container__room_item__map_screen${roomId === activeRoom ? '__active' : ''}`

  const getEditBtnStyle = roomId => {
    const basic = 'room_card__edit_marker_btn'
    return `${basic}${(roomId === activeRoom && mode === 'write') ? '__active' : ''}`
  }

  const getAddBtnStyle = roomId => {
    const basic = 'room_card__add_marker_btn'
    if (!isMapSet) return `${basic}__disabled`
    return `${basic}${(roomId === activeRoom && mode === 'write') ? '__active' : ''}`
  }

  const deleteRoomMarker = (e, roomId) => {
    const room = { room: roomId }
    stopPropagation(() => deleteMarker(activeFloor, room), e)
  }

  const setWriteMarkerMode = (e, roomId) => stopPropagation(() => setActiveRoom(roomId, 'write'), e)

  const renderRooms = () => {
    const sortedRooms = serializeRoomsByTypes(rooms)
    const list = Object.keys(sortedRooms).map(type => (
      <RoomTypeGroupCard
        key={type}
        type={type}
        rooms={sortedRooms[type]}
        isMapSet={isMapSet}
        isRoomActiveInWriteMode={isRoomActiveInWriteMode}
        getRoomCardStyle={getRoomCardStyle}
        getEditBtnStyle={getEditBtnStyle}
        getAddBtnStyle={getAddBtnStyle}
        deleteRoomMarker={deleteRoomMarker}
        setWriteMarkerMode={setWriteMarkerMode}
        stateWithoutRedux={stateWithoutRedux}
        setConfirmModal={setConfirmModal}
        dispatchWithoutRedux={dispatchWithoutRedux}
      />
    ))

    if (refreshRoomsLoading) return <LargeSpin />
    return list
  }

  return (
    <div className='container__room__map_screen'>
      <UnderlinedTitle title='ПОМЕЩЕНИЯ' />
      <ScrollTransition loading>
        <div className='flex_container__column' style={{ paddingRight: '10px' }}>
          {activeFloor && rooms.length > 0
            ? renderRooms()
            : <EmptyPlaceholder text='Список помещений пуст' />}
        </div>
        <div className='map-screen__button-wrap' onClick={addNewRoomHandler}>
          <div className='simple-btn_with-background'><span>Создать помещение</span></div>
        </div>
      </ScrollTransition>
    </div>
  )
}

const mapStateToProps = ({ offices_newDesign = {}, offices = {} }, { activeFloor }) => {
  const roomsOnFloor = offices_newDesign.office?.rooms?.filter(room => room.floor.id === activeFloor)
  const currentFloor = offices.floors?.list?.find(floor => floor.id === activeFloor)
  const isMapSet = Boolean(currentFloor?.floor_map?.image?.path)
  const refreshRoomsLoading = offices_newDesign.refreshRoomsLoading

  return ({ rooms: roomsOnFloor || [], isMapSet, refreshRoomsLoading })
}

export default connect(mapStateToProps)(RoomsList)
