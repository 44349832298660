import React from 'react'

export default () => (
  <div className='icon-attension'>
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M16 0C7.14667 0 0 7.14667 0 16C0 24.8533 7.14667 32 16 32C24.8533 32 32 24.8533 32 16C32 7.14667 24.8533 0 16 0ZM16 29.8667C8.32 29.8667 2.13333 23.68 2.13333 16C2.13333 8.32 8.32 2.13333 16 2.13333C23.68 2.13333 29.8667 8.32 29.8667 16C29.8667 23.68 23.68 29.8667 16 29.8667Z' fill='#CD4141' />
      <path d='M18 8L17.1801 18.9888H14.795L14 8H18ZM17.8509 24H14.1491V20.6742H17.8509V24Z' fill='#CD4141' />
    </svg>
  </div>
)
