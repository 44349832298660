import React from 'react'
import { ErrorMessage, Form as FormikForm } from 'formik'

import SimpleInput from '../../UI/input/SimpleInput'
import SimpleTextArea from '../../UI/input/SimpleTextArea'
import ValidationMsg from '../../UI/ValidationMsg'
import RadioGroup from '../../UI/input/RadioGroup'

const GroupInfoForm = ({
  setFieldValue,
  setFieldTouched,
  values,
  radioOptions,
  preDefined,
  getErrorCondition,
}) => (
  <div className='container__left_side__user_group_form'>
    <FormikForm>
      <SimpleInput
        name='title'
        label='Название*'
        placeholder='Введите название группы'
        value={values.title}
        onChange={setFieldValue}
        setFieldTouched={setFieldTouched}
        autofocus
        disabled={preDefined}
        error={getErrorCondition('title')}
      >
        <ErrorMessage component={ValidationMsg} name='title' />
      </SimpleInput>
      <SimpleTextArea
        name='description'
        label='Описание'
        placeholder='Введите описание группы'
        value={values.description}
        onChange={setFieldValue}
        setFieldTouched={setFieldTouched}
        style={{ height: '120px' }}
        autoSize={{ minRows: 4, maxRows: 14 }}
        error={getErrorCondition('description')}
      >
        <ErrorMessage component={ValidationMsg} name='description' />
      </SimpleTextArea>
      {values.access !== 'Оператор' && (
        <div className='user_group_form__radio_group'>
          <RadioGroup
            name='access'
            label='Права доступа'
            value={values.access}
            setFieldTouched={setFieldTouched}
            onChange={setFieldValue}
            options={radioOptions}
            disabled={preDefined}
          />
        </div>
      )}
    </FormikForm>
  </div>
)

export default GroupInfoForm
