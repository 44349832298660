import React from 'react'

const Gym = ({ className = 'icon__add_white', color = 'white', height = '16', width = '16' }) => (
  <div className={className} >
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M29.4761 9.2156L26.9954 6.73485L28.8602 4.87069C29.0251 4.70598 29.1178 4.4828 29.1178 4.24936C29.1178 4.01592 29.0251 3.79274 28.8603 3.62797L26.3635 1.13117C26.0339 0.801579 25.4503 0.801579 25.1207 1.13117L23.2565 2.99604L20.7754 0.51482C20.089 -0.171607 18.976 -0.171607 18.2895 0.51482C17.603 1.20125 17.603 2.31424 18.2895 3.00072L26.9902 11.7015C27.6767 12.3879 28.7897 12.3879 29.4761 11.7015C30.1626 11.0151 30.1626 9.90208 29.4761 9.2156Z" fill="white" />
        <path d="M11.7106 26.9993L3.00991 18.2985C2.32336 17.6121 1.21049 17.6121 0.523945 18.2985C-0.162482 18.9851 -0.162482 20.098 0.523945 20.7845L3.0051 23.2656L1.14387 25.1261C0.979044 25.2909 0.886348 25.514 0.886348 25.7475C0.886348 25.9809 0.979044 26.2041 1.14387 26.3689L3.64067 28.8648C3.81229 29.0366 4.03717 29.1224 4.26206 29.1224C4.48694 29.1224 4.71182 29.0366 4.88345 28.8649L6.74392 27.0044L9.22467 29.4852C9.91109 30.1717 11.0241 30.1717 11.7106 29.4852C12.3971 28.7987 12.3971 27.6857 11.7106 26.9993Z" fill="white" />
        <path d="M15.8035 10.4586L10.4676 15.8126L14.1965 19.5415L19.5324 14.1874L15.8035 10.4586Z" fill="white" />
        <path d="M26.9902 14.1873L15.8035 3.00066C15.1171 2.31423 14.004 2.31423 13.3176 3.00066C12.6311 3.6872 12.6311 4.80008 13.3176 5.48662L24.5043 16.6733C25.1907 17.3597 26.3037 17.3597 26.9902 16.6733C27.6767 15.9869 27.6767 14.8739 26.9902 14.1873Z" fill="white" />
        <path d="M16.6825 24.5133L5.49575 13.3266C4.80932 12.6402 3.69633 12.6402 3.0099 13.3266C2.32336 14.0131 2.32336 15.1261 3.0099 15.8125L14.1966 26.9992C14.883 27.6856 15.996 27.6856 16.6825 26.9992C17.369 26.3128 17.369 25.1998 16.6825 24.5133Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
)

export default Gym
