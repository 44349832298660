import React from 'react'

const Question = ({ className = 'icon__add_white', color = 'white', height = '16', width = '16' }) => (
  <div className={className} >
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6553 15.0307H10.3742C10.3742 11.7496 12.1241 10.6559 13.3272 9.9997C13.7647 9.78096 14.0928 9.56222 14.3115 9.34347C14.8584 8.79662 14.6396 8.03103 14.5303 7.81229C14.2022 7.0467 13.4366 6.28111 12.0148 6.28111C9.71798 6.28111 9.2805 8.35914 9.2805 8.79662L5.99939 8.35914C6.21813 6.49985 7.85868 3 12.0148 3C14.5303 3 16.7177 4.42181 17.5926 6.49985C18.3582 8.35914 18.0301 10.3278 16.7177 11.6403C16.1708 12.1871 15.5146 12.5152 14.9678 12.8433C13.9834 13.3902 13.6553 13.937 13.6553 15.0307Z" fill="white" />
      <path d="M14.2021 18.3118C14.2087 18.6031 14.157 18.8928 14.0501 19.1639C13.9432 19.4349 13.7833 19.6819 13.5796 19.8902C13.3759 20.0986 13.1327 20.2642 12.8642 20.3773C12.5956 20.4903 12.3072 20.5486 12.0158 20.5487C11.7245 20.5488 11.436 20.4906 11.1674 20.3777C10.8988 20.2648 10.6555 20.0993 10.4517 19.891C10.248 19.6828 10.0879 19.4359 9.98084 19.1649C9.87379 18.8939 9.82197 18.6042 9.82842 18.3129C9.84105 17.7414 10.0769 17.1975 10.4856 16.7978C10.8942 16.398 11.4431 16.174 12.0147 16.1739C12.5864 16.1737 13.1354 16.3974 13.5442 16.797C13.9531 17.1966 14.1892 17.7403 14.2021 18.3118Z" fill="white" />
    </svg>
  </div>
)

export default Question
