import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Modal from '../../../UI/modals/Modal'
import GroupListItem from './GroupListItem'
import ScrollTransition from '../../../UI/animations/transition/ScrollTransition'
import EmptyPlaceholder from '../../../UI/EmptyPlaceholder'
import { GET_USER_ACCESS_GROUPS } from '../../../../actions/users'

import './groupsInfoModal.scss'

const GroupsInfoModal = ({ start, onClose, user = {} }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    start && dispatch({ type: GET_USER_ACCESS_GROUPS, id: user.id })
  }, [start])

  return (
    <Modal start={start} onClose={onClose} extraClassName='user-groups-list__modal'>
      <h1 className='text-modal__title' style={{ marginRight: '10px' }}>ГРУППЫ И ДОСТУПЫ</h1>
      {user.groups?.length
        ? (
          <ScrollTransition loading>
            {user.groups.map(group => <GroupListItem key={group.id} group={group} />)}
          </ScrollTransition>
        )
        : <EmptyPlaceholder text='Пользователь не добавлен ни в одну группу' />
      }
    </Modal>
  )
}

export default GroupsInfoModal
