import React, { Component, useRef, useState, useEffect } from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Button from '../../UI/Button'
import FullScreenLoading from '../../UI/FullScreenLoading'
import NewUserModal from '../../Users/modals/NewUserModal'
import UsersList from './UsersList'
import UploadButton from '../../UI/UploadButton'
import { CREATE_USER, GET_USERS, IMPORT_USERS_IN_GROUP } from '../../../actions/users'

const UsersPickContainer = ({
  match,
  group = {},
  importUsersInGroup,
  usersList,
  getUsers,
  createUser,
  usersLoading,
  onSave,
  importLoading,
  usersSearchLoading,
}) => {
  const [selected, setSelected] = useState(group.users?.list || [])
  const [searchStrInUnassigned, setSearchStrInUnassigned] = useState('')
  const [searchStrInGroup, setSearchStrInGroup] = useState('')
  const [isNewUserModalVisible, setIsNewUserModalVisible] = useState(false)

  const { current: groupId } = useRef(match.params.id)

  const getRemainItem = () => _.differenceBy(usersList, selected, 'id')

  const addToSelected = payload => {
    const newSelected = selected?.length ? [...selected, payload] : [payload]
    setSelected(newSelected)
  }

  const removeFromSelected = payload => {
    const newSelected = selected.filter(user => user.id !== payload.id)
    setSelected(newSelected)
  }

  const onCardClick = (departure, payload) => (departure === 'remain')
    ? addToSelected(payload)
    : removeFromSelected(payload)

  const onSearchClose = () => {
    setSearchStrInUnassigned('')
    getUsers()
  }

  const setSearchString = val => setSearchStrInUnassigned(val)

  const toggleNewUserModalVisibility = val => setIsNewUserModalVisible(val)

  const promisificatedCreateUser = userPayload =>
    new Promise(resolve => createUser(userPayload, resolve))

  const setSelectedUsers = list => {
    const newSelectedUsersList = selected.length ? [...selected, ...list] : list
    setSelected(newSelectedUsersList)
  }

  const onNewUserSaveClick = (userPayload, formCallback) =>
    promisificatedCreateUser(userPayload)
      .then(res => addToSelected(_.pick(res, ['id', 'name', 'description', 'phone_number'])))
      .then(() => toggleNewUserModalVisibility(false))
      .then(formCallback)

  const packFile = file => {
    const data = new FormData()
    data.append('file', file)
    data.append('group', groupId)
    return data
  }

  const handleCsvImport = ({ target }) => {
    const file = target.files[0]
    if (!file) return

    const data = packFile(file)
    importUsersInGroup(data, setSelectedUsers)
  }

  useEffect(() => {
    setSelected(group.users?.list)
  }, [usersSearchLoading])

  return (
    <>
      <div className='container__right_side' style={{ paddingLeft: '22px' }}>
        <div className='flex_container full_height'>
          <UsersList
            // search
            name='selected'
            list={selected}
            loading={usersSearchLoading}
            title='Пользователи в группе'
            onCardClick={onCardClick}
            searchString={searchStrInGroup}
            setSearchString={setSearchStrInGroup}
            getItems={({ searchString }) => getUsers(searchString, groupId)}
          />
          <UsersList
            search
            name='remain'
            list={getRemainItem()}
            loading={usersLoading}
            title='Пользователи не в группе'
            onCardClick={onCardClick}
            searchString={searchStrInUnassigned}
            setSearchString={setSearchString}
            onSearchClose={onSearchClose}
            getItems={({ searchString }) => getUsers(searchString)}
          />
        </div>
        <div className='button_container__edit_card'>
          <UploadButton
            acceptFiles='.csv'
            btnIcon='icon__download_2'
            btnClassName='button__import_users_csv'
            btnTitle='Импортировать из .csv'
            handleChange={handleCsvImport}
          />
          <Button
            styles='button__new_user_in_group'
            title='Новый пользователь'
            onClick={() => toggleNewUserModalVisibility(true)}
          />
          <button
            type='submit'
            className='rounded-btn_blue'
            onClick={() => onSave({ usersList: selected })}
          >
            Сохранить
          </button>
        </div>
      </div>
      <NewUserModal
        start={isNewUserModalVisible}
        handleClose={() => toggleNewUserModalVisibility(false)}
        onSave={onNewUserSaveClick}
      />
      {importLoading && <FullScreenLoading />}
    </>
  )
}

// GPN version, no personal data
const mapStateToProps = ({ users = {} }) => {
  const { groups = {} } = users
  const usersWithoutExtraInfo = users.list?.map(({ id, name, description, phone_number }) =>
    ({ id, name, description, phone_number }))

  return ({
    group: groups?.currentGroup,
    usersList: usersWithoutExtraInfo,
    usersLoading: users.loading,
    usersSearchLoading: groups?.currentGroup?.users?.loading,
    loading: groups?.loading,
    importLoading: groups?.importLoading,
    count: users?.count,
  })
}

const mapDispatchToProps = dispatch => ({
  getUsers: (searchString, group) => dispatch({ type: GET_USERS, searchString, group }),
  createUser: (user, callback) => dispatch({ type: CREATE_USER, user, callback, isPromise: true }),
  importUsersInGroup: (file, callback) => dispatch({ type: IMPORT_USERS_IN_GROUP, file, callback }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersPickContainer))
