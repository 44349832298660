import React from 'react'
import { Tooltip } from 'antd'
import { withRouter } from 'react-router-dom'

import stopPropagation from '../../../utils/stopPropagation'

const KioskCardListItem = ({
  kiosk,
  index,
  onDelete,
  onItemClick,
  getItemActivityClass,
}) => {
  const { id, office, floor, firstname } = kiosk

  return (
    <div
      className={getItemActivityClass()}
      onClick={e => stopPropagation(() => onItemClick(kiosk), e)}
    >
      <span className='card_list__item_id'>{`${index + 1}.`}</span>
      <span className='card_list__item_user_name'>
        {firstname === 'Название не указано'
          ? <div className='main_text__inactive text__wrapper'>{firstname}</div>
          : firstname}
      </span>
      <span className='card_list__item_colon_block__left_border' style={{ flex: 4 }}>
        <span className='secondary_text margin_right'>БЦ:</span>
        {office?.title
          ? <span className='secondary_text__white'>{office?.title}</span>
          : <div className='secondary_text__inactive text__wrapper'>Не указано</div>}
      </span>
      <span className='card_list__item_colon_block__left_border' style={{ flex: 1 }}>
        <span className='secondary_text margin_right'>Этаж:</span>
        <span className='secondary_text__white'>{floor?.title}</span>
      </span>
      {onDelete && (
        <Tooltip title='Удалить' mouseEnterDelay={0.6}>
          <div
            className='icon__wrapper__hover_enabled full_height'
            onClick={e => stopPropagation(() => onDelete({ id, name: firstname }), e)}
          >
            <div className='icon__delete' />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default withRouter(KioskCardListItem)
