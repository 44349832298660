import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import Logo from '../helpers/Logo'
import UserInfo from './UserInfo'
import { NAVIGATION } from '../../utils/data/navigation'

const Header = ({ history }) => {
  const renderNavigation = () => (
    NAVIGATION.map(({ address, title }) => (
      <NavLink
        to={address}
        key={title}
        className='nav_link__inactive'
        activeClassName='nav_link__active'
      >
        {title}
      </NavLink>
    ))
  )

  return (
    <div className='header'>
      <div className='logo_placeholder' onClick={() => history.push('/')}>
        <Logo />
      </div>
      <nav className='nav_block'>{renderNavigation()}</nav>
      <UserInfo />
    </div>
  )
}

export default withRouter(Header)
