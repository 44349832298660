import React from 'react'
import { connect } from 'react-redux'
import { CREATE_KIOSK, PUT_KIOSK } from '../../../../actions/users'
import Form from './Form'
import DisabledInputBlock from '../../../UI/dataBlock/DisabledInputBlock'

const KioskForm = ({ isEditMode, offices, kiosk, onCancel, createKiosk, putKiosk }) => {
  const onEditSave = values => putKiosk(kiosk.id, values, onCancel)
  const onCreate = (values, callback) => createKiosk(values, callback)

  const renderCode = () => (
    <DisabledInputBlock
      title='Код доступа'
      subtitle={kiosk.access_code}
      style={{ margin: '3px 0 16px', padding: 0, userSelect: 'text' }}
    />
  )

  const getFormProps = () => isEditMode
    ? { onSave: onEditSave, className: 'kiosk_form__edit', code: renderCode(), initialValues: kiosk }
    : { onSave: onCreate, className: 'kiosk_form__new' }

  const formProps = getFormProps()

  return (
    <div className={formProps.className}>
      <Form
        isEditMode={isEditMode}
        offices={offices}
        onSave={formProps.onSave}
        code={formProps.code}
        initialValues={formProps.initialValues}
      />
    </div>
  )
}

const mapStateToProps = ({ offices }) => ({ offices: offices.list || [] })

const mapDispatchToProps = dispatch => ({
  putKiosk: (kioskId, kiosk, callback) => dispatch({ type: PUT_KIOSK, kioskId, kiosk, callback }),
  createKiosk: (kiosk, callback) => dispatch({ type: CREATE_KIOSK, kiosk, callback }),
})

export default connect(mapStateToProps, mapDispatchToProps)(KioskForm)
