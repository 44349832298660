import React from 'react'
import { renderToString } from 'react-dom/server'
import QRCode from 'qrcode.react'

const codeStyles = {
  border: '1px dashed black',
}

const getTablesCodes = ({ tables, title }) =>
  tables.reduce((acc, currentTable) => {
    const code = renderToString(
      <QRCode
        value={currentTable.id}
        renderAs='svg'
        level='H'
        includeMargin
        style={codeStyles}
        size={215}
      />,
    )
    return [...acc, { code, roomTitle: title, tableTitle: currentTable.title }]
  }, [])

export default rooms =>
  rooms.reduce((acc, currentRoom) => [...acc, ...getTablesCodes(currentRoom)], [])
