import moment from 'moment'

export const sortByAlphabetOrder = (a, b, field) => {
  if (a[field] > b[field]) return 1
  if (a[field] < b[field]) return -1
  return 0
}

export const sortByDateOrder = (a, b, field, diff) => {
  if (moment(a[field]).isAfter(moment(b[field]), diff)) return 1
  if (a[field] === b[field]) return 0
  return -1
}

export const sortByDateOrderRevert = (a, b, field, diff) => {
  if (moment(a[field]).isAfter(moment(b[field]), diff)) return -1
  if (a[field] === b[field]) return 0
  return 1
}

export const sortListByName = list => list.sort((a, b) => sortByAlphabetOrder(a, b, 'name'))

export const sortListByTitle = list => list.sort((a, b) => sortByAlphabetOrder(a, b, 'title'))

export const sortListByDay = (list, field = 'date') =>
  list.sort((a, b) => sortByDateOrder(a, b, field, 'day'))

export const sortListByHour = (list, field = 'date') =>
  list.sort((a, b) => sortByDateOrder(a, b, field, 'minute'))

export const sortListByHourRevert = (list, field = 'date') =>
  list.sort((a, b) => sortByDateOrderRevert(a, b, field, 'minute'))
