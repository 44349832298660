import React from 'react'
import { withRouter } from 'react-router-dom'

import EmptyPlaceholder from '../UI/EmptyPlaceholder'
import UsersGroupListItem from './ListItem'
import ScrollTransition from '../UI/animations/transition/ScrollTransition'

const UsersGroupList = ({ groups = [], history, onDelete }) => {
  const redirectToGroupPage = id => history.push(`groups/${id}`)

  return groups.length
    ? (
      <ScrollTransition loading>
        <div className='card_list'>
          {groups.map((group, index) => (
            <UsersGroupListItem
              group={group}
              key={group.id}
              index={index}
              hideDeleteIcon={group.pre_defined}
              onClick={() => redirectToGroupPage(group.id)}
              onDelete={() => onDelete({ id: group.id, title: group.title })}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Список групп пуст' />
}

export default withRouter(UsersGroupList)
