import React from 'react'
import { withRouter } from 'react-router-dom'
import { Tooltip } from 'antd'

import getFullName from '../../../utils/getFullName'
import { dateToStringDayOfWeek } from '../../../utils/dates/formatDate'
import DoubleLineColonBlock from '../../UI/dataBlock/DoubleLineColonBlock'

const BookingCardListItem = ({ booking }) => {
  const fullName = booking.user ? getFullName(booking.user) : 'Имя'

  const codeTextColor = booking.active ? 'secondary_text__green' : 'secondary_text__white'

  const getNameElem = () => fullName.length < 12
    ? fullName
    : <Tooltip title={fullName} mouseEnterDelay={0.5}>{fullName}</Tooltip>

  return (
    <div className='flex_container full_width' style={{ width: 'calc(100% - 20px)' }}>
      <DoubleLineColonBlock
        first={{ title: 'с', text: dateToStringDayOfWeek(booking.date_from) }}
        second={{ title: 'по', text: dateToStringDayOfWeek(booking.date_to) }}
        containerClass='card_list__item_duration__table_screen'
        containerStyle={{ width: '35%', minWidth: '35%', maxWidth: '35%' }}
      />
      <span
        className='card_list__item_name main_text__white'
        style={{ width: '40%', minWidth: '40%', paddingLeft: '3%', maxWidth: '40%' }}
      >
        {/* Test version */}
        {booking?.user?.phone_number}
        {/* {getNameElem()} */}
      </span>
      <span
        className={`card_list__item_small_description ${codeTextColor}`}
        style={{ borderRight: 0, width: '25%', minWidth: '25%' }}
      >
        {booking.active ? 'Подтверждено' : 'Ожидание'}
      </span>
    </div>
  )
}

export default withRouter(BookingCardListItem)
