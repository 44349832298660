import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import UnderlinedTitle from '../../../../../UI/UnderlinedTitle'
import Button from '../../../../../UI/Button'
import ImportFromCsvModal from '../../helpers/ImportFromCsvModal'
import SubtitleWithActions from '../../../../../UI/SubtitleWithActions'
import TextModal from '../../../../../UI/modals/TextModal'
import TableHeader from '../../../../../UI/TableHeader'
import RoomTypesListItem from './RoomTypesListItem'
import EmptyPlaceholder from '../../../../../UI/EmptyPlaceholder'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import ColorPickModal from '../../../../../UI/modals/ColorPickModal'
import LargeSpin from '../../../../../UI/Spin/LargeSpin'
import {
  DELETE_ROOM_TYPE,
  POST_ROOM_TYPE,
  PUT_ROOM_TYPE,
  POST_ROOM_TYPE_ICON,
} from '../../../../../../actions/offices'

const LIST_COLUMNS_NAMES = [
  'Название типа помещения',
  'Иконка',
  'Цвет',
  'Бронь',
  'Часовой инт-л',
  'Дневной инт-л',
]

const COLUMNS_FLEX_VALUES = [10, 3, 2, 3, 6, 6]

const roomTypeDefault = {
  title: '',
  color: null,
  bookable: false,
  icon: null,
  work_interval_days: 0,
  work_interval_hours: 0,
}

const RoomTypes = ({ match }) => {
  const [selectedItem, setSelectedItem] = useState() // tracks editing item for colorpicker modal
  const [isImportFromCsvModalVisible, setIsImportFromCsvModalVisible] = useState(false)
  const [isTextModalOpen, setIsTextModalOpen] = useState(false)
  const [isColorPickerModalOpen, setIsColorPickerModalOpen] = useState(false)
  const [isAddSingleRoomTypeInputVisible, setIsAddSingleRoomTypeInputVisible] = useState(false)

  const dispatch = useDispatch()

  const { current: office } = useRef(match.params.id)

  const { list: roomTypesList = [], loading = false } = useSelector(state =>
    state.offices_newDesign.roomTypes || {})

  const handleAddMultipleRoomTypes = () => setIsTextModalOpen(true)

  const toggleAddSingleRoomType = () => setIsAddSingleRoomTypeInputVisible(val => !val)

  const handleRoomTypePut = (roomTypeId, payload, callback) =>
    dispatch({ type: PUT_ROOM_TYPE, roomTypeId, payload, callback })

  const handleRoomTypePost = payload => dispatch({
    type: POST_ROOM_TYPE,
    payload: { ...payload, office },
    callback: toggleAddSingleRoomType,
  })

  const handlePackageUpload = (titles, callback) => dispatch({
    type: POST_ROOM_TYPE,
    payload: { title: titles, office },
    isPackageUpload: true,
    callback,
  })

  const handleRoomTypeDelete = roomTypeId => dispatch({
    type: DELETE_ROOM_TYPE,
    roomTypeId,
  })

  const handleSaveColor = color =>
    handleRoomTypePut(selectedItem.id, { color }, () => setIsColorPickerModalOpen(false))

  const handleColorPickerBtn = item => {
    setIsColorPickerModalOpen(true)
    setSelectedItem(item)
  }

  const renderImportFromCsvBtn = () => (
    <Button
      icon='icon__download_2'
      styles='rounded_btn__yellow'
      title='Импорт из .csv'
      onClick={() => setIsImportFromCsvModalVisible(true)}
    />
  )

  const renderRoomTypesList = () => roomTypesList.length || isAddSingleRoomTypeInputVisible
    ? (
      <ScrollTransition loading>
        <div className='room-types-list'>
          {isAddSingleRoomTypeInputVisible && (
            <RoomTypesListItem
              roomType={roomTypeDefault}
              handleColorPickerBtn={handleColorPickerBtn}
              onSave={handleRoomTypePost}
              onCancel={toggleAddSingleRoomType}
              isNew
            />
          )}
          {roomTypesList.map(roomType => (
            <RoomTypesListItem
              handleColorPickerBtn={handleColorPickerBtn}
              onDelete={handleRoomTypeDelete}
              onSave={(payload, callback) => handleRoomTypePut(roomType.id, payload, callback)}
              roomType={roomType}
              key={roomType.id}
              onIconUpload={(roomTypeId, icon) =>
                dispatch({ type: POST_ROOM_TYPE_ICON, roomTypeId, icon })}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Здесь пока нет типов помещений' />

  return (
    <>
      {/* <EmptyPlaceholder text='Функционал в работе. Доступны 2 типа помещения: Рабочее место и Переговорная.' /> */}
      <div style={{ paddingRight: '10px' }}>
        <UnderlinedTitle title='ТИПЫ ПОМЕЩЕНИЙ' />
        <SubtitleWithActions
          subtitle={`Всего типов помещений: ${roomTypesList.length}`}
          // actions={renderImportFromCsvBtn()}
        />
        <div className='btn-group' style={{ margin: '12px 0 20px' }}>
          <Button
            onClick={toggleAddSingleRoomType}
            styles='btn_solid__light-blue btn-group__item'
            title={isAddSingleRoomTypeInputVisible ? 'Отменить добавление' : 'Добавить 1 тип'}
          />
          <Button
            onClick={handleAddMultipleRoomTypes}
            styles='btn_solid__light-blue btn-group__item'
            title='Добавить несколько'
          />
        </div>
      </div>
      <TableHeader
        columns={LIST_COLUMNS_NAMES}
        flexValues={COLUMNS_FLEX_VALUES}
        rightPaddingWidth={50}
        style={[{ marginRight: '5px' }]}
      />
      {loading ? <LargeSpin /> : renderRoomTypesList()}

      {/* MODALS */}
      <ImportFromCsvModal
        form='room-types'
        onClose={() => setIsImportFromCsvModalVisible(false)}
        start={isImportFromCsvModalVisible}
      />
      <TextModal
        isOpen={isTextModalOpen}
        setIsOpen={setIsTextModalOpen}
        onSubmit={handlePackageUpload}
        title='ДОБАВИТЬ НЕСКОЛЬКО ТИПОВ ПОМЕЩЕНИЙ'
        subtitle='Введите названия типов помещений через запятую'
        hint='Пример: Рабочее мест, Туалет, Кофе-пойнт'
        submitTitle='Добавить типы помещений'
      />
      {isColorPickerModalOpen && (
        <ColorPickModal
          icon={selectedItem.icon?.path || null}
          color={selectedItem.color}
          onClose={() => setIsColorPickerModalOpen(false)}
          start={isColorPickerModalOpen}
          onSave={handleSaveColor}
        />
      )}
    </>
  )
}

export default withRouter(RoomTypes)
