/* eslint-disable quote-props */
const ERRORS = {
  '404': {
    title: '404',
    text: 'Страница, которую вы ищите, не существует',
  },
  '500': {
    title: '500',
    text: 'Внутренняя ошибка сервера',
  },
}

export default ERRORS
