import React from 'react'
import { Field as FormikField } from 'formik'
import { Form, Input } from 'antd'

const SimpleTextArea = ({
  name,
  label,
  value,
  style,
  onChange,
  setFieldTouched,
  children,
  placeholder,
  error,
  rows,
}) => (
  <Form.Item label={label}>
    <div className='validation_block'>
      <FormikField
        render={() => (
          <Input.TextArea
            rows={rows}
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={({ target }) => onChange(name, target.value)}
            onBlur={() => setFieldTouched(name, true)}
            style={{ ...style, border: error && '1px solid #CD4141' }}
          />
        )}
      />
      <div className='validation_block margin_top__small'>{children}</div>
    </div>
  </Form.Item>
)

export default SimpleTextArea
