import React from 'react'

import Marker from './Marker'

class Map extends React.PureComponent {
  state = {
    startX: undefined,
    startY: undefined,
    loaded: false,
    moving: false,
  }

  DELTA = 3

  componentDidMount() {
    document.getElementById('map').addEventListener('mousedown', this.setPositions)
    document.getElementById('map').addEventListener('mouseup', this.handle)
  }

  componentWillUnmount() {
    document.getElementById('map').removeEventListener('mousedown', this.setPositions)
    document.getElementById('map').removeEventListener('mouseup', this.handle)
  }

  packMarker = (e, elem) => {
    const { points, activeRoom } = this.props

    const x = e.offsetX
    const y = e.offsetY
    const xP = (x / elem.offsetWidth) * 100
    const yP = (y / elem.offsetHeight) * 100

    const type = points?.find(point => point.roomId === activeRoom)?.type

    return { x: xP.toFixed(2), y: yP.toFixed(2), type }
  }

  handle = e => {
    if (this.props.mode !== 'write' || !this.props.activeRoom) {
      this.setState({ moving: false })
      return
    }

    const { startX, startY } = this.state
    const { activeRoom, addMarker } = this.props

    const diffX = Math.abs(e.pageX - startX)
    const diffY = Math.abs(e.pageY - startY)
    if (Number.isNaN(diffX) || Number.isNaN(diffY) || diffX > this.DELTA || diffY > this.DELTA) {
      this.setState({ moving: false })
      return
    }

    if (activeRoom) {
      const elem = document.getElementById('map')
      if (!elem) {
        this.setState({ moving: false })
        return
      }

      const marker = this.packMarker(e, elem)
      addMarker(marker)
    }
    this.setState({ moving: false })
  }

  setPositions = e => this.setState({ startX: e.pageX, startY: e.pageY, moving: true })

  getSize = () => {
    const { scale } = this.props
    const size = (34 / scale)

    return { width: size + 'px', height: size + 'px' }
  }

  render() {
    const { map, scale, getMarkerStyle, points = [] } = this.props
    const { loaded, moving } = this.state

    return (
      <>
        <div className='container_map__inner'>
          <img src={map} alt='' id='map' onLoad={() => this.setState({ loaded: true })} />
        </div>
        {loaded && points.map((point, index) => point.x && (
          <Marker
            scale={scale}
            point={point}
            key={`point-${index}`}
            index={index}
            moving={moving}
            size={this.getSize()}
            extraClassName={getMarkerStyle(point.roomId)}
          />
        ))}
      </>
    )
  }
}

export default Map
