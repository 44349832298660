import React from 'react'
import { connect } from 'react-redux'

import { importGroups } from '../../actions/actionCreator/groups'
import UploadButton from '../UI/UploadButton'
import Modal from '../UI/modals/Modal'
import packFile from '../../utils/objects/packFile'

const ImportGroupsFromCsvModal = ({ importGroups, start, onClose }) => {
  const handleCsvImport = ({ target }, targetEndPoint) => {
    const file = target.files[0]
    if (!file) return

    const data = packFile(file)
    importGroups(data, targetEndPoint, onClose)
  }

  return (
    <Modal start={start} onClose={onClose} style={{ width: '700px' }}>
      <span className='modal_card__title'>ИМПОРТИРОВАТЬ ИЗ ФАЙЛА</span>
      <div className='flex_container__row full_height' style={{ marginTop: '30px' }}>
        <div
          className='flex_container__column border_right'
          style={{ flex: 1, padding: '0 37px 0px 7px' }}
        >
          <h3 className='h2'>Только группы</h3>
          <div className='code__block'>
            <span className='text__code'>
              Access Group 1<br />
              Access Group 2<br />
              Access Group 3<br />
              Access Group 4<br />
            </span>
          </div>
          <UploadButton
            acceptFiles='.csv'
            btnClassName='rounded_btn__green min_width__100'
            btnTitle='Загрузить из файла'
            handleChange={e => handleCsvImport(e, '/import_titles')}
          />
        </div>
        <div
          className='flex_container__column'
          style={{ flex: 1, padding: '0 7px 0px 37px' }}
        >
          <h3>Группы и пользователей</h3>
          <div className='code__block'>
            <span className='text__code'>
              +79213867200,Access Group 1
              +79213867201,Access Group 2
              +79213867202,Access Group 3
              +79213867203,Access Group 4
            </span>
          </div>
          <UploadButton
            acceptFiles='.csv'
            btnClassName='rounded_btn__green min_width__100'
            btnTitle='Загрузить из файла'
            handleChange={e => handleCsvImport(e, '/import_list')}
          />
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ users }) => ({ importLoading: users?.groups?.importLoading })

const mapDispatchToProps = { importGroups }

export default connect(mapStateToProps, mapDispatchToProps)(ImportGroupsFromCsvModal)
