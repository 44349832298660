import * as _ from 'lodash'
import {
  OFFICES_LOADING,
  GET_OFFICES_SUCCEED,
  GET_OFFICE_SUCCEED,
  GET_FLOORS_SUCCEED,
  GET_OFFICE_FAILED,
  GET_OFFICES_FAILED,
  CLEAN_OFFICES_LIST,
  SET_TABLES_LOADING,
  GET_TABLES_SUCCEED,
  CLEAN_TABLES,
  GET_ROOMS_LIST_SUCCEED,
  SET_ROOMS_LOADING,
  CLEAN_OFFICE,
  SET_FLOORS_LOADING,
  GET_TABLE_TAGS_SUCCEED,
  POST_OFFICE_SUCCEED,
  PUT_OFFICE_SUCCEED,
  POST_ROOM_SUCCEED,
  POST_TABLE_SUCCEED,
  DELETE_TABLE_SUCCEED,
  DELETE_ROOM_SUCCEED,
  PUT_ROOM_SUCCEED,
  PUT_TABLE_SUCCEED,
  SET_CURRENT_TABLE,
  ACTIVATE_TABLE_SUCCEED,
  GET_SEARCH_OFFICES_SUCCEED,
  SET_TABLE_TAGS_LOADING,
  DELETE_TABLE_TAG_SUCCEED,
  ADD_MARKER_SUCCEED,
  POST_MAP_TO_FLOOR_SUCCEED,
  DELETE_MAP_SUCCEED,
  DELETE_MARKER_SUCCEED,
  DELETE_OFFICE_PHOTO,
  POST_OFFICE_IMAGE_SUCCEED,
  DELETE_ROOM_PHOTO,
  POST_ROOM_IMAGE_SUCCEED,
  GET_LICENSES_SUCCEED,
  DELETE_ROOM_FROM_MAP_SUCCEED,
} from '../actions/offices'

import { officeDef, floorsDef } from './localData'
import { sortListByTitle } from '../utils/sort'

const defaultState = {
  floors: {
    loading: false,
    list: [],
  },
}

export const offices = (state = defaultState, action) => {
  switch (action.type) {
    case OFFICES_LOADING: return { ...state, loading: true }

    case GET_OFFICES_SUCCEED: {
      const newOffices = (state.list && action.page !== 1)
        ? [...state.list, ...action.list]
        : action.list

      return { ...state, list: newOffices, count: action.count, loading: false }
    }

    case GET_SEARCH_OFFICES_SUCCEED: {
      return { ...state, searchList: action.list }
    }

    case GET_OFFICES_FAILED: return { ...state, loading: false, list: [] }

    case GET_OFFICE_SUCCEED: {
      return action.editMode
        ? {
          ...state,
          office: { ...action.office, floors: state.floors.list },
          loading: false,
          floors: {},
        }
        : { ...state, office: action.office, loading: false }
    }

    case GET_OFFICE_FAILED:
      return { ...state, office: officeDef, floors: floorsDef, loading: false }

    case SET_TABLES_LOADING: return { ...state, tables: { ...state.tables, loading: true } }

    case GET_TABLES_SUCCEED: {
      const { editMode, list, roomId, page, count } = action
      if (editMode) {
        const targetRoomIndex = state.office.rooms.findIndex(r => r.id === roomId)
        const newRoom = state.office.rooms[targetRoomIndex]
        newRoom.tables = list
        const newRooms = state.office.rooms
        newRooms[targetRoomIndex] = newRoom

        return { ...state, office: { ...state.office, rooms: newRooms } }
      }

      const shouldRewrite = !(state.tables.list && (page && page !== 1))
      const newTables = shouldRewrite ? list : [...state.tables.list, ...list]

      return { ...state, tables: { ...state.tables, loading: false, list: newTables, count } }
    }

    case SET_ROOMS_LOADING: return { ...state, rooms: { ...state.rooms, loading: true } }

    case GET_ROOMS_LIST_SUCCEED: {
      const { page, list, count } = action
      const isRoomsExist = (state.rooms.list && page !== 1)
      const newRooms = isRoomsExist ? [...state.rooms.list, ...list] : list

      return { ...state, rooms: { ...state.rooms, list: newRooms, count, loading: false } }
    }

    case SET_FLOORS_LOADING: return { ...state, floors: { ...state.floors, loading: true } }

    case GET_FLOORS_SUCCEED:
      return { ...state, floors: { ...state.floors, list: action.floors, loading: false } }

    case SET_TABLE_TAGS_LOADING: return { ...state, tableTags: { loading: true } }

    case GET_TABLE_TAGS_SUCCEED:
      return { ...state, tableTags: { list: action.tagsList, loading: false } }

    case PUT_OFFICE_SUCCEED:
    case POST_OFFICE_SUCCEED: return { ...state, office: { ...state.office, ...action.newOffice } }

    case POST_OFFICE_IMAGE_SUCCEED:
      return { ...state, office: { ...state.office, photo: action.newImage } }

    case DELETE_OFFICE_PHOTO:
      return { ...state, office: { ...state.office, photo: undefined } }

    case POST_ROOM_SUCCEED: {
      const { office } = state
      const isRoomsExist = (office.rooms && office.rooms.length > 0)
      const newList = isRoomsExist ? [action.newRoom, ...office.rooms] : [action.newRoom]

      return { ...state, office: { ...office, rooms: newList } }
    }

    case DELETE_ROOM_PHOTO: {
      const { roomId } = action
      const { office } = state

      const targetRoomIndex = office.rooms.findIndex(r => r.id === roomId)
      const newList = office.rooms
      const newRoom = { ...newList[targetRoomIndex], photo: {} }
      newList[targetRoomIndex] = newRoom

      return { ...state, office: { ...office, rooms: newList } }
    }

    case POST_TABLE_SUCCEED: {
      const { office } = state
      const { newTable } = action

      const targetRoomIndex = office.rooms.findIndex(r => r.id === newTable.room)
      const newRoom = office.rooms[targetRoomIndex]
      const isTablesExist = newRoom.tables && newRoom.tables.length > 0
      newRoom.tables = isTablesExist ? [newTable, ...newRoom.tables] : [newTable]
      newRoom.capacity += 1

      const newRooms = office.rooms
      newRooms[targetRoomIndex] = newRoom

      return { ...state, office: { ...office, rooms: newRooms } }
    }

    case CLEAN_OFFICES_LIST: return { ...state, list: [] }

    case CLEAN_TABLES: return { ...state, tables: {} }

    case CLEAN_OFFICE: return { ...state, tables: {}, rooms: {}, floors: {}, office: {} }

    case DELETE_TABLE_SUCCEED: {
      const { office } = state
      const targetRoomIndex = office.rooms.findIndex(r => r.id === action.roomId)
      const newRoom = office.rooms[targetRoomIndex]

      newRoom.tables = newRoom.tables.filter(t => t.id !== action.tableId)
      newRoom.capacity -= 1
      const newRooms = office.rooms
      newRooms[targetRoomIndex] = newRoom

      return { ...state, office: { ...office, rooms: newRooms } }
    }

    case DELETE_ROOM_SUCCEED: {
      const { office } = state
      const newRooms = office.rooms.filter(r => r.id !== action.roomId)

      return { ...state, office: { ...office, rooms: newRooms } }
    }

    case PUT_ROOM_SUCCEED: {
      const { office } = state
      const targetRoomIndex = office.rooms.findIndex(r => r.id === action.newRoom.id)
      const newList = office.rooms
      newList[targetRoomIndex] = action.newRoom

      return { ...state, office: { ...office, rooms: newList } }
    }

    case POST_ROOM_IMAGE_SUCCEED: {
      const { office } = state
      const { roomId, newImage } = action

      const targetRoomIndex = office.rooms.findIndex(r => r.id === roomId)
      const newList = office.rooms
      const newRoom = { ...newList[targetRoomIndex], photo: newImage }
      newList[targetRoomIndex] = newRoom

      return { ...state, office: { ...office, rooms: newList } }
    }

    case PUT_TABLE_SUCCEED: {
      const { office } = state
      const { roomId, newTable } = action

      const newList = office.rooms
      const targetRoomIndex = newList.findIndex(r => r.id === roomId)

      const newRoom = newList[targetRoomIndex]
      const targetTableIndex = newRoom.tables.findIndex(t => t.id === newTable.id)
      const newTables = newRoom.tables
      newTables[targetTableIndex] = newTable

      newRoom.tables = [...newTables]
      newList[targetRoomIndex] = newRoom

      const newTableTags = newTable.tags
        ? _.uniqBy([...state.tableTags.list, ...newTable.tags], 'id')
        : state.tableTags.list

      return {
        ...state,
        office: { ...office, rooms: [...newList] },
        tableTags: { list: newTableTags },
      }
    }

    case SET_CURRENT_TABLE: return { ...state, currentTable: action.table }

    case ACTIVATE_TABLE_SUCCEED: {
      const { office } = state
      const { roomId, table } = action

      const newList = office.rooms
      const targetRoomIndex = newList.findIndex(r => r.id === roomId)
      const newRoom = newList[targetRoomIndex]
      const newTables = newRoom.tables.filter(t => t.id !== table.id)

      newRoom.tables = sortListByTitle([...newTables, table])
      newList[targetRoomIndex] = newRoom
      return { ...state, office: { ...office, rooms: newList }, currentTable: action.table }
    }

    case DELETE_TABLE_TAG_SUCCEED: {
      const { tagId } = action
      const newRoomsList = state.office.rooms.map(room => {
        if (room.tables) {
          const newTablesList = room.tables.map(table => {
            const newTags = table.tags.filter(tag => tag.id !== tagId)
            return { ...table, tags: newTags }
          })
          return { ...room, tables: newTablesList }
        }
        return room
      })
      const newTableTagsList = state.tableTags.list.filter(tag => tag.id !== tagId)

      return {
        ...state,
        office: { ...state.office, rooms: newRoomsList },
        tableTags: { list: newTableTagsList },
      }
    }

    // Licenses

    case GET_LICENSES_SUCCEED: return { ...state, licenses: action.list }

    // Maps
    case DELETE_MAP_SUCCEED:
    case POST_MAP_TO_FLOOR_SUCCEED: {
      const { floorId, newFloor } = action

      const floorIndex = state.floors.list.findIndex(floor => floor.id === floorId)
      const newFloors = state.floors.list
      newFloors[floorIndex] = newFloor

      return { ...state, floors: { ...state.floor, list: [...newFloors] } }
    }

    default: return state
  }
}

export default offices
