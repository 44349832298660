import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Checkbox, Spin } from 'antd'

import ExpandingSearch from '../UI/ExpandingSearch'
import LargeSpin from '../UI/Spin/LargeSpin'
import RecipientsList from './RecipientsList'
import useEventListener from '../../utils/hooks/useEventListener'

const CheckableList = ({
  loading,
  renderListItem,
  renderListHeader,
  searchPlaceholder,
  onCheck,
  fieldToShowInSearch,
  actionTypeOnSearch,
  searchFieldIdSuffix,
  rowHeight = 54,
  title = 'Список',
  list = [],
}) => {
  const [searchString, setSearchString] = useState('')
  const [searchResult, setSearchResult] = useState({ loading: false, list: [] })
  const [isPopUpVisible, setIsPopUpVisible] = useState(false)

  const searchFieldRef = useRef()

  const dispatch = useDispatch()

  const handleSucceedSearch = users => {
    const usersWithCheckField = users.map(user => ({
      ...user,
      checked: list?.find(item => item.id === user.id).checked,
    }))

    setSearchResult({ loading: false, list: usersWithCheckField })
    !isPopUpVisible && setIsPopUpVisible(true)
  }

  const handleSearch = ({ searchString }) => {
    if (searchString.length < 3) {
      isPopUpVisible && setIsPopUpVisible(false)
      return
    }

    setSearchResult({ ...searchResult, loading: true })
    dispatch({
      type: actionTypeOnSearch,
      searchString,
      callback: handleSucceedSearch,
    })
  }

  const getUpdatedUsers = users => {
    const newUsers = searchResult.list.map(user => {
      const updatedUser = users.find(u => u.id === user.id)
      return updatedUser
    })

    return newUsers
  }

  const onSearchFieldFocus = () => searchString.length > 2 && setIsPopUpVisible(true)

  const handleClickOutside = ({ target }) => {
    const isClickInside = searchFieldRef.current?.contains(target)
    if (!isClickInside) setIsPopUpVisible(false)
  }

  const renderPopUpList = () => searchResult.list.length
    ? searchResult.list.map(res =>(
      <div className='pop-up__item' key={`search-${res.id}`} onClick={() => onCheck(res)}>
        <Checkbox checked={res.checked} />
        <span>{res[fieldToShowInSearch] || ''}</span>
      </div>
    ))
    : (
      <div className='pop-up__item'>
        <span className='secondary_text__inactive'>Не найдено</span>
      </div>
    )

  const renderPopUp = () => (
    <div className={`pop-up${isPopUpVisible ? '' : '_hidden'}`}>
      {searchResult.loading
        ? (
          <div className='flex_container__center' style={{ height: '20px' }}>
            <Spin style={{ height: '20px' }} />
          </div>
        )
        : <div style={{ maxHeight: '100%' }}>{renderPopUpList()}</div>}
    </div>
  )

  useEffect(() => {
    searchString && setSearchResult({ ...searchResult, list: getUpdatedUsers(list) })
  }, [list])

  useEventListener('mousedown', handleClickOutside)

  return (
    <div className='notifications__list-container'>
      <h3>{title}</h3>
      {loading
        ? <LargeSpin />
        : (
          <>
            <ExpandingSearch
              setSearchString={setSearchString}
              searchString={searchString}
              getItems={handleSearch}
              placeholder={searchPlaceholder}
              onFocus={onSearchFieldFocus}
              autocomplete='off'
              idSuffix={searchFieldIdSuffix}
              containerRef={searchFieldRef}
              defaultExpanded
              isExpanded
            >
              {renderPopUp()}
            </ExpandingSearch>
            <RecipientsList
              rowHeight={rowHeight}
              renderListItem={renderListItem}
              header={renderListHeader}
              list={list}
            />
          </>
        )}
    </div>
  )
}

export default CheckableList
