import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox } from 'antd'

import CardListItem from '../../../helpers/lists/CardListItem'
import IconDownload from '../../../UI/icons/Download'
import IconPlus from '../../../UI/icons/PlusRounded'
import ListPopUp from '../../../ListPopUp'
import TableList from './TableList'
import ConfirmationModal from '../../../UI/modals/ConfirmationModal'

const TABLE_FLEX_VALUES = [0, 1, 1, 0.3, 0.5, 0.5]

const getImagePreviewStyle = images => {
  const path = images[0].thumb || images[0].path
  return ({ backgroundImage: `url(${path})` })
}

const RoomListItem = ({
  index,
  flexValues,
  onCheck,
  room = {},
  handleAddTable,
  officeId,
  setSelectedDispatch,
  handleEditRoom,
  handleDeleteRoom,
  tags,
}) => {
  const [showTableList, setShowTableList] = useState(false)
  const [isPopUpMenuOpen, setIsPopUpMenuOpen] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const togglePopUp = () => setIsPopUpMenuOpen(val => !val)

  const toggleTableList = () => {
    setShowTableList(!showTableList)
  }

  const onAddTableClick = e => {
    e.stopPropagation()
    handleAddTable(room.id)
  }

  const getConfirmDeletionText = () => {
    const baseText = `Удалить помещение '${room.title}'?`
    return (room.tables.length || room.type === 'Переговорная')
      ? baseText
      : `${baseText} Будет удалено рабочих мест: ${room.tables.length}.`
  }

  const getNotOccupiedCount = () =>
    room.tables.reduce((acc, cur) => cur.is_occupied ? acc : acc + 1, 0)

  const popUpOptions = [
    // {
    //   title: 'Добавить несколько рабочих мест',
    //   icon: 'icon__edit',
    //   onClick: () => console.log('add'),
    // },
    {
      title: 'Редактировать',
      icon: 'icon__edit',
      onClick: () => handleEditRoom(room),
    },
    {
      title: <Link to={`/offices/${officeId}/map/${room.floor.id}`}>Управлять картой</Link>,
      icon: 'icon__map_marker',
      style: { height: '15px', width: '14px' },
    },
    {
      title: 'Удалить',
      icon: 'icon__trash',
      onClick: () => setIsDeleteModalVisible(true),
    },
  ]

  return (
    <>
      <CardListItem onCardClick={toggleTableList}>
        <div className='rooms-list__checkbox' style={{ flex: flexValues[0] }}>
          <Checkbox
            checked={room.checked}
            onChange={({ target }) => onCheck(room.id, target.checked)}
            onClick={e => e.stopPropagation()}
          />
        </div>
        <div className='rooms-list__room-number' style={{ flex: flexValues[1] }}>{index + 1}.</div>
        <div className='rooms-list__room-title' style={{ flex: flexValues[2] }}>{room.title}</div>
        <div className='rooms-list__room-zone' style={{ flex: flexValues[3] }}>{room.zone?.title}</div>
        <div className='rooms-list__room-type' style={{ flex: flexValues[4] }}>{room.type}</div>
        <div className='rooms-list__room-floor' style={{ flex: flexValues[5] }}>{room.floor?.title}</div>
        <div className='rooms-list__room-book' style={{ flex: flexValues[6] }}>
          {room.is_bookable
            ? (
              <>
                <span className='text_green'>{getNotOccupiedCount()}</span>
                <span className='text_gray'> / </span>
                <span>{room.tables?.length || 0}</span>
              </>
            )
            : <span className='text_gray'>–</span>}
        </div>
        <div style={{ flex: flexValues[7] }}>
          {room.images?.length
            ? <div className='icon_preview' style={getImagePreviewStyle(room.images)} />
            : <span className='secondary_text__inactive'>–</span>}
        </div>
        {room.is_bookable && !room.room_type_unified
          ? (
            <div onClick={onAddTableClick} className='rooms-list__icon-wrapper'>
              <IconPlus color='#0079C1' />
            </div>
          )
          : <div className='rooms-list__icon-wrapper' style={{ width: '18px' }} />}
        {/* <div */}
        {/*  className='rooms-list__icon-wrapper' */}
        {/*  onClick={e => { */}
        {/*    e.stopPropagation() */}
        {/*  }} */}
        {/* > */}
        {/*  <IconDownload */}
        {/*    width={16} */}
        {/*    height={16} */}
        {/*    className='rooms-list__icon-wrapper' */}
        {/*  /> */}
        {/* </div> */}
        <div
          style={{ marginRight: 0 }}
          className={`icon__more ${isPopUpMenuOpen ? 'icon__active' : ''}`}
          onClick={e => e.stopPropagation()}
          onMouseDown={e => {
            e.stopPropagation()
            togglePopUp()
          }}
        />
        {isPopUpMenuOpen && <ListPopUp options={popUpOptions} closePopUp={togglePopUp} />}
      </CardListItem>
      <ConfirmationModal
        start={isDeleteModalVisible}
        text={getConfirmDeletionText()}
        onConfirm={() => handleDeleteRoom(room.id)}
        onClose={() => setIsDeleteModalVisible(false)}
      />
      {showTableList && (
        <TableList
          tags={tags}
          officeId={officeId}
          flexValues={TABLE_FLEX_VALUES}
          room={room}
          tables={room.tables}
          setSelectedDispatch={setSelectedDispatch}
        />
      )}
    </>
  )
}

export default RoomListItem
