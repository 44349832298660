import React from 'react'

export default ({ tooltipText, style, onClick, className, width = '24', height = '24', color = '#CD4141' }) => (
  <div data-tooltip={tooltipText} className={className} onClick={onClick}>
    <svg style={style} width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path fillRule='evenodd' clipRule='evenodd' d='M18.2156 6.90139L17.0991 5.78491C16.9999 5.68567 16.9083 5.67795 16.8245 5.76175L12.0005 10.5858L7.1823 5.7676C7.09459 5.6799 7.00112 5.68567 6.90188 5.78491L5.78539 6.90139C5.68615 7.00064 5.68038 7.09411 5.76808 7.18181L10.5863 12L5.76223 16.824C5.67843 16.9079 5.68615 16.9994 5.78539 17.0986L6.90188 18.2151C7.00112 18.3143 7.09264 18.3221 7.17645 18.2383L12.0005 13.4142L16.8187 18.2324C16.9064 18.3201 16.9999 18.3143 17.0991 18.2151L18.2156 17.0986C18.3148 16.9994 18.3206 16.9059 18.2329 16.8182L13.4147 12L18.2387 7.17597C18.3225 7.09216 18.3148 7.00064 18.2156 6.90139Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill='white' transform='translate(0.000488281)' />
        </clipPath>
      </defs>
    </svg>
  </div>
)
