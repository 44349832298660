import React from 'react'

const Email = ({ isActive, onClick }) => (
  <div className={`icon__email${isActive ? '__active' : ''}`} onClick={onClick}>
    <svg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M3 2C2.45228 2 2 2.45228 2 3V15C2 15.5477 2.45228 16 3 16H19C19.5477 16 20 15.5477 20 15V3C20 2.45228 19.5477 2 19 2H3ZM0 3C0 1.34772 1.34772 0 3 0H19C20.6523 0 22 1.34772 22 3V15C22 16.6523 20.6523 18 19 18H3C1.34772 18 0 16.6523 0 15V3Z' fill='black' />
      <path fillRule='evenodd' clipRule='evenodd' d='M0.180762 2.42654C0.497477 1.97409 1.12101 1.86406 1.57346 2.18077L11 8.77935L20.4265 2.18077C20.879 1.86406 21.5025 1.97409 21.8192 2.42654C22.1359 2.87899 22.0259 3.50252 21.5735 3.81923L11.5735 10.8192C11.2291 11.0603 10.7709 11.0603 10.4265 10.8192L0.426532 3.81923C-0.0259174 3.50252 -0.135952 2.87899 0.180762 2.42654Z' fill='black' />
    </svg>
  </div>
)

export default Email
