import React from 'react'

const MeetingRoom = ({ className = 'icon__add_white', color = 'white', height = '16', width = '16' }) => (
  <div className={className} >
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.9 3H3.85C3.3825 3 3 3.3825 3 3.85V15.75L6.4 12.35H14.9C15.3675 12.35 15.75 11.9675 15.75 11.5V3.85C15.75 3.3825 15.3675 3 14.9 3ZM14.05 4.7V10.65H5.6945L4.7 11.6445V4.7H14.05ZM17.45 6.40007H19.15C19.6175 6.40007 20 6.78257 20 7.25007V20.0001L16.6 16.6001H7.25C6.7825 16.6001 6.4 16.2176 6.4 15.7501V14.0501H17.45V6.40007Z" fill="#FBFBFB" />
    </svg>
  </div>
)

export default MeetingRoom
