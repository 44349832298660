import { useEffect } from 'react'

const useEventListener = (event, handler) => {
  useEffect(() => {
    document.addEventListener(event, handler)
    return () => document.removeEventListener(event, handler)
  }, [])
}

export default useEventListener
