import React from 'react'
import { Route, Switch } from 'react-router-dom'

import List from './List/index'
import OfficeDetailInfo from './OfficeEdit/DetailInfo/index'
import OfficeMainInfoEdit from './OfficeEdit/MainInfo/index'
import OfficeMapFuncComponent from '../Map/OfficeMapFuncComponent'
import TableReservationScreen from '../Reservation/bookTableScreen'

const OfficesRouter = () => (
  <Switch>
    <Route exact path='/offices' component={List} />
    <Route exact path='/offices/new' render={props => <OfficeMainInfoEdit {...props} isNew />} />
    <Route exact path='/offices/:id/edit-main-info' component={OfficeMainInfoEdit} />
    <Route exact path='/offices/:id/edit-detail-info' component={OfficeDetailInfo} />
    <Route path='/offices/:id/map' component={OfficeMapFuncComponent} />
    <Route exact path='/offices/reservations/table/:id' component={TableReservationScreen} />
  </Switch>
)

export default OfficesRouter
