import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getAvatarStyle } from '../../utils/getStyle/getImageStyle'
import { removeTokenFromStorage } from '../../utils/manageTokens'
import UserPopUpInfo from './UserPopUpInfo'
import getFullName from '../../utils/getFullName'

const UserInfo = ({ name, email, description, history, firstName, photo, phone_number }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const logoClassNames = 'flex_container flex_container__center user_info__inner'

  const logOut = () => removeTokenFromStorage(() => history.push('/auth'))

  const getArrowStyle = () => ({ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' })

  return (
    <div className='user_info' id='userInfoWrap'>
      <div className={logoClassNames} id='userLogoWrap' onClick={() => setIsExpanded(!isExpanded)}>
        {/* Test version */}
        {phone_number
          ? <span id='userInfo' className='user_name'>{phone_number}</span>
          : <div className='placeholder__on_load' />}
        {/*{firstName*/}
        {/*  ? <span className='user_name'>{firstName}</span>*/}
        {/*  : <div style={titlePlaceholderStyle} />}*/}
        <span className='card_list__item_avatar'>
          <div className='card_list__avatar' style={photo && getAvatarStyle(photo)} />
        </span>
        <div className='expand_arrow' style={getArrowStyle()} />
      </div>
      <UserPopUpInfo
        phone={phone_number}
        name={name}
        email={email}
        description={description}
        closePopUp={() => setIsExpanded(false)}
        isExpanded={isExpanded}
        onLogOut={logOut}
      />
    </div>
  )
}

const mapStateToProps = ({ account }) => {
  const user = account?.account
  if (user) {
    const {
      photo,
      firstname = 'Не указано',
      email = 'Не указан',
      description = 'Не указано',
      phone_number = 'Не указан',
    } = user

    return {
      firstName: firstname,
      name: getFullName(user),
      photo: { id: photo?.id, path: photo?.path },
      email,
      phone_number,
      description,
    }
  }
  return {
    photo: null,
    firstName: '',
    name: 'Не указано',
    email: 'Не указан',
    description: 'Не установлен',
  }
}

export default withRouter(connect(mapStateToProps)(UserInfo))
