export const NAVIGATION = [
  {
    title: 'Уведомления',
    address: '/notifications',
  },
  {
    title: 'Бизнес Центры',
    address: '/offices',
  },
  {
    title: 'Группы',
    address: '/groups',
  },
  {
    title: 'Пользователи',
    address: '/users',
  },
]
