import React from 'react'

const Coffee = ({ className = 'icon__add_white', color = 'white', height = '16', width = '16' }) => (
  <div className={className} >
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M8.94918 5.24414C9.78356 4.68803 10.2814 3.64032 10.2814 2.63766C10.2814 1.63501 9.78356 0.70454 8.94918 0.148311C8.54313 -0.122099 7.99809 -0.0121772 7.73026 0.39206C7.46067 0.796356 7.57059 1.34233 7.97401 1.61098C8.3234 1.8436 8.52338 2.21784 8.52338 2.63766C8.52338 3.05748 8.3234 3.54891 7.97401 3.78153C7.13963 4.3377 6.64176 5.26822 6.64176 6.27082C6.64176 7.27341 7.13963 8.20394 7.97401 8.76017C8.38528 9.03286 8.92803 8.91433 9.19293 8.51642C9.46252 8.11212 9.35342 7.56703 8.94912 7.2975C8.59973 7.06488 8.39975 6.69064 8.39975 6.27082C8.39981 5.85099 8.59985 5.47676 8.94918 5.24414Z" fill="white" />
        <path d="M13.3442 5.24414C14.1786 4.68803 14.6765 3.64032 14.6765 2.63766C14.6765 1.63501 14.1786 0.70454 13.3442 0.148311C12.9374 -0.122099 12.3931 -0.0121772 12.1253 0.39206C11.8557 0.796356 11.9656 1.34233 12.3691 1.61098C12.7185 1.8436 12.9184 2.21784 12.9184 2.63766C12.9184 3.05748 12.7185 3.54891 12.3691 3.78153C11.5347 4.3377 11.0368 5.26822 11.0368 6.27082C11.0368 7.27341 11.5347 8.20394 12.3691 8.76017C12.7803 9.03286 13.3231 8.91433 13.588 8.51642C13.8576 8.11212 13.7485 7.56703 13.3442 7.2975C12.9948 7.06488 12.7949 6.69064 12.7949 6.27082C12.7949 5.85099 12.9948 5.47676 13.3442 5.24414Z" fill="white" />
        <path d="M17.7392 5.2442C18.5736 4.68797 19.0715 3.64026 19.0715 2.63766C19.0715 1.63507 18.5736 0.70454 17.7392 0.148311C17.3324 -0.122099 16.789 -0.0121772 16.5203 0.39206C16.2507 0.796356 16.3606 1.34233 16.7641 1.61098C17.1134 1.8436 17.3134 2.21784 17.3134 2.63766C17.3134 3.05748 17.1134 3.54891 16.7641 3.78153C15.9297 4.33776 15.4318 5.26828 15.4318 6.27088C15.4318 7.27347 15.9297 8.204 16.7641 8.76023C17.1754 9.03292 17.7181 8.91439 17.983 8.51648C18.2526 8.11218 18.1435 7.56709 17.7392 7.29755C17.3898 7.06494 17.1899 6.6907 17.1899 6.27088C17.1899 5.85105 17.3899 5.47682 17.7392 5.2442Z" fill="white" />
        <path d="M28.0152 14.5709C27.216 13.2077 25.7448 12.3614 24.1764 12.3614H22.482V11.4824C22.482 10.9965 22.0888 10.6033 21.6029 10.6033H4.02286C3.537 10.6033 3.14384 10.9964 3.14384 11.4823V17.0577C3.14384 18.4612 3.37042 19.9034 3.81855 21.2289C4.29409 22.6412 5.17569 23.8282 6.29559 24.7261H19.3302C19.9582 24.2226 20.4996 23.6195 20.9481 22.9455C24.1675 22.8296 26.6346 21.3637 28.0822 18.7711C28.8144 17.4603 28.7895 15.8902 28.0152 14.5709ZM26.5473 17.9143C25.5451 19.7079 23.9588 20.811 21.8367 21.1102C22.2591 19.8204 22.4819 18.42 22.4819 17.0576V14.1193H24.1764C25.1378 14.1193 26.0065 14.6206 26.4992 15.461C26.9611 16.2482 26.9783 17.1418 26.5473 17.9143Z" fill="white" />
        <path d="M23.3609 26.4839H2.26482C1.77937 26.4839 1.3858 26.8775 1.3858 27.363C1.3858 28.8194 2.5664 30 4.0228 30H21.6029C23.0593 30 24.2399 28.8194 24.2399 27.363C24.24 26.8775 23.8464 26.4839 23.3609 26.4839Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>

  </div>
)

export default Coffee
