import React from 'react'

const Hanger = ({ className = 'icon__add_white', color = 'white', height = '16', width = '16' }) => (
  <div className={className} >
    <svg width={width} height={height} viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.426745 23.3286L15.9592 11.9858V9.6616C15.9592 9.08679 16.4253 8.62082 17.0001 8.62082C18.0262 8.62082 18.8609 7.77998 18.8609 6.74637C18.8609 5.71276 18.0261 4.87192 17.0001 4.87192C15.974 4.87192 15.1392 5.71276 15.1392 6.74637C15.1392 7.32118 14.6732 7.78715 14.0984 7.78715C13.5236 7.78715 13.0576 7.32112 13.0576 6.74637C13.0576 4.56492 14.8262 2.79028 17.0001 2.79028C19.1739 2.79028 20.9425 4.56492 20.9425 6.74637C20.9425 8.56643 19.7114 10.1033 18.0409 10.5625V11.9859L33.5733 23.3286C34.3821 23.9198 33.9651 25.2097 32.9592 25.2097H1.0408C0.0350151 25.2097 -0.382216 23.9199 0.426745 23.3286ZM27.642 22.4342L17 14.6627L6.35801 22.4342H27.642Z" fill="white" />
    </svg>
  </div>
)

export default Hanger
