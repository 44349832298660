import React, { useState } from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import moment from 'moment'
import { Tooltip } from 'antd'
import { withFormik, Form as FormikForm, ErrorMessage } from 'formik'

import Button from '../../../UI/Button'
import citiesSource from '../../../../assets/cities'
import DatePick from '../../../UI/input/DatePick'
import generalCitiesSource from '../../../../assets/generalCities'
import getFullCityName from '../../../../utils/getFullCityName'
import getStyle from '../../../../utils/getStyle/getStyleUserForm'
import packUser from '../../../../utils/objects/packUser'
import RadioGroup from '../../../UI/input/RadioGroup'
import SelectWithSearch from '../../../UI/input/SelectWithSearch'
import SimpleImageUploader from '../SimpleImageUploader'
import SimpleInput from '../../../UI/input/SimpleInput'
import SimpleTextArea from '../../../UI/input/SimpleTextArea'
import ValidationMsg from '../../../UI/ValidationMsg'
import { phoneRegExp } from '../../../../utils/data/regExp'
import { PROMOTE_OPERATOR, PUT_USER, RESET_PASSWORD } from '../../../../actions/users'

const ValidationSchema = Yup.object().shape({
  // firstName: Yup.string()
  //   .min(2, 'Минимум 2 символа')
  //   .max(50, 'Максимум 50 символов')
  //   .required('Обязательное поле'),
  // middleName: Yup.string()
  //   .max(50, 'Максимум 50 символов'),
  // lastName: Yup.string()
  //   .min(2, 'Минимум 2 символа')
  //   .max(50, 'Максимум 50 символов')
  //   .required('Обязательное поле'),
  phone_number: Yup.string()
    .matches(phoneRegExp, 'Введите номер телефона от 10 до 13 символов')
    .required('Обязательное поле'),
  description: Yup.string()
    .max(150, 'Максимум 150 символов'),
  // email: Yup.string()
  //   .email('Введите адрес электронной почты')
  //   .required('Обязательное поле'),
  // city: Yup.object().shape({
  //   id: Yup.string().nullable().required('Обязательное поле'),
  // }),
  // birthday: Yup.object().nullable().required('Обязательное поле'),
})

const InnerForm = ({
  values,
  user,
  setFieldValue,
  setFieldTouched,
  onCancel,
  touched,
  errors,
  userPhoto,
  userEmailInStore,
  onEmailConfirm,
  resetPassword,
  promoteOperator,
}) => {
  const [citiesList, setCitiesList] = useState(generalCitiesSource)
  const halfWidthStyle = { maxWidth: '47%' }

  const getCities = searchVal => {
    if (searchVal.length < 3) {
      setCitiesList(generalCitiesSource)
      return null
    }
    setCitiesList(citiesSource.filter(city =>
      city.name.toLowerCase().includes(searchVal.toLowerCase())))
  }

  const getEmailConfirmationBtnStyle = () => {
    const { email, isEmailConfirmed } = values
    const basicStyle = 'input_suffix_btn__'
    if (isEmailConfirmed)
      return `${basicStyle}${(email === userEmailInStore) ? 'inactive' : 'disabled'}`
    return `${basicStyle}${(email === userEmailInStore) ? 'active' : 'disabled'}`
  }

  const getBtnText = suffixStyle => {
    if (suffixStyle === 'input_suffix_btn__inactive')
      return { tooltip: 'Email подтвержден', title: 'Подтвержден' }
    if (suffixStyle === 'input_suffix_btn__disabled')
      return { tooltip: 'Введите и сохраните email перед подтверждением', title: 'Подтвердить' }
    return {
      tooltip: 'Подтвердите email, чтобы пользователю стали доступны не только общедоступные пространства',
      title: 'Подтвердить',
    }
  }

  // Test version
  const getEmailConfirmationSuffix = () => {
    const { id, name } = user
    const shouldHandleClick = !values.isEmailConfirmed && userEmailInStore
    const obj = { id, name, email: userEmailInStore }

    const suffixStyle = getEmailConfirmationBtnStyle()
    const btnText = getBtnText(suffixStyle)

    return (
      <Tooltip
        overlayStyle={{ minWidth: suffixStyle === 'input_suffix_btn__inactive' ? '140px' : '250px' }}
        title={btnText.tooltip}
      >
        <div className={suffixStyle} onClick={() => shouldHandleClick && onEmailConfirm(obj)}>
          {btnText.title}
        </div>
      </Tooltip>
    )
  }

  const resetUserPassword = () => resetPassword({ account: user.id }, user.email)

  const grantCpAccess = () => promoteOperator(user.id, user.email)

  const renderGrantRightsBtns = () => (
    <div className='btn__user_card__top_right'>
      <div onClick={grantCpAccess}>
        {user?.has_cp_access
          ? 'Лишить доступа к панели оператора '
          : 'Предоставить доступ к панели оператора '}
      </div>
      {user?.has_cp_access && (
        <>
          <span style={{ margin: '0 3px' }}>/</span>
          <div onClick={resetUserPassword}> Сбросить пароль</div>
        </>
      )}
    </div>
  )

  const onPhoneFieldFocus = () =>
    !values.phone_number ? setFieldValue('phone_number', '+') : null

  const onPhoneFieldBlur = () =>
    values.phone_number === '+' ? setFieldValue('phone_number', '') : null

  const getErrorCondition = field => touched[field] && errors[field]

  const getInputProps = name => ({
    name,
    setFieldTouched,
    value: values[name],
    onChange: setFieldValue,
    error: getErrorCondition(name),
  })

  return (
    <div className='user_form__edit'>
      {renderGrantRightsBtns()}
      <FormikForm>
        <div className='container_form__left'>
          <SimpleInput
            disabled
            label='Имя*'
            placeholder='Имя пользователя'
            {...getInputProps('firstName')}
          >
            <ErrorMessage component={ValidationMsg} name='firstName' />
          </SimpleInput>
          <SimpleInput
            disabled
            label='Отчество'
            placeholder='Отчество пользователя'
            {...getInputProps('middleName')}
          >
            <ErrorMessage component={ValidationMsg} name='middleName' />
          </SimpleInput>
          <SimpleInput
            disabled
            label='Фамилия*'
            placeholder='Фамилия пользователя'
            {...getInputProps('lastName')}
          >
            <ErrorMessage component={ValidationMsg} name='lastName' />
          </SimpleInput>
          <div className='flex_container flex_container__space_between'>
            <div className='flex_container__flex_1 radio_group__horizontal' style={halfWidthStyle}>
              <DatePick
                disabled
                label='Дата рождения*'
                format='DD.MM.YYYY'
                disabledDate={current => current && current > moment().endOf('day')}
                {...getInputProps('birthday')}
              >
                <ErrorMessage component={ValidationMsg} name='birthday' />
              </DatePick>
            </div>
            <div className='radio_group__horizontal' style={halfWidthStyle}>
              <RadioGroup
                disabled
                name='sex'
                label='Пол'
                value={values.sex}
                setFieldTouched={setFieldTouched}
                onChange={setFieldValue}
                options={['М', 'Ж']}
              />
            </div>
          </div>
          <SimpleTextArea
            label='Должность/Описание'
            style={{ height: '60px' }}
            {...getInputProps('description')}
          >
            <ErrorMessage component={ValidationMsg} name='description' />
          </SimpleTextArea>
        </div>

        <div className='container_form__right'>
          <SimpleInput
            label='Телефон*'
            placeholder='+***********'
            style={getStyle()}
            onFocus={onPhoneFieldFocus}
            onBlur={onPhoneFieldBlur}
            {...getInputProps('phone_number')}
          >
            <ErrorMessage component={ValidationMsg} name='phone_number' />
          </SimpleInput>
          <SimpleInput
            disabled
            label='Email*'
            style={{ ...getStyle(), marginTop: 0 }}
            placeholder='Адрес электронной почты'
            {...getInputProps('email')}
          >
            <ErrorMessage component={ValidationMsg} name='email' />
          </SimpleInput>

          <SelectWithSearch
            disabled
            label='Город*'
            placeholder='Введите название города'
            onSearch={getCities}
            source={citiesList}
            targetField='name'
            idField='id'
            style={{ width: '100%', marginBottom: '2px' }}
            toShownString={getFullCityName}
            {...getInputProps('city')}
          >
            {touched.city && errors.city && <ValidationMsg>{errors.city.id}</ValidationMsg> }
          </SelectWithSearch>

          <SimpleImageUploader label='Фото' photo={userPhoto} userId={user?.id} />

          <div className='button_container__content_right'>
            <div className='lecture_form__button_wrapper'>
              <Button
                style={{ marginRight: '20px' }}
                styles='simple_btn'
                title='Отмена'
                onClick={onCancel}
              />
              <button type='submit' className='rounded-btn_blue'>
                <span className='large_btn__title'>Сохранить</span>
              </button>
            </div>
          </div>
        </div>
      </FormikForm>
    </div>
  )
}


const EditUserForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ user }) {
    const getCityName = () => citiesSource?.find(city => city.id === user.city).name
    return ({
      firstName: user.firstname || '',
      lastName: user.lastname || '',
      middleName: user.middlename || '',
      phone_number: user.phone_number || '',
      email: user.email || '',
      description: user.description || '',
      sex: user.gender ? user.gender.substring(0, 1) : null,
      birthday: user.birthday ? moment(user.birthday) : null,
      city: user.city ? { id: user.city, name: getCityName() } : { id: null },
      isEmailConfirmed: user.email_confirmed || false,
    })
  },
  validationSchema: ValidationSchema,
  handleSubmit: (values, { props }) => {
    props.putUser(packUser(values), props.userPhoto, props.user.id, props.onCancel)
  },
})(InnerForm)

const mapStateToProps = ({ files, users }, { user }) => {
  const getUserPhoto = () => {
    const fileInCurrentFiles = files?.currentFilesList?.find(file => file.userId === user.id)
    return fileInCurrentFiles || user?.photo
  }

  const getUserEmail = () => users?.list?.find(u => u.id === user.id).email

  return ({
    userPhoto: getUserPhoto(),
    userEmailInStore: getUserEmail(),
  })
}

const mapDispatchToProps = dispatch => ({
  putUser: (values, photo, userId, callback) =>
    dispatch({ type: PUT_USER, user: values, photo, userId, callback }),
  resetPassword: (userId, userEmail) => dispatch({ type: RESET_PASSWORD, userId, userEmail }),
  promoteOperator: (userId, userEmail) => dispatch({ type: PROMOTE_OPERATOR, userId, userEmail }),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditUserForm)
