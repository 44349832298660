import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Checkbox } from 'antd'

import MainCard from '../../../MainCard'
import actions from './selectedReducer/actions'
import useSelectedReducer from './selectedReducer/reducer'
import Button from '../../../UI/Button'
import EmptyPlaceholder from '../../../UI/EmptyPlaceholder'
import TableHeader from '../../../UI/TableHeader'
import RoomListItem from './RoomListItem'
import ScrollTransition from '../../../UI/animations/transition/ScrollTransition'
import RoomFormModal from './Modal/RoomFormModal'
import pluralizeString from '../../../../utils/pluralizeString'
import IconPrint from '../../../UI/icons/Print'
import QRPrintModal from './Modal/QRPrintModal/QRPrintModal'
import TableFormModal from './Modal/TableFormModal/TableFormModal'
import ConfirmationModal from '../../../UI/modals/ConfirmationModal'
import FilterInput from './FilterInput'
import {
  DELETE_ROOMS_NEW_DESIGN,
  DELETE_TABLES_NEW_DESIGN,
  DELETE_ROOM_NEW_DESIGN,
} from '../../../../actions/offices'

const {
  CHECK_ROOM,
  CHECK_ALL_ROOMS,
  DELETE_ROOMS_SUCCESS,
  DELETE_TABLES_SUCCESS,
} = actions

const getTableHeaderColumns = checkboxProps =>
  [<Checkbox {...checkboxProps} />, '#', 'Название помещения', 'Зона', 'Тип помещения', 'Этаж', 'Бронь', 'Фото']

const COLUMNS_FLEX_VALUES = [0, 0.1, 1, 1, 1, 0.3, 0.3, 0.3, 0]

const COLUMNS_EXTRA_STYLE = [{ marginRight: '20px', minWidth: '16px', paddingTop: '2px' }]

const DetailInfo = ({ deleteRoom, deleteRooms, deleteTables, match }) => {
  const { current: officeId } = useRef(match.params.id)

  const [{
    rooms,
    selectedRoomsCount,
    selectedTablesCount,
    loading,
    title: officeTitle,
    floors,
    zones,
    roomTypes,
    tags,
  }, dispatch, getFilteredRooms] = useSelectedReducer(officeId)
  const [roomFormState, setRoomFormState] = useState({ visible: false })
  const [isQRPrintModalVisible, setIsQRPrintModalVisible] = useState(false)
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false)
  const [tableFormState, setTableFormState] = useState({ targetRoomId: '', visible: false })

  const handleAddTable = roomId =>
    setTableFormState({ visible: !tableFormState.visible, targetRoomId: roomId })

  const closeTableFormModal = () =>
    setTableFormState({ ...tableFormState, visible: false })

  const toggleRoomForm = () =>
    setRoomFormState({ visible: !roomFormState.visible })

  const handleEditRoom = room =>
    setRoomFormState({ room, visible: !roomFormState.visible })

  const handleRoomCheck = roomId =>
    dispatch({ type: CHECK_ROOM, roomId })

  const getRoomsForQRPrintModal = () =>
    rooms.reduce((acc, cur) => {
      const { checked: isRoomChecked, tables: tablesInRoom } = cur
      if (isRoomChecked || tablesInRoom.some(table => table.checked)) {
        const newRoom = {
          ...cur,
          tables: tablesInRoom.filter(table => table.checked),
        }
        return [...acc, newRoom]
      }
      return acc
    }, [])

  const isAllRoomsSelected = rooms.length > 0 && selectedRoomsCount === rooms.length

  const handleSelectAllCheckbox = ({ target }) =>
    dispatch({ type: CHECK_ALL_ROOMS, checked: target.checked })

  const indeterminate = selectedRoomsCount > 0 && selectedRoomsCount < rooms.length

  const selectAllCheckboxProps = {
    checked: isAllRoomsSelected,
    onChange: handleSelectAllCheckbox,
    indeterminate,
  }

  const handleDeleteRoom = roomId => {
    const callback = dispatch({ type: DELETE_ROOMS_SUCCESS, roomIds: [roomId] })
    deleteRoom(roomId, callback)
  }

  const renderRoomsList = () => (
    <ScrollTransition loading>
      <div className='rooms-list'>
        {rooms.map((room, index) => (
          <RoomListItem
            tags={tags}
            handleDeleteRoom={handleDeleteRoom}
            handleEditRoom={handleEditRoom}
            setSelectedDispatch={dispatch}
            officeId={officeId}
            room={room}
            index={index}
            key={room.id}
            handleAddTable={handleAddTable}
            onCheck={handleRoomCheck}
            flexValues={COLUMNS_FLEX_VALUES}
          />
        ))}
      </div>
    </ScrollTransition>
  )

  const getTotalSelectedRoomsStr = () =>
    `${selectedRoomsCount} ${pluralizeString(selectedRoomsCount, ['помещение', 'помещения', 'помещений'])}`

  const getTotalSelectedTablesStr = () =>
    `${selectedTablesCount} ${pluralizeString(selectedTablesCount, ['стол', 'стола', 'столов'])}`

  const getTotalSelectedStr = () =>
    `Выбрано: ${getTotalSelectedRoomsStr()} и ${getTotalSelectedTablesStr()}`

  const deleteRoomCallback = ids => dispatch({ type: DELETE_ROOMS_SUCCESS, roomIds: ids })

  const handleDeleteRooms = () => {
    const selectedRoomIds = rooms.reduce((acc, cur) => cur.checked ? [...acc, cur.id] : acc, [])
    deleteRooms(selectedRoomIds, () => deleteRoomCallback(selectedRoomIds))
  }

  const handleDeleteTables = () => {
    const getSelectedTableIds = tables =>
      tables.reduce((acc, cur) => cur.checked ? [...acc, cur.id] : acc, [])

    const tableIds = rooms.reduce((acc, cur) => {
      if (!cur.checked && cur.tables.some(table => table.checked))
        return [...acc, ...getSelectedTableIds(cur.tables)]
      return acc
    }, [])

    if (tableIds.length) {
      const callback = () => dispatch({ type: DELETE_TABLES_SUCCESS, tableIds })
      deleteTables(tableIds, callback)
    }
  }

  const handleDeleteSelected = () => {
    selectedRoomsCount && handleDeleteRooms()
    handleDeleteTables()
    setIsDeletionModalVisible(false)
  }

  const renderActivityHeader = () => (
    <div className='office-details-screen__summary' style={{ paddingBottom: '20px' }}>
      <span className='office-details-screen__summary-info'>
        {getTotalSelectedStr()}
      </span>
      <IconPrint onClick={() => setIsQRPrintModalVisible(val => !val)} />
      <div
        onClick={() =>
          (selectedRoomsCount || selectedTablesCount) && setIsDeletionModalVisible(true)}
        className='icon__trash'
      />
    </div>
  )

  const CardSubtitle = (() => (
    <>
      <FilterInput
        officeId={officeId}
        onSearch={getFilteredRooms}
        tags={tags}
        floors={floors}
        zones={zones}
        roomTypes={roomTypes}
        placeholder='Введите название помещения, этаж или зону'
      />
      <div className='button_container__content_right'>
        {/* <Button */}
        {/*  icon='icon__download_2' */}
        {/*  styles='button__import_users_csv' */}
        {/*  title='Импорт помещений из .csv' */}
        {/*  onClick={() => setIsImportFromCsvModalVisible(true)} */}
        {/* /> */}
        <Button
          confirm
          icon='icon__add__in_circle'
          styles='bordered_btn'
          title='Добавить помещение'
          onClick={toggleRoomForm}
        />
      </div>
    </>
  ))()

  return (
    <>
      <MainCard
        icon='icon__briefcase'
        title={officeTitle || 'БИЗНЕС-ЦЕНТР'}
        subtitleClassName='main_card__subtitle main_card__subtitle__office-detail'
        loading={loading}
        filter={CardSubtitle}
        innerContainerClassName='office-details-screen'
      >
        {renderActivityHeader()}
        <TableHeader
          columns={getTableHeaderColumns(selectAllCheckboxProps)}
          flexValues={COLUMNS_FLEX_VALUES}
          style={COLUMNS_EXTRA_STYLE}
          rightPaddingWidth={48}
        />
        {rooms?.length
          ? renderRoomsList()
          : <EmptyPlaceholder text='Здесь пока нет кабинетов' />}
      </MainCard>

      {roomFormState.visible && (
        <RoomFormModal
          isNew={!roomFormState.room}
          start={roomFormState.visible}
          onClose={toggleRoomForm}
          setSelectedDispatch={dispatch}
          floors={floors}
          zones={zones}
          room={roomFormState.room}
          roomTypes={roomTypes}
        />
      )}

      <QRPrintModal
        rooms={getRoomsForQRPrintModal()}
        start={isQRPrintModalVisible}
        onClose={() => setIsQRPrintModalVisible(false)}
      />

      {tableFormState.visible && (
        <TableFormModal
          setSelectedDispatch={dispatch}
          isNew
          officeId={officeId}
          start={tableFormState.visible}
          onClose={closeTableFormModal}
          roomId={tableFormState.targetRoomId}
          tags={tags}
        />
      )}
      <ConfirmationModal
        start={isDeletionModalVisible}
        text={`Удалить ${getTotalSelectedRoomsStr()} и ${getTotalSelectedTablesStr()}?`}
        onConfirm={handleDeleteSelected}
        onClose={() => setIsDeletionModalVisible(false)}
      />
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  deleteRooms:
    (roomIds, callback) => dispatch({ type: DELETE_ROOMS_NEW_DESIGN, roomIds, callback }),
  deleteTables:
    (tableIds, callback) => dispatch({ type: DELETE_TABLES_NEW_DESIGN, tableIds, callback }),
  deleteRoom:
    (roomId, callback) => dispatch({ type: DELETE_ROOM_NEW_DESIGN, roomId, callback }),
})

export default withRouter(connect(null, mapDispatchToProps)(DetailInfo))
