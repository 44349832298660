import React from 'react'
import { Button } from 'antd'
import { PDFDownloadLink } from '@react-pdf/renderer'

import Modal from '../../../../../UI/modals/Modal'
import IconAttension from '../../../../../UI/icons/Attension'
import TableHeader from '../../../../../UI/TableHeader'
import RoomListItem from './RoomListItem'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import formDocument from '../../../../../../utils/formDocument'
import formCodes from '../../../../../../utils/formCodes'
import PDFDocument from './PDFDocument'

const TABLE_COLUMNS = [
  '#',
  'Название помещения',
  'Этаж',
  'Кол-во QR кодов',
]

const TABLE_HEADER_FLEX_VALUES = [
  0.1, 0.5, 0.5, 0.5,
]

const QRPrintModal = ({ start, onClose, rooms }) => {
  const handlePrint = () => {
    const codes = formCodes(rooms)
    formDocument(codes)
  }

  const emptyRoomsCount = rooms.filter(room => room.tables.length === 0).length

  const isPrintBtnDisabled = emptyRoomsCount === rooms.length

  const hasEmptyRooms = !!emptyRoomsCount

  const handlePDFDownloadBtn = ({ loading }) => loading ? 'Подождите...' : 'Скачать PDF'

  return (
    <Modal extraClassName='qr-print-modal' start={start} onClose={onClose}>
      <h1 className='modal_card__title border_bottom'>
        ПЕЧАТЬ QR КОДОВ РАБОЧИХ МЕСТ
      </h1>

      {hasEmptyRooms && (
        <div className='qr-print-modal-disclaimer'>
          <IconAttension />
          <div className='qr-print-modal-disclaimer__text-wrapper'>
            <h3 className='qr-print-modal-disclaimer__title'>
              Вы выбрали одно/несколько помещений без рабочих мест
            </h3>
            <h3 className='qr-print-modal-disclaimer__subtitle'>
              Возможно, выбранный тип помещения не предусматривает наличие рабочих мест и QR кодов для них.
            </h3>
          </div>
        </div>
      )}

      <h2 className='qr-print-modal-description'>
        Список кабинетов, для которых будут распечатаны QR коды
      </h2>

      <TableHeader
        style={[{ marginLeft: '1rem' }]}
        rightPaddingWidth='0'
        columns={TABLE_COLUMNS}
        flexValues={TABLE_HEADER_FLEX_VALUES}
      />
      <ScrollTransition loading>
        <div style={{ margin: '0 5px' }}>
          {rooms.map((roomItem, index) => (
            <RoomListItem index={index} key={roomItem.id} {...roomItem} />
          ))}
        </div>
      </ScrollTransition>
      <div className='qr-print-modal-actions'>
        <Button type='text' onClick={onClose}>Отмена</Button>
        {/* <PDFDownloadLink
          className='ant-btn ant-btn-primary'
          document={<PDFDocument rooms={rooms} />}
          fileName='QR-коды.pdf'
          style={{ marginRight: '1rem' }}
        >
          {handlePDFDownloadBtn}
        </PDFDownloadLink> */}
        <Button type='primary' disabled={isPrintBtnDisabled} onClick={handlePrint}>Печать</Button>
      </div>
    </Modal>
  )
}

export default QRPrintModal
