import React, { useRef, useEffect } from "react";

function useOutsideAlerter(ref, callback = () => { }) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) callback()
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function OutsideClicker(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.callback);

  return <div
    ref={wrapperRef}
    className={props.className || ''}
    onClick={props.onClick || null}
  >
    {props.children}
  </div >;
}

export default OutsideClicker;
