import React, { useState } from 'react'
import Tag from './Tag'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import SimpleInput from '../../../../../UI/input/SimpleInput'
import EmptyPlaceholder from '../../../../../UI/EmptyPlaceholder'

const TagsSelector = ({ tags, setFieldValue, selectedTags, name, inputProps }) => {
  const [tagsFilterValue, setTagsFilterValue] = useState('')

  const isSelected = tag => selectedTags.some(selectedTag => selectedTag.id === tag.id)

  const selectTag = tag => setFieldValue(name, [...selectedTags, tag])

  const deselectTag = tag => {
    const newSelectedTags = selectedTags.filter(selectedTag => selectedTag.id !== tag.id)
    setFieldValue(name, newSelectedTags)
  }

  const onTagClick = tag => isSelected(tag) ? deselectTag(tag) : selectTag(tag)

  const renderTagsWithoutFiltering = () => tags.length
    ? tags.map(tag => (
      <Tag
        key={tag.id}
        onClick={onTagClick}
        selected={isSelected(tag)}
        tag={tag}
      />
    ))
    : (
      <EmptyPlaceholder
        style={{ marginTop: '-5px', textAlign: 'left' }}
        text='Для выбранного БЦ отсутствуют теги'
      />
    )

  const renderFilteredTags = () => {
    const lowerCaseFilterValue = tagsFilterValue.toLowerCase()
    const filteredTags = tags.filter(({ title }) =>
      title.toLowerCase().includes(lowerCaseFilterValue))
    return filteredTags.length
      ? filteredTags.map(tag => (
        <Tag
          key={tag.id}
          onClick={onTagClick}
          selected={isSelected(tag)}
          tag={tag}
        />
      ))
      : <span className='tags-selector__empty-placeholder'>Ничего не найдено</span>
  }

  const renderTags = () => tagsFilterValue.length > 2
    ? renderFilteredTags()
    : renderTagsWithoutFiltering()

  const isInputDisabled = !tags.length

  return (
    <>
      <SimpleInput
        disabled={isInputDisabled}
        value={tagsFilterValue}
        onChange={(_, value) => setTagsFilterValue(value)}
        label='Введите название тега или выберите в списке ниже'
        placeholder='Начните вводить название тега'
        {...inputProps}
      />
      <div className='tags-selector'>
        <ScrollTransition loading style={{ display: 'block' }}>{renderTags()}</ScrollTransition>
      </div>
    </>
  )
}

export default TagsSelector
