import React, { useCallback, useEffect, useRef, useState } from 'react'
import Button from '../UI/Button'
import PopUpTransition from '../UI/animations/transition/PopUptransition'
import PasswordChangeModal from './PasswordChangeModal'
import LabeledDataBlock from '../UI/dataBlock/LabeledDataBlock'

const UserPopUpInfo = ({ isExpanded, name, description, email, onLogOut, closePopUp, phone }) => {
  const [isPasswordChangeModalVisible, setIsPasswordChangeModalVisible] = useState(false)

  const wrapperRef = useRef(null)

  const handleKeyDown = useCallback(({ keyCode }) => keyCode === 27 && closePopUp(), [closePopUp])

  // const handleClickOutside = ({ target }) => {
  //   if (target.id !== 'userInfo' && target.id !== 'userInfoWrap' && target.id !== 'userLogoWrap') {
  //     const popUpContainer = wrapperRef?.current
  //     return !popUpContainer?.contains(target) && closePopUp()
  //   }
  // }

  const showChangeConfirmPassword = () => {
    closePopUp()
    setIsPasswordChangeModalVisible(true)
  }

  const TARGET_EVENTS = [
    // { name: 'click', action: handleClickOutside },
    { name: 'keydown', action: handleKeyDown }]

  useEffect(() => {
    TARGET_EVENTS.forEach(e => document.addEventListener(e.name, e.action))
    return () => { TARGET_EVENTS.forEach(e => document.removeEventListener(e.name, e.action)) }
  }, [isExpanded, handleKeyDown])

  return (
    <>
      <PopUpTransition loading={isExpanded}>
        <div className='user_info_pop_up' ref={wrapperRef} onKeyDown={handleKeyDown}>
          <div className='user_info_pop_up__triangle' />
          {/*Test version*/}
          <LabeledDataBlock title='Телефон' data={phone} />
          {/*<LabeledDataBlock title='Ф.И.О.' data={name} />*/}
          <LabeledDataBlock title='Должность' data={description} />
          {/*<LabeledDataBlock title='Email' data={email} />*/}
          <div className='flex_container flex_container__center'>
            <Button
              styles='simple-btn'
              title='Сменить пароль'
              onClick={showChangeConfirmPassword}
            />
          </div>
          <Button
            title='Выйти'
            icon='icon__logout'
            styles='bordered_btn'
            style={{ margin: '0 auto', marginTop: '10px' }}
            onClick={onLogOut}
          />
        </div>
      </PopUpTransition>
      <PasswordChangeModal
        start={isPasswordChangeModalVisible}
        onClose={() => setIsPasswordChangeModalVisible(false)}
      />
    </>
  )
}

export default UserPopUpInfo
