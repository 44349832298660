import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { Form as FormikForm, withFormik } from 'formik'

import getBasicInputProps from '../../../utils/getBasicInputProps'
import getStyle from '../../../utils/getStyle/getStyleUserForm'
import Modal from '../../UI/modals/Modal'
import SimpleTextArea from '../../UI/input/SimpleTextArea'
import SimpleInput from '../../UI/input/SimpleInput'
import { ErrorMsg } from '../../UI/ValidationMsg'
import { phoneRegExp } from '../../../utils/data/regExp'
import Button from '../../UI/Button'

const ValidationSchema = Yup.object().shape({
  description: Yup.string()
    .max(150, 'Максимум 150 символов'),
  phone_number: Yup.string()
    .matches(phoneRegExp, 'Введите номер телефона от 10 до 13 символов')
    .required('Обязательное поле'),
})

const InnerForm = ({
  start,
  handleClose,
  setFieldValue,
  setFieldTouched,
  setTouched,
  values,
  errors,
  touched,
  resetForm,
  onSave,
}) => {
  const onPhoneFieldFocus = () =>
    !values.phone_number ? setFieldValue('phone_number', '+') : null

  const onPhoneFieldBlur = () =>
    values.phone_number === '+' ? setFieldValue('phone_number', '') : null

  const onModalClose = () => {
    handleClose()
    resetForm()
  }

  const touchForm = () => setTouched({ phone_number: true, description: true })

  const onUserSaveClick = () => {
    touchForm()
    if (!ValidationSchema.isValidSync(values)) return

    const userPayload = { ...values, sms_invite: true }
    onSave(userPayload, resetForm)
  }

  const getInputProps = getBasicInputProps({
    values,
    errors,
    touched,
    setFieldTouched,
    onChange: setFieldValue,
  })

  const handleEnter = e => e.stopPropagation()

  useEffect(() => {
    start && resetForm()
  }, [start])

  useEffect(() => {
    document.addEventListener('keydown', handleEnter)
    return () => document.removeEventListener('keydown', handleEnter)
  }, [start])

  return (
    <Modal start={start} onClose={onModalClose} extraClassName='modal_send_email'>
      <span className='large_btn__title__center border_bottom'>НОВЫЙ ПОЛЬЗОВАТЕЛЬ В ГРУППЕ</span>

      <FormikForm>
        <div className='flex_container__column margin_top full_width'>
          <SimpleInput
            label='Телефон*'
            placeholder='+***********'
            style={getStyle()}
            onFocus={onPhoneFieldFocus}
            onBlur={onPhoneFieldBlur}
            onEnter={onUserSaveClick}
            {...getInputProps('phone_number')}
          >
            <ErrorMsg field='phone_number' />
          </SimpleInput>
          <SimpleTextArea
            label='Должность/Описание'
            placeholder='Описание пользователя'
            style={{ height: '66px' }}
            onEnter={onUserSaveClick}
            {...getInputProps('description')}
          >
            <ErrorMsg field='description' />
          </SimpleTextArea>
          <div className='button_container__content_right'>
            <Button styles='simple-btn' title='Отмена' onClick={onModalClose} />
            <Button
              delay={300}
              title='Сохранить'
              styles='btn_solid margin_top'
              onClick={onUserSaveClick}
            />
          </div>
        </div>
      </FormikForm>
    </Modal>
  )
}

const NewUserModal = withFormik({
  mapPropsToValues: () => ({ description: '', phone_number: '' }),
  handleSubmit: () => null,
  validationSchema: ValidationSchema,
})(InnerForm)

export default NewUserModal
