export const officeDef = {
  id: '5d9ef4eae869a48570cdd1ce',
  title: 'Бизнес-центр Пушкина',
  description: 'дом Колотушкина',
  floors_number: 4,
  occupied: 3,
  capacity: 22,
}

export const floorsDef = [
  {
    id: '5d9ef4eae869a48570cdd1cf',
    title: '1',
    description: null,
    office: '5d9ef4eae869a48570cdd1ce',
    occupied: 3,
    capacity: 22,
    rooms: [
      {
        id: '5d9eff10e8d18ebed5ea987c',
        title: '11',
        description: null,
        type: 'Переговорная',
        occupied: 1,
        capacity: 3,
        is_occupied: false,
        tables: [
          {
            id: '5d9f03sdfsdvdiisj4',
            title: '223',
            description: null,
            is_occupied: false,
          },
          {
            id: '5d9f03hsdfjsdakc',
            title: '34',
            description: null,
            is_occupied: true,
          },
          {
            id: '5d9f031bajsdhsd72',
            title: '45Е',
            description: null,
            is_occupied: false,
          },
        ],
      },
      {
        id: '5d9eff15e8d18ebed5ea987d',
        title: '12',
        description: null,
        type: 'Переговорная',
        occupied: null,
        capacity: null,
        is_occupied: false,
        tables: [],
      },
      {
        id: '5d9eff1de8d18ebed5ea987e',
        title: '13',
        description: null,
        type: 'Переговорная',
        occupied: null,
        capacity: null,
        is_occupied: false,
        tables: [],
      },
      {
        id: '5d9eff583e59391b9daa153e',
        title: '14',
        description: null,
        type: 'Переговорная',
        occupied: null,
        capacity: null,
        is_occupied: false,
        tables: [],
      },
      {
        id: '5d9eff9d3e59391b9daa153f',
        title: '101',
        description: null,
        type: 'Рабочее место',
        occupied: 3,
        capacity: 22,
        is_occupied: null,
        tables: [
          {
            id: '5d9f030da5173aee4bae4f90',
            title: '1A',
            description: null,
            is_occupied: false,
          },
          {
            id: '5d9f0318a5173aee4bae4f91',
            title: '1B',
            description: null,
            is_occupied: false,
          },
          {
            id: '5d9f031ba5173aee4bae4f92',
            title: '1C',
            description: null,
            is_occupied: false,
          },
        ],
      },
      {
        id: '5d9eff9f3e59391b9daa1540',
        title: '102',
        description: null,
        type: 'Рабочее место',
        occupied: 3,
        capacity: 22,
        is_occupied: null,
        tables: [],
      },
    ],
  },
  {
    id: '5d9ef4eae869a48570cdd1d0',
    title: '2',
    description: null,
    office: '5d9ef4eae869a48570cdd1ce',
    occupied: 3,
    capacity: 22,
    rooms: [
      {
        id: '5d9effab3e59391b9daa1541',
        title: '201',
        description: null,
        type: 'Рабочее место',
        occupied: 3,
        capacity: 22,
        is_occupied: null,
        tables: [],
      },
      {
        id: '5d9effad3e59391b9daa1542',
        title: '202',
        description: null,
        type: 'Рабочее место',
        occupied: 3,
        capacity: 22,
        is_occupied: null,
        tables: [],
      },
    ],
  },
  {
    id: '5d9ef4eae869a48570cdd1d1',
    title: '3',
    description: null,
    office: '5d9ef4eae869a48570cdd1ce',
    occupied: 3,
    capacity: 22,
    rooms: [],
  },
  {
    id: '5d9ef4eae869a48570cdd1d2',
    title: '4',
    description: null,
    office: '5d9ef4eae869a48570cdd1ce',
    occupied: 3,
    capacity: 22,
    rooms: [],
  },
]

export const roomsDef = [
  {
    id: '5d9e156ab322648adaa3191f',
    title: '101',
    description: null,
    type: 'Рабочее место',
    floor: {
      id: '5d9da0b469284e670eb94f1b',
      title: '1',
      description: null,
      office: '5d9c83dc835af4993feac450',
      occupied: 3,
      capacity: 22,
    },
    occupied: 3,
    capacity: 22,
    is_occupied: null,
  },
  {
    id: '5d9e157bb322648adaa31920',
    title: '102',
    description: null,
    type: 'Рабочее место',
    floor: {
      id: '5d9da0b469284e670eb94f1b',
      title: '1',
      description: null,
      office: '5d9c83dc835af4993feac450',
      occupied: 3,
      capacity: 22,
    },
    occupied: 3,
    capacity: 22,
    is_occupied: null,
  },
  {
    id: '5d9e157db322648adaa31921',
    title: '103',
    description: null,
    type: 'Рабочее место',
    floor: {
      id: '5d9da0b469284e670eb94f1b',
      title: '1',
      description: null,
      office: '5d9c83dc835af4993feac450',
      occupied: 3,
      capacity: 22,
    },
    occupied: 3,
    capacity: 22,
    is_occupied: null,
  },
  {
    id: '5d9e1587b322648adaa31922',
    title: '11',
    description: null,
    type: 'Переговорная',
    floor: {
      id: '5d9da0b469284e670eb94f1b',
      title: '1',
      description: null,
      office: '5d9c83dc835af4993feac450',
      occupied: 3,
      capacity: 22,
    },
    occupied: null,
    capacity: null,
    is_occupied: false,
  },
  {
    id: '5d9e1589b322648adaa31923',
    title: '12',
    description: null,
    type: 'Переговорная',
    floor: {
      id: '5d9da0b469284e670eb94f1b',
      title: '1',
      description: null,
      office: '5d9c83dc835af4993feac450',
      occupied: 3,
      capacity: 22,
    },
    occupied: null,
    capacity: null,
    is_occupied: false,
  },
  {
    id: '5d9e158cb322648adaa31924',
    title: '13',
    description: null,
    type: 'Переговорная',
    floor: {
      id: '5d9da0b469284e670eb94f1b',
      title: '1',
      description: null,
      office: '5d9c83dc835af4993feac450',
      occupied: 3,
      capacity: 22,
    },
    occupied: null,
    capacity: null,
    is_occupied: false,
  },
]
