import React from 'react'
import { Field as FormikField } from 'formik'
import { Form, Select } from 'antd'

const SimpleSelect = ({
  name,
  label,
  value,
  onChange,
  setFieldTouched,
  style,
  disabled,
  children,
  error,
  errorComponent,
  placeholder,
  className,
  parentNodeId,
  mode,
}) => {
  const getPopupContainer = () => parentNodeId
    ? document.getElementById(parentNodeId)
    : document.body

  return (
    <Form.Item className={className} label={label}>
      <div className='validation_block'>
        <FormikField
          render={() => (
            <Select
              mode={mode}
              name={name}
              value={value}
              disabled={disabled}
              placeholder={disabled ? '' : placeholder}
              notFoundContent='Не найдено'
              onChange={val => onChange(name, val)}
              onBlur={() => setFieldTouched(name, true)}
              style={{ ...style, border: error && '1px solid #CD4141', width: '100%' }}
              defaultActiveFirstOption
              getPopupContainer={getPopupContainer}
            >
              {children}
            </Select>
          )}
        />
        {errorComponent
        && <div className='validation_block margin_top__small'>{errorComponent()}</div>}
      </div>
    </Form.Item>
  )
}

export default SimpleSelect
