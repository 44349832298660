import React, { useEffect, useState } from 'react'

import Tooltip from './MapTooltip'
import Toolbar from './MapToolbar'

// function areEqual(prevProps, nextProps) {
//   console.log('ARE EQUAL', prevProps, nextProps, prevProps.point.x === nextProps.point.x && prevProps.point.y === nextProps.point.y)
//   return prevProps.point.x === nextProps.point.x && prevProps.point.y === nextProps.point.y
// }

const Marker = ({ point, size, scale, extraClassName, moving }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [tooltipPosition, setTooltipPosition] = useState({})
  const [toolbar, setToolbar] = useState(false)
  let cont = document.getElementById('map')

  const getIcon = iconName => {
    switch (iconName) {
      case 'laptop': return ''
      case 'hanger': return ''
      case 'meeting': return ''
      case 'lecture': return ''
      case 'coffee': return ''
      case 'gym': return ''
      case 'food': return ''
      case 'wc': return ''
      default: return '?'
    }
  }

  const getMarkerPosition = (() => {
    const width = cont?.offsetWidth
    const height = cont?.offsetHeight

    const offsetX = (17 / (scale * width)) * 100
    const offsetY = (17 / (scale * height)) * 100

    const pointX = Number(point?.x)
    const pointY = Number(point?.y)

    return ({
      top: (pointY - offsetY) + '%',
      left: (pointX - offsetX) + '%',
    })
  })()

  const getMarkerStyle = () => {
    const padding = 10 / scale

    return ({
      padding: padding + 'px',
      ...getMarkerPosition,
      ...size,
    })
  }

  const getBorderWidth = () => {
    const borderPx = 2 / scale
    return `${borderPx}px solid white`
  }

  const getFontSize = () => (Number(size.width.replace('px', '')) / 2.5).toFixed() + 'px'

  const getMarkerCoordinates = () => {
    const { x, y } = document.getElementById(`marker-${point.roomId}`).getBoundingClientRect()
    return { x, y }
  }

  const handleHoverEnter = e => {
    e.stopPropagation()

    if (isTooltipVisible) return
    const { x, y } = getMarkerCoordinates()

    setIsTooltipVisible(true)
    setTooltipPosition({ top: y + 40, left: x - 103 })
  }

  const handleHoverLeave = e => {
    e.stopPropagation()

    setIsTooltipVisible(false)
    setTooltipPosition({})
  }

  const getToolbarPosition = () => {
    const { x, y } = getMarkerCoordinates()
    return { top: y - 58, left: x - 103 }
  }

  const handleClick = e => {
    e.stopPropagation()
    setToolbar(true)
  }

  useEffect(() => {
    const newCont = document.getElementById('map-container')
    if (cont !== newCont) cont = newCont
    const marker = document.getElementById(`marker-${point.roomId}`)

    marker.addEventListener('mouseover', handleHoverEnter)
    marker.addEventListener('mouseleave', handleHoverLeave)
    marker.addEventListener('mousedown', handleClick)
    return () => {
      marker.removeEventListener('mouseover', handleHoverEnter)
      marker.removeEventListener('mouseleave', handleHoverLeave)
      marker.addEventListener('mousedown', handleClick)
    }
  }, [point])

  useEffect(() => {
    setToolbar(false)
  }, [moving])

  useEffect(() => {
    if (point.deleted) setToolbar(false)
  }, [point])

  return (
    <>
      <div
        id={`marker-${point.roomId}`}
        className={extraClassName || 'marker'}
        style={{
          ...getMarkerStyle(),
          padding: point.room_type_icon && 0,
          display: point.deleted ? 'none' : 'flex',
        }}
      >
        <div
          className='marker__inner'
          style={{
            backgroundColor: point.room_type_color,
            border: getBorderWidth(),
          }}
        >
          {point.room_type_icon
            ? (
              <div
                className='marker__inner-image'
                style={{ backgroundImage: `url('${point.room_type_icon}')` }}
              />
            )
            : (
              <div className='icons_text' style={{ fontSize: getFontSize() }}>
                {getIcon(point.icon)}
              </div>
            )}
        </div>
      </div>
      <Tooltip start={isTooltipVisible} style={tooltipPosition} marker={point} />
      {toolbar && (
        <Toolbar
          marker={point}
          start={toolbar}
          index={point.roomId}
          style={toolbar && getToolbarPosition()}
          onClose={() => setToolbar(false)}
        />
      )}
    </>
  )
}

export default Marker
