import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux'

import Auth from './components/Auth'
import App from './App'

import './styles/animations/cardList.scss'
import './styles/animations/fade.scss'
import './styles/animations/underlined.scss'
import './styles/animations/lectureCard.scss'
import './styles/animations/popUp.scss'
import './styles/animations/userForm.scss'
import './styles/animations/form.scss'
import './styles/animations/slide.scss'
import './styles/animations/subFormFade.scss'
import './styles/forms.scss'
import './styles/icons.scss'
import './styles/index.scss'
import './styles/screens/mapScreen.scss'

const AuthRouter = ({ token }) => (
  <Router>
    {token?.access_token && token?.access_token !== 'undefined'
      ? <App />
      : <div className='app_BG'><Auth /></div>}
  </Router>
)

const mapStateToProps = ({ account }) => ({ token: account.token })

export default connect(mapStateToProps)(AuthRouter)
