export const ITEMS_ON_PAGE = 20

export const MAX_IMAGE_SIZE_IN_BITS = 10000000

export const MAX_IMAGE_SIZE_IN_PIXELS = 500

export const ITEMS_OFFSET = ITEMS_ON_PAGE - 1

export const colorPalette = ['#0079C1', '#0097A7', '#00796B', '#303F9F', '#7B1FA2', '#512DA8', '#C2185B', '#D32F2F', '#E64A19', '#F57C00', '#FFA000', '#388E3C', '#689F38', '#AFB42B', '#5D4037', '#455A64']

export const PUSH_WORKSPACE = `simpleoffice-${process.env.REACT_APP_WORKSPACE}`
