import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Button from '../UI/Button'
import ConfirmationModal from '../UI/modals/ConfirmationModal'
import List from './List'
import MainCard from '../MainCard'
import { deleteGroup, getGroupsList, importGroups } from '../../actions/actionCreator/groups'
import ImportGroupsFromCsvModal from './ImportGroupsFromCsvModal'
import FullScreenLoading from '../UI/FullScreenLoading'

const UsersGroupList = ({
  getGroupsList,
  groups,
  loading,
  history,
  deleteGroup,
  importLoading,
}) => {
  const [isConfirmShow, setIsConfirmShow] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({ id: null, title: '' })
  const [isImportFromCsvModalVisible, setIsImportFromCsvModalVisible] = useState(false)

  const createButtonBlock = () => (
    <div className='button_container__content_right'>
      <Button
        icon='icon__download_2'
        styles='button__import_users_csv'
        title='Импортировать из .csv'
        onClick={() => setIsImportFromCsvModalVisible(true)}
      />
      <Button
        styles='bordered_btn__flex__end'
        icon='icon__add__in_circle'
        title='Добавить группу'
        onClick={() => history.push('groups/new')}
      />
    </div>
  )

  const onDeleteIconClick = ({ id, title }) => {
    setIsConfirmShow(true)
    setSelectedGroup({ id, title })
  }

  const onConfirm = () => deleteGroup(selectedGroup.id, () => setIsConfirmShow(false))

  useEffect(() => { getGroupsList() }, [getGroupsList])

  return (
    <>
      <MainCard
        icon='icon__groups'
        title='Доступы пользователей'
        btn={createButtonBlock()}
        loading={loading}
      >
        <List groups={groups} onDelete={onDeleteIconClick} />
      </MainCard>
      {isConfirmShow && (
        <ConfirmationModal
          start={isConfirmShow}
          text={`Удалить группу "${selectedGroup.title}"?`}
          onConfirm={onConfirm}
          onClose={() => setIsConfirmShow(false)}
        />
      )}
      <ImportGroupsFromCsvModal
        start={isImportFromCsvModalVisible}
        onClose={() => setIsImportFromCsvModalVisible(false)}
      />
      {importLoading && <FullScreenLoading />}
    </>
  )
}

const mapStateToProps = ({ users }) => {
  const { groups } = users
  return ({
    groups: groups?.list,
    loading: groups?.loading,
    importLoading: groups?.importLoading,
  })
}

const mapDispatchToProps = { getGroupsList, deleteGroup, importGroups }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersGroupList))
