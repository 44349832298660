import React, { useState } from 'react'
import { ErrorMessage, withFormik } from 'formik'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Select, Button } from 'antd'
import Gallery from 'react-dynamic-image-gallery'
import * as Yup from 'yup'

import actions from '../selectedReducer/actions'
import Modal from '../../../../UI/modals/Modal'
import ValidationMsg from '../../../../UI/ValidationMsg'
import SimpleInput from '../../../../UI/input/SimpleInput'
import SimpleTextArea from '../../../../UI/input/SimpleTextArea'
import SimpleSelect from '../../../../UI/input/SimpleSelect'
import {
  POST_ROOM_NEW_DESIGN,
  PUT_ROOM_NEW_DESIGN,
  PUT_ROOM_NEW_DESIGN_SUCCEED,
} from '../../../../../actions/offices'
import SimpleInputNumber from '../../../../UI/input/SimpleInputNumber'
import { onImageUpload } from '../../uploadImage'
import ScrollTransition from '../../../../UI/animations/transition/ScrollTransition'
import { EDIT_ROOM } from '../../../../Map/helpers/useReducerForMapComponent'

const { ADD_ROOM_SUCCESS, UPDATE_ROOM_SUCCESS } = actions

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Обязательное поле')
    .max(140, 'Название не более 140 символов'),
  zone: Yup.string()
    .required('Обязательное поле'),
  floor: Yup.string()
    .required('Обязательное поле'),
  description: Yup.string()
    .max(150, 'Максимальная длина - 150 символов'),
})

const InnerForm = ({
  start,
  onClose,
  values,
  setFieldValue,
  setFieldTouched,
  setSelectedDispatch,
  errors,
  touched,
  floors,
  zones,
  postRoom,
  putRoom,
  isNew,
  room,
  roomTypes,
  mapMode,
  dispatchWithoutRedux,
}) => {
  const dispatch = useDispatch()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const getErrorCondition = field => errors[field] && touched[field]

  const getRoomTypeId = title => roomTypes.find(type => type.title === title)?.id

  const getPayload = () => ({
    title: values.title.trim().replace(/ +(?= )/g, ''),
    description: values.description,
    floor: values.floor,
    type: isNew && values.type === 'Рабочее место' ? getRoomTypeId('Рабочее место') : values.type,
    zone: values.zone,
    images: values.images.map(img => img.id),
    seats_amount: values.type === 'Переговорная' ? values.seats_amount : undefined,
  })

  const callback = room => {
    const actionObj = isNew
      ? { type: ADD_ROOM_SUCCESS, room }
      : { type: UPDATE_ROOM_SUCCESS, room }

    if (setSelectedDispatch) setSelectedDispatch(actionObj)
    else dispatch(actionObj)
    onClose()
  }

  const callbackMapMode = room => {
    dispatch({ type: PUT_ROOM_NEW_DESIGN_SUCCEED, room })
    onClose()
  }

  const handleSubmit = () => {
    setIsSubmitDisabled(true)
    const payload = getPayload()

    isNew
      ? postRoom(payload, mapMode ? callbackMapMode : callback)
      : (() => {
        putRoom(payload, room.id, mapMode ? callbackMapMode : callback)
        dispatchWithoutRedux({ type: EDIT_ROOM, payload })
      })()
    setIsSubmitDisabled(false)
  }

  const isFormValid = ValidationSchema.isValidSync(values)

  const setImages = images => {
    const fulFilledImages = images.map(img => img.status === 'fulfilled' && img.value)

    const nextImagesArr = values.images.length
      ? [...values.images, ...fulFilledImages]
      : fulFilledImages

    setFieldValue('images', nextImagesArr)
  }

  const handleImageUpload = e => onImageUpload(e, setImages)

  const onImageDelete = deletedImage =>
    setFieldValue('images', values.images.filter(img => img.id !== deletedImage.id))

  const getFormTitle = () => isNew ? 'ДОБАВИТЬ КАБИНЕТ' : ' РЕДАКТИРОВАТЬ КАБИНЕТ'

  return (
    <Modal extraClassName='room-form__modal' start={start} onClose={onClose}>
      <h1 className='room-form__modal-title'>
        {getFormTitle()}
      </h1>

      <ScrollTransition style={{ height: '100%' }} loading>
        <div style={{ height: '100%', padding: '0 10px 0 2px' }}>
          <SimpleInput
            name='title'
            label='Название*'
            value={values.title}
            placeholder='Введите название кабинета'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('title')}
          >
            <ErrorMessage component={ValidationMsg} name='title' />
          </SimpleInput>
          <SimpleSelect
            name='floor'
            label='Этаж*'
            value={values.floor}
            placeholder='Выберите этаж'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            errorComponent={() => <ErrorMessage component={ValidationMsg} name='floor' />}
          >
            {floors?.map(floor =>
              <Select.Option key={floor.id} value={floor.id}>{floor.title}</Select.Option>)}
          </SimpleSelect>
          <SimpleSelect
            name='zone'
            label='Зона*'
            value={values.zone}
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            placeholder='Зона бизнес-центра'
            error={getErrorCondition('zone')}
            errorComponent={() => <ErrorMessage component={ValidationMsg} name='zone' />}
          >
            {zones?.length > 0 && zones.map(zone => (
              <Select.Option value={zone.id} key={zone.id}>{zone.title}</Select.Option>))}
          </SimpleSelect>
          <div className='flex_container'>
            <div style={{ marginRight: '20px' }} className='flex_container__flex_3'>
              <SimpleSelect
                name='type'
                label='Тип'
                value={values.type}
                placeholder='Выберите тип кабинета'
                onChange={setFieldValue}
                setFieldTouched={setFieldTouched}
              >
                {roomTypes?.map(type =>
                  <Select.Option key={type.id} value={type.id}>{type.title}</Select.Option>)}
              </SimpleSelect>
            </div>
            <div className='flex_container__flex_1'>
              <SimpleInputNumber
                min={1}
                disabled={values.type !== 'Переговорная'}
                name='seats_amount'
                label='К-во мест'
                style={{ width: '100%' }}
                value={values.seats_amount}
                onChange={setFieldValue}
                setFieldTouched={setFieldTouched}
                error={getErrorCondition('seats_amount')}
              >
                <ErrorMessage component={ValidationMsg} name='seats_amount' />
              </SimpleInputNumber>
            </div>
          </div>
          <SimpleTextArea
            name='description'
            label='Описание'
            value={values.description}
            placeholder='Введите описание'
            style={{ height: '70px' }}
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('description')}
          >
            <ErrorMessage component={ValidationMsg} name='description' />
          </SimpleTextArea>
          <span className='secondary_text__white' style={{ margin: '6px 0 12px' }}>
            Добавьте фото кабинета
          </span>

          <Gallery
            onUpload={handleImageUpload}
            source={values.images}
            itemsToShow={values.images.length}
            itemsToShowInPreview={5}
            uploadEnabled
            isItemDeleteEnable
            onItemDelete={onImageDelete}
          />
        </div>
      </ScrollTransition>

      <div
        className='button_container__content_right'
        style={{ margin: '20px 10px 0 0', maxHeight: '32px' }}
      >
        <Button type='text' onClick={onClose}>Отмена</Button>
        <Button
          disabled={isSubmitDisabled || !isFormValid}
          type='primary'
          onClick={handleSubmit}
        >
          {isNew ? 'Сохранить и добавить' : 'Сохранить изменения'}
        </Button>
      </div>
    </Modal>
  )
}

const RoomFormModal = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ isNew, roomTypes = [], room = {}, activeFloor, floors }) => {
    const defaultFloor = floors?.find(f => f.id === activeFloor)
    return ({
      title: room.title || '',
      floor: room.floor?.id || defaultFloor?.id || undefined,
      zone: room.zone?.id || undefined,
      type: isNew ? 'Рабочее место' : roomTypes.find(type => type.title === room.type)?.id,
      description: room.description || '',
      seats_amount: room.seats_amount || 1,
      images: room.images || [],
    })
  },
  validationSchema: ValidationSchema,

})(InnerForm)

const mapDispatchToProps = dispatch => ({
  postRoom: (room, callback) =>
    dispatch({ type: POST_ROOM_NEW_DESIGN, room, callback }),
  putRoom: (room, roomId, callback) =>
    dispatch({ type: PUT_ROOM_NEW_DESIGN, room, roomId, callback }),
})

export default withRouter(connect(null, mapDispatchToProps)(RoomFormModal))
