export const contentHeader = (token = localStorage.getItem('access_token')) => ({
  'Content-Type': 'application/json',
  'X-Workspace': process.env.REACT_APP_WORKSPACE,
  Authorization: `Bearer ${token}`,
})

export const authHeader = (token = localStorage.getItem('access_token')) => ({
  'X-Workspace': process.env.REACT_APP_WORKSPACE,
  Authorization: `Bearer ${token}`,
})

export const auth = 'auth'
export const content = 'content'
