import React, { useState } from 'react'
import { Input, message } from 'antd'
import * as _ from 'lodash'

import Modal from './Modal'
import { Button } from '../Button'

const isRangeValid = range =>
  range.length === 2
  && Number(range[0]) < Number(range[1])
  && range.every(number => Number.isInteger(Number(number)))

const getValuesFromRange = interval => {
  const parsedInterval = []
  for (let step = Number(interval[0]); step <= Number(interval[1]); step++)
    parsedInterval.push(String(step))
  return parsedInterval
}

const parseInputValue = inputValue => {
  let error = ''
  if (!inputValue) return ['Ошибка. Проверьте вводимые значения']

  const parsedValues = inputValue.split(',').map(str => {
    const trimmedStr = str.trim()
    if (!trimmedStr.includes('..')) return trimmedStr

    const interval = trimmedStr.split('..')

    if (isRangeValid(interval)) return getValuesFromRange(interval)
    error = 'Ошибка. Проверьте вводимые значения'
  })

  const flattenArray = _.flatten(parsedValues).filter(Boolean)
  if (flattenArray.length) return [error, flattenArray]

  return ['Ошибка. Проверьте вводимые значения']
}

export default ({
  className,
  onSubmit,
  title,
  subtitle,
  hint,
  submitTitle,
  btnStyle = 'bordered_btn',
  isOpen,
  setIsOpen,
}) => {
  const [value, setValue] = useState('')

  const succeedSubmitCallback = () => {
    setIsOpen(false)
    setValue('')
  }

  const handleSubmit = () => {
    const [error, parsedValues] = parseInputValue(value)
    if (error) {
      message.error(error)
      return
    }
    onSubmit(parsedValues, succeedSubmitCallback)
  }

  return (
    <Modal
      extraClassName={className ? `${className} text-modal` : 'text-modal'}
      start={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <h1 className='text-modal__title'>{title}</h1>
      <h3 className='text-modal__subtitle'>{subtitle}</h3>
      <h3 className='text-modal__hint'>{hint}</h3>
      <Input.TextArea
        autoFocus
        onPressEnter={handleSubmit}
        rows='4'
        value={value}
        onChange={({ target }) => setValue(target.value)}
      />
      <Button
        styles={`text-modal__btn ${btnStyle}`}
        onClick={handleSubmit}
        title={submitTitle}
      />
    </Modal>
  )
}
