import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox } from 'antd'

import IconStar from '../../../UI/icons/Star'
import IconPrint from '../../../UI/icons/Print'
import IconEdit from '../../../UI/icons/Edit'
import ListPopUp from '../../../ListPopUp'
import CardListItem from '../../../helpers/lists/CardListItem'
import ConfirmationModal from '../../../UI/modals/ConfirmationModal'

const getImagePreviewStyle = images => {
  const path = images[0].thumb || images[0].path
  return ({ backgroundImage: `url(${path})` })
}

const TableListItem = ({
  table,
  flexValues,
  handlePrint,
  handleTableCheck,
  handleEditTable,
  handleDeleteTable,
}) => {
  const [isPopUpMenuOpen, setIsPopUpMenuOpen] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const popUpOptions = [
    {
      title: <Link to={`/offices/reservations/table/${table.id}`}>Бронирования</Link>,
      icon: 'icon__booking',
    },
    {
      title: 'Удалить',
      icon: 'icon__trash',
      onClick: () => setIsDeleteModalVisible(true),
    },
  ]

  const togglePopUp = () => setIsPopUpMenuOpen(!isPopUpMenuOpen)

  const formatTags = () => table.tags.length ? table.tags.map(tag => tag.title).join(', ') : 'Нет тегов'

  const formatStatus = () => table.current_booking ? 'Занято' : 'Свободно'

  const handlePrintBtn = () => handlePrint({ title: table.title, id: table.id, tags: table.tags })

  return (
    <>
      <CardListItem>
        <div style={{ flex: flexValues[0] }} className='table-list-item__checkbox'>
          <Checkbox checked={table.checked} onChange={() => handleTableCheck(table.id)} />
        </div>
        <div style={{ flex: flexValues[1] }} className='table-list-item__title'>{table.title}</div>
        <div
          style={{ flex: flexValues[2] }}
          className={`table-list-item__tags ${!table.tags.length ? 'secondary_text__inactive' : ''}`}
        >
          {formatTags()}
        </div>

        <div style={{ flex: flexValues[3] }} className='table-list-item__rating'>
          <IconStar className='table-list-item__rating-icon' />
          {table.rating}
        </div>

        <div style={{ flex: flexValues[4] }} className='table-list-item__status'>
          <span className={`icon-status ${table.current_booking ? '' : 'icon-status-available'}`} />
          {formatStatus()}
        </div>

        <div style={{ flex: flexValues[5] }} className='table-list-item__photos'>
          {table.images?.length
            ? <div className='icon_preview' style={getImagePreviewStyle(table.images)} />
            : <span className='secondary_text__inactive'>–</span>}
        </div>

        <div className='table-list-item__actions'>
          <IconPrint onClick={handlePrintBtn} />
          <IconEdit onClick={() => handleEditTable(table)} width='1rem' height='1rem' />
          <div
            style={{ marginRight: 0 }}
            className='icon__more'
            onMouseDown={e => {
              e.stopPropagation()
              togglePopUp()
            }}
          />
          {isPopUpMenuOpen && <ListPopUp options={popUpOptions} closePopUp={togglePopUp} />}
        </div>
      </CardListItem>
      <ConfirmationModal
        start={isDeleteModalVisible}
        text={`Удалить помещение '${table.title}'?`}
        onConfirm={() => handleDeleteTable(table.id)}
        onClose={() => setIsDeleteModalVisible(false)}
      />
    </>
  )
}

export default TableListItem
