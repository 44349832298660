import React from 'react'
import { CSSTransition } from 'react-transition-group'

const PopUpTransition = ({ loading, children }) => (
  <CSSTransition
    in={loading}
    classNames='pop_up'
    timeout={300}
    appear
    mountOnEnter
    exit
    unmountOnExit
  >
    {children}
  </CSSTransition>
)

export default PopUpTransition
