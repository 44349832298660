import { all, fork } from 'redux-saga/effects'
import filesSagas from './filesSagas'
import usersSagas from './usersSagas'
import accountSagas from './accountSagas'
import officesSagas from './officesSagas'
import bookingSagas from './bookingSagas'
import notificationsSagas from './notificationsSagas'

export default function* watchSaga() {
  yield all([
    fork(filesSagas),
    fork(usersSagas),
    fork(accountSagas),
    fork(officesSagas),
    fork(bookingSagas),
    fork(notificationsSagas),
  ])
}
