import React from 'react'

export default React.forwardRef(({
  width = '18',
  height = '10',
  onClick,
  color = '#FBFBFB',
}, ref) => (
  <div ref={ref} className='icon__filter' onClick={onClick}>
    <svg
      style={{ transition: '0.3s stroke ease' }}
      width={width}
      height={height}
      viewBox='0 0 18 10'
      stroke={color}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M1 1H17' strokeWidth='2' strokeLinecap='round' />
      <path d='M4 5H14' strokeWidth='2' strokeLinecap='round' />
      <path d='M7 9H11' strokeWidth='2' strokeLinecap='round' />
    </svg>
  </div>
))
