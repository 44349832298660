import React from 'react'
import { Form, Input } from 'antd'
import { Field as FormikField } from 'formik'

const PasswordInput = ({
  name,
  label,
  value,
  style,
  onChange,
  setFieldTouched,
  children,
  placeholder,
  onEnter,
  error,
}) => {
  const getStyle = () => ({ ...style, border: `1px solid ${error ? '#CD4141' : 'transparent'}` })
  return (
    <Form.Item label={label}>
      <div className='validation_block'>
        <FormikField
          render={() => (
            <Input.Password
              visibilityToggle
              name={name}
              value={value}
              placeholder={placeholder}
              onPressEnter={onEnter}
              onBlur={() => setFieldTouched(name, true)}
              onChange={({ target }) => onChange(name, target.value)}
              style={getStyle()}
            />
          )}
        />
        <div className='validation_block margin_top__small'>{children}</div>
      </div>
    </Form.Item>
  )
}

export default PasswordInput
