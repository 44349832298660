import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd';
import { useDispatch } from 'react-redux';

import IconPlus from '../../UI/icons/Plus'
import IconEdit from '../../UI/icons/Edit'
// import { convertTypeName } from '../helpers/roomsTypes'
import AnimatedHeight from '../../UI/animations/AnimatedHeight'
import IconExpand from '../../UI/icons/Expand'
import IconDelete from '../../UI/icons/Delete'
import { translateRoomTypes, geiIconSVG } from '../helpers/roomsTypes'
import {
  SET_STATE_WITHOUT_REDUX,
  SET_MARKER_ON_CLICK_ATTR,
  SET_ROOM_ON_HOVER_ATTR,
} from '../helpers/useReducerForMapComponent'
import { DELETE_ROOM_FROM_MAP, REFRESH_ROOMS } from '../../../actions/offices'

import './roomType.scss'
const RoomTypeGroupCard = (props) => {
  const {
    type,
    rooms,
    isMapSet,
    getRoomCardStyle,
    deleteRoomMarker,
    stateWithoutRedux,
    setConfirmModal,
    dispatchWithoutRedux,
  } = props
  const {
    isActiveAddMarkerMode,
    activeRoom,
    markers,
    roomOnHoverAttr,
  } = stateWithoutRedux
  const [isExpanded, setIsExpanded] = useState(false)
  const getContainerHeight = () => 73 * (rooms.length) + 20
  const dispatch = useDispatch();

  const renderAddMarkerBtn = roomId => {
    const onClickHandler = () => {
      const isFalseCurrentAddMarkerMode = !isActiveAddMarkerMode
      const isEqualId = roomId === activeRoom
      // делает true или false режиму добавления маркеров на карту по клику
      if (isFalseCurrentAddMarkerMode) {
        dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { activeRoom: roomId, isActiveAddMarkerMode: true } })
      } else if (isEqualId) {
        dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { activeRoom: roomId, isActiveAddMarkerMode: false } })
      } else {
        dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { activeRoom: roomId, isActiveAddMarkerMode: true } })
      }
    }

    const onMouseEnter = e => {
      e.preventDefault()
      const marker = markers.find(p => p.roomId === roomId)
      const type = marker?.type
      const iconName = translateRoomTypes(type)
      const Svg = geiIconSVG(iconName);
      dispatchWithoutRedux({ type: SET_ROOM_ON_HOVER_ATTR, payload: { isHover: true, id: roomId, Svg } })
    }

    const onMouseLeave = e => {
      e.preventDefault()
      dispatchWithoutRedux({ type: SET_ROOM_ON_HOVER_ATTR, payload: { isHover: false } })
    }

    const getBackgroundColorAddMarker = roomId => {
      if (activeRoom === roomId) {
        return isActiveAddMarkerMode ? '#0DCF5C' : '#0079C1'
      }
      return ''
    }

    const isVisibleIconPlace = roomOnHoverAttr.isHover && roomOnHoverAttr.id === roomId

    const getSvgIcon = () => roomOnHoverAttr.Svg ? <stateWithoutRedux.roomOnHoverAttr.Svg /> : <IconPlus />
    return (
      <Tooltip title='Перенесите маркер на карту'>
        <div
          id={roomId}
          className='room-card__add-marker-btn'
          style={{ backgroundColor: getBackgroundColorAddMarker(roomId) }}
          onClick={onClickHandler}
          draggable
          onDragStart={() => {
            dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { activeRoom: roomId } })
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {isVisibleIconPlace || (isActiveAddMarkerMode && roomId === activeRoom)
            ? getSvgIcon()
            : <IconPlus />
          }
        </div >
      </Tooltip>
    )
  }

  const renderMarkerIndicator = (roomId, dispatch) => {
    const onClickDeleteMarker = e => {
      deleteRoomMarker(e, roomId)
      dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
    }

    const removeRoom = () => {
      dispatch({ type: DELETE_ROOM_FROM_MAP, roomId: roomId });
      dispatch({ type: REFRESH_ROOMS, withLoader: false });
    }
    return (
      <>
        <Tooltip title='Убрать маркер с карты'>
          <div className='icon__wrapper' onClick={onClickDeleteMarker}>
            <div className='icon__delete align_self__center' />
          </div>
        </Tooltip>
        <Tooltip title='Удалить помещение'>
          <div
            className='delete-room-icon-wrap align_self__center'
            onClick={() => {
              dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
              setConfirmModal({ isVisible: true, type: 'removeRoom', callback: removeRoom })
            }}>
            <IconDelete />
          </div>
        </Tooltip>
      </>
    )
  }

  const renderRoom = (room, dispatch) => {
    if (room.deleted) return null
    return (
      <div
        id='rooms-list'
        key={room.id}
        className={getRoomCardStyle(room.id)}
      >
        <div className='room_card__main_info'>
          <h1>{room.title}</h1>
          {room?.zone && <span className='secondary_text'>{room?.zone?.title}</span>}
        </div>
        {(room.marker && isMapSet)
          ? renderMarkerIndicator(room.id, dispatch)
          : renderAddMarkerBtn(room.id)}
      </div>
    )
  }

  return (
    <div className={`container__room_type${isExpanded ? '__active' : ''}`} key={type}>
      <div
        className='container__title__rooms_group_type'
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className='title__rooms_group_type'>
          {type.toUpperCase()}
        </span>
        <IconExpand isExpanded={isExpanded} />
      </div>

      <AnimatedHeight condition={isExpanded} maxHeight={getContainerHeight()}>
        {rooms?.map(r => renderRoom(r, dispatch))}
      </AnimatedHeight>
    </div>
  )
}

export default RoomTypeGroupCard
