import React from 'react'
import ReactDOM from 'react-dom'

import './tooltip.scss'

const rootDocument = document.getElementById('root')

const TooltipInner = ({ style, marker }) => (
  <div className='marker__tooltip' style={style}>
    <span className='title'>{marker.title}</span>
    {marker.description && <span className='subtitle'>{marker.description}</span>}
    <span className='subtitle'>{marker.zoneTitle}</span>
  </div>
)

const Tooltip = ({ start, style, marker }) => start
  && ReactDOM.createPortal(<TooltipInner style={style} marker={marker} />, rootDocument)

export default Tooltip
