import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Button from '../../UI/Button'
import UserBookingHistory from './history/UserBookingHistory'
import FadeTransition from '../../UI/animations/transition/FadeTransition'
import getFullName from '../../../utils/getFullName'
import MainCard from '../../MainCard'
import NewBookingForm from './form/NewBookingForm'
import UnderlinedTitle from '../../UI/UnderlinedTitle'
import UserInfo from './UserInfo'
import { GET_USER_BOOKING_HISTORY } from '../../../actions/booking'
import { GET_OFFICES } from '../../../actions/offices'

const UserReservationScreen = ({
  getUserBookingHistory,
  match,
  loading,
  user,
  getOffices,
}) => {
  const [isFormShow, setIsFormShow] = useState(false)
  useEffect(() => {
    getUserBookingHistory(match.params.id, 1, getOffices)
    return undefined
  }, [getOffices, getUserBookingHistory, match.params.id])

  const FormBlock = () => {
    const basicStyle = 'flex_container__column full_height flex_container__flex_1'
    const formBlockStyle = `${basicStyle} ${(user.description || user.email) ? 'margin_top' : ''}`
    return (
      <div className={formBlockStyle} style={{ flex: 1 }}>
        <FadeTransition
          loading={isFormShow}
          style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
        >
          <div className='full_height flex_container__flex_1'>
            <UnderlinedTitle title='+ НОВАЯ БРОНЬ' />
            <NewBookingForm
              meetingRoom
              userId={match.params.id}
              isFormShown={isFormShow}
              table={match?.params.id || null}
              onCancel={() => setIsFormShow(false)}
            />
          </div>
        </FadeTransition>
      </div>
    )
  }

  return (
    <MainCard
      title='ПОЛЬЗОВАТЕЛЬ'
      icon='icon__user'
      loading={loading}
      innerContainerStyle={{ overflow: 'hidden' }}
    >
      <div className='flex_container__column full_height'>
        <FadeTransition loading>
          <div className='flex_container'>
            {user?.id && (
              <div
                className='card_h1 flex_container__flex_1'
                style={{ overflow: 'auto' }}
              >
                {/* Test version */}
                {/*<h1 className='text__wrapper'>{getFullName(user)}</h1>*/}
                <h1 className='text__wrapper'>{user?.phone_number}</h1>
                <div className='main_text'>{user.access}</div>
              </div>
            )}
            <Button
              title='Добавить новую бронь'
              styles='bordered_btn align_self__center'
              style={{ marginRight: '17px' }}
              icon='icon__add__in_circle'
              onClick={() => setIsFormShow(true)}
            />
          </div>
        </FadeTransition>

        <div className='flex_container full_height' style={{ marginRight: '10px' }}>
          <UserInfo user={user} FormBlock={FormBlock} isFormShow={isFormShow} />
          <UserBookingHistory />
        </div>
      </div>
    </MainCard>
  )
}

const mapStateToProps = ({ booking }) => {
  const { loading, user, count } = booking
  return ({ loading, user: user || {}, count: count || null })
}

const mapDispatchToProps = dispatch => ({
  getOffices: searchString => dispatch({ type: GET_OFFICES, searchString }),
  getUserBookingHistory: (userId, page, callback) =>
    dispatch({ type: GET_USER_BOOKING_HISTORY, userId, page, callback }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserReservationScreen)
