import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import moment from 'moment'
import { Form as FormikForm, withFormik } from 'formik'

import getStyle from '../../../../utils/getStyle/getStyleUserForm'
import ScrollTransition from '../../../UI/animations/transition/ScrollTransition'
import SimpleImageUploader from '../SimpleImageUploader'
import SimpleInput from '../../../UI/input/SimpleInput'
import ValidationMsg, { ErrorMsg } from '../../../UI/ValidationMsg'
import packUser from '../../../../utils/objects/packUser'
import RadioGroup from '../../../UI/input/RadioGroup'
import DatePick from '../../../UI/input/DatePick'
import SelectWithSearch from '../../../UI/input/SelectWithSearch'
import getFullCityName from '../../../../utils/getFullCityName'
import citiesSource from '../../../../assets/cities'
import generalCitiesSource from '../../../../assets/generalCities'
import UnderlinedTitle from '../../../UI/UnderlinedTitle'
import CheckBox from '../../../UI/input/CheckBox'
import Button from '../../../UI/Button'
import getBasicInputProps from '../../../../utils/getBasicInputProps'
import { phoneRegExp } from '../../../../utils/data/regExp'
import { CLEAR_FILES } from '../../../../actions/files'
import { CREATE_OPERATOR, DELETE_USER, GET_USERS, CREATE_USER } from '../../../../actions/users'

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .when('isOperator', {
      is: true,
      then: Yup.string()
        .min(2, 'Минимум 2 символа')
        .max(50, 'Максимум 50 символов')
        .required('Обязательное поле'),
      otherwise: Yup.string().nullable(),
    }),
  middleName: Yup.string()
    .max(50, 'Максимум 50 символов'),
  lastName: Yup.string()
    .when('isOperator', {
      is: true,
      then: Yup.string()
        .min(2, 'Минимум 2 символа')
        .max(50, 'Максимум 50 символов')
        .required('Обязательное поле'),
      otherwise: Yup.string().nullable(),
    }),
  phone_number: Yup.string()
    .matches(phoneRegExp, 'Введите номер телефона от 10 до 13 символов')
    .required('Обязательное поле'),
  description: Yup.string()
    .max(150, 'Максимум 150 символов'),
  email: Yup.string()
    .when('isOperator', {
      is: true,
      then: Yup.string()
        .email('Введите адрес электронной почты')
        .max(120, 'Максимум 120 символов')
        .required('Обязательное поле'),
      otherwise: Yup.string().nullable(),
    }),
  city: Yup.object().when('isOperator', {
    is: true,
    then: Yup.object().shape({ id: Yup.string().nullable().required('Обязательное поле') }),
    otherwise: Yup.object().shape({ id: Yup.string().nullable() }),
  }),
  birthday: Yup.object().when('isOperator', {
    is: true,
    then: Yup.object().nullable().required('Обязательное поле'),
    otherwise: Yup.object().nullable(),
  }),
})

const InnerForm = ({
  createOperator,
  createUser,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
  clearPhoto,
  resetForm,
  userPhoto,
  setTouched,
}) => {
  const [citiesList, setCitiesList] = useState(generalCitiesSource)
  const halfWidthStyle = { maxWidth: '47%' }

  const resetFullForm = () => {
    resetForm()
    clearPhoto()
  }

  const touchForm = () => setTouched({
    firstName: true,
    lastName: true,
    phone_number: true,
    description: true,
    birthday: true,
    email: true,
    city: true,
  })

  const handleSubmit = () => {
    touchForm()

    if (!ValidationSchema.isValidSync(values)) return
    if (values.isOperator) {
      createOperator(packUser(values), userPhoto, resetFullForm)
      return
    }

    const user = { phone_number: values.phone_number, description: values.description }
    createUser(user, resetFullForm)
  }

  const isStrContainSearchStr = (name, search) => name.toLowerCase().includes(search.toLowerCase())

  const getCities = searchVal => {
    if (searchVal.length < 3) {
      setCitiesList(generalCitiesSource)
      return null
    }
    setCitiesList(citiesSource.filter(c => isStrContainSearchStr(c?.name, searchVal)))
  }

  const onPhoneFieldFocus = () =>
    !values.phone_number ? setFieldValue('phone_number', '+') : null

  const onPhoneFieldBlur = () =>
    values.phone_number === '+' ? setFieldValue('phone_number', '') : null

  const isInputsDisabled = !values.isOperator

  useEffect(() => clearPhoto, [])

  const getInputProps = getBasicInputProps({
    values,
    errors,
    touched,
    setFieldTouched,
    onChange: setFieldValue,
  })

  return (
    <div className='container__left_side__new_user_form__inner'>
      <UnderlinedTitle title='СОЗДАНИЕ ПОЛЬЗОВАТЕЛЯ' style={{ width: 'calc(100% - 17px)' }} />
      <ScrollTransition loading style={{ display: 'flex', flexDirection: 'column' }}>
        <FormikForm style={{ paddingRight: '17px' }}>
          <CheckBox
            title='Оператор'
            style={{ marginBottom: '10px' }}
            {...getInputProps('isOperator')}
          />
          <SimpleInput
            disabled={isInputsDisabled}
            label='Имя*'
            placeholder='Имя пользователя'
            {...getInputProps('firstName')}
          >
            <ErrorMsg field='firstName' />
          </SimpleInput>
          <SimpleInput
            disabled={isInputsDisabled}
            label='Отчество'
            placeholder='Отчество пользователя'
            {...getInputProps('middleName')}
          >
            <ErrorMsg field='middleName' />
          </SimpleInput>
          <SimpleInput
            disabled={isInputsDisabled}
            label='Фамилия*'
            placeholder='Фамилия пользователя'
            {...getInputProps('lastName')}
          >
            <ErrorMsg field='lastName' />
          </SimpleInput>

          <div className='flex_container flex_container__space_between'>
            <div className='flex_container__flex_1 radio_group__horizontal' style={halfWidthStyle}>
              <DatePick
                disabled={isInputsDisabled}
                label='Дата рождения*'
                // name='birthday'
                // value={values.birthday}
                // onChange={setFieldValue}
                // onBlur={setFieldTouched}
                // error={getErrorCondition('birthday')}
                format='DD.MM.YYYY'
                {...getInputProps('birthday')}
                disabledDate={current => current && current > moment().endOf('day')}
              >
                <ErrorMsg field='birthday' />
              </DatePick>
            </div>
            <div className='flex_container__flex_1 radio_group__horizontal' style={halfWidthStyle}>
              <RadioGroup
                disabled={isInputsDisabled}
                label='Пол'
                options={['М', 'Ж']}
                {...getInputProps('sex')}
              />
            </div>
          </div>
          <SelectWithSearch
            disabled={isInputsDisabled}
            label='Город*'
            placeholder='Введите название города'
            onSearch={getCities}
            source={citiesList}
            targetField='name'
            idField='id'
            style={{ width: '100%' }}
            toShownString={getFullCityName}
            {...getInputProps('city')}
          >
            {touched.city && errors.city && <ValidationMsg>{errors.city.id}</ValidationMsg> }
          </SelectWithSearch>

          <SimpleInput
            disabled={isInputsDisabled}
            label='Email*'
            placeholder='Адрес электронной почты'
            style={getStyle()}
            {...getInputProps('email')}
          >
            <ErrorMsg field='email' />
          </SimpleInput>
          <SimpleInput
            label='Телефон*'
            placeholder='+***********'
            style={getStyle()}
            onFocus={onPhoneFieldFocus}
            onBlur={onPhoneFieldBlur}
            {...getInputProps('phone_number')}
          >
            <ErrorMsg field='phone_number' />
          </SimpleInput>
          <SimpleInput
            label='Должность/Описание'
            placeholder='Описание пользователя'
            style={{ height: '32px' }}
            {...getInputProps('description')}
          >
            <ErrorMsg field='description' />
          </SimpleInput>

          <SimpleImageUploader label='Фото' isNew disable={!values.isOperator} />
          <div className='flex_container flex_container__flex_1'>
            <div className='button_container__content_right' style={{ alignSelf: 'flex-end' }}>
              <Button
                delay={300}
                onClick={handleSubmit}
                title={`Добавить ${values.isOperator ? 'оператора' : 'пользователя'}`}
                styles='rounded-btn_blue'
              />
            </div>
          </div>
        </FormikForm>
      </ScrollTransition>
    </div>
  )
}

const NewUserForm = withFormik({
  mapPropsToValues: () => ({
    isOperator: false,
    description: '',
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    phone_number: '',
    sex: 'М',
    birthday: null,
    city: { id: null },
  }),
  validationSchema: ValidationSchema,
})(InnerForm)

const mapStateToProps = ({ files, users }) => ({
  userPhoto: files.photo,
  users: users.list,
  loading: users.loading,
  count: users.count,
})

const mapDispatchToProps = dispatch => ({
  clearPhoto: () => dispatch({ type: CLEAR_FILES }),
  createUser: (user, callback) => dispatch({ type: CREATE_USER, user, callback }),
  deleteUser: userId => dispatch({ type: DELETE_USER, userId }),
  getUsers: page => dispatch({ type: GET_USERS, page }),
  createOperator: (user, photo, callback) =>
    dispatch({ type: CREATE_OPERATOR, user, photo, callback }),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewUserForm)
