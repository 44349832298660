export const isAllItemsChecked = source => source.every(item => item.checked)

export const isSomeItemsChecked = source => source.some(item => item.checked)
  && source.some(item => !item.checked)

export const getSelectedIds = source => source.filter(item => item.checked).map(item => item.id)

export const setCheckedToValue = (source, value = false) =>
  source.map(item => ({ ...item, checked: value }))

export const getUpdatedList = (prevList, checkedItem) => {
  const newList = [...prevList]
  const targetItemIndex = prevList.findIndex(item => item.id === checkedItem.id)
  const oldItem = { ...newList[targetItemIndex] }
  newList[targetItemIndex] = { ...oldItem, checked: !checkedItem.checked }

  return newList
}
