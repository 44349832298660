// constants
export const SET_VIEWPORT_DIMENSIONS = 'SET_VIEWPORT_DIMENSIONS'
export const SET_MARKER_ON_HOVER_ATTR = 'SET_MARKER_ON_HOVER_ATTR'
export const SET_MARKER_ON_CLICK_ATTR = 'SET_MARKER_ON_CLICK_ATTR'
export const SET_ROOM_ON_HOVER_ATTR = 'SET_ROOM_ON_HOVER_ATTR'
export const SET_MAP_STATE = 'SET_MAP_STATE'
export const ON_CLICK_MARKER = 'ON_CLICK_MARKER'
export const SET_STATE_WITHOUT_REDUX = 'SET_STATE_WITHOUT_REDUX'
export const SET_MARKERS = 'SET_MARKERS'
export const SET_IS_EXPANDED = 'SET_IS_EXPANDED'
export const SET_IS_VISIBLE_BUCKET = 'SET_IS_VISIBLE_BUCKET'
export const TOOGLE_IS_EXPANDED = 'TOOGLE_IS_EXPANDED'
export const SET_IS_EXPANDED_FLOORLIST = 'SET_IS_EXPANDED_FLOORLIST'
export const EDIT_ROOM = 'EDIT_ROOM'
export const SET_IS_BUCKET_HOVER = 'SET_IS_BUCKET_HOVER'
export const DELETING_MARKER = 'DELETING_MARKER'
export const SET_DELETING_MARKER_ROOM_ID = 'SET_DELETING_MARKER_ROOM_ID'
export const SET_BUCKET_POSITION = 'SET_BUCKET_POSITION'

// initialState
export const DEFAULT_MARKER_ON_HOVER_ATTR = {
  hover: false,
  isCursorMove: false,
  id: null,
  x: null,
  y: null,
  title: '',
  zoneTitle: '',
  description: '',
}

export const DEFAULT_MARKER_ON_CLICK_ATTR = {
  isActive: false,
  id: null,
  x: null,
  y: null,
}

export const DEFAULT_ROOM_ON_HOVER_ATTR = {
  isHover: false,
  id: null,
  src: null,
  Svg: null
}

export const DEFAULT_MAP_STATE = {
  scale: 1,
  x: 0,
  y: 0,
  image: null,
  minScale: 1,
  maxScale: 1,
  loading: true,
  isDragging: false,
}

export const INITIAL_STATE_WITHOUT_REDUX = {
  mode: 'read',
  activeFloor: null,
  activeRoom: undefined,
  loaded: false,
  isRoomModalVisible: false,
  editableRoom: null,
  isAddRoomModalVisible: false,
  isActiveAddMarkerMode: null,
  isBucketHover: false,
  bucketPosition: 0,
  isVisibleBucket: false,
  isDeletingMarker: false,
  deletingMarkerRoomId: null,
  markers: [],
  viewportDimensions: { width: 0, height: 0 },
  markerOnHoverAttr: DEFAULT_MARKER_ON_HOVER_ATTR,
  markerOnClickAttr: DEFAULT_MARKER_ON_CLICK_ATTR,
  roomOnHoverAttr: DEFAULT_ROOM_ON_HOVER_ATTR,
  mapState: DEFAULT_MAP_STATE,
  floorsList: {
    isExpanded: false
  },
  clickTarget: null,
}


export const reducer = (state, action) => {
  const { payload } = action
  switch (action.type) {
    case SET_VIEWPORT_DIMENSIONS:
      return { ...state, viewportDimensions: { ...payload } };
    case SET_MARKER_ON_HOVER_ATTR:
      return { ...state, markerOnHoverAttr: { ...state.markerOnHoverAttr, ...payload } };
    case SET_ROOM_ON_HOVER_ATTR:
      return { ...state, roomOnHoverAttr: { ...state.roomOnHoverAttr, ...payload } };
    case SET_MARKER_ON_CLICK_ATTR:
      return { ...state, markerOnClickAttr: { ...state.markerOnClickAttr, ...payload } };
    case SET_MAP_STATE:
      return { ...state, mapState: { ...state.mapState, ...payload } };
    case SET_IS_EXPANDED:
      return { ...state, floorsList: { isExpanded: payload } };
    case SET_IS_BUCKET_HOVER:
      return { ...state, isBucketHover: payload };
    case SET_BUCKET_POSITION:
      return { ...state, bucketPosition: payload.top };
    case SET_IS_VISIBLE_BUCKET:
      return { ...state, isVisibleBucket: payload };
    case TOOGLE_IS_EXPANDED:
      return { ...state, floorsList: { ...state.floorsList, isExpanded: !state.floorsList.isExpanded } };
    case SET_IS_EXPANDED_FLOORLIST:
      return { ...state, floorsList: { ...state.floorsList, isExpanded: payload } };
    case DELETING_MARKER:
      return { ...state, isDeletingMarker: payload };
    case SET_DELETING_MARKER_ROOM_ID:
      return { ...state, deletingMarkerRoomId: payload };
    case SET_MARKERS:
      const markersNewFormat = payload.map(p => ({
        ...p,
        id: p.roomId,
        isDragging: p.isDragging,
        isVisible: !!p.x,
        x: Number(p.x),
        y: Number(p.y),
      }))
      return { ...state, markers: [...markersNewFormat] };
    case EDIT_ROOM:
      return { ...state, markerOnClickAttr: { ...state.markerOnClickAttr, ...payload } };
    case SET_STATE_WITHOUT_REDUX:
      return { ...state, ...payload };
    case ON_CLICK_MARKER:
      const newIsActive = payload.id === state.markerOnClickAttr.id ? !state.markerOnClickAttr.isActive : true
      const newState = {
        ...state,
        markerOnClickAttr: {
          ...state.markerOnClickAttr,
          ...payload,
          isActive: newIsActive,
        },
        clickTarget: 'marker',
      }
      return { ...newState };
  }
}