import IconWorkPlace from '../../../assets/images/icons/workplace.svg'
import IconMeetingRoom from '../../../assets/images/icons/meeting_room.svg'
import IconQuestion from '../../../assets/images/icons/question.svg'
import IconCoffee from '../../../assets/images/icons/coffee.svg'
import IconHanger from '../../../assets/images/icons/hanger.svg'
import IconWC from '../../../assets/images/icons/wc.svg'
import IconLecture from '../../../assets/images/icons/lecture.svg'
import IconGym from '../../../assets/images/icons/gym.svg'
import IconFood from '../../../assets/images/icons/food.svg'

import MeetingRoomJS from '../../UI/icons/MeetingRoom'
import QuestionJS from '../../UI/icons/Question'
import WorkPlaceJS from '../../UI/icons/WorkPlace'
import CoffeeJS from '../../UI/icons/Coffee'
import HangerJS from '../../UI/icons/Hanger'
import WaterCloset from '../../UI/icons/WaterCloset'
import LectureJS from '../../UI/icons/Lecture'
import GymJS from '../../UI/icons/Gym'
import FoodJS from '../../UI/icons/Food.js'

export const sortRoomsByTypes = rooms => {
  const sortedRooms = {}

  const pushToNewField = (field, room) => sortedRooms[field] = [room]

  const addRoomToGroup = (field, room) => {
    sortedRooms[field]
      ? sortedRooms[field].push(room)
      : pushToNewField(field, room)
  }

  rooms.forEach(room => {
    switch (room.type) {
      case 'Рабочее место': {
        addRoomToGroup('table', room)
        break
      }
      case 'Переговорная': {
        addRoomToGroup('meeting', room)
        break
      }
      case 'Туалетная': {
        addRoomToGroup('toilet', room)
        break
      }
      case 'Гардеробная': {
        addRoomToGroup('cloakroom', room)
        break
      }
      case 'Лекторий': {
        addRoomToGroup('lecture', room)
        break
      }
      case 'Кафе': {
        addRoomToGroup('cafe', room)
        break
      }
      case 'Кофе-поинт': {
        addRoomToGroup('coffeePoint', room)
        break
      }
      case 'Спортзал': {
        addRoomToGroup('gym', room)
        break
      }
      default: break
    }
  })
  return sortedRooms
}

export const serializeRoomsByTypes = rooms => rooms.reduce((acc, room) => acc[room.type]
  ? { ...acc, [room.type]: [...acc[room.type], room] }
  : { ...acc, [room.type]: [room] },
  {})

export const convertTypeName = typeInEnglish => {
  switch (typeInEnglish) {
    case 'table': return 'Рабочее место'
    case 'meeting': return 'Переговорная'
    case 'toilet': return 'Туалетная'
    case 'cloakroom': return 'Гардеробная'
    case 'lecture': return 'Лекторий'
    case 'cafe': return 'Кафе'
    case 'coffeePoint': return 'Кофе-поинт'
    case 'gym': return 'Спортзал'
    default: return null
  }
}

export const translateRoomTypes = type => {
  switch (type) {
    case 'Рабочее место': return 'laptop'
    case 'Переговорная': return 'meeting'
    case 'Лекторий': return 'lecture'
    case 'Туалетная': return 'wc'
    case 'Гардеробная': return 'hanger'
    case 'Кофе-поинт': return 'coffee'
    case 'Кафе': return 'food'
    case 'Спортзал': return 'gym'
    default: return null
  }
}

export const geiIconSrc = iconName => {
  switch (iconName) {
    case 'laptop': return IconWorkPlace
    case 'hanger': return IconHanger
    case 'meeting': return IconMeetingRoom
    case 'lecture': return IconLecture
    case 'coffee': return IconCoffee
    case 'gym': return IconGym
    case 'food': return IconFood
    case 'wc': return IconWC
    default: return IconQuestion
  }
}

export const geiIconSVG = iconName => {
  switch (iconName) {
    case 'laptop': return WorkPlaceJS
    case 'hanger': return HangerJS
    case 'meeting': return MeetingRoomJS
    case 'lecture': return LectureJS
    case 'coffee': return CoffeeJS
    case 'gym': return GymJS
    case 'food': return FoodJS
    case 'wc': return WaterCloset
    default: return QuestionJS
  }
}
