import React from 'react'

import Icon from './Icon'

export const Button = ({ icon, title, styles, onClick, style, delay, renderIcon }) => {
  let debounce = null

  const delayedExecution = func => {
    if (debounce) clearTimeout(debounce)
    debounce = setTimeout(func, delay)
  }

  return (
    <div
      className={styles}
      style={style}
      onClick={delay ? () => delayedExecution(onClick) : onClick}
    >
      {renderIcon ? renderIcon() : icon && <Icon name={icon} />}
      <span className='large_btn__title'>{title}</span>
    </div>
  )
}

export default Button
