import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import LargeSpin from '../../UI/Spin/LargeSpin'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import ExpandingSearch from '../../UI/ExpandingSearch'
import Icon from '../../UI/Icon'
import Button from '../../UI/Button'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import { GET_GROUP_ACCESS } from '../../../actions/users'

import './officesAndZonesList.scss'

const MOCK_OFFICES = [
  {
    id: 727,
    title: 'БЦ Авеню на Аптекарской наб.',
    description: 'Аптекарская набережная д.20',
    zones: [
      {
        id: 666,
        title: 'Зона коворкинга',
      },
      {
        id: 7777,
        title: 'Зона для сотрудников',
      },
    ],
  },
  {
    id: 9927,
    title: 'БЦ Сенатор',
    description: 'Большая морская 20',
    zones: [
      {
        id: 999,
        title: 'Зона коворкинга',
      },
      {
        id: 7777,
        title: 'Зона для сотрудников',
      },
      {
        id: 875565,
        title: 'Зона для Ильи',
      },
    ],
  },
  {
    id: 8238,
    title: 'БЦ Авеню на Аптекарской наб.',
    description: 'Аптекарская набережная д.20',
    zones: [
      {
        id: 666,
        title: 'Зона коворкинга',
      },
      {
        id: 7777,
        title: 'Зона для сотрудников',
      },
    ],
  },
  {
    id: 88,
    title: 'БЦ Сенатор',
    description: 'Большая морская 20',
    zones: [
      {
        id: 999,
        title: 'Зона коворкинга',
      },
      {
        id: 7777,
        title: 'Зона для сотрудников',
      },
      {
        id: 875565,
        title: 'Зона для Ильи',
      },
    ],
  },
  {
    id: 66,
    title: 'БЦ Сенатор',
    description: 'Большая морская 20',
    zones: [
      {
        id: 999,
        title: 'Зона коворкинга',
      },
      {
        id: 7777,
        title: 'Зона для сотрудников',
      },
      {
        id: 875565,
        title: 'Зона для Ильи',
      },
    ],
  },
  {
    id: 53,
    title: 'БЦ Сенатор',
    description: 'Большая морская 20',
    zones: [
      {
        id: 999,
        title: 'Зона коворкинга',
      },
      {
        id: 7777,
        title: 'Зона для сотрудников',
      },
      {
        id: 875565,
        title: 'Зона для Ильи',
      },
    ],
  },
]

const OfficesAndZonesList = ({ onButtonClick, shouldShowBtn, loading }) => {
  const { accessInfo = [] } = useSelector(state => state.users.groups.currentGroup)
  const dispatch = useDispatch()

  const { id } = useParams()

  const renderOfficesList = () => accessInfo?.length
    ? accessInfo.map(office => (
      <div className='groups-screen__office-card' key={office.id}>
        <div className='office-card__header'>
          <span className='office-card__office-title'>{office.title}</span>
          <span className='office-card__office-description'>
            <Icon name='icon__location' />
            {office.description || <span className='secondary_text__inactive'>Не указано</span>}
          </span>
        </div>
        <div className='office-card__zones-list'>
          {office.zones.map((zone, index) => (
            <div className='office-card__zone' key={zone.id}>
              <span className='office-card__zone-id'>{`${index + 1}.`}</span>
              <span className='office-card__zone-title'>{zone.title}</span>
            </div>
          ))}
        </div>
      </div>
    ))
    : <EmptyPlaceholder text='В группе нет пользователей' />

  useEffect(() => {
    dispatch({ type: GET_GROUP_ACCESS, id })
  }, [dispatch])

  return (
    <div className='container__right_side'>
      <div style={{ margin: '0 17px 20px 0' }}>
        <ExpandingSearch
          setSearchString={() => {}}
          searchString=''
          getItems={() => {}}
          placeholder='Начните искать бизнес-центр'
          defaultExpanded
          large
          disabled
        />
      </div>
      <ScrollTransition loading style={{ marginBottom: '10px' }}>
        <div className='card_list full_height' style={{ flex: 1 }}>
          {loading ? <LargeSpin styles={{ flex: 1 }} /> : renderOfficesList()}
        </div>
      </ScrollTransition>
      {shouldShowBtn && (
        <div className='button_container__edit_card'>
          <Button
            icon='icon__edit icon__wrapper'
            styles='bordered_btn'
            title='Редактировать'
            onClick={onButtonClick}
          />
        </div>
      )}
    </div>
  )
}

export default OfficesAndZonesList
