import React from 'react'
import { DatePicker, Form, ConfigProvider } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'
import Icon from '../Icon'

const DatePick = ({
  name,
  value,
  label,
  onBlur,
  onChange,
  disabledDate,
  format,
  children,
  error,
  disabled,
}) => (
  <Form.Item label={label} className='date_pick'>
    <ConfigProvider locale={ruRU}>
      <DatePicker
        name={name}
        value={value}
        format={format || 'DD MMMM YYYY'}
        disabled={disabled}
        disabledDate={disabledDate}
        suffixIcon={<Icon name='icon__calendar ant-calendar-picker-icon' />}
        onBlur={onBlur}
        onChange={date => onChange(name, date)}
        className='date_picker__single'
        style={{ width: '100%', border: error && '1px solid #CD4141' }}
      />
      <div className='validation_block margin_top__small'>{children}</div>
    </ConfigProvider>
  </Form.Item>
)

export default DatePick
