import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Circle, Stage, Layer, Group, Rect, Text, Image, Star } from 'react-konva';

import MarkerKonva from './MarkerKonva';
import { translateRoomTypes } from './helpers/roomsTypes'
import { ADD_MARKER, REFRESH_ROOMS, DELETE_MARKER } from '../../actions/offices'
import MarkerHover from './MarkerHover';
import MarkerClick from './MarkerClick';
import BucketKonva from './BucketKonva';
import { equationStraightLineGenerate } from './utils'
import {
  SET_VIEWPORT_DIMENSIONS,
  SET_MARKER_ON_HOVER_ATTR,
  SET_MARKER_ON_CLICK_ATTR,
  DEFAULT_MARKER_ON_CLICK_ATTR,
  SET_MAP_STATE,
  SET_STATE_WITHOUT_REDUX,
  SET_MARKERS,
  SET_IS_BUCKET_HOVER,
  SET_ROOM_ON_HOVER_ATTR,
  DELETING_MARKER,
  SET_DELETING_MARKER_ROOM_ID,
  SET_IS_VISIBLE_BUCKET,
  SET_BUCKET_POSITION,
} from './helpers/useReducerForMapComponent.js'

const MapKonva = (props) => {
  const {
    map,
    floorMap,
    activeFloor,
    activeRoom,
    setConfirmModal,
    stateWithoutRedux,
    dispatchWithoutRedux,
  } = props

  const {
    viewportDimensions,
    markerOnHoverAttr,
    markerOnClickAttr,
    mapState,
    markers,
    isBucketHover,
    isDeletingMarker,
    isVisibleBucket,
    bucketPosition,
  } = stateWithoutRedux
  const dispatch = useDispatch()
  const mapWrap = useRef()
  const stageRef = useRef();
  const [isMouseOverMap, setIsMouseOverMap] = useState(null)

  const updateImage = () => {
    const mapImage = new window.Image();
    mapImage.src = map;
    mapImage.onload = () => {
      dispatchWithoutRedux({ type: SET_MAP_STATE, payload: { image: mapImage, loading: false } })
    };
  }

  useEffect(() => {
    updateImage();
  }, [map])

  useEffect(() => {
    const width = mapWrap.current.offsetWidth
    const height = mapWrap.current.offsetHeight
    dispatchWithoutRedux({ type: SET_VIEWPORT_DIMENSIONS, payload: { width, height } })
    dispatchWithoutRedux({ type: SET_BUCKET_POSITION, payload: { top: height } })
    const widthCoefficient = 1 / (floorMap.width / width) //коеф. уменьшения длины
    const heightCoefficient = 1 / (floorMap.height / height) //коеф. уменьшения высоты
    const newScale = heightCoefficient > widthCoefficient ? heightCoefficient : widthCoefficient
    const newMinScale = newScale / 2
    const newMaxScale = newScale * 4

    const newMapState = { scale: newScale > 1 ? 1 : newScale, minScale: newMinScale, maxScale: newMaxScale, x: 0, y: 0 }
    dispatchWithoutRedux({ type: SET_MAP_STATE, payload: newMapState })
  }, [])

  useEffect(() => {
    const width = mapWrap.current.offsetWidth
    const height = mapWrap.current.offsetHeight
    dispatchWithoutRedux({ type: SET_VIEWPORT_DIMENSIONS, payload: { width, height } })
    const widthCoefficient = 1 / (floorMap.width / width) //коеф. уменьшения длины
    const heightCoefficient = 1 / (floorMap.height / height) //коеф. уменьшения высоты
    const newScale = heightCoefficient > widthCoefficient ? heightCoefficient : widthCoefficient
    const newMinScale = newScale / 2
    const newMaxScale = newScale * 4

    const newMapState = { scale: newScale > 1 ? 1 : newScale, minScale: newMinScale, maxScale: newMaxScale, x: 0, y: 0 }
    dispatchWithoutRedux({ type: SET_MAP_STATE, payload: newMapState })
  }, [activeFloor])

  const handleWheel = e => {
    e.evt.preventDefault()
    const scaleByCoef = 1.06; // TODO - определить скорость увеличения
    const stage = e.target.getStage()
    const oldScale = stage.scaleX()
    const newLargerScale = (oldScale * scaleByCoef) > mapState.maxScale ? mapState.maxScale : oldScale * scaleByCoef
    const newSmallerScale = (oldScale / scaleByCoef) < mapState.minScale ? mapState.minScale : oldScale / scaleByCoef

    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
    };
    const newScale = e.evt.deltaY > 0 ? newLargerScale : newSmallerScale;
    stage.scale({ x: newScale, y: newScale })
    const newMapState = {
      scale: newScale,
      x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale
    }
    dispatchWithoutRedux({ type: SET_MAP_STATE, payload: newMapState })
    dispatchWithoutRedux({ type: SET_MARKER_ON_HOVER_ATTR, payload: { hover: false, isCursorMove: false } })
  };

  const postMarker = (floorId, marker) => dispatch({ type: ADD_MARKER, floorId, marker })

  const handlePointDragStart = (e) => {
    const id = e.target.id()
    dispatchWithoutRedux({ type: SET_IS_VISIBLE_BUCKET, payload: true })
    dispatchWithoutRedux({ type: SET_MARKER_ON_HOVER_ATTR, payload: { hover: false, isCursorMove: true } })
    const markersCopy = markers.slice();
    const marker = markersCopy.find(m => m.id === id);
    const idx = markersCopy.indexOf(marker);
    markersCopy.splice(idx, 1);
    markersCopy.push({ ...marker, isDragging: true })

    dispatchWithoutRedux({ type: SET_MARKERS, payload: markersCopy })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
  };

  const handlePointDragEnd = e => {
    if (isDeletingMarker) {
      dispatchWithoutRedux({ type: SET_IS_BUCKET_HOVER, payload: false })
      dispatchWithoutRedux({ type: SET_IS_VISIBLE_BUCKET, payload: false })
      setTimeout(() => {
        dispatchWithoutRedux({ type: DELETING_MARKER, payload: false })
        dispatchWithoutRedux({ type: SET_DELETING_MARKER_ROOM_ID, payload: null })
      }, 100)
      return null
    }
    dispatchWithoutRedux({ type: SET_IS_VISIBLE_BUCKET, payload: false })
    const id = e.target?.attrs?.id
    const newX = (e.target.attrs.x * 100) / floorMap.width
    const newY = (e.target.attrs.y * 100) / floorMap.height
    const marker = markers.find(p => p.id === id)
    const roomId = marker?.roomId
    const icon = marker?.icon
    dispatchWithoutRedux({ type: SET_MARKER_ON_HOVER_ATTR, payload: { hover: true, x: newX, y: newY, isCursorMove: true } })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false, x: newX, y: newY } })
    const newMarkers = markers.map(point => ({ ...point, isDragging: false }))
    dispatchWithoutRedux({ type: SET_MARKERS, payload: newMarkers })
    postMarker(activeFloor, { x: newX.toFixed(2), y: newY.toFixed(2), room: roomId, icon })
  };

  const handleStageDragStart = (e) => {
    const id = e.target?.attrs?.id
    if (id === 'Stage') {
      dispatchWithoutRedux({ type: SET_MAP_STATE, payload: { isDragging: true } })
      dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
    }
  }

  const handleStageDragEnd = e => {
    if (e.target?.attrs?.width === viewportDimensions.width) {
      // если двигалась именно карта - изменяется x и y
      const newMapState = { x: e.target.attrs.x, y: e.target.attrs.y, isDragging: false }
      dispatchWithoutRedux({ type: SET_MAP_STATE, payload: newMapState })
    }
  }

  const handleStageClick = e => {
    const id = e.target?.parent?.attrs?.id;
    const isClickOnMarker = markers.some(m => m.id === id);
    if (!isClickOnMarker) {
      dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: DEFAULT_MARKER_ON_CLICK_ATTR })
    }
  }

  const handleStageWrapClick = e => {
    e.preventDefault()
    if (stateWithoutRedux.isActiveAddMarkerMode) {
      postMarkerFromClickOrDrop(e, stageRef);
      dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { isActiveAddMarkerMode: false } })
    }
  }

  const handleDrop = e => {
    postMarkerFromClickOrDrop(e, stageRef)
    dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { isActiveAddMarkerMode: false } })
  }

  const handleHoverBucket = e => {
    dispatchWithoutRedux({ type: SET_IS_BUCKET_HOVER, payload: true })
  }

  const handleDropBucket = e => {
    const activeMarker = markers.find(m => m.isDragging)
    dispatchWithoutRedux({ type: SET_DELETING_MARKER_ROOM_ID, payload: activeMarker?.roomId })
    dispatch({
      type: DELETE_MARKER,
      floorId: activeMarker?.floorId,
      room: { room: activeMarker?.roomId },
    })
    dispatch({ type: REFRESH_ROOMS, withLoader: false })
    dispatchWithoutRedux({ type: SET_ROOM_ON_HOVER_ATTR, payload: { isHover: false } })
    dispatchWithoutRedux({ type: DELETING_MARKER, payload: true })
  }

  const handleMouseLevaeFromBucket = e => {
    dispatchWithoutRedux({ type: SET_IS_BUCKET_HOVER, payload: false })
    dispatchWithoutRedux({ type: DELETING_MARKER, payload: false })
  }

  const postMarkerFromClickOrDrop = (e, ref) => {
    e.preventDefault()
    ref.current.setPointersPositions(e)
    const [mousePosition] = ref.current.getPointersPositions(e)
    const xWithoutMapOffset = mousePosition.x - mapState.x;
    const yWithoutMapOffset = mousePosition.y - mapState.y;
    /* 
    карта может быть смещена относительно вьюпорта. Вычисления выше убирают смещение карты относительно вьюпорта
    */
    const newX = xWithoutMapOffset * 100 / (floorMap.width * mapState.scale)
    const newY = yWithoutMapOffset * 100 / (floorMap.height * mapState.scale)
    const marker = markers.find(p => p.roomId === activeRoom)
    const type = marker?.type
    const icon = translateRoomTypes(type)
    postMarker(activeFloor, { x: newX.toFixed(2), y: newY.toFixed(2), room: activeRoom, type, icon })
  }

  const handleClickOnScaleButton = type => {
    const scaleByCoef = 1.045; // TODO - определить скорость увеличения
    const { mapState: prevMapState } = stateWithoutRedux
    const { scale: prevScale } = prevMapState
    const newScale = type === '+' ? prevScale * scaleByCoef : prevScale / scaleByCoef
    const newMapState = { ...prevMapState, scale: newScale }
    dispatchWithoutRedux({ type: SET_MAP_STATE, payload: newMapState })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
  }

  const visiblePoints = markers.filter(p => p.isVisible)

  const currentRadius = (() => {
    const maxValue = (mapState?.image?.width * 0.04 / 2).toFixed(2) // для ф-ии equationStraightLineGenerate
    const minValue = (mapState?.image?.width * 0.005).toFixed(2) // для ф-ии equationStraightLineGenerate
    const maxScale = mapState?.maxScale.toFixed(2) // для ф-ии equationStraightLineGenerate
    const minScale = mapState?.minScale.toFixed(2) // для ф-ии equationStraightLineGenerate
    const easyScale = mapState.scale.toFixed(2) // для ф-ии equationStraightLineGenerate

    const newCurrentRadius = equationStraightLineGenerate(minScale / 20, maxValue, maxScale, minValue)(easyScale)

    const maxRadius = mapState?.image?.width * 0.04 / 2
    const minRadius = viewportDimensions.width * 0.015 / 2
    if (newCurrentRadius >= maxRadius) return maxRadius
    if (newCurrentRadius <= minRadius) return minRadius
    return newCurrentRadius
  })()

  const getCursorClassName = () => {
    const isActiveAddMarkerMode = stateWithoutRedux.isActiveAddMarkerMode;
    const isClicked = markerOnClickAttr.isActive;
    const isHover = markerOnHoverAttr.isCursorMove;
    const isMapDragged = mapState.isDragging;

    switch (true) {
      case isMapDragged: return 'dragging'
      case isActiveAddMarkerMode: return 'cell'
      case isClicked: return 'click'
      case isHover: return 'hover'
      default: return ''
    }
  };

  return (
    <div
      ref={mapWrap}
      style={{ width: '100%', height: '100%' }}
      onDrop={handleDrop}
      onClick={handleStageWrapClick}
      onDragOver={e => e.preventDefault()}
      onMouseLeave={() => setIsMouseOverMap(false)}
      onMouseOver={() => setIsMouseOverMap(true)}
      className={`canvas-map-wrap ${getCursorClassName()}`}
    >
      {markerOnClickAttr.isActive && (
        <MarkerClick
          mapState={mapState}
          currentRadius={currentRadius}
          pointer={markerOnClickAttr}
          setConfirmModal={setConfirmModal}
          dispatchWithoutRedux={dispatchWithoutRedux}
        />
      )}
      {markerOnHoverAttr.hover && (
        <MarkerHover
          mapState={mapState}
          currentRadius={currentRadius}
          description={markerOnHoverAttr.description}
          title={markerOnHoverAttr.title}
          zoneTitle={markerOnHoverAttr.zoneTitle}
          x={markerOnHoverAttr.x}
          y={markerOnHoverAttr.y}
        />
      )}
      {isVisibleBucket && (
        <BucketKonva
          style={{ top: bucketPosition * 0.90 }}
          isBucketHover={isBucketHover}
          handleHoverBucket={handleHoverBucket}
          handleDropBucket={handleDropBucket}
          handleMouseLevaeFromBucket={handleMouseLevaeFromBucket}
        />
      )}
      <div style={{ overflow: 'hidden', height: '100%' }}>
        <Stage
          draggable
          ref={stageRef}
          width={viewportDimensions.width}
          height={viewportDimensions.height}
          onWheel={handleWheel}
          onDragStart={handleStageDragStart}
          onDragEnd={handleStageDragEnd}
          onClick={handleStageClick}
          scaleX={mapState.scale}
          scaleY={mapState.scale}
          x={mapState.x}
          y={mapState.y}
          id='Stage'
        >
          <Layer style={{ width: '100%' }}>
            {!mapState.loading && <Image image={mapState.image} name='map' id='map' />}
          </Layer>
          <Layer >
            {!mapState.loading && visiblePoints.map(point => (
              <MarkerKonva
                key={point.id}
                point={point}
                x={point.x / 100 * floorMap.width}
                y={point.y / 100 * floorMap.height}
                handlePointDragStart={handlePointDragStart}
                handlePointDragEnd={handlePointDragEnd}
                viewportDimensions={viewportDimensions}
                points={visiblePoints}
                currentRadius={currentRadius}
                isMouseOverMap={isMouseOverMap}
                dispatchWithoutRedux={dispatchWithoutRedux}
                stateWithoutRedux={stateWithoutRedux}
              />
            ))}
          </Layer>
        </Stage>
      </div>
      <div className='map-buttons-wrap'>
        <button
          className='map__button'
          type='button'
          onClick={e => handleClickOnScaleButton('+')}
        >
          +
        </button>
        <button
          className='map__button'
          type='button'
          onClick={e => handleClickOnScaleButton('-')}
        >
          -
        </button>
      </div>
    </div >
  )
}

export default MapKonva