import React from 'react'
import { Field as FormikField } from 'formik'
import { Form, Input } from 'antd'

const SimpleInput = ({
  name,
  label,
  value,
  style,
  onChange,
  setFieldTouched,
  children,
  disabled,
  placeholder,
  onEnter,
  onFocus,
  onBlur,
  autofocus,
  suffix,
  wideSuffix,
  error,
  id,
  autocomplete,
  type = 'text',
}) => {
  const OnInputBlur = () => {
    onBlur && onBlur()
    setFieldTouched(name, true)
  }

  return (
    <Form.Item label={label}>
      <div className={`validation_block ${wideSuffix ? 'wide_suffix' : ''}`}>
        <FormikField
          render={() => (
            <Input
              id={id}
              type={type}
              name={name}
              value={value}
              placeholder={placeholder}
              autoFocus={autofocus}
              suffix={suffix}
              disabled={disabled}
              autoComplete={autocomplete}
              onFocus={onFocus}
              onPressEnter={onEnter}
              onChange={({ target }) => onChange(name, target.value)}
              onBlur={OnInputBlur}
              style={{ ...style, width: '100%', border: error && '1px solid #CD4141' }}
            />
          )}
        />
        { children && <div className='validation_block margin_top__small'>{children}</div>}
      </div>
    </Form.Item>
  )
}

export default SimpleInput
