import React from 'react'

const Print = ({ onClick }) => (
  <div onClick={onClick} className='icon__print'>
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M4 1C4 0.447715 4.44772 0 5 0H17C17.5523 0 18 0.447715 18 1V7H19C19.7957 7 20.5587 7.31607 21.1213 7.87868C21.6839 8.44129 22 9.20435 22 10V15C22 15.7957 21.6839 16.5587 21.1213 17.1213C20.5587 17.6839 19.7957 18 19 18H18V21C18 21.5523 17.5523 22 17 22H5C4.44772 22 4 21.5523 4 21V18H3C2.20435 18 1.44129 17.6839 0.87868 17.1213C0.31607 16.5587 0 15.7956 0 15V10C0 9.20435 0.31607 8.44129 0.87868 7.87868C1.44129 7.31607 2.20435 7 3 7H4V1ZM6 17V20H16V17V14H6V17ZM18 16V13C18 12.4477 17.5523 12 17 12H5C4.44772 12 4 12.4477 4 13V16H3C2.73478 16 2.48043 15.8946 2.29289 15.7071C2.10536 15.5196 2 15.2652 2 15V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H5H17H19C19.2652 9 19.5196 9.10536 19.7071 9.29289C19.8946 9.48043 20 9.73478 20 10V15C20 15.2652 19.8946 15.5196 19.7071 15.7071C19.5196 15.8946 19.2652 16 19 16H18ZM16 2V7H6V2H16Z' fill='black' />
    </svg>
  </div>
)

export default Print
