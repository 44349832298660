import React from 'react'

const Lecture = ({ className = 'icon__add_white', color = 'white', height = '16', width = '16' }) => (
  <div className={className} >
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M24.231 11.5386C23.9182 11.5386 23.6481 11.6526 23.4196 11.8807C23.1912 12.1094 23.0772 12.3801 23.0772 12.6922V15.0003C23.0772 17.2244 22.2866 19.1266 20.7063 20.7069C19.1256 22.2873 17.2234 23.0775 14.9999 23.0775C12.7762 23.0775 10.8739 22.2873 9.29359 20.7069C7.71291 19.1266 6.92274 17.2244 6.92274 15.0003V12.6925C6.92274 12.3804 6.80835 12.1102 6.58028 11.881C6.35187 11.653 6.08124 11.5389 5.76882 11.5389C5.45607 11.5389 5.18544 11.653 4.95742 11.881C4.72897 12.1098 4.61496 12.3804 4.61496 12.6925V15.0003C4.61496 17.6568 5.50095 19.9674 7.2743 21.9323C9.04694 23.898 11.2377 25.0249 13.846 25.3126V27.693H9.23052C8.91777 27.693 8.64714 27.8064 8.41907 28.0351C8.19067 28.2632 8.0766 28.5337 8.0766 28.8465C8.0766 29.1586 8.19067 29.4288 8.41907 29.6579C8.64714 29.8861 8.91772 30.0001 9.23052 30.0001H20.7694C21.0818 30.0001 21.3524 29.8861 21.5808 29.6579C21.8089 29.4292 21.9232 29.1586 21.9232 28.8465C21.9232 28.5337 21.8089 28.2635 21.5808 28.0351C21.3524 27.8064 21.0818 27.693 20.7694 27.693H16.1539V25.3126C18.7619 25.0245 20.9522 23.8976 22.7256 21.9323C24.4982 19.9673 25.3849 17.6568 25.3849 15.0003V12.6925C25.3849 12.3804 25.2705 12.1102 25.0424 11.881C24.8138 11.6529 24.5435 11.5386 24.231 11.5386Z" fill="white" />
        <path d="M12.2806 11.2325H9.23053V13.3486H12.2806C12.7465 13.3486 13.1241 13.7261 13.1241 14.1921C13.1241 14.658 12.7465 15.0356 12.2806 15.0356H9.23224C9.24067 16.6068 9.80415 17.9537 10.9253 19.0749C12.0549 20.2051 13.4135 20.7692 14.9999 20.7692C16.5864 20.7692 17.9447 20.2051 19.0747 19.0749C20.1955 17.9537 20.7593 16.6068 20.7677 15.0356H17.8257C17.3597 15.0356 16.9821 14.658 16.9821 14.1921C16.9821 13.7261 17.3597 13.3486 17.8257 13.3486H20.7694V11.2325H17.8257C17.3597 11.2325 16.9821 10.8549 16.9821 10.389C16.9821 9.92311 17.3597 9.54553 17.8257 9.54553H20.7694V7.29646H17.8257C17.3597 7.29646 16.9821 6.91888 16.9821 6.45298C16.9821 5.98708 17.3597 5.6095 17.8257 5.6095H20.7623C20.7253 4.09227 20.1658 2.7862 19.0751 1.69435C17.9451 0.564803 16.5867 0 15.0003 0C13.4135 0 12.0552 0.564803 10.9256 1.69435C9.83447 2.78614 9.27541 4.09222 9.2383 5.6095H12.2812C12.7472 5.6095 13.1247 5.98702 13.1247 6.45298C13.1247 6.91893 12.7472 7.29646 12.2812 7.29646H9.23119V9.54553H12.2812C12.7472 9.54553 13.1247 9.92306 13.1247 10.389C13.1247 10.855 12.7465 11.2325 12.2806 11.2325Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
)

export default Lecture
