import { call, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import getResponse from './getResponse'
import isNoMessage from '../utils/isNoMessage'
import { POST_PUSH_NOTIFICATION } from '../actions/notifications'
import { POST, GET } from '../utils/methods'
import notificationBasicSetups from '../utils/data/notificationConfig'
import { auth } from '../utils/data/requestHeaders'

function* postNotification({ payload, callback, errorCallback }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      path: '/send_broadcast',
      headers: auth,
      body: payload,
      isPush: true,
    }))

    if (isNoMessage(response)) {
      notification.success({
        ...notificationBasicSetups,
        className: 'notification_antd___succeed',
        message: 'Уведомления отправлено',
        description: `Уведомления получат ${payload.accounts.length} пользователей`,
      })
      yield callback && callback()
    }
  } catch (e) {
    console.log(e.message)
    errorCallback()
    notification.error({
      ...notificationBasicSetups,
      className: 'notification_antd',
      message: 'Ошибка отправки',
      description: 'Не удалось отправить PUSH-уведомление',
    })
  }
}

export default function* notificationsSagas() {
  yield takeEvery(POST_PUSH_NOTIFICATION, postNotification)
}
