import React from 'react'

const TableHeader = ({ rightPaddingWidth, columns = [], flexValues = [], style = [] }) => (
  <div className='card_list__header'>
    {columns.map((column, i) => (
      <div key={`list-header-${i}`} style={{ flex: flexValues[i], ...style[i] }}>{column}</div>
    ))}
    <div style={{ width: `${rightPaddingWidth}px` }} />
  </div>
)

export default TableHeader
