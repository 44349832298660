import React from 'react'

const WaterCloset = ({ className = 'icon__add_white', color = 'white', height = '16', width = '16' }) => (
  <div className={className} >
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.62683 10.3613C5.22151 10.3613 3.26457 8.40436 3.26457 5.99904C3.26457 3.59372 5.22151 1.63678 7.62683 1.63678C10.0322 1.63678 11.9891 3.59372 11.9891 5.99904C11.9891 8.40436 10.0322 10.3613 7.62683 10.3613Z" fill="white" />
      <path d="M7.62684 28.3486C7.25994 28.3486 6.93104 28.1224 6.79966 27.7798L1.0586 14.4103C0.95423 14.138 0.990622 13.8317 1.15587 13.5914C1.32113 13.3511 1.59418 13.2073 1.88578 13.2073H13.3677C13.6593 13.2073 13.9323 13.3511 14.0976 13.5914C14.2628 13.8317 14.2992 14.138 14.1948 14.4103L8.45402 27.7798C8.32264 28.1224 7.99373 28.3486 7.62684 28.3486Z" fill="white" />
      <path d="M22.3731 10.3613C19.9678 10.3613 18.0109 8.40436 18.0109 5.99904C18.0109 3.59372 19.9678 1.63678 22.3731 1.63678C24.7784 1.63678 26.7354 3.59372 26.7354 5.99904C26.7354 8.40436 24.7784 10.3613 22.3731 10.3613Z" fill="white" />
      <path d="M28.1142 28.3486H16.6323C16.3407 28.3486 16.0676 28.2051 15.9024 27.9647C15.7371 27.7244 15.7008 27.4182 15.8051 27.1458L21.546 13.7761C21.6773 13.4335 22.0062 13.2073 22.3731 13.2073C22.7403 13.2073 23.0692 13.4335 23.2003 13.7761L28.9414 27.1458C29.0457 27.4182 29.0094 27.7244 28.8441 27.9647C28.6789 28.2051 28.4058 28.3486 28.1142 28.3486Z" fill="white" />
    </svg>
  </div>
)

export default WaterCloset
