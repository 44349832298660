import React from 'react'

import DisabledInputBlock from '../../UI/dataBlock/DisabledInputBlock'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'

const GroupInfo = ({ group = {} }) => {
  const { global_read, global_write, global_manage, title, description } = group

  const access = (function getAccess() {
    if (global_manage) return 'Администратор'
    if (global_write && global_read) return 'Оператор'
    return 'Посетитель'
  }())

  return (
    <div className='container__left_side__user_group_form'>
      <ScrollTransition loading>
        <div style={{ paddingRight: '22px' }}>
          <DisabledInputBlock title='Название' subtitle={title || 'Не указано'} />
          <DisabledInputBlock title='Описание' subtitle={description || 'Не указано'} />
          <DisabledInputBlock title='Права доступа' subtitle={access || 'Не указано'} />
        </div>
      </ScrollTransition>
    </div>
  )
}

export default GroupInfo
