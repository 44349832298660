import React, { useState } from 'react'
import { Form as FormikForm, withFormik } from 'formik'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { UPDATE_ADVANCED_SETTINS_RESPONSE } from '../../../../../../actions/offices'
import UnderlinedTitle from '../../../../../UI/UnderlinedTitle'
import Button from '../../../../../UI/Button'
import Modal from '../../../../../UI/modals/Modal'
import SettingsItem from './SettingsItem'

import './advancedSettings.scss'

const generateTimeIntervalArray = (from, to, interval, tail = 'мин') => {
  const length = to / interval + 1
  const newArray = new Array(length).fill(null).map((el, idx) => {
    if (idx === 0) return { value: from, title: `${from} ${tail}` }
    return { value: from + idx * interval, title: `${from + idx * interval} ${tail}` }
  })
  return newArray
}

export const DEFAULT_TIME_INTERVAL = generateTimeIntervalArray(0, 60, 5)

const InnerForm = props => {
  const { values, setFieldValue, putAdvancedSettings } = props
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const [typeModal, setTypeModal] = useState()

  const getInfoModalText = type => {
    const correctTimeInterval = type === 'beforeBookTime'
      ? values.timeBeforeBook
      : values.timeAfterBook

    const getTime = () => {
      const bookingHour = moment().startOf('day').add(9, 'hours')
      let correctHour
      let preposition

      switch (type) {
        case 'beforeBookTime':
          correctHour = bookingHour.subtract(correctTimeInterval, 'minutes').format('hh:mm')
          preposition = 'с'
          break
        case 'afterBookTime':
          correctHour = bookingHour.add(correctTimeInterval, 'minutes').format('hh:mm')
          preposition = 'до'
          break
        default: return ''
      }
      return preposition + ' ' + correctHour
    }

    const firstString = 'Сотрудник забронировал рабочее место с 09:00 до 18:00'
    const secondString = `Он сможет подтвердить бронирование QR кодом ${getTime()}`
    const thirdString = `Так как сейчас значение параметра установлено на ${correctTimeInterval} мин`

    return (
      <>
        <span style={{ marginBottom: '1rem' }}>{firstString}</span><br />
        <span style={{ marginBottom: '1rem' }}>{secondString}</span><br />
        <span>{thirdString}</span>
      </>
    )
  }

  const setDefaultSettings = () => {
    setFieldValue('timeBeforeBook', 15)
    setFieldValue('timeAfterBook', 15)
  }

  const onClickInfoHandler = type => {
    setTypeModal(type)
    setIsInfoModalVisible(true)
  }

  const handleSelectChange = (value, type) => {
    setTypeModal(type)
    const field = type === 'beforeBookTime' ? 'timeBeforeBook' : 'timeAfterBook'
    setFieldValue(field, value)
  }

  const onSubmitHandler = e => {
    e.preventDefault()
    putAdvancedSettings(values)
  }

  return (
    <div className='flex_container__column' style={{ paddingRight: '10px', flex: 1 }}>
      <Modal
        start={isInfoModalVisible}
        onClose={() => setIsInfoModalVisible(false)}
      >
        <span className='large_btn__title__center border_bottom'>ПРИМЕР</span>
        <p className='modal-info-center'>{getInfoModalText(typeModal)}</p>
        <div className='button_container__content_right margin_top'>
          <Button styles='rounded-btn_blue' title='Ок' onClick={() => setIsInfoModalVisible(false)} />
        </div>
      </Modal>
      <UnderlinedTitle title='ДОПОЛНИТЕЛЬНЫЕ НАСТРОЙКИ' />
      <FormikForm style={{ flex: 1 }} className='flex_container__column'>
        <SettingsItem
          title='Время для подтверждения бронирования до его начала'
          onClickInfoHandler={onClickInfoHandler}
          handleSelectChange={handleSelectChange}
          value={values.timeBeforeBook}
          type='beforeBookTime'
        />
        <SettingsItem
          title='Время для подтверждения бронирования после его начала'
          onClickInfoHandler={onClickInfoHandler}
          handleSelectChange={handleSelectChange}
          value={values.timeAfterBook}
          type='afterBookTime'
        />
        <div className='button_container__content_right' style={{ alignSelf: 'flex-end' }}>
          <Button
            styles='simple-btn'
            title='По умолчанию'
            onClick={setDefaultSettings}
          />
          <button type='submit' className='rounded-btn_blue' onClick={onSubmitHandler}>
            Сохранить
          </button>
        </div>
      </FormikForm>
    </div>
  )
}

const AdvancedSettings = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ isNew, advancedSettings }) => {
    return {
      timeBeforeBook: advancedSettings.timeBeforeBook || 15,
      timeAfterBook: advancedSettings.timeAfterBook || 15,
    }
  },
})(InnerForm)

const mapStateToProps = state => ({
  advancedSettings: state.offices_newDesign.office.advancedSettings || [],
})

const mapDispatchToProps = dispatch => ({
  putAdvancedSettings: settings => dispatch({ type: UPDATE_ADVANCED_SETTINS_RESPONSE, settings }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvancedSettings))
