import React from 'react'
import Icon from './Icon'

const BigCardTitle = ({ icon, title, subtitle }) => (
  <div className='card_title'>
    <Icon name={icon} />
    <div className='flex_container'>
      {[title, subtitle].map((text, i) =>
        <span key={`key-title-${i}`} className='large_btn__title'>{text?.toUpperCase()}</span>)}
    </div>
  </div>
)

export default BigCardTitle
