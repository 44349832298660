import React from 'react'

const MarkerHover = (props) => {
  const {
    x: xPercent,
    y: yPercent,
    mapState,
    currentRadius,
    description,
    title,
    zoneTitle,
  } = props;

  const realMapSize = { width: mapState.image.width * mapState.scale, height: mapState.image.height * mapState.scale }

  const top = realMapSize.height * yPercent / 100 + mapState.y;
  const left = realMapSize.width * xPercent / 100 + mapState.x;

  return (
    <div className='marker__tooltip marker-hover' style={{
      top: top + currentRadius * 1.8 * mapState.scale,
      left: left,
      minWidth: '20%'
    }}>
      <span className='title'>{title}</span>
      {description && <span className='subtitle'>{description}</span>}
      <span className='subtitle'>{zoneTitle}</span>
    </div>
  )
}

export default MarkerHover