const formPath = (base, params) => {
  if (!params?.length) return base

  const paramsStr = params.reduce((path, p) => {
    const key = Object.keys(p)[0]
    const value = p[key]

    if (value == null || value === '') return path
    const getParam = `${key}=${value}`
    return path ? `${path}&${getParam}` : getParam
  }, '')

  return paramsStr ? `${base}?${paramsStr}` : base
}

export const createPathWithFilters = (root, params) => {
  const paramsStr = params
    .reduce((acc, [key, value]) => Array.isArray(value)
      ? [...acc, `${key}=${value.join(`&${key}=`)}`]
      : [...acc, `${key}=${value}`], [])
    .join('&')

  return `${root}?${paramsStr}`
}

export default formPath
