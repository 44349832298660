import { combineReducers } from 'redux'
import account from './account'
import files from './files'
import offices from './offices'
import users from './users'
import booking from './booking'
import offices_newDesign from './offices_newDesign'

export default combineReducers({
  files,
  users,
  account,
  offices,
  booking,
  offices_newDesign,
})
