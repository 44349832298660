import React from 'react'
import { Tooltip } from 'antd'
import { withRouter } from 'react-router-dom'

import Icon from '../../UI/Icon'
import stopPropagation from '../../../utils/stopPropagation'

const CardListItem = ({
  id,
  children,
  onDelete,
  onCardClick,
  onEdit,
  small,
  style,
  confirm,
  confirmDeletion,
  extraClassName,
  extraIcon,
}) => {
  const getListItemStyle = () => `card_list__item${small ? '__small' : ''} ${extraClassName || ''}`

  const renderBtn = (title, icon, callback) => (
    <Tooltip title={title} mouseEnterDelay={0.6}>
      <div
        className='icon__wrapper__hover_enabled full_height'
        onClick={e => stopPropagation(callback, e)}
      >
        <Icon name={icon} />
      </div>
    </Tooltip>
  )

  return (
    <>
      <div
        id={id}
        style={style}
        className={getListItemStyle()}
        onClick={e => onCardClick ? stopPropagation(onCardClick, e) : null}
      >
        { children }
        {(onDelete || confirmDeletion)
          && renderBtn('Удалить', 'icon__delete', confirm ? confirmDeletion : onDelete)}
        {onEdit && renderBtn('Редактировать', 'icon__edit', onEdit)}
        {extraIcon}
      </div>
    </>
  )
}

export default withRouter(CardListItem)
