import React from 'react'
import { Tooltip } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getAvatarStyle } from '../../../utils/getStyle/getImageStyle'
import stopPropagation from '../../../utils/stopPropagation'
import IconEmail from '../../UI/icons/Email'
import IconBooking from '../../UI/icons/Booking'

const UserCardListItem = ({
  history,
  userPhoto,
  user,
  index,
  onDelete,
  onItemClick,
  getItemActivityClass,
  onSendEmailClick,
  openGroupsInfoModal,
}) => {
  const { id, name, description } = user

  const redirectToBookingPage = e => {
    e.stopPropagation()
    history.push(`/users/${user.id}/booking`)
  }

  return (
    <div
      className={getItemActivityClass()}
      onClick={e => stopPropagation(() => onItemClick(user), e)}
    >
      <span className='card_list__item_id'>{`${index + 1}.`}</span>
      <span className='card_list__item_avatar'>
        <div className='card_list__avatar' style={getAvatarStyle(userPhoto)} />
      </span>
      <span className='card_list__item_user_name'>
        {/* Test version */}
        {user?.phone_number || <div className='main_text__inactive text__wrapper'>Не указано</div>}
        {/*{name === 'Имя не указано'*/}
        {/*  ? <div className='main_text__inactive text__wrapper'>{name}</div>*/}
        {/*  : name}*/}
      </span>
      <span className='card_list__item__user_description' style={{ border: 0 }}>
        {description
          ? <span className='secondary_text'>{description}</span>
          : <div className='secondary_text__inactive text__wrapper'>Без описания</div>}
      </span>
      <Tooltip title='Группы доступа' mouseEnterDelay={0.6}>
        <div
          className='icon__wrapper__hover_enabled full_height'
          onClick={e => stopPropagation(openGroupsInfoModal, e)}
        >
          <div className='icon__groups' style={{ marginRight: 0, backgroundSize: 'contain' }} />
        </div>
      </Tooltip>
      {onSendEmailClick && user.email && (
        <Tooltip title='Отправить сообщение' mouseEnterDelay={0.6}>
          <div
            className='icon__wrapper__hover_enabled full_height'
            onClick={e => stopPropagation(() => onSendEmailClick({ id, name }), e)}
          >
            <IconEmail />
          </div>
        </Tooltip>
      )}
      <Tooltip title='Бронирования' mouseEnterDelay={0.6}>
        <div
          className='icon__wrapper__hover_enabled full_height'
          onClick={redirectToBookingPage}
        >
          <IconBooking />
        </div>
      </Tooltip>
      {onDelete && (
        <Tooltip title='Удалить' mouseEnterDelay={0.6}>
          <div
            className='icon__wrapper__hover_enabled full_height'
            onClick={e => stopPropagation(() => onDelete({ id, name }), e)}
          >
            <div className='icon__delete' />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

const mapStateToProps = ({ users }, { user }) => ({
  userPhoto: users?.list?.find(u => u.id === user.id)?.photo,
})

export default withRouter(connect(mapStateToProps)(UserCardListItem))
