import React, { useEffect } from 'react'
import KioskForm from './forms/Kiosk/index'
import NewUserForm from './forms/User/NewUserForm'
import FilterItem from '../helpers/FilterItem'

const LeftSidePanel = ({ accountType, setAccountType, getOffices }) => {
  const setFilterTabStyle = radioItem => {
    const defaultClass = 'filter__tab'
    return (radioItem === accountType) ? `${defaultClass}__active` : defaultClass
  }

  const accountTypeSwitch = () => {
    const tabs = [{ title: 'Пользователь', id: 'user' }, { title: 'Киоск', id: 'kiosk' }]
    return (
      <div className='filter__tabs__account_type'>
        {tabs.map(({ title, id }) => (
          <FilterItem
            key={`account-type-switch-${id}`}
            className={setFilterTabStyle(title)}
            htmlFor={id}
            name='accountType'
            value={title}
            id={id}
            onChange={e => setAccountType(e.target.value)}
          >
            {title}
          </FilterItem>
        ))}
      </div>
    )
  }

  useEffect(() => { accountType === 'Киоск' && getOffices({ all: true }) }, [accountType])

  return (
    <div className='container__left_side__new_user_form'>
      {accountTypeSwitch()}
      {accountType === 'Пользователь' ? <NewUserForm /> : <KioskForm isEditMode={false} />}
    </div>
  )
}

export default LeftSidePanel
