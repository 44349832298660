import React, { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import * as easings from 'd3-ease'

const AnimatedHeight = ({ children, condition, config, maxHeight = 1000 }) => {
  const [isVisible, setIsVisible] = useState(condition)
  useEffect(() => {
    if (condition && !isVisible) setIsVisible(condition)
  }, [condition, isVisible])

  const props = useSpring({
    maxHeight: condition ? maxHeight : 0,
    opacity: condition ? 1 : 0,
    display: isVisible ? 'block' : 'none',
    overflow: 'hidden',
    config: config
      ? { ...config }
      : { duration: 300, easing: easings.easeQuad },
  })

  return (
    <animated.div style={props}>{children}</animated.div>
  )
}

export default AnimatedHeight
