import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Input, message } from 'antd'

import IconSave from '../../../../../UI/icons/Save'
import IconEdit from '../../../../../UI/icons/Edit'
import IconMap from '../../../../../UI/icons/Map'
import IconDelete from '../../../../../UI/icons/Delete'
import CardListItem from '../../../../../helpers/lists/CardListItem'
import AddImageBtn from '../../helpers/AddImageBtn'
import Button from '../../../../../UI/Button'
import { DELETE_MAP } from '../../../../../../actions/offices'
import ConfirmationModal from '../../../../../UI/modals/ConfirmationModal'

const FloorListItem = ({
  floor = {},
  isNew,
  onDelete,
  redirectToMap,
  onMapUpload,
  onSave,
  onCancel,
}) => {
  const [floorTitle, setFloorTitle] = useState('')
  const [floorMap, setFloorMap] = useState(null)
  const [error, setError] = useState(false)
  const [isEdit, setIsEdit] = useState(isNew)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const dispatch = useDispatch()

  const mapContainerStyle = { flex: 1, marginRight: isEdit && '64px' }
  const inputStyle = { border: error && '1px solid #CD4141' }

  const isFloorTitleValid = (title = '') => {
    const titleLength = title.length
    if (titleLength && titleLength <= 30) return true

    message.error(`Введите название этажа (до 30 символов). Введено: ${titleLength} символов`)
    return false
  }

  const handleSave = () => {
    const formattedTitle = floorTitle.trim()

    if (formattedTitle === floor.title) {
      setIsEdit(false)
      return
    }

    if (isFloorTitleValid(formattedTitle)) {
      onSave({ title: formattedTitle }, () => setIsEdit(val => !val))
      return
    }

    !error && setError(true)
  }

  const handleInputChange = ({ target }) => {
    const inputValue = target.value
    setFloorTitle(inputValue)
    setError(!inputValue)
  }

  const renderInput = () => (
    <Input
      autoFocus
      className='floors-list__item-input'
      id={floor.id}
      name='title'
      value={floorTitle}
      placeholder='Название этажа'
      onChange={handleInputChange}
      style={inputStyle}
    />
  )

  const renderDeleteIcon = () => (
    <Button
      onClick={() => dispatch({
        type: DELETE_MAP,
        floor: { floor: floor.id },
        callback: () => setFloorMap(null),
      })}
      styles='btn-remove-image'
      renderIcon={() =>
        <IconDelete height={16} width={16} className='btn-remove-image__icon_red' />}
    />
  )

  useEffect(() => {
    if (isNew) return

    setFloorTitle(floor.title)
    setFloorMap(floor.floor_map?.image)
  }, [floor])

  const clickOutsideNewItemHandler = ({ target }) => {
    const isClickOnBtn = target.closest('.btn-group__item')
    const isClickOnSidePanel = target.closest('.side_panel')
    if (isClickOnBtn || isClickOnSidePanel) return

    const isClickOutsideItem = !target.closest('.floors-list__item')
    if (isClickOutsideItem) onCancel()
  }

  useEffect(() => {
    if (isNew) {
      document.addEventListener('mousedown', clickOutsideNewItemHandler)
      return () => document.removeEventListener('mousedown', clickOutsideNewItemHandler)
    }
  }, [])

  return (
    <>
      <CardListItem extraClassName='floors-list__item'>
        <div className='floors-list__item-title'>
          {isEdit ? renderInput() : floor.title}
        </div>

        {isNew
          ? (
            <span className='secondary_text__inactive' style={mapContainerStyle}>
              Сохраните этаж перед добавлением карты
            </span>
          )
          : (
            <AddImageBtn
              image={floorMap?.thumb || floorMap?.path || null}
              onUpload={onMapUpload}
              style={mapContainerStyle}
              extraIcon={isEdit && renderDeleteIcon()}
            />
          )}

        {isEdit
          ? (
            <IconSave className='floors-list__icon-wrapper' onClick={handleSave} />
          )
          : (
            <>
              <IconDelete
                onClick={() => setIsDeleteModalVisible(true)}
                className='floors-list__item-action'
              />
              <IconMap
                onClick={() => redirectToMap(floor.id)}
                className='floors-list__item-action'
              />
              <IconEdit
                width='18'
                height='18'
                className='floors-list__item-action'
                onClick={() => setIsEdit(true)}
              />
            </>
          )}
      </CardListItem>
      <ConfirmationModal
        start={isDeleteModalVisible}
        text={`Удалить этаж '${floor.title}'?`}
        onConfirm={onDelete}
        onClose={() => setIsDeleteModalVisible(false)}
      />
    </>
  )
}

export default FloorListItem
