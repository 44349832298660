import React from 'react'
import { Field as FormikField } from 'formik'
import { Checkbox, Form } from 'antd'

const CheckBox = ({
  name,
  value,
  style,
  title,
  onChange,
  setFieldTouched,
}) => (
  <Form.Item>
    <FormikField
      render={() => (
        <Checkbox
          name={name}
          style={style}
          checked={value}
          onBlur={() => setFieldTouched(name, true)}
          onChange={({ target }) => onChange(name, target.checked)}
        >
          {title}
        </Checkbox>
      )}
    />
  </Form.Item>
)

export default CheckBox
