import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import FloorsInfo from './forms/Floors/FloorsInfo'
import GeneralInfo from './forms/GeneralInfo'
import LargeSpin from '../../../UI/Spin/LargeSpin'
import RoomTypes from './forms/RoomTypes/RoomTypes'
import SideNavigationPanel from './SideNavigationPanel'
import TagsInfo from './forms/Tags/TagsInfo'
import ZonesInfo from './forms/Zones/ZonesInfo'
import AdvancedSettings from './forms/AdvancedSettings/AdvancedSettings'
import {
  GET_OFFICE_NEW_DESIGN,
  CLEAR_OFFICE_NEW_DESIGN, GET_LICENSES, GET_ROOM_TYPES, GET_OFFICE_TAGS,
} from '../../../../actions/offices'
import { GET_ACCESS_GROUPS } from '../../../../actions/users'

const SIDE_PANEL_BUTTONS = [
  { title: 'Информация о БЦ', name: 'general' },
  { title: 'Этажи', name: 'floors' },
  { title: 'Зоны', name: 'zones' },
  { title: 'Теги', name: 'tags' },
  { title: 'Типы помещений', name: 'room-types' },
  { title: 'Доп настройки', name: 'advanced-settings' },
]

const renderActiveForm = (name, isNew, office) => {
  switch (name) {
    case 'general':
      return <GeneralInfo isNew={isNew} office={office} />
    case 'floors':
      return <FloorsInfo isNew={isNew} office={office} />
    case 'zones':
      return <ZonesInfo isNew={isNew} office={office} />
    case 'tags':
      return <TagsInfo isNew={isNew} office={office} />
    case 'room-types':
      return <RoomTypes isNew={isNew} office={office} />
    case 'advanced-settings':
      return <AdvancedSettings />
  }
}

const OfficeMainInfoEdit = ({
  history,
  isNew,
  match,
  getOffice,
  office,
  loading,
  clearOffice,
  getFreeLicenses,
  getRoomTypes,
  getGroupsList,
  getTags,
}) => {
  const [activeForm, setActiveForm] = useState('general')
  const goBack = () => history.push('/offices')

  useEffect(() => {
    const officeId = match.params.id

    if (!isNew) {
      getOffice(officeId)
      getTags(officeId)
      getRoomTypes(officeId)
    }

    getFreeLicenses()
    getGroupsList()

    return clearOffice
  }, [])

  return (
    <>
      <SideNavigationPanel
        navItems={SIDE_PANEL_BUTTONS}
        backBtnTitle='Назад к списку БЦ'
        onBackBtnClick={goBack}
        activeItem={activeForm}
        isSaved={!isNew}
        setActiveItem={setActiveForm}
      />
      <div className='office_main_info__card'>
        {loading ? <LargeSpin /> : renderActiveForm(activeForm, isNew, office)}
      </div>
    </>
  )
}

const mapStateToProps = ({ offices_newDesign: offices, users = {} }) => {
  const { office = {} } = offices
  const tagsLoading = office.tags?.loading
  const userLoading = users.groups?.loading

  return ({ office, loading: office.loading || userLoading || tagsLoading })
}

const mapDispatchToProps = dispatch => ({
  getOffice: (officeId, callback) =>
    dispatch({ type: GET_OFFICE_NEW_DESIGN, officeId, expanded: true, callback }),
  getFreeLicenses: () => dispatch({ type: GET_LICENSES }),
  getTags: officeId => dispatch({ type: GET_OFFICE_TAGS, officeId }),
  clearOffice: () => dispatch({ type: CLEAR_OFFICE_NEW_DESIGN }),
  getRoomTypes: officeId => dispatch({ type: GET_ROOM_TYPES, officeId }),
  getGroupsList: () => dispatch({ type: GET_ACCESS_GROUPS }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OfficeMainInfoEdit))
