import React, { useRef } from 'react'
import Button from '../../../../UI/Button'
import IconPlus from '../../../../UI/icons/Plus'

const AddImageBtn = ({ style, image, onUpload, extraIcon }) => {
  const input = useRef()

  return (
    <div className='flex_container' style={style}>
      {image
        ? (
          <>
            <div className='icon_preview' style={{ backgroundImage: `url(${image})` }} />
            {extraIcon}
          </>
        )
        : (
          <>
            <input
              type='file'
              ref={input}
              className='input__file_uploader'
              accept='image/*'
              onClick={e => e.target.value = null}
              onChange={onUpload}
            />
            <Button
              onClick={() => input.current.click()}
              styles='btn-add-image'
              renderIcon={() =>
                <IconPlus height={20} width={20} className='btn-add-image__icon_blue' />}
            />
          </>
        )}
    </div>
  )
}

export default AddImageBtn
