import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'

import InfoIcon from '../UI/icons/InfoBig'
import EditIcon from '../UI/icons/Edit'
import CrossIcon from '../UI/icons/Cross'
import DeleteIcon from '../UI/icons/Delete'
import ToolbarInfo from './ToolbarInfo'
import EditRoomModalContext from './editRoomModalContext'
import { DELETE_MARKER, DELETE_ROOM_FROM_MAP, ADD_MARKER, REFRESH_ROOMS } from '../../actions/offices'
import { SET_MARKER_ON_CLICK_ATTR } from './helpers/useReducerForMapComponent.js'

const MarkerClick = (props) => {
  const {
    pointer,
    mapState,
    currentRadius,
    setConfirmModal,
    dispatchWithoutRedux,
  } = props;
  const [infoTabVisibility, setInfoTabVisibility] = useState(false)

  const roomEditModalManipulation = useContext(EditRoomModalContext)
  const dispatch = useDispatch()

  const { x: xPercent, y: yPercent } = pointer

  const realMapSize = { width: mapState.image.width * mapState.scale, height: mapState.image.height * mapState.scale }
  const top = realMapSize.height * yPercent / 100 + mapState.y;
  const left = realMapSize.width * xPercent / 100 + mapState.x;

  const deleteMarker = () => {
    dispatch({
      type: DELETE_MARKER,
      floorId: pointer.floorId,
      room: { room: pointer.roomId },
    })
    dispatch({ type: REFRESH_ROOMS, withLoader: false })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
  }

  const deleteRoom = () => {
    dispatch({ type: DELETE_ROOM_FROM_MAP, roomId: pointer.roomId })
    dispatch({ type: REFRESH_ROOMS, withLoader: false })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
  }

  const onPressDelete = () => {
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } });
    setConfirmModal(prev => ({ ...prev, isVisible: true, type: 'removeRoom', callback: deleteRoom }))
  }

  return (
    <>
      {infoTabVisibility
        ? (
          <ToolbarInfo
            marker={pointer}
            roomEditModalManipulation={roomEditModalManipulation}
            setInfoTabVisibility={setInfoTabVisibility}
            top={top}
            left={left}
            currentRadius={currentRadius}
            mapState={mapState}
          />
        )
        : (
          <div
            className={`marker__toolbar marker-click ${infoTabVisibility ? 'wide' : ''}`}
            style={{ top: top - currentRadius * mapState.scale, left: left, zIndex: 50, }}
          >
            <InfoIcon
              onClick={() => setInfoTabVisibility(true)}
              className='tooltip'
              tooltipText='Показать информацию'
            />
            <EditIcon
              className='tooltip'
              tooltipText='Редактировать информацию'
              width='20'
              height='20'
              color='#0079C1'
              onClick={() => {
                roomEditModalManipulation().set(pointer.roomId);
                dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
              }}
            />
            <CrossIcon
              className='tooltip'
              tooltipText='Удалить маркер'
              style={{ marginTop: '6px' }}
              onClick={deleteMarker}
            />
            <DeleteIcon
              className='tooltip'
              tooltipText='Удалить помещение'
              width='18'
              height='18'
              onClick={onPressDelete}
            />
          </div>
        )
      }
    </>
  )
}

export default MarkerClick