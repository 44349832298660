export const SET_BOOKING_LOADING = 'SET_BOOKING_LOADING'

export const GET_BOOKINGS = 'GET_BOOKINGS'
export const GET_BOOKINGS_SUCCEED = 'GET_BOOKINGS_SUCCEED'

export const DELETE_BOOKING = 'DELETE_BOOKING'
export const DELETE_BOOKING_SUCCEED = 'DELETE_BOOKING_SUCCEED'

export const POST_FAST_BOOKING = 'POST_FAST_BOOKING'
export const POST_FAST_BOOKING_SUCCEED = 'POST_FAST_BOOKING_SUCCEED'

export const POST_BOOKING = 'POST_BOOKING'
export const POST_BOOKING_SUCCEED = 'POST_BOOKING_SUCCEED'

export const GET_USER_BOOKING_HISTORY = 'GET_USER_BOOKING_HISTORY'
export const GET_USER_BOOKING_HISTORY_SUCCEED = 'GET_USER_BOOKING_HISTORY_SUCCEED'
