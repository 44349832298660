import React from 'react'

export default ({ onClick, className, tooltipText, width = '20', height = '20', color = '#0079C1' }) => (
  <div className={className} onClick={onClick} data-tooltip={tooltipText}>
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M9.56736 0.25C9.75981 -0.0833334 10.2409 -0.0833333 10.4334 0.25L12.1654 3.25C12.3579 3.58333 12.1173 4 11.7324 4H10.7503V16H11.7324C12.1173 16 12.3579 16.4167 12.1654 16.75L10.4334 19.75C10.2409 20.0833 9.75981 20.0833 9.56736 19.75L7.83531 16.75C7.64286 16.4167 7.88342 16 8.26832 16H9.25034V4H8.26832C7.88342 4 7.64286 3.58333 7.83531 3.25L9.56736 0.25Z' fill={color} />
      <path fillRule='evenodd' clipRule='evenodd' d='M0.250488 10.4331C-0.0828452 10.2407 -0.0828451 9.75955 0.250488 9.5671L3.25049 7.83505C3.58382 7.6426 4.00049 7.88316 4.00049 8.26806L4.00049 9.25015L16.0005 9.25015L16.0005 8.26806C16.0005 7.88316 16.4172 7.6426 16.7505 7.83505L19.7505 9.5671C20.0838 9.75955 20.0838 10.2407 19.7505 10.4331L16.7505 12.1652C16.4172 12.3576 16.0005 12.1171 16.0005 11.7322L16.0005 10.7501L4.00049 10.7501L4.00049 11.7322C4.00049 12.1171 3.58382 12.3576 3.25049 12.1652L0.250488 10.4331Z' fill={color} />
    </svg>
  </div>
)
